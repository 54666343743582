import styled from 'styled-components';
import { CounterCircle } from '../counter/counter.style';
import { Colors, FontSizes, media } from '../../constants/theme';

interface Props {
  fontSize?: number;
  productName?: boolean;
  quantity?: boolean;
  price?: boolean;
  onCheckoutPage?: boolean;
}

const { secondaryColor, primaryGrey, blackLight, primaryColor } = Colors;
const { mobile } = media;
const { primaryFontSize } = FontSizes;

export const CartItemCardContainer = styled.div`
  margin: 20px 0;
  width: 100%;
  border-top: ${({ onCheckoutPage }: Props) => (onCheckoutPage ? 'none' : '1px solid #e4ebf4')};
  ${mobile} {
    width: 100%;

    p {
      font-size: 14px;
    }
  }
`;

export const CartItemDetailsContainer = styled.div`
  width: 80%;
  display: flex;
  flex-flow: column;
  margin-left: 10px;
  text-align: initial;
`;

export const CartItemDetails = styled.div`
  display: flex;
  align-items: center;

  ${mobile} {
    flex-direction: column;
  }
`;

export const CartItemPrice = styled.div`
  display: flex;
  width: 105px;
  flex-direction: column;
  margin: 0 10px;
  justify-content: center;
  align-items: start;

  ${mobile} {
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
  }
`;

export const CartItemTitle = styled.div`
  width: 70%;

  ${mobile} {
    display: flex;
    align-items: center;

    .pricing {
      text-align: start;
      font-size: 1rem !important;
    }
  }
`;

export const CartText = styled.p`
  font-size: ${({ fontSize }: Props): string => `${fontSize}px`};
  color: ${primaryGrey};
  font-weight: 500;

  ${({ productName }: Props) =>
    productName &&
    `
    margin-bottom: 5px;
  `}

  ${({ quantity }: Props) =>
    quantity &&
    `
    margin: 0;
  `}

  ${({ price }: Props) =>
    price &&
    `
    margin-top: 5px;
    color: ${secondaryColor};
    font-weight: 700;
  `}

  ${mobile} {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 10ch;
    white-space: nowrap;
  }
`;

export const CartItemImage = styled.img`
  width: 20%;
  margin: 0;
  margin-left: 15px;
  max-width: 275px;
  align-self: center;
  object-fit: cover;
`;
export const DeleteContainer = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;

  p {
    color: #ff0000;
    font-size: 14px;
    padding-left: 10px;
  }

  :hover {
    opacity: 0.6;
  }

  ${mobile} {
    justify-content: flex-start;
    margin: 0 1rem;
    p {
      margin: 0;
    }
  }
`;

export const BinBadge = styled(CounterCircle)`
  background-color: ${secondaryColor};
  display: flex;
  align-items: center;
  cursor: pointer;

  .bin {
    height: 20px;
    width: auto;
  }
`;
export const Top = styled.div`
  display: flex;
  align-items: center;
  height: 100px;
  img {
    height: 80%;
    width: auto;
  }
`;
export const Bottom = styled.div`
  height: 20px;
  p {
    font-size: 13px;
    color: ${blackLight};
    span {
      color: ${primaryColor};
    }
  }
  ${mobile} {
    p {
      font-size: 12px;
    }
  }
`;
export const CustomVariation = styled.p`
  font-size: 15px;
  color: #4f4f4f;
  span {
    font-weight: 600;
  }
`;
export const VariationContainer = styled.div`
  display: flex;
`;
