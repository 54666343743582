import React from 'react';
import { Flex, Span } from '../../../GlobalStyles/CustomizableGlobal.style';
import { Container, ImageBox, InfoCard } from './ConnectAndSell.style';
import CheerfulLady from '../../../img/cheerful-lady.png';
import LadiesWithPhone from '../../../img/ladies-with-phone.png';
import Bargain from '../../../img/bargain.png';
import ProductCard from '../../../img/product-card.png';
import GucciProductCard from '../../../img/gucci-product-card.png';
import { Link } from 'react-router-dom';
import Sync from '../../../img/landing-shop-icon.svg';
import Pickup from '../../../img/landing-analytics-icon.svg';
import BargainIcon from '../../../img/landing-file-icon.svg';
import Custom from '../../../img/landing-graph-icon.svg';

const ConnectAndSell = () => {
  const array = [
    {
      icon: Sync,
      title: ' Bargain Products',
      content:
        'The customer should have the ability to negotiate with the merchant for products and complete the checkout process smoothly without any hassle.'
    },
    {
      icon: Pickup,
      title: 'Chat Seller Online',
      content:
        'When customers have queries related to particular products, they ought to have the capability to engage in a conversation with the seller through chatting.'
    },
    {
      icon: BargainIcon,
      title: 'Fast Pick Up & Delivery Service',
      content: 'Our Fast Pick Up & Delivery Service is available to ensure timely delivery of your products.'
    },
    {
      icon: Custom,
      title: 'Request For Delivery Location',
      content: "If your location is not within the merchant's delivery zone, you can request a delivery to a different address."
    }
  ];
  return (
    <Container>
      <Flex
        className="header"
        alignItems="center"
        justifyContent="center"
        gap="1rem"
        direction="column"
        margin="8rem 0 3rem 0"
        background="transparent"
      >
        <h1>Connect & Sell on Timart Shop</h1>
        <p>Download and install the desktop app on your Windows and Mac Computers for easy sales.</p>
      </Flex>
      <Flex
        className="image-container"
        gap="3rem"
        margin="5rem auto 1rem auto"
        justifyContent="space-between"
        width="60%"
        background="transparent"
      >
        <ImageBox>
          <img src={CheerfulLady} alt="" />
        </ImageBox>
        <ImageBox className="second">
          <img src={LadiesWithPhone} alt="" />
        </ImageBox>
        <img className="bargain" src={Bargain} alt="" />
        <img className="product" src={ProductCard} alt="" />
        <img className="gucci" src={GucciProductCard} alt="" />
      </Flex>
      <Flex className="buttonContainer" alignItems="center" justifyContent="space-between" margin="0 auto 2rem auto" width="50%" gap="3rem">
        <a className="download" href="https://play.google.com/store/apps/details?id=com.quicktelsolution.timart_shop">
          Download App
        </a>
        <a className="sell" href="https://gettimart.com/download">
          Start Selling
        </a>
      </Flex>
      <Flex alignItems="center" justifyContent="space-evenly" margin="5rem auto 4rem auto" background="transparent" flexFlow="row wrap">
        {array.map((entry, i) => {
          return (
            <InfoCard key={i}>
              <img src={entry.icon} alt="" />
              <span>{entry.title}</span>
              <p>{entry.content}</p>
            </InfoCard>
          );
        })}
      </Flex>
      {/* <Flex
        className="word-flex-cont"
        alignItems="flex-start"
        justifyContent="space-between"
        margin=" 2rem 5rem"
        background="transparent"
        flexFlow="row wrap"
      >
        <Flex
          id="words"
          alignItems="flex-start"
          justifyContent="space-between"
          direction="column"
          width="40%"
          gap="2rem"
          background="transparent"
        >
          <Span color="black" fontSize="2rem" fontWeight="600">
            Unlimited Possibilities with the power of your online store
          </Span>
          <Span fontSize="1.1rem" color="#30415A">
            Timart Business App provides you with ease of managing your business, improving sales, tracking inventories and satisfying your
            customers all in one app.
          </Span>

          <Link className="sell" to="/">
            Get Started
          </Link>
        </Flex>
        <Flex
          id="flash-words"
          alignItems="flex-start"
          justifyContent="space-between"
          margin="4rem 0 0 10rem"
          gap="1.5rem"
          direction="column"
          width="60%"
          background="transparent"
        >
          <h3 className="connect">Connect</h3>
          <h3 className="sync">Synchronize</h3>
          <h3 className="start-selling">Start Selling.</h3>
        </Flex>
      </Flex> */}
    </Container>
  );
};

export default ConnectAndSell;
