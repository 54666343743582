import React, { FunctionComponent, useState } from 'react';
import { Container, DropdownBtn, IconContainer, Icon, Selected, Options } from './style';
import { AiOutlineCaretDown } from 'react-icons/ai';

interface DropdownProps {
  width?: string;
  height?: string;
  borderRadius?: string;
  containerColor?: string;
  icon?: string;
  fontSize?: string;
  selected?: any;
  setValue?: (value: any) => void;
  options: (string | number)[];
  color?: string;
  boxShadow?: string;
  margin?: string;
  padding?: string;
  placeholder?: string;
  overflowY?: string;
  border?: string;
  disabled?: boolean;
  optionsWidth?: string;
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>> | any;
}
const CustomDropdown: FunctionComponent<DropdownProps> = ({
  width,
  optionsWidth,
  height,
  containerColor,
  icon,
  fontSize,
  selected,
  setValue,
  options,
  borderRadius,
  color,
  boxShadow,
  margin,
  padding,
  placeholder,
  overflowY,
  disabled,
  border,
  setOpenModal
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  return (
    <>
      <Container
        containerColor={containerColor}
        width={width}
        color={color}
        height={height}
        fontSize={fontSize}
        boxShadow={boxShadow}
        margin={margin}
        border={border}
      >
        <DropdownBtn
          disabled={disabled}
          containerColor={containerColor}
          placeholder={placeholder}
          onClick={(e) => {
            e.preventDefault();
            setShowDropdown(!showDropdown);
          }}
          fontSize={fontSize}
          padding={padding}
        >
          {icon && (
            <Icon>
              <img src={icon} alt="" />
            </Icon>
          )}
          <Selected color={color} style={{ opacity: `${selected < 0 ? 0.6 : 1}` }}>
            {selected < 0 ? placeholder : options[selected]}
          </Selected>
          <IconContainer>
            <AiOutlineCaretDown color="black" />
          </IconContainer>
        </DropdownBtn>
        {showDropdown && (
          <Options
            height={height}
            width={optionsWidth}
            optionsWidth={optionsWidth}
            containerColor={containerColor}
            color={color}
            borderRadius={borderRadius}
            boxShadow={boxShadow}
            fontSize={fontSize}
            overflowY={overflowY}
          >
            {options.map((value, index) => {
              return (
                <button
                  className="select-button"
                  onClick={() => {
                    setValue!(index);
                    setShowDropdown(false);
                    setOpenModal && setOpenModal(true);
                  }}
                  key={index}
                >
                  {value}
                </button>
              );
            })}
          </Options>
        )}
        {showDropdown && <div className="closeDiv" onClick={() => setShowDropdown(false)}></div>}
      </Container>
    </>
  );
};

export default CustomDropdown;
