import { EXPAND_STOCK_CARD, COLLAPSE_STOCK_CARD } from "../actionTypes";
import { ActionProps } from "../../interfaces/actions.interface";
import { StockCardState } from "../../interfaces/state.interface";
import StockCardInitalState from "../initialStates/stockCardInitialState";

export default function stockCardFunctions(state = StockCardInitalState, action: ActionProps): StockCardState {
  switch (action.type) {
    case EXPAND_STOCK_CARD: {
      return {
        ...state,
        stockCardOpen: true,
        stockCardId: action.payload,
      };
    }

    case COLLAPSE_STOCK_CARD: {
      return {
        ...state,
        stockCardOpen: false,
        stockCardId: action.payload,
      };
    }

    default:
      return state;
  }
}
