import { createStore } from "redux";
import rootReducer from "./reducers/index";

export default function configureStore() {
  const store = createStore(
    rootReducer /* preloadedState, */,
    (globalThis as any).__REDUX_DEVTOOLS_EXTENSION__ && (globalThis as any).__REDUX_DEVTOOLS_EXTENSION__()
  );
  return store;
}
