import styled, { keyframes } from 'styled-components';
import { Colors, media } from '../../constants/theme';

interface Props {
  showAsShopModal?: boolean;
}

const { primaryColor, white } = Colors;
const { mobile } = media;

const slide = keyframes`
  0% {transform: translateX(900px);}
  100% {transform: translateX(0);}
`;

export const ModalContainer = styled.div`
  position: fixed;
  background-attachment: fixed;
  background-color: ${white};
  height: 100%;
  width: 422px;
  right: 0;
  top: 0;
  overflow: auto;
  max-width: 100%;
  box-shadow: -8px 0px 30px rgba(24, 88, 177, 0.25);
  z-index: 12;
  animation: ${slide} 0.9s forwards;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: ${primaryColor};
  }
  ${mobile} {
    width: 100%;
    height: 100%;
  }
  ${(props: Props) =>
    props.showAsShopModal &&
    `
          width: 50%;
          ${mobile} {
            width: 100%;
          }
      `}
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(24, 88, 177, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  ${mobile} {
    z-index: 7;
  }
`;

export const ExitButton = styled.i`
  display: none;

  ${mobile} {
    display: block;
    color: ${primaryColor};
    font-size: 20px;
    position: absolute;
    top: 38px;
    right: 20px;
    padding: 10;
    z-index: 11;
    cursor: pointer;
  }
`;
