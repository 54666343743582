import styled, { keyframes } from 'styled-components';
import { media, Colors, FontSizes } from '../../../constants/theme';
import Rectangle from '../../../img/rectangle.svg';

const { mobile, tablet, smallScreenLaptop } = media;
const { white, primaryColor, black } = Colors;

const upDown = keyframes`
   0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
`;
const rightLeft = keyframes`
   0% {
    transform: translateY(0);
  }
  50% {
    transform: translateX(-60px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  background-image: url(${Rectangle});
  background-size: cover;
  position: relative;
  transition: all ease-in-out 0.3s;
  margin-top: 3rem;

  .download,
  .sell {
    display: flex;
    border-radius: 10px;
    padding: 4px 20px;
    height: 45px;
    min-width: 250px;
    font-size: 1.4rem;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
  }

  .download {
    background-color: transparent;
    border: 1px solid black;
    color: ${black};

    &:hover {
      background-color: ${black};
      border: 1px solid black;
      color: ${white};
    }
  }
  .sell {
    background-color: ${primaryColor};
    border: 1px solid ${primaryColor};
    color: ${white};

    ${mobile} {
      min-width: unset;
    }

    &:hover {
      background-color: transparent;
      color: ${primaryColor};
    }
  }

  .image-container {
    position: relative;
    top: 3rem;
    margin-bottom: 6rem;
    width: 80%;
    height: 600px;

    .second {
      position: absolute;
      top: -80px;
      right: 0px;
    }

    .bargain {
      position: absolute;
      top: -80px;
      left: -50px;
      animation: ${upDown} 18s ease-in-out infinite;
    }
    .product {
      position: absolute;
      top: -50px;
      right: 30px;
      animation: ${upDown} 15s ease-in-out infinite;
    }

    .gucci {
      position: absolute;
      left: 50%;
      bottom: 0px;
      top: 280px;
      animation: ${rightLeft} 35s ease-in-out infinite;
    }

    ${mobile} {
      margin: 0 1rem;
      width: calc(100% - 2rem);
      border: 1px solid red;
      height: 250px;

      .second {
        top: -20px;
      }
      .bargain {
        width: 100px;
        left: 20px;
        top: -10px;
      }
      .product {
        width: 100px;
        left: 250px;
      }

      .gucci {
        width: 100px;
        top: 100px;
      }
    }
  }

  .connect {
    font-size: 6.5rem;
    margin: 0;
    color: #636d7b;
    font-family: 'Poppins', sans-serif;
  }

  .sync {
    font-size: 3.5rem;
    margin: 0;
    color: #636d7b;
    font-family: 'Poppins', sans-serif;
  }
  .start-selling {
    font-size: 3.5rem;
    margin: 0;
    color: ${primaryColor};
    font-family: 'Poppins', sans-serif;
  }

  ${mobile} {
    .connect,
    .sync,
    .start-selling {
      font-size: 2.5rem;
    }
  }

  h1 {
    font-size: 3.5rem;
    line-height: 2.5rem;
    margin: 0;
    font-family: 'Poppins', sans-serif;
  }
  p {
    width: 35%;
    line-height: 1.5rem;
    font-family: 'Poppins', sans-serif;
    text-align: center;
  }

  ${tablet} {
    .header {
      margin-top: 2rem;
      margin: 0 1rem;
      h1 {
        font-size: 2rem;
        line-height: 2.2rem;

        text-align: center;
      }
      p {
        width: 100%;
      }
    }
  }

  .buttonContainer {
    ${tablet} {
      margin-top: 4rem;
      width: calc(100% - 3rem);
      gap: unset;

      .download,
      .sell {
        padding: 4px 20px;
        height: 30px;
        min-width: 90px;
        font-size: 0.8rem;
      }
    }
  }

  ${tablet} {
    .word-flex-cont {
      margin: 2rem;
    }

    #words {
      width: 100%;
    }

    #flash-words {
      width: 100%;
      margin: 4rem 0;
    }
  }
`;

export const ImageBox = styled.div`
  width: 600px;
  height: 600px;

  img {
    width: 100%;
  }

  ${mobile} {
    width: 180px;
    height: 180px;

    img {
      width: 100%;
    }
  }
`;
export const InfoCard = styled.div`
  width: 230px;
  height: 230px;
  background-color: ${white};
  padding: 2rem 1rem;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
  transition: all ease-in-out 0.5s;

  &:hover {
    transform: scale(1.05);
  }

  p {
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-size: 0.8rem;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  span {
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  ${tablet} {
    margin: 1rem 0;
  }
`;
