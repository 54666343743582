import React from 'react';

export const SearchIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 17L13.2223 13.2156L17 17ZM15.3158 8.15789C15.3158 10.0563 14.5617 11.8769 13.2193 13.2193C11.8769 14.5617 10.0563 15.3158 8.15789 15.3158C6.2595 15.3158 4.43886 14.5617 3.0965 13.2193C1.75413 11.8769 1 10.0563 1 8.15789C1 6.2595 1.75413 4.43886 3.0965 3.0965C4.43886 1.75413 6.2595 1 8.15789 1C10.0563 1 11.8769 1.75413 13.2193 3.0965C14.5617 4.43886 15.3158 6.2595 15.3158 8.15789V8.15789Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export const SendIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.7141 11.0289L3.36527 12.42C3.26928 12.4361 3.17921 12.4771 3.1041 12.5389C3.02899 12.6008 2.9715 12.6814 2.93741 12.7725L0.0587585 20.4851C-0.216137 21.1945 0.525417 21.8707 1.20601 21.5293L21.1581 11.5532C21.296 11.4841 21.412 11.378 21.493 11.2467C21.5741 11.1155 21.617 10.9643 21.617 10.81C21.617 10.6558 21.5741 10.5045 21.493 10.3733C21.412 10.242 21.296 10.1359 21.1581 10.0668L1.20601 0.0907358C0.525417 -0.249559 -0.216137 0.426597 0.0587585 1.1349L2.93852 8.8475C2.97261 8.93865 3.0301 9.01921 3.10521 9.08109C3.18032 9.14296 3.27039 9.18397 3.36638 9.19999L11.7152 10.5911C11.7673 10.5994 11.8147 10.626 11.849 10.6661C11.8832 10.7063 11.902 10.7573 11.902 10.81C11.902 10.8628 11.8832 10.9138 11.849 10.9539C11.8147 10.994 11.7673 11.0206 11.7152 11.0289H11.7141Z"
        fill="#FFA412"
      />
    </svg>
  );
};

export const MicIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.8898 8.31189V10.9566C18.8898 14.7064 15.839 17.7572 12.0892 17.7572C8.33943 17.7572 5.28859 14.7064 5.28859 10.9566V8.31189H3.77734V10.9566C3.77734 15.285 7.10323 18.8507 11.3336 19.2337V23.4244H12.8448V19.2337C17.0752 18.8507 20.4011 15.285 20.4011 10.9566V8.31189H18.8898Z"
        fill="#FFA412"
      />
      <path
        d="M12.0904 15.4903C13.2924 15.489 14.4448 15.0109 15.2947 14.1609C16.1447 13.311 16.6228 12.1586 16.6241 10.9566V5.28937C16.6241 4.08694 16.1465 2.93376 15.2962 2.08352C14.446 1.23328 13.2928 0.755615 12.0904 0.755615C10.888 0.755615 9.73479 1.23328 8.88455 2.08352C8.0343 2.93376 7.55664 4.08694 7.55664 5.28937V10.9566C7.55799 12.1586 8.03609 13.311 8.88604 14.1609C9.73599 15.0109 10.8884 15.489 12.0904 15.4903ZM9.06789 5.28937C9.06789 4.48775 9.38633 3.71896 9.95316 3.15213C10.52 2.58531 11.2888 2.26687 12.0904 2.26687C12.892 2.26687 13.6608 2.58531 14.2276 3.15213C14.7944 3.71896 15.1129 4.48775 15.1129 5.28937V10.9566C15.1129 11.7582 14.7944 12.527 14.2276 13.0938C13.6608 13.6606 12.892 13.9791 12.0904 13.9791C11.2888 13.9791 10.52 13.6606 9.95316 13.0938C9.38633 12.527 9.06789 11.7582 9.06789 10.9566V5.28937Z"
        fill="#FFA412"
      />
    </svg>
  );
};

export const CameraIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.4049 5.94937H17.1929L16.4277 3.80489C16.3748 3.65815 16.2779 3.53131 16.1502 3.4417C16.0226 3.35208 15.8704 3.30406 15.7144 3.3042H8.46853C8.14969 3.3042 7.86392 3.50495 7.75764 3.80489L6.99007 5.94937H3.77808C2.73418 5.94937 1.88867 6.79488 1.88867 7.83877V18.6084C1.88867 19.6523 2.73418 20.4978 3.77808 20.4978H20.4049C21.4487 20.4978 22.2943 19.6523 22.2943 18.6084V7.83877C22.2943 6.79488 21.4487 5.94937 20.4049 5.94937ZM20.5938 18.6084C20.5938 18.7123 20.5088 18.7973 20.4049 18.7973H3.77808C3.67416 18.7973 3.58914 18.7123 3.58914 18.6084V7.83877C3.58914 7.73486 3.67416 7.64983 3.77808 7.64983H8.18748L8.59134 6.52091L9.13218 5.00467H15.0484L15.5892 6.52091L15.9931 7.64983H20.4049C20.5088 7.64983 20.5938 7.73486 20.5938 7.83877V18.6084ZM12.0915 9.16136C10.0037 9.16136 8.31265 10.8524 8.31265 12.9402C8.31265 15.028 10.0037 16.719 12.0915 16.719C14.1793 16.719 15.8703 15.028 15.8703 12.9402C15.8703 10.8524 14.1793 9.16136 12.0915 9.16136ZM12.0915 15.2075C10.8397 15.2075 9.82418 14.1919 9.82418 12.9402C9.82418 11.6884 10.8397 10.6729 12.0915 10.6729C13.3432 10.6729 14.3588 11.6884 14.3588 12.9402C14.3588 14.1919 13.3432 15.2075 12.0915 15.2075Z"
        fill="#FFA412"
      />
    </svg>
  );
};
