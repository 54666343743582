import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router-dom';
import Header from '../../components/header/header';
import WrapWithLocation from '../../components/WrapWithLocation';
import { Title } from '../../constants/meta.constant';
import BottomNav from '../../components/footer/bottomNav';
import { LocationPageContainer } from './location.style';
import StateCityModal from '../../components/StateCityModal';
import useWindowDimensions from '../../utils/windowsDimension.utils';

const Location = () => {
  const useQueryParams = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQueryParams();
  const history = useHistory();
  const { width } = useWindowDimensions();
  const isMobile = width < 577;

  useEffect(() => {
    if (!isMobile) {
      history.push('/');
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${Title} | Location - ${query}`}</title>
        <meta name="description" content="Search favorite Products and Shops" />
      </Helmet>
      <WrapWithLocation>
        <LocationPageContainer bodyScroll={false}>
          <Header />
          <StateCityModal />
          <BottomNav />
        </LocationPageContainer>
      </WrapWithLocation>
    </>
  );
};

export default Location;
