import styled, { keyframes } from 'styled-components';
import { Colors } from '../../constants/theme';

const { primaryColor } = Colors;

const dotPulseBefore = keyframes`
  0% {
    box-shadow: 9984px 0 0 -5px ${primaryColor};
  }
  30% {
    box-shadow: 9984px 0 0 2px ${primaryColor};
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px ${primaryColor};
  }
`;

const dotPulse = keyframes`
  0% {
    box-shadow: 9999px 0 0 -5px ${primaryColor};
  }
  30% {
    box-shadow: 9999px 0 0 2px ${primaryColor};
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px ${primaryColor};
  }
`;

const dotPulseAfter = keyframes`
  0% {
    box-shadow: 10014px 0 0 -5px ${primaryColor};
  }
  30% {
    box-shadow: 10014px 0 0 2px ${primaryColor};
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px ${primaryColor};
  }
`;

export const LoadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: fit-content;
  position: relative;
  margin: 200px auto;
  bottom: 50px;
  opacity: 0.79;

  .loader {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s;
    z-index: 10;
  }

  .snippet:before {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 5px;
    font-size: 0.75rem;
    color: white;
    border-radius: 0.25rem 0 0.25rem 0;
  }

  .pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ffa412;
    color: #ffa412;
    animation: ${dotPulse} 1.5s infinite linear;
    animation-delay: 0.25s;
  }

  .pulse::before,
  .pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ffa412;
    color: #ffa412;
  }

  .pulse::before {
    box-shadow: 9984px 0 0 -5px #ffa412;
    animation: ${dotPulseBefore} 1.5s infinite linear;
    animation-delay: 0s;
  }

  .pulse::after {
    box-shadow: 10014px 0 0 -5px #ffa412;
    animation: ${dotPulseAfter} 1.5s infinite linear;
    animation-delay: 0.5s;
  }
`;
