import React, { FunctionComponent } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Header, RandomProductsContents, RandomProductsWrapper, SectionWrapper } from './TopProductList.style';
import ProductCard from '../../../components/ProductCard';
import Loader from '../../../components/LoaderComponent';
import { Colors } from '../../../constants/theme';
import getProductPrice from '../../../interfaces/GetProductPrice.interface';
import { Inventory } from '../../../interfaces/inventory.interface';
import { GET_INVENTORIES_AT_RANDOM_SCHEMA } from '../../../schema/inventory.schema';
import whiteLogo from '../../../img/logo-white.png';
import arrowRight from '../../../img/arrow-grey.svg';
import searchIcon from '../../../img/search-b.svg';
import locationIcon from '../../../img/location.svg';
import { limit } from '../../../constants/limit.constant';
import { Link } from 'react-router-dom';
import { getItem } from '../../../utils/localStorage.utils';
import { useUserLocation } from '../../../hooks/locationContext';

const TopProducts: FunctionComponent = () => {
  const { body } = Colors;
  const { currentCity, currentState } = useUserLocation() || {};

  const { data, loading } = useQuery<{ getInventoriesAtRandom: Inventory[] }>(GET_INVENTORIES_AT_RANDOM_SCHEMA, {
    variables: {
      limit: 12,
      stateId: currentState?.value,
      cityId: currentCity?.value
    }
  });

  if (loading) return <Loader />;

  const location = 'Minna, Niger state';

  return (
    <RandomProductsWrapper>
      {/* <Header>
        <p>
          <img src={searchIcon} alt="" />
          <span>Popular Products near you</span>
        </p>{' '}
        <p id="location">
          <img src={locationIcon} alt="" />
          <span>Location</span>
        </p>
      </Header> */}
      <SectionWrapper>
        <RandomProductsContents>
          {data?.getInventoriesAtRandom.map((product) => {
            const { inventoryId, inventoryType, Variations } = product;
            const variationId = Variations.length && Variations?.[0].variationId;

            return (
              <ProductCard
                key={inventoryId}
                price={getProductPrice(product, inventoryType || 'NON_TRACKABLE', variationId)}
                inventory={product}
              />
            );
          })}
        </RandomProductsContents>
      </SectionWrapper>
    </RandomProductsWrapper>
  );
};

export default TopProducts;
