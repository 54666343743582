import React, { FunctionComponent } from 'react';
import { AppDownloadMediumsContainer } from './AppDownloadMediums.style';
import AppStore from '../../img/appStore.png';
import GooglePlay from '../../img/googlePlay.png';

interface Props {
  id?: string;
}

const AppDownloadMediums: FunctionComponent<Props> = ({ id }) => {
  return (
    <AppDownloadMediumsContainer id={id}>
      {/* <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_APPSTORE_DOWNLOAD_LINK}>
        <img src={AppStore} alt="Appstore" />
      </a> */}
      <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_PLAYSTORE_DOWNLOAD_LINK}>
        <img src={GooglePlay} alt="playstore" />
      </a>
    </AppDownloadMediumsContainer>
  );
};

export default AppDownloadMediums;
