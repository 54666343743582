import styled from 'styled-components';
import { Colors, media } from '../../constants/theme';
import caretDown from '../../img/caret-down.png';
const { mobile } = media;
export const Container = styled.div``;
export const FormContainer = styled.form`
  padding: 0 15px;
`;
export const DropDownContainer = styled.div`
  display: flex;
  gap: 20px;
  ${mobile} {
    flex-direction: column;
    gap: 10px;
  }
  margin: 10px 0;
`;

export const DropDown = styled.div`
  margin: 0;
  width: 100%;
  ${mobile} {
    margin: 0.5% 0;
  }
`;
