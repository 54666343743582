import React, { FunctionComponent } from 'react';
import { connect, useSelector } from 'react-redux';
import { ModalState } from '../../interfaces/state.interface';
import TopNav from '../../components/header/topNav';
import CustomNav from '../../components/header/customNavbar/customNav';
import CheckoutPageContent from './CheckoutPageContent';
import { Helmet } from 'react-helmet-async';
import { Title } from '../../constants/meta.constant';
import { AppState } from '../../store/reducers';
import PopupCard from '../../components/PopupCard';
import Error from '../../components/ErrorComponent';

const mapStateToProps = (state: ModalState): ModalState => {
  return { modalOpen: state.modalOpen, modalType: state.modalType };
};

const Checkout: FunctionComponent = () => {
  const message = useSelector((state: AppState) => state.fetch.message);
  const status = useSelector((state: AppState) => state.fetch.status);

  return (
    <div>
      <Helmet>
        <title>{`${Title} | Checkout`}</title>
        <meta name="description" content="Complete payment for your items" />
      </Helmet>
      <TopNav />
      <CustomNav />
      <CheckoutPageContent />

      {status === 'error' && (
        <PopupCard>
          <Error message={message} />
        </PopupCard>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(Checkout);
