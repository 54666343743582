import React, { FunctionComponent, useState } from 'react';
import SeeAll from '../../../img/see-all-mobile.svg';
import GenStores from '../../../img/gen-stores-mobile.svg';
import Computing from '../../../img/computing-mobile.svg';
import FastFood from '../../../img/fast-food-mobile.svg';
import BioMed from '../../../img/bio-med-mobile.svg';
import { Flex, Text } from '../../../GlobalStyles/CustomizableGlobal.style';
import { Colors } from '../../../constants/theme';
import search from '../../../img/search.svg';
import { CategoriesImg, InputContainer, InputField, SearchAndCategoriesContainer, SearchButton } from './SearchAndCategories.style';
import { useHistory, useLocation } from 'react-router-dom';

interface Props {
  setMenuOpen: Function;
}

const SearchAndCategories: FunctionComponent<Props> = ({ setMenuOpen }) => {
  const { lightBlue } = Colors;
  const categories = [
    { title: 'General Stores', key: 'GENERAL STORE', icon: GenStores },
    { title: 'Food & Beverages', key: 'RESTAURANTS', icon: FastFood },
    { title: 'Biomedicine', key: 'BIOMEDCINE', icon: BioMed }
  ];

  const [searchInput, setSearchInput] = useState<string>('');
  const history = useHistory();
  const urlLocation = useLocation();

  const handleSubmit = () => {
    history.push(
      searchInput !== ''
        ? {
            pathname: '/searchQuery',
            search: `?p=1&q=${encodeURIComponent(searchInput)}`
          }
        : urlLocation
    );
  };

  const handleCategoryChange = (cat: string) => {
    history.push({
      pathname: '/searchQuery',
      search: `?category=${encodeURIComponent(cat)}&p=1}`
    });
  };

  const handleInputChange = (e: any) => {
    setSearchInput(e.target.value);
  };

  return (
    <SearchAndCategoriesContainer>
      <form onSubmit={handleSubmit}>
        <InputContainer>
          <InputField onChange={handleInputChange} type="text" placeholder="Search products..." />
          <div className="divider" />
          <SearchButton type="submit">
            <img src={search} alt="searchIcon" />
          </SearchButton>
        </InputContainer>
      </form>
      <Flex width="100%" justifyContent="space-between" alignItems="center" margin="1rem 0">
        <Flex direction="column" alignItems="center" justifyContent="space-between" onClick={() => setMenuOpen(true)}>
          <CategoriesImg src={SeeAll} />
          <Text color={lightBlue} fontSize="0.6rem">
            See All
          </Text>
        </Flex>
        {categories.map((cat) => (
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            onClick={() => handleCategoryChange(cat.key)}
            key={cat.key}
          >
            <CategoriesImg src={cat.icon} />
            <Text color={lightBlue} fontSize="0.6rem">
              {cat.title}
            </Text>
          </Flex>
        ))}
      </Flex>
    </SearchAndCategoriesContainer>
  );
};

export default SearchAndCategories;
