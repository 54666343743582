import React, { FunctionComponent, useEffect, useState } from 'react';
import { ModalState } from '../../interfaces/state.interface';
import { Container, SearchContainer, ShopViewContainer } from './shop.style';
import ShopContents from './ShopViewContents';
import BottomNav from '../../components/footer/bottomNav';
import Snackbar from '../../components/snackbar';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/reducers';
import { useQuery } from '@apollo/client';
import { useLocation, useParams } from 'react-router-dom';
import Header from '../../components/header/header';
import ShopHeader from '../../components/header/shopHeader';
import getGeoLocationPosition from '../../utils/queryLocation.utils';
import { Redirect } from 'react-router-dom';
import routes from '../../utils/urlRoutes.utils';
import { BROAD_MIND_SHOP_TAG } from '../../utils/pushRoutes.utils';
import { GetAllShopInventoryCategoryProps, GetAllShopInventory } from '../../interfaces/inventory.interface';
import { GET_ALL_SHOP_INVENTORY_CATEGORY_SCHEMA, GET_ALL_SHOP_INVENTORY_SCHEMA } from '../../schema/inventory.schema';
import { limit } from '../../constants/limit.constant';
import WrapWithLocation from '../../components/WrapWithLocation';
import { Input } from '../../GlobalStyles/CustomizableGlobal.style';
import CustomDropdown from '../../components/custom-dropdown/custom-dropdown';
import SearchIcon from '../../img/search-orange.svg';
import SearchIconBlue from '../../img/search-blue.svg';
import ClearIcon from '../../img/close-orange.svg';
import { Colors } from '../../constants/theme';
import qs from 'query-string';
import history from '../../utils/history.utils';
import useWindowDimensions from '../../utils/windowsDimension.utils';
import MobileShopSearch from './MobileShopSearch';
import Loader from '../../components/LoaderComponent';
import { windowAdapter } from '../../utils/serverClientAdapter.utils';

const { lightBlue } = Colors;

interface ShopViewParams {
  tag: string;
}
export interface CategoriesProp {
  inventoryCategoryId: string;
  inventorycategoryName: string;
}

const ShopView: FunctionComponent<ModalState> = ({ modalOpen }) => {
  const [selectedCategory, setSelectedCategory] = useState<number>(0);
  const [shopCategories, setShopCategories] = useState<CategoriesProp[] | []>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [openSearch, setOpenSearch] = useState<boolean>(false);

  const position = getGeoLocationPosition();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const searchParam = query.get('q') || '';
  const show = useSelector((state: AppState) => state.snackbar.toggleSnackbar);
  let { tag: shopTag } = useParams<ShopViewParams>();
  const queryParams = qs.parse(location.search);
  const { width } = useWindowDimensions();

  if (shopTag === 'broadmind') {
    shopTag = BROAD_MIND_SHOP_TAG;
  }

  const pageParam = query.get('p') || 1;
  const modalParam = query.get('m') || '';
  const currentPage = Number(pageParam) || 1;

  const { data: categoryData } = useQuery<GetAllShopInventoryCategoryProps>(GET_ALL_SHOP_INVENTORY_CATEGORY_SCHEMA, {
    variables: { shopTag: shopTag }
  });

  const { data, loading, error } = useQuery<GetAllShopInventory>(GET_ALL_SHOP_INVENTORY_SCHEMA, {
    variables: {
      shopTag,
      searchString: searchParam,
      inventoryCategoryId: shopCategories[selectedCategory]?.inventoryCategoryId,
      page: currentPage,
      limit: limit,
      latitude: position.latitude,
      longitude: position.longitude
    }
  });

  const handleInventorySearch = (event: any) => {
    event.preventDefault();

    history.push({
      search: qs.stringify({
        q: searchTerm
      })
    });
  };

  useEffect(() => {
    if (categoryData) {
      setShopCategories([
        {
          inventoryCategoryId: '',
          inventorycategoryName: 'All Categories'
        },
        ...categoryData.getAllShopInventoryCategory
      ]);
    }
  }, [categoryData]);

  const clearSearch = () => {
    setSearchTerm('');
    setSelectedCategory(0);

    history.push({
      search: qs.stringify({
        q: '',
        cat: ''
      })
    });
  };

  const handleCategorySearch = (value: number) => {
    setSelectedCategory(value);
    const newQueries = {
      ...queryParams,
      cat: shopCategories[value]?.inventorycategoryName
    };
    history.push({
      search: qs.stringify(newQueries)
    });
  };
  const isMobile = width <= 776;
  const getWidth = () => {
    if (width >= 1200 && width < 3000) return 500;
    if (width >= 768 && width < 1200) return 400;
    if (width > 576 && width < 768) return 300;
  };

  const handleOpenSearch = () => {
    setOpenSearch(true);
    history.push({
      pathname: location.pathname,
      search: `?m=search`
    });
  };
  if (windowAdapter.onpopstate) {
    windowAdapter.onpopstate = () => {
      if (modalParam === 'search') {
        windowAdapter.history.go(-1);
        setOpenSearch(false);
      }
    };
  }

  const shop = data?.getAllShopInventory?.Shop;

  function SearchBar() {
    if (loading) return null;
    return (
      <SearchContainer width={`${getWidth()}px`}>
        <Container onSubmit={handleInventorySearch} width={isMobile ? '60%' : '50%'}>
          {isMobile && (
            <img
              id="search-icon-mobile"
              src={SearchIconBlue}
              alt="search"
              onClick={(e) => {
                handleInventorySearch(e);
              }}
            />
          )}
          <Input
            margin="0"
            padding={isMobile ? '0 0 0 2.1rem' : '0 0 0 1rem'}
            height="40px"
            width="90%"
            placeholder="Search this store..."
            borderColor="transparent"
            background="transparent"
            color={lightBlue}
            fontSize="1rem"
            value={searchTerm}
            onClick={() => (isMobile ? handleOpenSearch() : null)}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Container>
        <div style={{ width: '40%' }}>
          <CustomDropdown
            width="95%"
            optionsWidth={`${getWidth()! / 2}px`}
            height="40px"
            fontSize="14px"
            color="#8196B3"
            selected={selectedCategory}
            setValue={handleCategorySearch}
            placeholder="All Categories"
            options={shopCategories.map((category) => category.inventorycategoryName)}
            border="none"
            containerColor="transparent"
            margin="0 10px"
          />
        </div>
        {!isMobile && (
          <button className="search-button" onClick={searchParam && searchTerm ? clearSearch : handleInventorySearch}>
            <img src={searchParam && searchTerm ? ClearIcon : SearchIcon} alt="" />
          </button>
        )}
      </SearchContainer>
    );
  }

  const notfound = error?.message === 'Shop not found';

  const { nossr } = routes;

  if (loading) return <Loader />;
  if (!loading && notfound) {
    return <Redirect to={nossr.pageNotFound} />;
  }

  return (
    <WrapWithLocation>
      <ShopViewContainer bodyScroll={modalOpen}>
        <div className="inner_container">
          <Header />
          <ShopHeader
            shopId={shop?.shopId}
            name={shop?.shopName || ''}
            location={shop?.fullAddress || ''}
            distance={shop?.distance}
            longitude={shop?.longitude}
            latitude={shop?.latitude}
            image={shop?.Images?.[0]?.mediumImageOnlineURL || ''}
            shopPhone={shop?.shopPhone || ''}
            loading={loading}
            shopTag={shopTag}
            searchBar={SearchBar}
          />
          <ShopContents
            inventories={data?.getAllShopInventory?.inventories || []}
            shop={shop}
            loading={loading}
            shopInventoryCategories={categoryData?.getAllShopInventoryCategory}
            totalInventories={data?.getAllShopInventory.totalInventory ?? 0}
            currentPage={currentPage}
            searchCategory={shopCategories[selectedCategory]?.inventorycategoryName}
            searchBar={SearchBar}
            openSearch={openSearch}
          />
        </div>
        <BottomNav />
        {show && <Snackbar />}
      </ShopViewContainer>
      {openSearch && (
        <MobileShopSearch
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setOpenSearch={setOpenSearch}
          width={width}
          openSearch={openSearch}
          handleInventorySearch={handleInventorySearch}
        />
      )}
    </WrapWithLocation>
  );
};

export default ShopView;
