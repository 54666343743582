import React, { FunctionComponent, useEffect, useState } from 'react';
import { LandingPageContainer } from './landing.style';
import Header from '../../components/header/header';
import HeroBanner from '../../components/header/heroBanner';
import BottomNav from '../../components/footer/bottomNav';
import RandomProducts from './RandomProductsList';
import Helmet from '../../components/header/Helmet';
import { Title } from '../../constants/meta.constant';
import TopProducts from './TopProductsList';
import TopStores from './TopStore';
import Subscribe from './Subscribe';
import BannerOne from '../../components/BannerOne';
import TopGadget from './TopGadget';
import BannerTwo from '../../components/BannerTwo';
import TopFoods from './TopFoods';
import TopStudentItems from './TopStudentItems';
import Pharmacies from './Pharmacies';
import ConnectAndSell from './ConnectAndSell';
import SearchAndCategories from './SearchAndCategories';
import { setItem } from '../../utils/localStorage.utils';
interface Props {
  modalOpen: boolean;
}

const LandingPage: FunctionComponent<Props> = ({ modalOpen }) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  useEffect(() => {
    setItem('userLocation', JSON.stringify({ selectecState: null, selectedCity: null }));
  }, []);

  return (
    <>
      <Helmet
        title={`${Title} | Home`}
        description="Shop online from stores and physical markets around you with ease"
        imageUrl="https://timat.com.ng/ogimage.jpg"
        type={'website'}
        isLandingPage
      />
      <LandingPageContainer bodyScroll={modalOpen}>
        <Header categoriesOpen={menuOpen} setCategoriesOpen={setMenuOpen} />
        <SearchAndCategories setMenuOpen={setMenuOpen} />
        <HeroBanner />
        <TopProducts />
        <BannerOne />
        <TopGadget />
        <BannerTwo />
        {/* <RandomProducts /> */}
        {/* <TopStores />
        <Subscribe /> */}
        <TopFoods />
        <TopStudentItems />
        <Pharmacies />
        <ConnectAndSell />
        <BottomNav />
      </LandingPageContainer>
    </>
  );
};

export default LandingPage;
