import { split, ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getItem } from './localStorage.utils';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import cookie from 'cookie';
import { v4 as uuid } from 'uuid';
import { isServer } from './server.utils';

const location = globalThis.location;
const isProduction = ['tmart.com.ng', 'www.tmart.com.ng'].includes(location?.host);

export const domainName = isProduction ? 'https://api.timart.com.ng' : 'https://staging-api.timart.com.ng';
export const mainApiUrl = `${domainName}/graphql`;
export const googleLoginUrl = `${domainName}/auth/google`;

let socketUrl = isProduction ? 'wss://api.timart.com.ng/graphql' : 'wss://staging-api.timart.com.ng/graphql';

const httpLink = createHttpLink({
  uri: mainApiUrl,
  credentials: 'same-origin'
});

// hack to prevent websocket error on server
if (isServer()) {
  (globalThis as any).WebSocket = {};
}

const link = createClient({
  url: socketUrl
});

const wsLink = new GraphQLWsLink(link);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink
);
const authLink = setContext((_, { headers }) => {
  const session = JSON.parse(getItem('session') || '{}');
  const token = session['token'];

  let cookies = cookie.parse(document.cookie);

  if (!cookies.identifier) {
    document.cookie = `${document.cookie} identifier=${uuid()};max-age=${60 * 60 * 24 * 365};path=/`;
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      identifier: cookies.identifier
    }
  };
});

const self = globalThis as any;

export default new ApolloClient<NormalizedCacheObject>({
  link: authLink.concat(splitLink),
  cache: new InMemoryCache().restore(self.__APOLLO_STATE__ || {}),
  ssrMode: true
});
