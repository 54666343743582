import React, { useRef, FunctionComponent } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { DynamicSearchBar, FilterCardContainer, SearchButton } from './FilterCard.style';
import redirectSearchResult from '../../store/actions/redirectToResult';
import { AppState } from '../../store/reducers/index';
import { redirectType } from '../../store/initialStates/redirectToSearchResult';
import search from '../../img/search.svg';

const mapStateToProps = (state: AppState) => {
  return { fireRedirect: state.redirect.fireRedirect };
};

interface IHeroBanner extends redirectType {
  redirectSearchResult: (value: boolean) => void;
  searchQuery: string;
}

const Filter: FunctionComponent<IHeroBanner> = ({ fireRedirect, redirectSearchResult, searchQuery }) => {
  const urlLocation = useLocation();

  const queryData = useRef<HTMLInputElement>(null);
  let searchInput: string = queryData.current ? queryData.current.value : '';

  const handleInputChange = (query: boolean): void => {
    redirectSearchResult(query);
  };

  return (
    <FilterCardContainer>
      {/* TODO
          <div style={{ margin: "0 10px" }}>
            <Button backgroundColor={inActiveTab} width={"50px"} height={"50px"} borderRadius={"50%"} padding={"0"}>
              <img src={FilterIcon} alt="filter-icon" />
            </Button>
          </div>
          */}
      {/* <FilterSelectContainer style={{ visibility: "hidden", position: "absolute" }}>
          <div className="filterTitle">
            <h2>Filter By</h2>
          </div>
          <div className="selectionRow">
            <SelectContainer
              classNamePrefix="react-select"
              defaultValue={selectedOption}
              onChange={handleChange}
              options={stateOptions}
              placeholder="States"
            />
            <SelectContainer
              classNamePrefix="react-select"
              defaultValue={selectedOption}
              onChange={handleChange}
              options={cityOptions}
              placeholder="Cities"
            />
          </div>
        </FilterSelectContainer> */}

      <DynamicSearchBar>
        <form className="input-container">
          <input
            className="input-field"
            onChange={() => handleInputChange(!fireRedirect)}
            type="text"
            defaultValue={searchQuery}
            ref={queryData}
            placeholder="Search shops..."
          />
          <Link
            to={
              searchInput !== ''
                ? {
                    pathname: '/merchant/1',
                    search: `?q=${encodeURIComponent(searchInput)}&p=1`
                  }
                : urlLocation
            }
          >
            <SearchButton>
              <img src={search} alt="searchIcon" />
            </SearchButton>
          </Link>
        </form>
      </DynamicSearchBar>
    </FilterCardContainer>
  );
};

export default connect(mapStateToProps, { redirectSearchResult })(Filter);
