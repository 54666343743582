import React from 'react';
import { HalfPageContent } from './login.style';
import Signup from './signup';
import AuthShell from './AuthShell';

const RegisterPage = () => {
  return (
    <AuthShell>
      <HalfPageContent>
        <Signup />
      </HalfPageContent>
    </AuthShell>
  );
};

export default RegisterPage;
