import { SHOP_INVENTORY_ID, selectModuleType } from "../actionTypes";
import { ShopInventoryIdInitialState, shopInventoryIdType } from "../initialStates/shopInventoryId";

const ShopInventoryId = (state: shopInventoryIdType = ShopInventoryIdInitialState, action: selectModuleType) => {
  switch (action.type) {
    case SHOP_INVENTORY_ID:
      return {
        ...state,
        currentInventoryId: action.payload,
      };

    default:
      return state;
  }
};

export default ShopInventoryId;
