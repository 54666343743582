import { useSelector } from 'react-redux';
import { Icart } from '../store/initialStates/cartSelections';
import { AppState } from '../store/reducers';
import { getItem } from './localStorage.utils';

interface OrderItemsType {
  inventoryId: string;
  variationId: string;
  inventoryType: string;
}

export const getOrderItems = (selectedItems: Icart[]) => {
  const orderItems = selectedItems.map((item: any) => {
    return {
      inventoryId: item.id,
      variationId: item.variationId || null,
      inventoryType: item.inventoryType,
      quantity: item.quantity
    };
  });
  return orderItems;
};
