import { CLICKED } from "../actionTypes";
import { NavProps } from "../../interfaces/actions.interface";
import ClickedButtonInitialState from "../initialStates/clickedNavButton";
import { ClickedState } from "../../interfaces/state.interface";

export default function clickButton(state = ClickedButtonInitialState, action: NavProps): ClickedState {
  switch (action.type) {
    case CLICKED:
      return {
        ...state,
        clicked: !state.clicked,
      };

    default:
      return state;
  }
}
