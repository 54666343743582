import styled, { keyframes, css } from 'styled-components';
import { Colors, FontSizes, media } from '../../../constants/theme';
import { Circle } from '../../ProductCard/ProductCard.style';
import { NavLink } from 'react-router-dom';
import { Flex } from '../../../GlobalStyles/CustomizableGlobal.style';

const { mobile, smallScreenLaptop, tablet } = media;
const { primaryColor, primaryGrey, white, transparentBlack } = Colors;
const { detailsFontSize, primaryFontSize } = FontSizes;

const navItemFade = keyframes`
from{
opacity:0;
transform: translate(50px);
}
to{
  opacity:1 ;
  transform: translate(0px);
}
`;

const navItemAnim = () => css`
  animation: ${navItemFade} 0.5s ease forwards ${0.1}s;
`;

interface IProps {
  menuOpen?: boolean;
  desktop?: boolean;
}

export const Nav = styled.nav`
  height: 80px;
  width: 100vw;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 38px;
  z-index: 7;
  background-color: ${white};
  border-bottom: 1px solid #e4ebf4;
  transition: all ease-in-out 0.3s;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

  .breadcrumb {
    display: none;
  }
  box-sizing: border-box;
  ${tablet} {
    height: 50px;
    width: 100vw;
    padding: 5px 1rem;
    .breadcrumb {
      display: block;
      width: 18px;
      margin-right: 1rem;
      cursor: pointer;
    }
  }

  ${mobile} {
    height: 50px;
    width: 100vw;
    padding: 0 0.5rem;
    top: 0;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
  .shop-link {
    min-width: 130px;
  }
  .mobile-search {
    display: none;
  }
  .nav-links-container {
    display: flex;
  }
  .nav-links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
  }

  ${smallScreenLaptop} {
    width: unset;
    .nav-links {
      gap: 1rem;
    }
    .nav-links-container {
      display: flex;
      width: 80%;
      justify-content: flex-end;
    }
    .mobile-search {
      display: block;
      cursor: pointer;
    }
  }

  ${tablet} {
    gap: 1rem;
    .shop-link {
      min-width: 0;
    }
    .nav-links-container {
      width: 100%;
      height: 100vh;
      background: #000000b3;
      position: fixed;
      top: 0;
      left: -101%;
      z-index: 8;

      &.active {
        left: 0;
      }
    }
    .nav-links {
      width: 75%;
      height: calc(100vh - 0px);
      padding-top: 100px;
      align-items: start;
      justify-content: start;
      flex-direction: column;
      background-color: ${white};
      transition: ease-in-out all 0.3s;
      position: fixed;
      top: 0;
      left: -76%;
      overflow-y: scroll;
      flex-direction: column-reverse;

      &.active {
        left: 0;
      }
    }
  }
  ${mobile} {
    .nav-links {
      height: 100vh;
      padding-top: 50px;
    }
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 9;

  ${media.tablet} {
    height: 60%;
  }
  ${smallScreenLaptop} {
    left: 2em;
  }

  ${mobile} {
    .breadcrumb {
      margin-right: 0.5rem;
    }
  }
`;

export const LogoImg = styled.img`
  height: 50px;

  ${tablet} {
    height: 90%;
  }

  ${mobile} {
    z-index: 20;
  }
`;

export const CartIcon = styled.img`
  height: 26px;
  width: 26px;
  cursor: pointer;
`;

export const CartCounter = styled(Circle)`
  width: 14px;
  height: 14px;
  font-size: 10px;
  position: absolute;
  background: #2f2f2f;
  top: 4px;
  right: 4px;
`;

export const Cart = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  color: ${primaryColor};
  background: #fff6ea;
  border-radius: 6px;
  padding: 2px 15px;
  span {
    margin-left: 5px;
    font-weight: 500;
    font-size: 14px;
  }
  img {
    height: 20px;
    width: 20px;
  }
  ${mobile} {
    background: transparent;
    color: #4f4f4f;
    padding: 4px 10px;
    span {
      display: none;
    }
  }
`;

export const LocationContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 50px;
  background-color: #f5f5f5;
  margin-left: 2em;

  ${mobile} {
    margin: 0;
    position: absolute;
    top: 0;
    left: 5.5em;
  }
`;

export const SearchContainer = styled.div`
  outline: none;
  position: relative;
  height: 40px;
  padding: 0;

  .input-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #e4ebf4;
    font-size: 20px;
    outline: none;
    border-radius: 4px 0px 0px 4px;
    border: none;
    width: 400px;
    height: 40px;
    padding: 0 50px 0 5px;
  }

  ${tablet} {
    height: 30px;

    .input-field {
      font-size: 16px;
      width: 50%;
      height: 30px;
      padding: 0 50px 0 5px;
    }
  }

  ${mobile} {
    display: none;
  }
`;
export const MerchantContainer = styled.div`
  ${tablet} {
    display: none;
  }
`;

export const DynamicSearchBar = styled.div<IProps>`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  transition: all ease-in-out 0.3s;
  background: #f6f8fb;
  border-radius: 4px;
  form {
    width: 100%;
  }
  select {
    border: none;
    background: transparent;
    color: #8196b3;
    border-left: 0.5px solid #8196b3;
    padding-left: 12px;
    margin-right: 12px;
    cursor: pointer;
  }
  &.active {
    top: 0;
  }

  .input-container {
    display: flex;
    position: relative;
    width: 100%;
  }
  .input-wrapper {
    width: 100%;
  }
  .select-input {
    outline: none;
  }
  .input-field {
    display: flex;
    background: transparent;
    outline: none;
    border: none;
    padding: 0 70px 0 20px;
    font-size: ${detailsFontSize};
    width: 100%;
    color: #8196b3;
    height: 40px;
  }
  .close-icon {
    cursor: pointer;
    margin-left: 10px;
  }
  .m-btn {
    display: none;
  }
  ${smallScreenLaptop} {
    height: 40px;
    width: 100%;
    background: transparent;
    &.active {
      top: 0;
    }
    .m-btn {
      display: block;
    }
    .input-field {
      width: 100%;
      height: 40px;
      font-size: 16px;
      padding: 0 53px 0 10px;
    }
    .input-container {
      display: flex;
      position: relative;
      width: 100%;
    }
    .input-wrapper {
      display: flex;
      align-items: center;
      margin: 0 15px;
      background: #f6f8fb;
      border-radius: 4px;
      a {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 5px;
      }
      button {
        outline: none;
        background: transparent;
        border: none;
        display: flex;
        align-items: center;
        height: 100%;
      }
    }
  }
  ${tablet} {
    height: 40px;
    width: 100%;

    &.active {
      top: 0;
    }

    .input-field {
      width: 100%;
      height: 40px;
      font-size: ${primaryFontSize};
      padding: 0 10px;
    }
    .input-container {
      display: flex;
      position: relative;
      width: 100%;
    }
    select {
      padding-left: 5px;
      margin-right: 5px;
    }
  }
  ${mobile} {
    width: 100%;
    top: -50px;
    height: 50px;

    &.active {
      top: 0;
    }

    .input-field {
      display: flex;
      outline: none;
      border: none;
      padding: 0 4px;
      font-size: ${detailsFontSize};
      width: 100%;
      height: 40px;
    }
    .input-container {
      display: flex;
      position: relative;
      width: 100%;
    }
    select {
      font-size: 12px;
      padding: 0 4px;
      width: 100px;
    }
  }
`;

export const SearchButton = styled.button<IProps>`
  display: flex;
  width: 70px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: ${primaryColor};
  border-radius: 4px;
  cursor: pointer;

  img {
    width: 1.2rem;
    height: 100%;
  }
  ${smallScreenLaptop} {
    height: 40px;
    width: 50px;
    display: none;
    img {
      width: 1rem;
    }
  }
  ${tablet} {
    width: 40px;
    height: 100%;

    img {
      width: 15px;
    }
  }
  ${mobile} {
    width: 50px;
    height: 100%;

    img {
      width: 15px;
    }
  }
`;
export const NavItem = styled.div<IProps>`
  display: flex;
  color: ${primaryGrey};
  align-items: center;
  margin: 0;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  ${({ menuOpen }: IProps) => (menuOpen ? navItemAnim : null)};

  &:hover {
    color: ${primaryColor};
  }

  h5 {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }

  .nav-icon {
    margin-right: 10px;
  }
  .caret {
    display: none;
  }

  ${smallScreenLaptop} {
    margin: 0;

    h5 {
      margin: 0;
      padding: 0;
      font-size: 12px;
    }

    .nav-icon {
      margin-right: 5px;
    }
  }
  ${tablet} {
    display: none;
  }
`;

export const Hr = styled.hr`
  display: none;

  ${mobile} {
    display: block;
    padding: 0;
    margin: 0;
    width: 100%;
    border-top: 1px solid #8196b32f;
    border-bottom: none;
  }
`;
export const MobileSidebar = styled.div`
  width: 100%;
  height: calc(100vh - 50px);
  overflow-y: scroll;
`;
export const ParentLink = styled(NavLink)`
  display: flex;
  justify-content: space-between;
  color: #ffa412;
  padding: 10px 15px;
  font-weight: 500;
`;
export const NormalLink = styled(NavLink)`
  color: #4f4f4f;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  font-weight: 500;
  font-size: ${detailsFontSize};
  text-transform: capitalize;
`;
export const SidebarSection = styled.div`
  width: 100%;
  border-top: 1px solid #8196b3;
`;

export const UserLocationContainer = styled(Flex)`
  ${mobile} {
    flex-direction: column;

    p,
    span {
      font-size: 0.5rem;
    }

    img {
      width: 10px;
    }

    .location-flex {
      padding: 0.2rem 0.5rem;
    }
  }
`;
export const HelpContainer = styled.div`
  position: relative;
  cursor: pointer;
  p {
    font-size: 14px;
    color: #4f4f4f;
    display: flex;
    align-items: center;
    height: 30px;
    column-gap: 7px;
  }
  .dropdown {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 50px;
    right: -20px;
    overflow: hidden;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    a {
      display: block;
      padding: 11px 14px;
      font-size: 14px;
      width: 155px;
      color: #8196b3;
      :hover {
        color: ${primaryColor};
        background: #fbf6f0;
      }
    }
  }
  :hover {
    p {
      color: ${primaryColor};
    }
    .dropdown {
      display: flex;
    }
  }
  ${tablet} {
    display: none;
  }
`;
