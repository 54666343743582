import { localStorage } from './server.utils';

export interface LocationProps {
  longitude: number | undefined;
  latitude: number | undefined;
}

export type permissionType = 'granted' | 'denied' | 'prompt';

export const locationPermissionRequest = (() => {
  const LOCATION_PERMISSION_KEY = 'location-permission';
  return {
    setPermissionStatus: (status: permissionType) => {
      localStorage.setItem(LOCATION_PERMISSION_KEY, status);
    },
    getPermissionStatus: (): permissionType => {
      return (localStorage.getItem(LOCATION_PERMISSION_KEY) as permissionType) || 'prompt';
    }
  };
})();

const locationStore = (() => {
  const LOCATION_STORAGE_KEY = 'geo-location';

  return {
    savePositions: (longitude: number, latitude: number) => {
      localStorage.setItem(LOCATION_STORAGE_KEY, JSON.stringify({ longitude, latitude }));
    },
    getPositions: (): LocationProps => {
      const noLocation = { latitude: undefined, longitude: undefined };

      const position = localStorage.getItem(LOCATION_STORAGE_KEY);
      if (position) {
        try {
          return JSON.parse(position);
        } catch (error) {
          console.error(error);
          return noLocation;
        }
      }
      return noLocation;
    }
  };
})();

const queryGeoLocation = async (callback?: (pos: LocationProps) => void): Promise<LocationProps | null> => {
  return new Promise((resolve) => {
    if (!globalThis?.navigator?.geolocation) {
      console.error('GeoLocation is not supported');
      resolve(null);
    } else {
      return globalThis?.navigator?.geolocation.getCurrentPosition(
        ({ coords }) => {
          locationStore.savePositions(coords.longitude, coords.latitude);
          const position = { longitude: coords.longitude, latitude: coords.latitude };
          resolve(position);
          callback && callback(position);
        },
        (error) => {
          console.error(error);
          resolve(null);
        }
      );
    }
  });
};

const getGeoLocationPosition = (callback?: (pos: LocationProps) => void): LocationProps => {
  if (locationPermissionRequest.getPermissionStatus() === 'granted') queryGeoLocation(callback);

  return locationStore.getPositions();
};

export default getGeoLocationPosition;
