import React, { FunctionComponent } from 'react';
import { useQuery } from '@apollo/client';
import arrowRight from '../../../src/img/carousel-right-arrow.svg';
import { Link } from 'react-router-dom';
import { GetAllShopInventory, Inventory } from '../../interfaces/inventory.interface';
import { GET_ALL_SHOP_INVENTORY_SCHEMA } from '../../schema/inventory.schema';
import { Header, RandomProductsWrapper, RandomProductsContents } from '../landing/RandomProductsList/RandomProductsList.style';
import Loader from '../../components/LoaderComponent';
import ProductCard from '../../components/ProductCard';
import getProductPrice from '../../interfaces/GetProductPrice.interface';
import Error from '../../components/ErrorComponent';

interface Props {
  shopId: string | undefined;
  shopTag: string | undefined;
}
const ProductsFromSeller: FunctionComponent<Props> = ({ shopId, shopTag }) => {
  const { data, loading, error } = useQuery<GetAllShopInventory>(GET_ALL_SHOP_INVENTORY_SCHEMA, {
    variables: {
      shopId,
      searchString: '',
      inventoryCategoryId: '',
      page: 1,
      limit: 24
    }
  });
  const shop = data?.getAllShopInventory.Shop;
  const inventories = data?.getAllShopInventory.inventories;
  const location = 'Minna, Niger state';

  if (loading) return <Loader />;
  if (error) return <Error message={error.message} />;
  return (
    <RandomProductsWrapper style={{ background: "transparent", boxShadow: "none" }}>
      <Header style={{ background: "#FFFFFF" }}>
        <p style={{ color: "#2F2F2F", fontFamily: "'Montserrat', san-serif", }}>Other products from this seller</p>{' '}
        <Link to={`/${shopTag}`} style={{ color: "#FFA412" }}>
          Visit store <img src={arrowRight} alt="" style={{ height: "10px" }} />
        </Link>
      </Header>
      <RandomProductsContents style={{ background: "transparent" }}>
        {inventories?.map((product: Inventory) => {
          const { inventoryId, inventoryType, Variations } = product;
          const variationId = Variations.length && Variations?.[0].variationId;

          return (
            <ProductCard
              key={inventoryId}
              price={getProductPrice(product, inventoryType || 'NON_TRACKABLE', variationId)}
              inventory={product}
              shop={shop}
            />
          );
        })}
      </RandomProductsContents>
    </RandomProductsWrapper>
  );
};
export default ProductsFromSeller;
