import styled from 'styled-components';
import { ProductDescription, ProductText } from '../../components/ProductCard/ProductCard.style';
import { Colors, FontSizes, media } from '../../constants/theme';
import caret from '../../img/carousel-right-arrow.svg';

const { primaryColor, primaryGrey, secondaryGrey, white } = Colors;
const { detailsFontSize, titleFont, bigFont } = FontSizes;
const { mobile } = media;

interface ProductSliderProps {
  price?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .sections {
    display: flex;
    margin: 20px 50px;
  }

  #image-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    a {
      color: ${primaryColor};
    }
  }

  #detail-container {
    gap: 1rem;
    @media screen and (max-width: 1091px) {
      margin: 7% 0;
    }
  }

  @media screen and (max-width: 851px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 2em;

    .sections {
      flex-direction: column;
      padding: 0;
    }
  }

  ${mobile} {
    .sections {
      margin: 0;
    }
  }
`;
export const Wrapper = styled.div`
  margin: 20px 50px;

  .location-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #40484ea3;
  }
  .location {
    position: relative;
    background-color: #fff;
    padding: 3rem;
    border-radius: 8px;

    img {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 1rem;
      right: 1rem;
      background-color: #7a8a9610;
      border-radius: 4px;
      padding: 10px;
      cursor: pointer;
      transition: all ease-in-out 0.3s;

      &:hover {
        opacity: 0.5;
      }
    }
  }
  ${mobile} {
    margin: 0;
  }
`;
export const Section = styled.div`
  max-width: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 1%;

  @media screen and (max-width: 851px) {
    width: 100%;
    max-width: 100%;
  }

  ${mobile} {
    width: 100%;
    max-width: 100%;
    #productInfo {
      flex-direction: column;
    }
  }

  .slick-dots li.slick-active button:before {
    color: ${primaryColor} !important;
  }

  .slick-dots {
    bottom: -80px;
  }

  .slick-slide img {
    width: 100%;
  }

  .slick-dots li {
    width: 58px;
    height: 58px;
  }

  hr {
    width: 100%;
  }

  ${mobile} {
    .price-bargain {
      flex-direction: column;
    }
  }
`;

export const RightArrow = styled.div`
  .slick-next:before,
  .slick-prev:before {
    font-size: 12px !important;
    line-height: 1;
    opacity: 0.75;
    color: #ffa412 !important;
    position: absolute;
    top: 10.5rem;
  }
  .slick-next:before {
    /* content: '🡢'; */
    content: url(${caret});
    right: 1rem;
  }
  .slick-prev:before {
    /* content: '🡠'; */
    content: url(${caret});
    transform: rotate(180deg);
    left: 1rem;
  }
  .slick-dots li button:before,
  .slick-next:before,
  .slick-prev:before {
    font-family: slick;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export const ProductImage = styled.img`
  margin-bottom: 25px;
  max-width: 275px;
  align-self: center;
  object-fit: cover;
  margin: 0 auto;
`;

export const ProductImageCard = styled.div`
  margin: 0 auto;
  margin-bottom: 80px;
  align-items: center;
  max-width: 350px;
  width: 100%;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(24, 88, 177, 0.12);
`;

export const ImageSelector = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(23, 46, 78, 0.06);
  display: flex;
  border: 1px solid #ffa412;
  position: absolute;
`;

export const RandomProductContainer = styled.div`
  margin: 50px;
  @media screen and (max-width: 500px) {
    margin: 1px;
  }
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

export const SlideProduct = styled(ProductText)`
  font-size: ${titleFont};

  ${(props: ProductSliderProps) =>
    props.price &&
    `
        font-size: ${bigFont};
        color: ${primaryColor};
        margin: 0;
    `}
`;

export const ProductDetails = styled(ProductDescription)`
  font-size: ${detailsFontSize};
  margin: 30px 0;
  color: ${primaryGrey};
  line-height: 20px;
`;

export const CounterContainer = styled.div`
  display: flex;
  margin: 1em 0;
  align-items: baseline;

  #add-to-cart {
    margin: unset;
  }

  #bargain-button {
    ${mobile} {
      width: 100%;
    }
  }

  ${mobile} {
    flex-direction: column;
  }

  .check-quantity-div {
    gap: 2rem;
    display: flex;
    align-items: center;
    width: 100%;

    ${mobile} {
      flex-direction: column;
      gap: 1rem;
    }
  }
`;

export const ToggleContainer = styled.div`
  width: 90%;
  display: flex;
  margin: 0 auto;
  background: #8196b3;
  border-radius: 5px;

  ${mobile} {
    display: none;
  }
`;

export const ToggleButton = styled.button<{ isActive: boolean }>`
  color: ${({}) => secondaryGrey};
  background: ${({}) => white};
  padding: 1%;
  width: 100%;
  border: none;
  text-align: center;
  cursor: pointer;
  font-family: 'Roboto';
  )font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  ${mobile} {
    font-size: 1rem;
  }
  :nth-of-type(1) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  :nth-of-type(2) {
    border-radius: 0;
  }
  :nth-of-type(3) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &:hover {
    color: ${primaryColor};
  }
`;

export const ToggleContent = styled.div`
  width: 88%;
  min-height: 6rem;
  padding: 0 1%;
  margin: 0 auto;

  ${mobile} {
    display: none;
  }
`;

export const Description = styled.div`
  #productInfo {
    ${mobile} {
      flex-direction: column;
    }
    align-items: start;
  }
`;

export const ProductSpecifications = styled.div``;

export const ProductCustomerReview = styled.div``;

export const VariationContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  .hide-radio {
    display: none;
    width: 10px;
    height: 10px;
  }

  .label-radio {
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4ebf4;
    border-radius: 3px;
    color: #4f4f4f;
    padding: 4px 10px;
  }

  input[type='radio']:checked + .label-radio {
    background-color: #ffa412;
    color: #fff;
    border: none;
    display: flex;
    padding: 4px 10px;
  }
`;

export const VariationLabel = styled.div`
  min-width: 19ch;
  color: #4f4f4f;
`;

export const CustomRadioButtomContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  margin: 1rem 0;

  .extra-margin {
    @media screen and (max-width: 1091px) {
      margin: 0 5%;
    }
  }
`;

export const CustomRadioButton = styled.div`
  margin: 0;
  display: flex;
`;

export const DisplayRating = styled.div`
  display: flex;
  color: #8196b3;
  margin: 1rem 0;

  span {
    margin-left: 1rem;
  }
  img {
    margin-right: 0.5rem;
  }
`;

export const OutOfStockDiv = styled.div`
  display: flex;
  img {
    border-radius: 5px;
    ${mobile} {
      margin: 15px auto;
    }
  }
`;

export const ProductClassWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-around;

  .product-type {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .radios {
    display: flex;
    gap: 2rem;
  }
  .radio {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;
interface LabelProps {
  active?: boolean;
}
export const Label = styled.div<LabelProps>`
  font-weight: 600;
  font-size: 16px;
  color: ${({ active }) => (active ? '#FFA412' : '#8196B3')};
`;
