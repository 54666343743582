import { setItem } from "../../utils/localStorage.utils";
import { ADD_TO_CART_FROM_LOCALSTORAGE, ADD_TO_CART, REMOVE_FROM_CART, CLEAR_CART, arrayActionType } from "../actionTypes";
import { CartCounterInitialState, IcartCounter } from "../initialStates/cartCount";

const CartCounterFunctions = (state: IcartCounter = CartCounterInitialState, action: arrayActionType) => {
  switch (action.type) {
    case ADD_TO_CART_FROM_LOCALSTORAGE: {
      return {
        ...state,
        cartItems: action.cartData,
      };
    }

    case ADD_TO_CART: {
      let newCart = [...state.cartItems, action.payload];
      setItem("allCartItems", newCart);
      return {
        ...state,
        cartItems: newCart,
      };
    }

    case REMOVE_FROM_CART: {
      let newCart = [...state.cartItems];
      const index = state.cartItems.findIndex((cartItem) => cartItem.id === action.id);

      if (index >= 0) {
        newCart.splice(index, 1);
      }
      setItem("allCartItems", newCart);

      return {
        ...state,
        cartItems: newCart,
      };
    }

    case CLEAR_CART: {
      return {
        ...state,
        cartItems: [],
      };
    }

    default:
      return state;
  }
};

export default CartCounterFunctions;
