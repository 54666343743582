import styled from 'styled-components';
import { Colors } from '../../constants/theme';

const { secondaryColor, blackLight, primaryColor } = Colors;

interface Props {
  primary?: string;
}

export const SuccessContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;

export const SuccessIcon = styled.i`
  color: ${primaryColor};
  font-size: 30px;
`;

export const SuccessMsg = styled.h5`
  color: ${blackLight};
  font-size: 20px;
  text-align: center;
  margin: 25px 0;
`;

export const Pinfo = styled.p`
  color: #b9b9b9;
  font-size: 16px;
  text-align: center;
  display: ${({ primary }: Props): string => (primary ? 'flex' : 'none')};
  justify-content: center;

  .link {
    color: ${secondaryColor};
    text-decoration: none;
    padding: 0 5px;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      color: #521010;
    }
  }
`;
