import styled from 'styled-components';
import { Colors } from '../../constants/theme';

const { secondaryColor, blackLight } = Colors;

export const ErrorContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;

export const ErrorIcon = styled.i`
  color: ${secondaryColor};
  font-size: 30px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

export const ErrorMessage = styled.h5`
  color: ${blackLight};
  font-size: 1em;
  text-align: center;
  margin: 1em 0;
`;
