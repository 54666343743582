import React, { FunctionComponent, useEffect, useState } from 'react';
import { Colors, FontSizes } from '../../constants/theme';
import { Container, Hr, OrderContainer, TabOption, TabContainer, TabSwitch, Text, Title } from './account.style';
import OrderCard from './OrderCard';
import Loader from '../../components/LoaderComponent';
import Error from '../../components/ErrorComponent';
import { GET_MEMBER_TYPE, GET_MEMBER_ORDERS_TYPE } from '../../interfaces/GetMember.interface';
import { useQuery } from '@apollo/client';
import { GET_MEMBER_ORDERS_SCHEMA } from '../../schema/member.schema';
import { Page } from './account.style';
import { getWindowSize } from '../../utils/getWindow.utils';

interface Props {
  memberData?: GET_MEMBER_TYPE;
  isMobile: boolean;
}

const OrderHistory: FunctionComponent<Props> = ({ memberData, isMobile }) => {
  const { blackLight } = Colors;
  const { bigFont } = FontSizes;
  const [tab, setTab] = useState('all');
  const [orderType, setOrderType] = useState('');

  const [memberOrders, setMemberOrders] = useState<any>([]);
  const [page, setPage] = useState<number>(1);

  const { data, loading, error, networkStatus, refetch } = useQuery<GET_MEMBER_ORDERS_TYPE>(GET_MEMBER_ORDERS_SCHEMA, {
    variables: {
      page,
      limit: 15
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (tab === 'all') {
      setMemberOrders(data?.getMemberOrders);
    } else {
      setMemberOrders(data?.getMemberOrders?.filter((order: any) => order?.OrderItems[0].Steps.stepName === tab));
    }
  }, [tab, data, page]);

  const nextPage = () => {
    setPage(page + 1);
  };
  const prevPage = () => {
    setPage(page > 1 ? page - 1 : page);
  };

  const Pagination = () => {
    return (
      <Page page={page}>
        <button disabled={page <= 1} className="prev" onClick={prevPage}>
          Prev
        </button>
        <button className="next" onClick={nextPage}>
          Next
        </button>
      </Page>
    );
  };

  if (loading) return <Loader />;
  if (error) return <Error message={error.message} />;
  if (!(networkStatus <= 7)) return <Error message={'You are not connected to the internet'} />;
  return (
    <Container>
      <Title subtitle>Order History</Title>
      <TabContainer>
        <TabSwitch>
          <TabOption
            id="all"
            onClick={() => {
              setTab('all');
              setOrderType('');
            }}
            active={tab === 'all' ? true : false}
          >
            <Text margin="0px">All</Text>
          </TabOption>
          <TabOption
            id="pending"
            onClick={() => {
              setTab('PROCESSING');
              setOrderType('');
            }}
            active={tab === 'PROCESSING' ? true : false}
          >
            <Text margin="0px">Processing ()</Text>
          </TabOption>
          <TabOption
            id="dispatched"
            onClick={() => {
              setTab('DISPATCHED');
              setOrderType('');
            }}
            active={tab === 'DISPATCHED' ? true : false}
          >
            <Text margin="0px">Dispatched ()</Text>
          </TabOption>
        </TabSwitch>
      </TabContainer>
      <Hr />
      {loading ? (
        <Loader />
      ) : (
        <OrderContainer>
          {memberOrders?.length > 0 ? (
            memberOrders?.map((order: any) => {
              return <OrderCard key={order.orderId} memberData={memberData} order={order} isMobile={isMobile} refetch={refetch} />;
            })
          ) : (
            <Text className="empty-text" color={blackLight} fontWeight="700" fontSize={bigFont}>
              {tab === 'PROCESSING'
                ? 'You have no processing orders'
                : tab === 'DISPATCHED'
                ? 'No orders are currently dispatched'
                : 'Place some orders'}
            </Text>
          )}
          {<Pagination />}
        </OrderContainer>
      )}
    </Container>
  );
};

export default OrderHistory;
