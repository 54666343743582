import styled from 'styled-components';
import { Colors, FontSizes, media } from '../../constants/theme';
import { PageContainer } from '../../GlobalStyles/FixedGlobal.style';

const { primaryColor } = Colors;

export const LocationPageContainer = styled(PageContainer)`
  margin: 2rem unset;

  .city-state-text {
    &:hover {
      color: ${primaryColor};
    }
  }
`;
