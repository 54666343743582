import React, { FunctionComponent } from 'react';
import { useQuery } from '@apollo/client';
import { Header, Info, Left, RandomProductsContents, RandomProductsWrapper, SectionWrapper } from './TopStudentItems.style';
import ProductCard from '../../../components/ProductCard';
import Loader from '../../../components/LoaderComponent';
import getProductPrice from '../../../interfaces/GetProductPrice.interface';
import { SEARCH_INVENTORY_TYPE } from '../../../interfaces/inventory.interface';
import { SEARCH_INVENTORY } from '../../../schema/inventory.schema';
import searchIcon from '../../../img/search-b.svg';
import { Link } from 'react-router-dom';
import getGeoLocationPosition from '../../../utils/queryLocation.utils';
import SchoolBag from '../../../img/school-bag.png';
import LunchBox from '../../../img/lunch-box.png';
import SchoolGirl from '../../../img/schoolGirl.png';
import SchoolBagMobile from '../../../img/school-bag-mobile.svg';
import LunchBoxMobile from '../../../img/lunch-box-mobile.svg';
import SchoolGirlMobile from '../../../img/schoolGirl-mobile.svg';
import useWindowDimensions from '../../../utils/windowsDimension.utils';
import { getItem } from '../../../utils/localStorage.utils';
import { useUserLocation } from '../../../hooks/locationContext';

const TopStudentItems: FunctionComponent = () => {
  const position = getGeoLocationPosition();
  const { width } = useWindowDimensions();
  const { currentCity, currentState } = useUserLocation() || {};

  const { data, loading } = useQuery<SEARCH_INVENTORY_TYPE>(SEARCH_INVENTORY, {
    variables: {
      category: 'HOME/OFFICE',
      page: 1,
      limit: 22,
      sortBy: 'RELEVANCE',
      searchType: 'INVENTORY',
      stateId: currentState?.value,
      cityId: currentCity?.value
    }
  });

  if (loading) return <Loader />;

  const isBig = width > 425;

  return (
    <RandomProductsWrapper>
      <Info>
        <div className="banner-container">
          <div className="image-banner">
            <img src={SchoolGirl} alt="" />
          </div>
          <div id="school">
            <Left>
              <h1>
                Let your kids go back to school with <span>style</span>
              </h1>
              <p id="desc-text">The market place with a complete peace of mind.</p>
              <Header>
                <p>
                  <span>Special just for your kids</span>
                </p>
              </Header>
            </Left>
            <img className="school-bag" src={SchoolBag} alt="" />
            <img className="lunch-box" src={LunchBox} alt="" />
            <Link to="/searchQuery?category=HOME/OFFICE&p=1">Start Shopping</Link>
          </div>
        </div>
      </Info>
      <SectionWrapper>
        <RandomProductsContents>
          {data?.searchInventoryOrShop.inventories.map((product) => {
            const { inventoryId, inventoryType, Variations } = product;
            const variationId = Variations.length && Variations?.[0].variationId;

            return (
              <ProductCard
                key={inventoryId}
                price={getProductPrice(product, inventoryType || 'NON_TRACKABLE', variationId)}
                inventory={product}
              />
            );
          })}
        </RandomProductsContents>
      </SectionWrapper>
    </RandomProductsWrapper>
  );
};

export default TopStudentItems;
