import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleSnackbarClose } from "../../store/actions/snackbarActions";
import { AppState } from "../../store/reducers";
import { SnackbarContainer } from "./snackbar.style";

const Snackbar: FunctionComponent = () => {
  const timeout = 3000;
  const dispatch = useDispatch();

  // select the UI states from the redux store
  const show = useSelector((state: AppState) => state.snackbar.toggleSnackbar);
  const message = useSelector((state: AppState) => state.snackbar.snackbarMessage);

  // convert the timeout prop to pass into the styled component
  let time = (timeout - 500) / 1000 + "s";

  let timer: ReturnType<typeof setTimeout>;

  function handleTimeout() {
    timer = setTimeout(() => {
      dispatch(toggleSnackbarClose());
    }, timeout);
  }

  useEffect(() => {
    if (show) {
      handleTimeout();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [show]);

  return (
    <SnackbarContainer time={time}>
      <p>{message}</p>
    </SnackbarContainer>
  );
};

export default Snackbar;
