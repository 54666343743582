import React, { FunctionComponent } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import qs from 'query-string';
import { ShopViewContents, ShopViewWrapper } from './ShopViewContents.style';
import { H1 } from '../../SearchQuery/SearchList/SearchList.style';
import ProductCard from '../../../components/ProductCard';
import Loader from '../../../components/LoaderComponent';
import getProductPrice from '../../../interfaces/GetProductPrice.interface';
import Pagination from '../../../components/pagination';
import Banner from '../../../img/deliveryBanner.svg';
import { domain, Title } from '../../../constants/meta.constant';
import Helmet from '../../../components/header/Helmet';
import formatImageUrl from '../../../utils/format.utils';
import { Inventory } from '../../../interfaces/inventory.interface';
import { Shop } from '../../../interfaces/shop.interface';
import { limit } from '../../../constants/limit.constant';
import BreadCrumb from '../../../components/BreadCrumb';
import { CategoriesProp } from '../shop';
import useWindowDimensions from '../../../utils/windowsDimension.utils';

interface ShopViewParams {
  tag: string;
  id?: string;
  page?: string;
  search?: string;
}

interface ShopContentsProps {
  inventories: Inventory[];
  loading: boolean;
  totalInventories: number;
  currentPage: number;
  shop?: Shop;
  shopInventoryCategories?: CategoriesProp[];
  searchCategory?: string;
  searchBar: Function;
  openSearch: boolean;
}

const ShopContents: FunctionComponent<ShopContentsProps> = ({ loading, inventories, shop, currentPage, searchBar, totalInventories }) => {
  const query = new URLSearchParams(useLocation().search);
  const searchParam = query.get('q') || '';
  const searchCategory = query.get('cat') || '';
  const { width } = useWindowDimensions();

  if (loading) return <Loader />;
  return (
    <>
      <Helmet
        title={`${shop?.shopName ?? Title}`}
        description={shop?.shopAddress ?? ''}
        imageUrl={formatImageUrl(shop?.Images?.[0]?.mediumImageOnlineURL, 'shop') as string}
        type="shop"
        url={`${domain}/${shop?.ShopURL?.shopTag}`}
        isLandingPage
      />
      {/* <img style={{ width: '100%' }} src={Banner} alt="Banner" /> */}
      <ShopViewWrapper>
        {/* <BreadCrumb
          crumbs={[
            { name: 'home', route: '/' },
            { name: 'shops', route: '/merchant/1' },
            { name: shop?.shopName, route: null }
          ]}
        /> */}

        {searchBar && width <= 776 && searchBar()}
        <ShopViewContents>
          {inventories && inventories.length < 1 && searchParam === '' && searchCategory === '' && (
            <div className="not-found">
              <H1>This shop has no products</H1>{' '}
            </div>
          )}
          {inventories && inventories.length < 1 && (searchParam !== '' || searchCategory !== '') && (
            <div className="not-found">
              <H1>Your search "{searchParam || searchCategory}" did not match any product</H1>
            </div>
          )}
          {inventories?.map((product) => {
            const { inventoryId, inventoryType, Variations, Shop } = product;
            const variationId = Variations.length && Variations?.[0].variationId;

            return (
              <ProductCard
                key={inventoryId}
                price={getProductPrice(product, inventoryType || 'NON_TRACKABLE', variationId)}
                inventory={product}
                shop={Shop || shop}
              />
            );
          })}
        </ShopViewContents>
        <Pagination paramType={'SEARCH'} currentPage={currentPage} totalItems={totalInventories} limit={limit} />
      </ShopViewWrapper>
    </>
  );
};

export default ShopContents;
