import styled from 'styled-components';
import { Colors, FontSizes, media } from '../../../constants/theme';

const { mobile, smallScreenLaptop, tablet } = media;
const { detailsFontSize } = FontSizes;
const { primaryColor } = Colors;

export const SearchAndCategoriesContainer = styled.div`
  display: none;
  margin-top: 50px;
  padding: 1rem;

  ${mobile} {
    display: block;
  }
`;

export const CategoriesImg = styled.img`
  width: 21px;
  height: 17px;
`;

export const InputContainer = styled.div`
  display: flex;
  position: relative;
`;

export const InputField = styled.input`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #e4ebf4;
  font-size: ${detailsFontSize};
  outline: none;
  border-radius: 4px 0px 0px 4px;
  border: none;
  width: 100%;
  height: 40px;
  padding: 0 50px 0 5px;
`;

export const SearchButton = styled.button`
  display: flex;
  width: 70px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: ${primaryColor};
  border-radius: 4px;
  cursor: pointer;

  img {
    width: 1.2rem;
    height: 100%;
  }
  ${smallScreenLaptop} {
    height: 40px;
    width: 50px;

    img {
      width: 1rem;
    }
  }
  ${tablet} {
    width: 40px;

    img {
      width: 15px;
    }
  }
  ${mobile} {
    width: 50px;

    img {
      width: 15px;
    }
  }
`;
