export default {
  nossr: {
    checkout: '/checkout',
    account: '/my-account/:tab',
    auth: '/auth/:tab',
    cart: '/cart',
    contactUs: '/contact-us',
    thankYou: '/thank-you',
    location: '/location',
    pageNotFound: '/404',
    returnPolicy: '/return-policy',
    acceptancePolicy: '/acceptance-policy',
    privacyPolicy: '/privacy-policy',
    termsAndConditions: '/terms-and-conditions',
    faq: '/faq',
    login: '/login',
    register: '/register'
  },
  ssr: {
    home: '/',
    tempHome: '/home',
    merchant: '/merchant/:page?',
    product: '/product/:url',
    shop: '/:tag',
    search: '/searchQuery'
  }
};
