import { ActionProps } from "../../interfaces/actions.interface";
import { TOGGLE_SNACKBAR_CLOSE, TOGGLE_SNACKBAR_OPEN } from "../actionTypes";
import { snackbarInitialState, SnackbarStateType } from "../initialStates/snackbarInitialState";

const snackbarFunctions = (state: SnackbarStateType = snackbarInitialState, action: ActionProps) => {
  switch (action.type) {
    case TOGGLE_SNACKBAR_OPEN: {
      return {
        ...state,
        toggleSnackbar: true,
        snackbarMessage: action.payload,
      };
    }

    case TOGGLE_SNACKBAR_CLOSE: {
      return {
        ...state,
        toggleSnackbar: false,
        snackbarMessage: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default snackbarFunctions;
