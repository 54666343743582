import React, { FC, FunctionComponent, useState } from 'react';
import { Colors, FontSizes } from '../../constants/theme';
import { OrderCardProps } from '../../interfaces/orderCardProps.interface';
import showAmount from '../../utils/priceFormat.utils';
import {
  Flex,
  Wrapper,
  CloseContainer,
  OrderDetailWrapper,
  Text,
  Title,
  DetailBox,
  ImgContainer,
  LargeScreenOnly,
  MobileOnly
} from './account.style';
import Shops from '../../img/shops.svg';
import { Button } from '../../GlobalStyles/CustomizableGlobal.style';
import { MdClose } from 'react-icons/md';
import Image from '../../components/image';
import formatImageUrl from '../../utils/format.utils';
import moment from 'moment';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import PopupCard from '../../components/PopupCard';
import Error from '../../components/ErrorComponent';
import Success from '../../components/success';
import Loader from '../../components/LoaderComponent';

const {
  lightBlue,
  primaryColor,
  blackLight,
  inActiveTab,
  red,
  lightRed,
  lightOrange,
  darkGreen,
  blue,
  faintBlue,
  lightGreen,
  secondaryColor,
  skyBlue,
  white
} = Colors;
const { primaryFontSize, descriptionFontSize, detailsFontSize } = FontSizes;

const UPDATE_ORDER_ITEM_STEP = gql`
  mutation UpdateOrderItemStep($stepName: Status!, $orderId: ID, $orderItemIds: [ID]) {
    updateOrderItemStep(stepName: $stepName, orderId: $orderId, orderItemIds: $orderItemIds)
  }
`;

const MARK_ORDER_AS_RECEIVED = gql`
  mutation MarkOrderAsReceived($orderId: String!) {
    markOrderAsReceived(orderId: $orderId) {
      successful
    }
  }
`;

interface StatusProps {
  status: string;
}

const StatusLabel: FunctionComponent<StatusProps> = ({ status }) => {
  return (
    <Flex grid={true} alignItems="center" justifyContent="space-between">
      {status === 'CANCELLED' && (
        <Text padding="2px 6px" background={lightRed} status color={red} fontSize={detailsFontSize}>
          {status?.toLowerCase()}
        </Text>
      )}
      {status === 'PENDING' && (
        <Text padding="2px 6px" background={lightOrange} status color={primaryColor} fontSize={detailsFontSize}>
          {status?.toLowerCase()}
        </Text>
      )}
      {status === 'PROCESSED' && (
        <Text padding="2px 6px" background={faintBlue} status color={blue} fontSize={detailsFontSize}>
          {status?.toLowerCase()}
        </Text>
      )}

      {status === 'PROCESSING' && (
        <Text padding="2px 6px" background={faintBlue} status color={blue} fontSize={detailsFontSize}>
          {status?.toLowerCase()}
        </Text>
      )}
      {(status === 'DELIVERED' || status === 'COMPLETED') && (
        <Text padding="2px 6px" background={lightGreen} status color={darkGreen} fontSize={detailsFontSize}>
          {status?.toLowerCase()}
        </Text>
      )}
    </Flex>
  );
};

const OrderDetail: FunctionComponent<OrderCardProps> = ({ memberData, order, setShowSummary, refetch }) => {
  const { OrderItems, totalAmount, paymentMethod, createdAt, deliveryFee, deliveryOption, orderNumber, orderId } = order;
  const [gqlError, setGqlError] = useState<string | undefined>();
  const [gqlSuccess, setGqlSuccess] = useState<string | undefined>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const fullAddress = memberData?.getMember?.Addresses[0].fullAddress;
  const orderItemsIds = OrderItems.map((a) => a.orderItemId);

  const [updateOrderItemStep] = useMutation<any>(UPDATE_ORDER_ITEM_STEP, {
    onCompleted({ updateOrderItemStep }) {
      setGqlSuccess('Order status updated');
      setLoading(false);
      setTimeout(() => setGqlSuccess(undefined), 3000);
      setShowSummary!(false);
      refetch!();
    },
    onError: (e) => {
      setGqlError(e.message);
      setLoading(false);
    }
  });

  const [MarkOrderAsReceived] = useMutation<any>(MARK_ORDER_AS_RECEIVED, {
    onCompleted(data) {
      console.log('completed', data);
    }
  });

  const cancelOrder = async (orderItemId: string) => {
    setLoading(true);
    await updateOrderItemStep({
      variables: {
        stepName: 'CANCELLED',
        orderItemIds: [orderItemId]
      }
    });
  };

  const markAsRecieved = async (orderItemId: string) => {
    setLoading(true);
    await updateOrderItemStep({
      variables: {
        stepName: 'DELIVERED',
        orderItemIds: [orderItemId]
      }
    });
    await MarkOrderAsReceived({
      variables: {
        orderId: orderId
      }
    });
  };

  return (
    <Wrapper>
      <OrderDetailWrapper>
        <div className="title">
          <Title margin="0px">Order Details</Title>
        </div>
        <DetailBox width="auto" margin="0 0 10px 0" border flexFlow="column">
          <DetailBox background={skyBlue} fontWeight="700" padding="5px 10px">
            <Text color={blackLight} fontSize={detailsFontSize} margin="2px 0">
              Order Information
            </Text>
          </DetailBox>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex margin="4px" flexFlow="column" width="70%" justifyContent="space-between">
              <Flex>
                <Text color={lightBlue} fontSize={descriptionFontSize} fontWeight="700">
                  Order ID:
                </Text>
                <Text color={lightBlue} fontSize={descriptionFontSize}>
                  #{orderNumber}
                </Text>
              </Flex>
              <Flex>
                <Text color={lightBlue} fontSize={descriptionFontSize} fontWeight="700">
                  Placed:
                </Text>
                <Text color={lightBlue} fontSize={descriptionFontSize}>
                  {moment(createdAt).format('Do MMM YYYY, h:mm A')}
                </Text>
              </Flex>
              <Flex>
                <Text color={lightBlue} fontSize={descriptionFontSize} fontWeight="700">
                  Total:
                </Text>
                <Text color={lightBlue} fontSize={descriptionFontSize}>
                  {showAmount(totalAmount)}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </DetailBox>
        <Flex width="auto" justifyContent="space-between" mobileDirection="column">
          <DetailBox borderRadius={'0 0 5px 5px'} margin="0 0 10px 0" width="48%" border flexFlow="column" background={inActiveTab}>
            <DetailBox background={skyBlue} fontWeight="700" padding="5px 10px">
              <Text color={blackLight} fontSize={detailsFontSize} margin="2px 0 0">
                Delivery Information
              </Text>
            </DetailBox>

            <Flex margin="4px" flexFlow="column" width="auto" justifyContent="space-between">
              <Text color={blackLight} fontSize={detailsFontSize} fontWeight={'700'}>
                Delivery Method
              </Text>
              <Text color={blackLight} fontSize={descriptionFontSize}>
                {deliveryOption?.toLowerCase().replace(/_/g, ' ')}
              </Text>
            </Flex>
            <Flex margin="4px" flexFlow="column" width="auto" justifyContent="space-between">
              <Text color={blackLight} fontSize={descriptionFontSize} fontWeight={'700'}>
                Shipping Address
              </Text>
              <Text color={blackLight} fontSize={descriptionFontSize}>
                {`${fullAddress}`}
              </Text>
            </Flex>
          </DetailBox>

          <DetailBox borderRadius={'0 0 5px 5px'} margin={'0 0 10px 0'} width="48%" border flexFlow="column" background={inActiveTab}>
            <DetailBox background={skyBlue} fontWeight="700" padding="5px 10px">
              <Text color={blackLight} fontSize={detailsFontSize} margin="2px 0 0">
                Payment Information
              </Text>
            </DetailBox>

            <Flex flexFlow="column" background={inActiveTab}>
              <Flex margin="4px" flexFlow="column" width="auto" justifyContent="space-between">
                <Text color={blackLight} fontSize={descriptionFontSize} fontWeight={'700'}>
                  Payment Method
                </Text>
                <Text color={blackLight} fontSize={descriptionFontSize}>
                  {paymentMethod?.toLowerCase().replace(/_/g, ' ')}
                </Text>
              </Flex>
              <Flex margin="4px" flexFlow="column" width="auto" justifyContent="space-between">
                <Text color={blackLight} fontSize={descriptionFontSize} fontWeight={'700'}>
                  Payment Details
                </Text>
                <Flex>
                  <Text color={blackLight} fontSize={descriptionFontSize} fontWeight={'700'}>
                    Delivery Fee:
                  </Text>
                  <Text color={blackLight} fontSize={descriptionFontSize}>
                    {showAmount(deliveryFee)}
                  </Text>
                </Flex>
                <Flex>
                  <Text color={blackLight} fontSize={descriptionFontSize} fontWeight={'700'}>
                    Total Amount:
                  </Text>
                  <Text color={blackLight} fontSize={descriptionFontSize}>
                    {showAmount(totalAmount)}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </DetailBox>
        </Flex>
        <DetailBox border flexFlow="column">
          <DetailBox background={skyBlue} fontWeight="700" padding="5px 10px">
            <Text color={blackLight} fontSize={detailsFontSize} margin="2px 0">
              Items Ordered
            </Text>
          </DetailBox>
          <Flex direction="column" justifyContent="space-between" width="98%" smWidth="95%" margin="6px">
            {OrderItems.map((orders, index) => {
              const { inventoryName, amount, quantity, orderItemId, Inventory, Steps } = orders;
              const stepName = Steps.stepName;
              return (
                <Flex key={index} width="90%" flexFlow="column" margin="6px">
                  <Flex smWidth="90%" justifyContent="space-between" alignItems="center" margin="0 0 10px 0">
                    <Flex>
                      <img src={Shops} alt="shop icon" />
                      <Text padding="0 0 0 10px" color={blackLight} fontSize={descriptionFontSize} fontWeight="600">
                        {order.Shop?.shopName}
                      </Text>
                    </Flex>
                    <LargeScreenOnly>
                      <StatusLabel status={stepName} />
                    </LargeScreenOnly>
                  </Flex>
                  <Flex justifyContent="space-between" mobileDirection="column">
                    <Flex smWidth="90%" justifyContent="space-between" alignItems="center">
                      <Flex style={{ columnGap: '1rem' }} width="fit-content" alignItems="center">
                        <ImgContainer width="25%">
                          <Image src={formatImageUrl(Inventory?.Images[0]?.smallImageOnlineURL, 'product')} alt="product image" />
                        </ImgContainer>
                        <Flex style={{ rowGap: '.5rem' }} smWidth="50%" flexFlow="column">
                          <Text color={blackLight} fontSize={descriptionFontSize}>
                            {inventoryName}
                          </Text>
                          <Text color={blackLight} fontSize={descriptionFontSize}>
                            {showAmount(amount)}x{quantity}
                          </Text>
                        </Flex>
                      </Flex>
                      <MobileOnly>
                        <StatusLabel status={stepName} />
                      </MobileOnly>
                    </Flex>
                    <Flex alignItems="center" margin="0 0rem">
                      {stepName === 'PENDING' && (
                        <Button
                          actionBtn
                          width="90%"
                          height="30px"
                          bgHover={secondaryColor}
                          borderColor={secondaryColor}
                          color={secondaryColor}
                          borderRadius="6px"
                          notFilled
                          onClick={() => cancelOrder(orderItemId)}
                        >
                          Cancel Order
                        </Button>
                      )}
                      {stepName === 'COMPLETED' && (
                        <Flex flexFlow="column" width="100%" justifyContent="space-between">
                          <Button
                            actionBtn
                            onClick={() => markAsRecieved(orderItemId)}
                            width="95%"
                            height="35px"
                            color={white}
                            borderColor={darkGreen}
                            backgroundColor={darkGreen}
                            borderRadius="6px"
                            padding="8px 10px"
                            margin="0 0 10px 0"
                          >
                            Mark as Recieved
                          </Button>
                          {/* <Button
                            notFilled
                            width="100%"
                            height="30px"
                            color={primaryColor}
                            bgHover={primaryColor}
                            borderRadius="6px"
                            padding="5px 15px"
                          >
                            Open Dispute
                          </Button> */}
                        </Flex>
                      )}
                      {(stepName === 'PROCESSING' || stepName === 'CANCELLED') && (
                        <Button actionBtn width="90%" height="30px" backgroundColor={primaryColor} borderRadius="6px">
                          Buy Again
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </DetailBox>
      </OrderDetailWrapper>
      {gqlError && (
        <PopupCard maxWidth="500px" close={() => setGqlError(undefined)}>
          <Error message={gqlError} />
        </PopupCard>
      )}
      {gqlSuccess && (
        <PopupCard maxWidth="500px" close={() => setGqlSuccess(undefined)}>
          <Success message={gqlSuccess} />
        </PopupCard>
      )}
      {isLoading && (
        <PopupCard transparent maxWidth="500px" close={() => setGqlSuccess(undefined)}>
          <Loader />
        </PopupCard>
      )}
    </Wrapper>
  );
};

export default OrderDetail;
