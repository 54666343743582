import React, { FunctionComponent } from "react";
import { Colors, FontSizes } from "../../constants/theme";
import { Button, Flex, Text } from "../../GlobalStyles/CustomizableGlobal.style";
import getGeoLocation from "../../utils/queryLocation.utils";
import PopupCard from "../PopupCard";

interface Props {
  setPermission: Function;
}

const LocationModal: FunctionComponent<Props> = ({ setPermission }) => {
  const { primaryGrey } = Colors;
  const { primaryFontSize } = FontSizes;

  const locationDenied = () => {
    setPermission("denied");
  };

  const locationGranted = async () => {
    setPermission("granted");
    getGeoLocation(() => {
      return globalThis?.location?.reload();
    });
  };

  return (
    <PopupCard unfit close={locationDenied}>
      <Flex direction="column" style={{ padding: "2em", textAlign: "center" }}>
        <Text fontSize={primaryFontSize} color={primaryGrey}>
          We need to know your location to show you <b>shops</b> close to you
        </Text>
        <Text fontSize={primaryFontSize} color={primaryGrey}>
          {" "}
          Allow access to your location?
        </Text>
        <Flex>
          <Button width={"8em"} backgroundColor="green" height="3em" margin="0 1em" onClick={locationGranted}>
            Yes, allow location
          </Button>
          <Button width={"8em"} height="3em" backgroundColor="grey" margin="0 1em" onClick={locationDenied}>
            No
          </Button>
        </Flex>
      </Flex>
    </PopupCard>
  );
};

export default LocationModal;
