import { INCREMENT_COUNTER, DECREMENT_COUNTER, RESET_COUNTER } from "../actionTypes";
import { ActionProps } from "../../interfaces/actions.interface";
import { CounterState } from "../../interfaces/state.interface";
import CounterInitialState from "../initialStates/counterState";

export default function counterFunctions(state = CounterInitialState, action: ActionProps): CounterState {
  switch (action.type) {
    case INCREMENT_COUNTER: {
      return {
        ...state,
        count: state.count + 1,
      };
    }

    case DECREMENT_COUNTER: {
      return {
        ...state,
        count: state.count - 1,
      };
    }

    case RESET_COUNTER: {
      return {
        ...state,
        count: 1,
      };
    }

    default:
      return state;
  }
}
