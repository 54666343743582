import { FIREREDIRECT } from "../actionTypes";

const redirectSearchResult = (value: boolean) => {
  return {
    type: FIREREDIRECT,
    payload: value,
  };
};

export default redirectSearchResult;
