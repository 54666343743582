import React, { FunctionComponent } from 'react';
import {
  OrderCompletionDetailsWrapper,
  OrderCompletionDetails,
  OrderDetail,
  RightOrderDetails,
  DetailName,
  OptionDescription,
  CompleteOrderButton,
  CompleteOrderButtonContainer
} from './OrderCompleted.style';
import { ModalDescription } from '../../GlobalStyles/forms.style';
import { useHistory } from 'react-router-dom';

interface Props {
  paymentMethod: string;
  deliveryOption: string;
}

const OrderCompleted: FunctionComponent<Props> = ({ paymentMethod, deliveryOption }) => {
  const history = useHistory();

  const getOption = (option: string) => {
    if (option === 'DOORSTEP') return 'Doorstep Delivery';
    if (option === 'PICKUP') return 'Pickup From Store';
    else return 'Email';
  };

  return (
    <OrderCompletionDetailsWrapper>
      <ModalDescription checkout>Order Completed</ModalDescription>
      <OrderCompletionDetails>
        <RightOrderDetails>
          <OrderDetail>
            <DetailName>Payment Method</DetailName>
            <OptionDescription>{paymentMethod === 'ONLINE' ? 'Online' : 'On Delivery'}</OptionDescription>
          </OrderDetail>

          <OrderDetail>
            <DetailName>Delivery Option</DetailName>
            <OptionDescription>{getOption(deliveryOption)}</OptionDescription>
          </OrderDetail>
        </RightOrderDetails>
      </OrderCompletionDetails>
      <CompleteOrderButtonContainer>
        <CompleteOrderButton onClick={() => history.push('/')}>Continue Shopping</CompleteOrderButton>
        <CompleteOrderButton onClick={() => history.push('/my-account/order-history')}>See Your Orders</CompleteOrderButton>
      </CompleteOrderButtonContainer>
    </OrderCompletionDetailsWrapper>
  );
};

export default OrderCompleted;
