import styled from 'styled-components';
import { media } from '../../constants/theme';
import { PageContainer } from '../../GlobalStyles/FixedGlobal.style';
import bg1 from '../../img/background-1.svg';
import bg2 from '../../img/background-2.svg';
import bg4 from '../../img/background-4.svg';

const { mobile } = media;

export const SearchQueryContainer = styled(PageContainer)`
  position: relative;
  height: max-content;
  background: url(${bg1}) 100% 5% no-repeat, url(${bg2}) 5% 40% no-repeat, url(${bg4}) 0% 100% no-repeat;

  .search-results {
    ${mobile} {
      padding: 3em 1em;
    }
  }
`;
