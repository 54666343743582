import React, { FunctionComponent, useState } from 'react';
import { Colors, FontSizes } from '../../constants/theme';
import showAmount from '../../utils/priceFormat.utils';
import OrderDetail from './OrderDetail';
import { Flex, OrderCardContainer, OrderCardContent, ImgContainer, Text, ViewButton } from './account.style';
import Shops from '../../img/shops.svg';
import { MdArrowForwardIos } from 'react-icons/md';
import { OrderCardProps } from '../../interfaces/orderCardProps.interface';
import Image from '../../components/image';
import formatImageUrl from '../../utils/format.utils';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PopupCard from '../../components/PopupCard';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

const OrderCard: FunctionComponent<OrderCardProps> = ({ memberData, order, isMobile, refetch }) => {
  const { totalAmount, OrderItems, createdAt, orderNumber }: OrderCardProps['order'] = order;
  const [showSummary, setShowSummary] = useState<boolean>(false);
  const { primaryGrey, blackLight } = Colors;
  const { detailsFontSize, primaryFontSize, descriptionFontSize } = FontSizes;

  const close = () => {
    setShowSummary(false);
  };

  const handleClick = () => {
    !showSummary && setShowSummary(true);
  };

  return (
    <OrderCardContainer onClick={handleClick}>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex>
          {!isMobile && <img src={Shops} alt="shop icon" />}
          {!isMobile ? (
            <Link to={`/${order.Shop?.ShopURL?.shopTag}`}>
              <Text color={primaryGrey} fontWeight="600" className="shopName">
                {order.Shop?.shopName}
              </Text>
            </Link>
          ) : (
            <Text mobile textAlign={'right'} color={blackLight} fontSize={descriptionFontSize}>
              <b>Order ID</b>: #{orderNumber}
            </Text>
          )}
        </Flex>
        <ViewButton onClick={() => setShowSummary(true)}>
          <Text margin="0px" fontSize={detailsFontSize} fontWeight="700">
            See Details
          </Text>
          <MdArrowForwardIos className="icon" />
        </ViewButton>
      </Flex>
      <OrderCardContent>
        <ImgContainer>
          <Image src={formatImageUrl(order.OrderItems[0]?.Inventory?.Images[0]?.smallImageOnlineURL, 'product')} alt="product image" />
        </ImgContainer>
        <Flex justifyContent={isMobile ? 'space-evenly' : 'space-between'} flexFlow="column" width="89%" mobileDirection="column">
          <Flex grid={true} alignItems="center" justifyContent="space-between">
            <Text color={blackLight} fontSize={detailsFontSize} fontWeight={isMobile ? '' : '700'}>
              {OrderItems.map((item) => `${item.inventoryName} `)}
            </Text>
            {!isMobile && (
              <Text mobile textAlign={'right'} color={blackLight} fontSize={descriptionFontSize} fontWeight="700">
                Order ID: {orderNumber}
              </Text>
            )}
          </Flex>

          {!isMobile && (
            <Flex justifyContent="space-between" mobileDirection="column">
              <Flex alignItems="center" justifyContent="space-between">
                <Text mobile color={blackLight} fontSize={primaryFontSize} fontWeight="700">
                  Date:
                </Text>
                <Text mobile color={blackLight}>
                  {moment(createdAt).format('Do MMM YYYY, h:mm A')}
                </Text>
              </Flex>
              <Flex alignItems="center" justifyContent="space-between">
                <Text mobile color={blackLight} fontSize={primaryFontSize} fontWeight="700">
                  Quantity:
                </Text>
                <Text mobile color={blackLight} fontSize={primaryFontSize}>
                  {OrderItems.length}
                </Text>
              </Flex>
              <Flex alignItems="center" justifyContent="space-between">
                <Text mobile color={blackLight} fontSize={primaryFontSize} fontWeight="700">
                  Total:
                </Text>
                <Text mobile color={blackLight} fontSize={primaryFontSize} fontWeight="700">
                  {showAmount(totalAmount)}
                </Text>
              </Flex>
            </Flex>
          )}
          {isMobile && (
            <Flex alignItems="center" justifyContent="space-between">
              <Flex>
                <Text mobile color={blackLight} fontSize={descriptionFontSize}>
                  {showAmount(totalAmount)} x {OrderItems.length}
                </Text>
              </Flex>
              <Flex alignItems="center" justifyContent="end">
                <Text mobile color={blackLight} fontSize={primaryFontSize} fontWeight="700">
                  Date:
                </Text>
                <Text mobile color={blackLight}>
                  {moment(createdAt).format('DD-MM-YY')}
                </Text>
              </Flex>
            </Flex>
          )}
        </Flex>
      </OrderCardContent>
      {showSummary && (
        <PopupCard maxWidth="800px" close={close}>
          <OrderDetail refetch={refetch} memberData={memberData} order={order} setShowSummary={setShowSummary} />
        </PopupCard>
      )}
    </OrderCardContainer>
  );
};

export default OrderCard;
