import styled from 'styled-components';
import { media } from '../../constants/theme';
import { PageContainer } from '../../GlobalStyles/FixedGlobal.style';
import bg1 from '../../img/background-1.svg';
import bg2 from '../../img/background-2.svg';
import bg3 from '../../img/background-3.svg';
import bg4 from '../../img/background-4.svg';

export const LandingPageContainer = styled(PageContainer)`
  position: relative;
  height: max-content;
  background: #fff;
  .heading {
    margin: 1rem;
  }
  ${media.tablet} {
    background: white;
  }
`;

export const ProductsListWrapper = styled.div`
  padding: 30px 2rem;
  max-width: 100%;

  ${media.mobile} {
    padding: 30px 1rem;
  }
`;

export const ProductsListContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: center;
`;
