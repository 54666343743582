import React, { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Button, ErrorMessage } from '../../GlobalStyles/forms.style';
import { Required } from '../../utils/formValidations.utils';
import { Colors } from '../../constants/theme';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Flex, Title, Input, FormContainer, InputBox, AccSettingRWrapper, Hr, Container } from './account.style';
import { isloading, hasfailed, resetFetch, hasSucceed } from '../../store/actions/fetchAction';
import PasswordField from '../../components/PasswordField';

const CHANGE_PASSWORD = gql`
  mutation memberPasswordChange($currentPassword: String!, $newPassword: String!) {
    memberPasswordChange(currentPassword: $currentPassword, newPassword: $newPassword) {
      success
      message
    }
  }
`;

interface Props {
  handleLogout: Function;
}

interface CHANGE_PASSWORD_TYPE {
  changePassword: {
    success: boolean;
  };
}

const ChangePassword: FunctionComponent<Props> = ({ handleLogout }) => {
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const [showSuccessMessage, setShowSuccessMessage] = useState<string>('');
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showconfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const [newPasswordMatchCheck, setNewPasswordMatchCheck] = useState<string>('');
  const [confirmPasswordMatchCheck, setConfirmPasswordMatchCheck] = useState<string>('');

  const [changePassword] = useMutation<any>(CHANGE_PASSWORD, {
    onCompleted() {
      setShowSuccessMessage('Password Changed Successfully');
      handleLogout();
    }
  });

  const handleNewPasswordChange = (event: any) => {
    const newPassword = event.target.value;
    setNewPasswordMatchCheck(newPassword);
  };

  const handleConfirmPasswordChange = (event: any) => {
    const confirmPassword = event.target.value;
    setConfirmPasswordMatchCheck(confirmPassword);
  };

  const onSubmit = async (data: any) => {
    const { currentPassword, newPassword, confirmPassword } = data;
    if (newPassword === confirmPassword) {
      dispatch(isloading());
      try {
        await changePassword({
          variables: {
            currentPassword: `${currentPassword}`,
            newPassword: `${newPassword}`
          }
        });
        dispatch(hasSucceed('Password Changed Successfully'));
      } catch (e) {
        dispatch(hasfailed(e instanceof Error ? `${e}` : 'An unknown error has occured'));
      }
    }
  };
  return (
    <Container style={{ width: '100%' }}>
      <Title subtitle>Change Password</Title>
      <Hr />
      <FormContainer onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Flex>
          <AccSettingRWrapper width="50%" direction="column">
            <PasswordField placeholder="Current Password" register={register} required={Required} errors={errors} />

            <InputBox style={{ borderColor: errors.newPassword && '#F75151' }}>
              <Input
                name="newPassword"
                ref={register(Required)}
                type={showNewPassword ? 'text' : 'password'}
                placeholder="New Password"
                onChange={handleNewPasswordChange}
              />
              <span>
                {showNewPassword ? (
                  <i className="im im-eye" onClick={() => setShowNewPassword(false)} />
                ) : (
                  <i className="im im-eye-off" onClick={() => setShowNewPassword(true)} />
                )}
              </span>
              {errors.newPassword && <ErrorMessage>This field is required</ErrorMessage>}
            </InputBox>

            <InputBox style={{ borderColor: errors.confirmPassword && '#F75151' }}>
              <Input
                name="confirmPassword"
                ref={register(Required)}
                type={showconfirmPassword ? 'text' : 'password'}
                placeholder="Confirm Password"
                onChange={handleConfirmPasswordChange}
              />
              <span>
                {showconfirmPassword ? (
                  <i className="im im-eye" onClick={() => setShowConfirmPassword(false)} />
                ) : (
                  <i className="im im-eye-off" onClick={() => setShowConfirmPassword(true)} />
                )}
              </span>
              {errors.confirmPassword && <ErrorMessage>This field is required</ErrorMessage>}
              {newPasswordMatchCheck != confirmPasswordMatchCheck && (
                <ErrorMessage>Password and confirmation password must be matched</ErrorMessage>
              )}
            </InputBox>

            <div>
              <ErrorMessage>{showSuccessMessage}</ErrorMessage>
            </div>
          </AccSettingRWrapper>
        </Flex>
        <Flex alignItems="center" justifyContent="center" width="50%">
          <Button>Update</Button>
        </Flex>
      </FormContainer>
    </Container>
  );
};

export default ChangePassword;
