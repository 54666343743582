import styled from 'styled-components';
import { Colors, media } from '../../constants/theme';
import { PageContainer } from '../../GlobalStyles/FixedGlobal.style';

const { lightBlue } = Colors;
const { mobile, tablet } = media;

interface ISearchContainer {
  width?: string;
}

export const ShopViewContainer = styled(PageContainer)`
  display: block;

  .inner_container {
    min-height: 100vh;
  }
`;

export const SearchContainer = styled.div<ISearchContainer>`
  display: flex;
  width: ${({ width }) => width ?? '100%'};
  min-width: 100%;
  background: #f6f8fb;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  min-height: 45px;
  height: 45px;

  .search-button {
    height: 100%;
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff6ea !important;
    border-radius: 0 8px 8px 0;
    border: none !important;
    cursor: pointer;

    img {
      margin: 0;
      width: 20px;
    }
  }

  ${tablet} {
    margin-top: 1rem;
  }
  ${mobile} {
  }
`;
export const Container = styled.form<{ width?: string }>`
  display: flex;
  width: ${({ width }) => width ?? '50%'};
  background: transparent;
  height: 70%;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-right: 1px solid ${lightBlue};
  position: relative;

  #search-icon-mobile {
    position: absolute;
    width: 20px;
    left: 10px;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  position: absolute;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  top: -50px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f6f6f6;
  z-index: 200;

  .input-container {
    position: relative;
    width: 90%;
  }

  #close {
    width: 25px;
  }
  #search-icon {
    position: absolute;
    right: 1.5rem;
    bottom: 0.8rem;
    width: 40px;
  }

  ${tablet} {
    top: 0;

    #container {
      max-height: 4rem;
    }
    .input-container {
      height: 50px;
      margin-right: 1rem;
      width: 85%;
      margin-left: 1rem;
    }
    #close {
      width: 15px;
      margin: 0 auto;
    }
    #search-icon {
      width: 25px;
      right: 0px;
    }
  }
  ${mobile} {
    top: -50px;
  }
`;
