const customMediaQuery = (maxWidth: number): string => `@media (max-width: ${maxWidth}px)`;

export const media = {
  standarScreenLaptop: customMediaQuery(1440),
  mediumScreenLaptop: customMediaQuery(1280),
  smallScreenLaptop: customMediaQuery(1024),
  tablet: customMediaQuery(768),
  mobile: customMediaQuery(576),
  miniMobile: customMediaQuery(320)
};

export const Colors = {
  primaryColor: '#FFA412',
  secondaryColor: '#F75151',
  primaryGrey: '#4F4F4F',
  body: '#2F2F2F',
  white: '#FFFFFF',
  black: '#000000',
  transparentBlack: '#00000014',
  blackLight: '#607087',
  lightBlue: '#8196B3',
  darkBlue: '#2B3C54',
  backgroundColor: '#E5E5E5',
  lightGrey: '#828282',
  inputBorderColor: '#C7C7C7',
  green: '#8FD597',
  placeholderColor: '#B9B9B9',
  emptyStarColor: '#C4C4C4',
  overlayColor: '#607087b3',
  inActiveTab: '#F5F5F5',
  grey4: '#BDBDBD',
  buttonHover: '#b58336',
  onlineGreen: '#6CFF7C',
  red: '#FF0000',
  lightRed: '#FCE9E9',
  lightOrange: '#FBEEDB',
  lightbg: '#F6F8FB',
  hoverPrimary: '#FBF6F0',
  darkGreen: '#219653',
  blue: '#1976D2',
  faintBlue: '#E6F1FC',
  lightGreen: '#DBF9E8',
  skyBlue: '#E4EBF4',
  line: '#E4EBF4',
  offWhite: '#F3F7FC',
  senderBubble: '#8196B2',
  receiverBubble: '#DAE2EC',
  deepBlue: '#1A222D',
  navyBlue: '#130F26',
  grey: '#CCC8C9',
  priceColor: '#181817',
  secondaryGrey: '#607087',
  tabColor: '#9EA8B7'
};

export const FontSizes = {
  titleFont: '24px',
  bigFont: '22px',
  detailsFontSize: '14px',
  descriptionFontSize: '12px',
  primaryFontSize: '16px',
  secondaryFontSize: '18px',
  headingFont: '28px',
  tinyDescriptionFontSize: '10px',
  subTitleFont: '20px'
};

export const FontFamily = {
  primaryFont: 'Roboto',
  secondaryFont: 'Helvetica'
};

export const BoxShadows = {
  primaryBoxShadow: '4px 4px 30px rgba(35, 54, 79, 0.1)',
  SmallerBoxShadow: '4px 4px 20px rgba(24, 88, 177, 0.2)',
  cardBoxShadow: '4px 4px 30px rgba(23, 46, 78, 0.1)'
};
