import React, { Fragment, FunctionComponent } from 'react';
import { BsArrowRightShort } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { Container, Crumb } from './BreadCrumb.styles';

interface Props {
  crumbs: any;
}

const BreadCrumb: FunctionComponent<Props> = ({ crumbs }) => {
  const history = useHistory();
  const pushToRoute = (route: string) => {
    route !== null && history.push(route);
  };
  return (
    <Container>
      {crumbs.map((crumb: any, i: number) => (
        <Fragment key={i}>
          <Crumb className="crumb" onClick={() => pushToRoute(crumb.route)}>
            {crumb.name}
          </Crumb>
          <BsArrowRightShort className="arrow" />
        </Fragment>
      ))}
    </Container>
  );
};

export default BreadCrumb;
