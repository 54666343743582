import React, { useEffect, useRef } from 'react';
import { Flex, Input } from '../../GlobalStyles/CustomizableGlobal.style';
import { Wrapper } from './shop.style';
import ChevronLeft from '../../img/chevron-left.svg';
import SearchIcon from '../../img/search-blue.svg';
import { Colors } from '../../constants/theme';
import { windowAdapter } from '../../utils/serverClientAdapter.utils';

const { lightBlue } = Colors;

interface SearchProps {
  searchTerm: string;
  openSearch: boolean;
  setSearchTerm: (value: string) => void;
  setOpenSearch: (value: boolean) => void;
  width: number;
  handleInventorySearch: Function;
}

const MobileShopSearch: React.FC<SearchProps> = ({
  searchTerm,
  setSearchTerm,
  setOpenSearch,
  width,
  handleInventorySearch,
  openSearch
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (windowAdapter.history) {
      if (openSearch) {
        const stateKey = 'search';
        windowAdapter.history.pushState({ stateKey }, '');
      } else {
        windowAdapter.history.go(-1);
      }
    }
  }, [openSearch]);

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setOpenSearch(false);
    handleInventorySearch(e);
  };

  return (
    <Wrapper>
      <Flex
        id="container"
        alignItems="center"
        justifyContent="space-between"
        padding="1rem"
        width="100%"
        maxHeight="6rem"
        background="white"
      >
        <img id="close" src={ChevronLeft} alt="close" onClick={() => setOpenSearch(false)} />
        <form className="input-container" onSubmit={handleSubmit}>
          <Input
            margin="0"
            ref={inputRef}
            padding={`0 ${width > 576 ? '5rem' : '3rem'} 0 1rem`}
            height="100%"
            width={`${width > 576 ? 'calc(100% - 5rem)' : 'calc(100% - 3rem)'}`}
            placeholder="Search this store..."
            borderColor="transparent"
            background="#F6F8FB"
            color={lightBlue}
            fontSize="1.2rem"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <img
            id="search-icon"
            src={SearchIcon}
            alt="search"
            onClick={(e) => {
              setOpenSearch(false);
              handleInventorySearch(e);
            }}
          />
        </form>
      </Flex>
    </Wrapper>
  );
};

export default MobileShopSearch;
