import { USER_CHECKOUT_INFO, PAYMENT_METHOD } from "../actionTypes";

export const userInfo = (userData: any) => {
  return {
    type: USER_CHECKOUT_INFO,
    userData: userData,
  };
};

export const checkoutMethod = (method: any) => {
  return {
    type: PAYMENT_METHOD,
    method: method,
  };
};
