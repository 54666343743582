import React, { FunctionComponent, useState } from 'react';
import { ErrorContainer, ErrorIcon, ErrorMessage } from './ErrorComponent.style';

interface Props {
  message: string;
  disableClick?: boolean;
}

const Error: FunctionComponent<Props> = ({ message, disableClick }) => {
  const [closeCard, setCloseCard] = useState<boolean>(true);
  const handleClose = () => {
    if (!disableClick) {
      setCloseCard(false);
    }
  };
  return (
    <>
      {closeCard && (
        <ErrorContainer>
          <ErrorIcon onClick={() => handleClose()} className="fas fa-times-circle" />
          <ErrorMessage>
            {message === 'Failed to fetch' || message === 'ApolloError: Failed to fetch'
              ? 'Please check your internet connection'
              : message}
          </ErrorMessage>
        </ErrorContainer>
      )}
    </>
  );
};

export default Error;
