import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AppState } from '../../../store/reducers/index';
import { formatName } from '../../../utils/format.utils';
import CloseIcon from '../../../img/close.svg';
import Warning from '../../../img/warning.svg';
import Info from '../../../img/info-ellipse.svg';
import EyeOn from '../../../img/eye.svg';
import { AiOutlineCaretDown } from 'react-icons/ai';
import {
  ShopHeaderContainer,
  ShopBannerContainer,
  Container,
  ShopImage,
  ShopInfoBanner,
  LeftContent,
  RightContent,
  ShopImageContainer,
  MessageBox,
  ShopName,
  ProductChatContainer,
  ChatProductCard
} from './shopHeader.style';
import { formatImageUrl } from '../../../utils/format.utils';
import formatDistance from '../../../utils/distanceFromat.utils';
import DefaultShopImage from '../../../img/sampleShopImg.jpg';
import { useMutation, useSubscription } from '@apollo/client';
import Moment from 'react-moment';
import moment from 'moment';
import { GET_ONLINE_STATUS_TYPE } from '../../../interfaces/shop.interface';
import { GET_ONLINE_STATUS } from '../../../schema/shop.schema';
import PopupCard from '../../PopupCard';
import { Button, Flex, Span, Text, TextArea } from '../../../GlobalStyles/CustomizableGlobal.style';
import { REQUEST_LOCATION_FEE, SEND_MESSAGE_TO_SHOP } from '../../../schema/messages.schema';
import { Colors } from '../../../constants/theme';
import { ContactOptions } from '../style';
import { Input } from '../../../GlobalStyles/forms.style';
import { OptionType } from '../../../interfaces/FilterSelect.interface';
import LocationEntry from '../../LocationEntry';
import Loader from '../../LoaderComponent';
import { toggleSnackbarOpen } from '../../../store/actions/snackbarActions';
import { hasfailed, isloading, resetFetch } from '../../../store/actions/fetchAction';
import Error from '../../ErrorComponent';
import useWindowDimensions from '../../../utils/windowsDimension.utils';
import { getItem } from '../../../utils/localStorage.utils';
const mapStateToProps = (state: AppState) => {
  return { currentShopCardId: state.ShopCardId.currentShopCardId };
};

const infoList = [
  `We advice you only use the merchant mobile number for enquiry 
only if they are not responding to their messages on this platform.`,
  `Do not engage in any financial transactions outside Timart platform 
for your own financial security. `,
  `We advise you to make use of the convenient methods we have 
provided for payment and service delivery.`
];

interface Props {
  shopId?: string;
  name: string;
  location: string;
  distance?: number;
  latitude?: number;
  longitude?: number;
  image: string;
  shopPhone: string;
  loading: boolean;
  shopTag: string;
  imageUrl?: string;
  description?: string;
  inventoryId?: string;
  isProductLocationChat?: boolean;
  setProductLocationChat?: Function;
  searchBar?: Function;
  productPrice?: string;
  productName?: string;
}

const ShopHeader: FunctionComponent<Props> = ({
  image,
  shopId,
  name,
  description,
  imageUrl,
  location,
  shopPhone,
  distance,
  loading,
  shopTag,
  inventoryId,
  isProductLocationChat,
  setProductLocationChat,
  searchBar,
  productName,
  productPrice
}) => {
  const { data } = useSubscription<GET_ONLINE_STATUS_TYPE>(GET_ONLINE_STATUS, {
    variables: { shopId }
  });
  const [isOnline, setIsOnline] = useState<boolean>(false);
  const [lastSeen, setLastSeen] = useState<number>(0);
  const [isContact, setContact] = useState<boolean>(false);
  const [isProductChat, setProductChat] = useState<boolean>(false);
  const [isLocationChat, setLocationChat] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [popUp, setPopup] = useState<boolean>(false);
  const [selectedState, setSelectedState] = useState<OptionType>();
  const [selectedCity, setSelectedCity] = useState<OptionType>();
  const [phoneWarning, setPhoneWarning] = useState<boolean | null>(false);
  const [showNumber, setShowNumber] = useState<boolean>(false);
  const [understand, setUnderstand] = useState<boolean>(false);

  const history = useHistory();
  const routerLocation = useLocation();
  const currentPath = routerLocation.pathname;
  const { width } = useWindowDimensions();
  const isMobile = width <= 576;
  const isSession = getItem('session');

  const dispatch = useDispatch();
  const isProductPage = useLocation().pathname.split('/')[1] === 'product';

  const fetchMessage = useSelector((state: AppState) => state.fetch.message);
  const status = useSelector((state: AppState) => state.fetch.status);

  const { primaryColor, lightbg, white } = Colors;

  useEffect(() => {
    if (!data?.shopOnlineStatus) {
      return;
    }
    const { isOnline, lastSeenDate } = data.shopOnlineStatus;
    setIsOnline(isOnline);

    if (isOnline) {
      return;
    }

    setLastSeen(parseInt(lastSeenDate));
    const difference = moment(new Date()).diff(parseInt(lastSeenDate), 'minutes');

    if (difference > 5) {
      return;
    }

    if (data?.shopOnlineStatus?.isOnline === false && difference <= 5) {
      setIsOnline(true);
      const setOnline = setTimeout(() => {
        setIsOnline(false);
      }, difference * 60 * 1000);

      return function cleanup() {
        clearTimeout(setOnline);
      };
    }
  }, [data]);

  const [sendMessageToShop] = useMutation<any>(SEND_MESSAGE_TO_SHOP, {
    onCompleted() {
      history.push(`/my-account/messages?shop=${shopId}`);
    },
    onError(e) {
      dispatch(hasfailed(e.message));
    }
  });

  const [requestLocationShippingFee] = useMutation<any>(REQUEST_LOCATION_FEE, {
    onCompleted() {
      setContact(false);
      dispatch(toggleSnackbarOpen('Request Sent To Merchant'));
    }
  });

  useEffect(() => {
    if (isProductLocationChat!) {
      setContact(true);
    }
  }, [isProductLocationChat]);

  const sendMessage = async (e: any) => {
    e.preventDefault();
    dispatch(isloading());
    try {
      await sendMessageToShop({
        variables: {
          shopId: shopId,
          message: message,
          inventoryId
        }
      });
      setContact(false);
      setMessage('');
    } catch (e) {
      alert(e instanceof Error ? `${e}` : 'An unknown error has occured');
    }
  };
  const requestDelivery = async () => {
    try {
      if (selectedState && selectedCity) {
        await requestLocationShippingFee({
          variables: {
            shopId,
            stateId: String(selectedState?.value),
            cityId: String(selectedCity?.value)
          }
        });
        setLocationChat(false);
        setProductLocationChat!(false);
      } else {
        dispatch(toggleSnackbarOpen('Please select a state and city'));
      }
    } catch (e) {
      setProductLocationChat!(false);
      if (e instanceof Error) {
        dispatch(toggleSnackbarOpen(`${e}`));
      } else {
        dispatch(toggleSnackbarOpen('An unknown error occurred'));
      }
    }
  };

  const closeModal = (e: any) => {
    const locationWrapper = document.querySelector('.location-wrapper');
    const closeButton = document.querySelector('#close');
    if (e.target.isEqualNode(locationWrapper) || e.target.isEqualNode(closeButton)) {
      setContact(false);
      setProductChat(false);
      setLocationChat(false);
      setProductLocationChat && setProductLocationChat(false);
      return true;
    }
  };

  return (
    <ShopHeaderContainer isOnline={isOnline}>
      {!loading && (
        <ShopBannerContainer>
          <ShopInfoBanner>
            <LeftContent isOnline={isOnline}>
              <Container isOnline={isOnline}>
                <ShopImageContainer>
                  <ShopImage src={image ? formatImageUrl(image, 'shop') : DefaultShopImage} alt="shops" />
                </ShopImageContainer>
              </Container>
              <div className="details">
                <Link to={`/${shopTag}`}>
                  <p className="text shop_name">{formatName(name)}</p>
                </Link>
                {location && <p className="text">{formatName(location)}</p>}
                {shopPhone && (
                  <div id="phone" onClick={() => setPhoneWarning(understand ? false : true)}>
                    <a className="text phone" href={understand ? `tel:${shopPhone}` : '#'}>
                      {understand ? shopPhone : '080 XXXX XXXX'}
                    </a>
                    <img src={EyeOn} alt="see number" />
                  </div>
                )}
                <div>
                  <span id="status-icon" />
                  {isOnline ? (
                    <p className="text status online">Merchant is online now</p>
                  ) : (
                    <p className="text status">
                      Last seen{' '}
                      {lastSeen > 0 ? (
                        <Moment fromNow ago>
                          {lastSeen}
                        </Moment>
                      ) : (
                        '...'
                      )}{' '}
                      ago
                    </p>
                  )}
                </div>
              </div>
            </LeftContent>
            <RightContent>
              {popUp && <div className="remove-popup"></div>}
              {distance && <p className="distance">{formatDistance(distance)} Away from you</p>}

              <div id="search-wrapper">{searchBar && width >= 768 && searchBar()}</div>
              <div className="contact-container">
                <button className="contact-button">
                  Contact Merchant <AiOutlineCaretDown className="caret-icon" />
                </button>

                <div className="chat">
                  <ContactOptions onClick={() => setContact(true)}>Chat Seller</ContactOptions>
                  {isProductPage && (
                    <ContactOptions
                      onClick={() => {
                        setContact(true);
                        setProductChat(true);
                      }}
                    >
                      Ask about this product
                    </ContactOptions>
                  )}
                  <ContactOptions
                    onClick={() => {
                      setContact(true);
                      setProductChat(false);
                      setLocationChat(true);
                    }}
                  >
                    Request delivery to your location
                  </ContactOptions>
                </div>
              </div>
            </RightContent>
          </ShopInfoBanner>
        </ShopBannerContainer>
      )}
      {isContact && (
        <Flex className="location-wrapper" onClick={closeModal}>
          <Flex direction="column" margin="2rem 0 0 0" width="400px" className="location">
            <img src={CloseIcon} id="close" alt="close" onClick={closeModal} style={{ background: 'transparent' }} />
            <Flex direction="column" width="100%">
              <Flex alignItems="center" justifyContent="space-between" width="100%" margin="0 0 10px 0">
                <Span fontWeight="500" fontSize="16px" color="#4F4F4F">
                  {formatName(name)}
                </Span>
              </Flex>
              <Flex justifyContent="flex-start" width="100%" margin="0 0 1rem 0">
                {isOnline ? (
                  <Span fontSize="12px" color="#6CFF7C">
                    {' '}
                    Merchant is online now
                  </Span>
                ) : (
                  <Span color="#B9B9B9" fontSize="12px">
                    {' '}
                    Merchant is offline
                  </Span>
                )}
              </Flex>
              <MessageBox>
                {isContact && !isProductChat && !isLocationChat && !isProductLocationChat && (
                  <TextArea
                    width="calc(100% - 20px)"
                    minWidth="calc(100% - 20px)"
                    height="100px"
                    padding="10px"
                    placeholder="Type your message"
                    onChange={(e) => setMessage(e.target.value)}
                  />
                )}
                {isProductChat && (
                  <ProductChatContainer>
                    <ChatProductCard>
                      <div>
                        <h4>{name}</h4>
                        <p id="product-name">
                          {productName} <span>{productPrice}</span>
                        </p>
                      </div>
                      <div>
                        <img src={imageUrl} alt="product image" id="img" />
                      </div>
                    </ChatProductCard>
                    <Input
                      name="message"
                      type="text"
                      placeholder="Type your message"
                      style={{ border: 'none', margin: 0 }}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </ProductChatContainer>
                )}

                {(isLocationChat || isProductLocationChat) && (
                  <Flex direction="column">
                    <Flex alignItems="center" justifyContent="space-between" width="100%">
                      <LocationEntry
                        flexDirection="column"
                        padding="10px 0"
                        selectedCity={selectedCity}
                        setSelectedCity={setSelectedCity}
                        selectedState={selectedState}
                        setSelectedState={setSelectedState}
                      />
                    </Flex>
                  </Flex>
                )}

                {isLocationChat || isProductLocationChat ? (
                  <Button width="100%" type="button" borderRadius="6px" margin="25px 0 0 0" onClick={requestDelivery}>
                    Request Delivery
                  </Button>
                ) : (
                  <Button
                    width="100%"
                    type="submit"
                    borderRadius="6px"
                    disabled={message.length < 1}
                    disable={message.length < 1}
                    margin="25px 0 0 0"
                    onClick={(e) => sendMessage(e)}
                  >
                    Send Message
                  </Button>
                )}
              </MessageBox>
            </Flex>
          </Flex>
        </Flex>
      )}
      {status === 'error' && (
        <PopupCard close={() => resetFetch()}>
          <Error message={fetchMessage} disableClick={true} />
          {!isSession && <Button onClick={() => history.push(`/login?redirect=${currentPath}`)}>Login</Button>}
        </PopupCard>
      )}
      {status === 'loading' && (
        <PopupCard transparent>
          <Loader />
        </PopupCard>
      )}
      {phoneWarning && (
        <PopupCard
          maxWidth={width < 577 ? '90%' : width < 1000 ? '70%' : '35%'}
          width={width < 577 ? '90%' : width < 1000 ? '70%' : '35%'}
          close={() => {
            setPhoneWarning(false);
            setUnderstand(false);
          }}
        >
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            background="white"
            padding={`${isMobile ? '2rem 0 0 0' : '1rem 0 0 0'}`}
          >
            <img src={Warning} alt="warning" width="150px" />
            <Flex direction="column" gap="1rem" margin="2rem 0 0 0">
              {infoList.map((text: string, i) => {
                return (
                  <Flex alignItems="center" justifyContent="flex-start" gap="2rem" width="100%" key={i}>
                    <img src={Info} alt="info-icon" width="50px" />
                    <Text width="70%" color="#4F4F4F" margin="0" fontSize={isMobile ? '0.9rem' : '1rem'}>
                      {text}
                    </Text>
                  </Flex>
                );
              })}

              <Button
                width="100%"
                borderRadius="8px"
                margin="2rem 0 0 0"
                onClick={() => {
                  setPhoneWarning(false);
                  setUnderstand(true);
                }}
              >
                I Understand
              </Button>
            </Flex>
          </Flex>
        </PopupCard>
      )}
    </ShopHeaderContainer>
  );
};

export default connect(mapStateToProps)(ShopHeader);
