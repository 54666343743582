import styled from 'styled-components';
import { Colors, FontSizes, media } from '../../constants/theme';

const { line, primaryColor } = Colors;
const { detailsFontSize } = FontSizes;
const { smallScreenLaptop, tablet, mobile } = media;

export const StateCityContainer = styled.div<{ grid: number }>`
  height: auto;
  max-height: 400px;

  .all-state {
    display: none;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(${({ grid }) => grid}, 1fr);
    margin-bottom: 1rem;
    padding-top: 10px;
    overflow-y: scroll;
    padding-right: 10px;
    padding-bottom: 40px;
    height: auto;
    max-height: 284px;
    grid-gap: 1rem;
  }

  .grid-item {
    display: flex;
    text-align: center;
    justify-content: space-between;
    height: 30px;
    max-height: 30px;

    &:hover {
      .city-state-text,
      .count-text {
        color: ${primaryColor};
        cursor: pointer;
      }
    }

    img {
      width: 10px;
    }
  }

  .header-text {
    cursor: pointer;

    &:hover {
      color: ${primaryColor};
    }
  }

  ${smallScreenLaptop} {
    .grid-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  ${tablet} {
    .grid-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  ${mobile} {
    height: auto;
    max-height: unset;
    width: auto;
    overflow-x: unset;

    .grid-container {
      display: flex;
      flex-direction: column;
      overflow-y: unset;
      height: auto;
      max-height: unset;
      width: auto;
      padding: 0px;
    }

    .grid-item {
      padding: 0 1rem;
    }

    .city-state-text {
      padding: 0;
    }

    .flex-grid {
      gap: 0;
      max-height: unset;
    }

    .header-text {
      display: none;
    }

    .state-city-item {
      padding: 0 1rem;
      justify-content: space-between;
    }

    .all-state {
      display: flex;
    }
  }
`;

export const LocationHeader = styled.div`
  width: 100%;
  height: fit-content;
  border-bottom: 1px solid ${line};
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;

  .back-arrow-mobile {
    display: none;
  }

  .back-arrow-desktop {
    display: block;
    cursor: pointer;
  }

  ${mobile} {
    margin: 4rem 0 1rem 0;

    .back-arrow-mobile {
      display: block;
      cursor: pointer;
      margin-left: 1rem;
    }

    .back-arrow-desktop {
      display: none;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  position: relative;

  ${mobile} {
    justify-content: space-between;
    width: 100%;
  }
`;

export const InputField = styled.input`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f4f6f9;
  font-size: ${detailsFontSize};
  outline: none;
  border-radius: 4px 0px 0px 4px;
  border: none;
  width: 300px;
  height: 40px;
  padding: 0 50px 0 16px;

  ${tablet} {
    width: 150px;
  }

  ${smallScreenLaptop} {
    width: 250px;
  }

  ${mobile} {
    width: calc(100vw - 160px);
    align-self: right;
    position: absolute;
    left: 2rem;
    top: -1rem;
    right: 0px;
  }
`;

export const SearchButton = styled.button`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: ${primaryColor};
  border-radius: 4px;
  cursor: pointer;

  img {
    width: 1.2rem;
    height: 100%;
  }
  ${smallScreenLaptop} {
    height: 40px;
    width: 50px;

    img {
      width: 1rem;
    }
  }
  ${tablet} {
    width: 40px;

    img {
      width: 15px;
    }
  }
  ${mobile} {
    display: none;
  }
`;

export const ClippedText = styled.p`
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 5px 0 0;
  color: #4f4f4f;

  &:hover::after {
    content: attr(data-tip);
    position: absolute;
    top: 27px;
    left: -8px;
    padding: 4px 8px;
    background-color: #000;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    z-index: 10;
  }
`;
