import styled from 'styled-components';
import { Colors, FontSizes, FontFamily, media } from '../constants/theme';

const { primaryColor, white, lightGrey, inputBorderColor, black, blackLight, placeholderColor, primaryGrey } = Colors;
const { descriptionFontSize, bigFont, detailsFontSize } = FontSizes;
const { primaryFont } = FontFamily;
const { mobile } = media;

interface Props {
  orange?: boolean;
  checkout?: boolean;
}

interface FormModalProps {
  width?: string;
}

export const FormModalContainer = styled.div<FormModalProps>`
  background-color: ${white};
  width: ${({ width }) => width || '50%'};
  overflow: auto;
  max-width: 100%;
  border-radius: 8px;
  padding: ${({ width }) => (width ? '' : '1rem')};

  &::-webkit-scrollbar {
    width: 5px;
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: ${primaryColor};
  }

  ${mobile} {
    width: 100%;
    top: 38px;
  }
`;

export const FormContents = styled.div`
  padding: 10px;
  max-width: 100%;
`;

export const Title = styled.h1`
  color: ${primaryColor};
  text-transform: uppercase;
  font-size: ${bigFont};
  margin: 0;
`;

export const ModalDescription = styled.p`
  color: ${primaryGrey};
  font-size: ${detailsFontSize};
  margin: 0;
  text-align: left;

  ${(props: Props) =>
    props.orange &&
    `
        color: ${primaryColor};
    `}
  ${(props: Props) =>
    props.checkout &&
    `
      color: ${blackLight};
      font-weight: 700;
      font-size: 18px;
      margin: 20px 0;
    `}
`;

export const TextFields = styled.form`
  display: flex;
  flex-flow: column;
  width: 100%;

  div {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
`;

export const Input = styled.input`
  height: 50px;
  border: 1px solid ${inputBorderColor};
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
  box-sizing: border-box;
  outline: none;
  width: 100%;

  &::placeholder {
    color: ${placeholderColor};
  }

  &:focus {
    border-color: ${primaryColor};
  }
`;

export const PasswordField = styled(Input).attrs({
  as: 'div'
})`
  display: flex;
  align-items: center;
  justify-content: space-between;

  i {
    color: ${lightGrey};
    font-size: ${bigFont};
    align-self: self-end;
  }

  input {
    height: 100%;
    width: 90%;
    border: none;
  }

  input:focus,
  input:focus-visible {
    border: none;
    outline: none;
  }

  input::placeholder {
    color: ${placeholderColor};
  }
`;

interface SelectProps {
  width?: string;
}

export const Select = styled.select<SelectProps>`
  height: 50px;
  border: 1px solid ${inputBorderColor};
  color: black;
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
  outline: none;
  width: ${({ width }) => width};
  background: ${white};

  option:checked {
    color: ${white};
    background-color: ${primaryColor};
  }
`;

export const Message = styled.textarea`
  height: 170px;
  border: 1px solid ${inputBorderColor};
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
  outline: none;
  font-family: ${primaryFont};
`;

interface ButtonProps {
  width?: string;
  contact?: boolean;
  shipping?: boolean;
  margin?: string;
  backgroundColor?: string;
  cursor?: string;
  opacity?: string;
}

export const Button = styled.button<ButtonProps>`
  width: ${({ width }) => (width ? width : '100%')};
  cursor: ${({ cursor }) => (cursor ? cursor : 'pointer')};
  opacity: ${({ opacity }) => opacity};
  height: 50px;
  border-radius: 5px;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : primaryColor)};
  color: ${white};
  text-align: center;
  border: none;
  outline: none;
  margin: ${({ margin }) => margin};

  &:hover {
    background-color: #f7ac3b;
  }

  ${({ contact }) =>
    contact &&
    `
        margin-top: 50px;
    `}

  ${({ shipping }) =>
    shipping &&
    `
          margin-top: 30px;
      `}
  ${mobile} {
    width: 100%;
  }
`;

export const Contact = styled.div`
  display: flex;
  align-items: center;
  margin: 20px;
  text-align: center;
  color: ${lightGrey};
  font-size: ${descriptionFontSize};
`;

export const Contacts = styled.div`
  display: inherit;
  margin-top: 40px;
`;

export const ModalIcon = styled.i`
  color: ${black};
  margin-right: 20px;
  font-size: 12px;
`;

export const Pinfo = styled.p`
  color: #b9b9b9;
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .link {
    color: ${primaryColor};
    text-decoration: none;
    padding: 0 5px;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      color: #521010;
    }
  }
`;

export const Link = styled.a`
  color: ${primaryColor};
  text-decoration: none;
  padding: 0 5px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: #521010;
  }
`;

export const Recover = styled.div`
  display: flex;
  font-size: ${descriptionFontSize};
  justify-content: flex-end;
  position: absolute;
  right: 0;
`;

export const ErrorMessage = styled(Link).attrs({ as: 'p' })`
  margin: 0;
  font-size: 10px;
`;

export const Error = styled(Link).attrs({ as: 'p' })`
  margin: 0;
  font-size: 10px;
`;
