import React from 'react';
import useHistory from '../../utils/history.utils';
import { getItem } from '../../utils/localStorage.utils';

const AuthenticatedHOC = (Component: any) => {
  return (props: any) => {
    const history = useHistory;

    if (!getItem('session')) {
      history.push(`/login?redirect=${history.location}`);
      return null;
    }

    return <Component {...props} />;
  };
};

export default AuthenticatedHOC;
