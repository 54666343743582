import { combineReducers } from 'redux';
import selectReport from './selectReportModule';
import clickButton from './clickedNavButton';
import checkoutFunctions from './checkoutReducer';
import counterFunctions from './counterReducer';
import stockCardFunctions from './stockCardReducer';
import inventoryStockReducer from './inventoryStockReducer';
import datePickerFunctions from './selectDateReducer';
import selectActiveShop from './selectShop';
import selectSalesReportRange from './selectSalesReportRange';
import landingNavButton from './landingNavButton';
import fetchFunctions from './fetchReducer';
import changeTabItem from './tabReducer';
import ShopCardId from './shopCardIdReducer';
import ShopInventoryId from './shopInventoryIdReducer';
import RedirectSearchResultPage from './redirectToResultReducer';
import CartCounterFunctions from './cartCounterReducer';
import fetchShopLimit from './fetchLimitReducer';
import UserDataFunctions from './userCheckoutInfo';
import snackbarFunctions from './snackbarReducer';
import selectCartFunctions from './addSelectionReducer';
import MessageReducer from './messageReducer';

const rootReducer = combineReducers({
  select: selectReport,
  clicked: clickButton,
  checkout: checkoutFunctions,
  stockCard: stockCardFunctions,
  inventoryData: inventoryStockReducer,
  activeShop: selectActiveShop,
  counter: counterFunctions,
  selectRange: selectSalesReportRange,
  datePicker: datePickerFunctions,
  landingNavButton: landingNavButton,
  fetch: fetchFunctions,
  changeTab: changeTabItem,
  ShopCardId: ShopCardId,
  ShopInventoryId: ShopInventoryId,
  redirect: RedirectSearchResultPage,
  CartCounter: CartCounterFunctions,
  fetchShopLimit: fetchShopLimit,
  UserData: UserDataFunctions,
  snackbar: snackbarFunctions,
  selectedCartItems: selectCartFunctions,
  messages: MessageReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
