import React, { FunctionComponent } from 'react';
import { ModalState } from '../../interfaces/state.interface';
import ShopList from './MerchantList';
import MerchantContainer from './merchant.style';
import BottomNav from '../../components/footer/bottomNav';
import WrapWithLocation from '../../components/WrapWithLocation';
import { Helmet } from 'react-helmet-async';
import { Title } from '../../constants/meta.constant';
import Header from '../../components/header/header';

const Merchant: FunctionComponent<ModalState> = ({ modalOpen }) => {
  return (
    <>
      <Helmet>
        <title>{`${Title} | All Shops`}</title>
        <meta name="description" content="Find favorite shops from our wide selection of shops" />
      </Helmet>
      <WrapWithLocation>
        <MerchantContainer bodyScroll={modalOpen}>
          <Header />
          <ShopList marginTop="150px" withSearch />
          <BottomNav />
        </MerchantContainer>
      </WrapWithLocation>
    </>
  );
};

export default Merchant;
