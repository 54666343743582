import styled from 'styled-components';
import { CounterText } from '../counter/counter.style';
import { Colors, FontSizes, media } from '../../constants/theme';
import { Flex } from '../../GlobalStyles/CustomizableGlobal.style';

interface Props {
  label?: boolean;
  fontSize?: string;
}

const { primaryColor, primaryGrey, white, tabColor } = Colors;
const { mobile, tablet, smallScreenLaptop } = media;
const { descriptionFontSize } = FontSizes;

interface SummaryContainerProps {
  onCheckoutPage?: boolean;
}

export const OrderSummaryContainer = styled.div<SummaryContainerProps>`
  display: flex;
  flex-direction: column;
  width: 65%;

  #clear-cart {
    cursor: pointer;
  }

  .cart-buttons {
    width: 40%;
    margin: 0 2em;
  }

  #cart-header-text {
    a {
      display: block;
      padding: 10px 20px;
      border: 1px solid ${primaryColor};
      color: ${primaryColor};
      border-radius: 5px;
      :hover {
        background-color: ${primaryColor};
        color: ${white};
      }
    }
  }

  ${smallScreenLaptop} {
    width: 100%;
  }
  ${tablet} {
    margin-top: 50px;
    width: 100%;
  }
  ${mobile} {
    margin-top: 3rem;
    width: 100%;
    margin: ${({ onCheckoutPage }) => (onCheckoutPage ? 'unset' : '')};

    .cart-buttons {
      margin: 0 1em;
    }

    .cart-text {
      font-size: ${descriptionFontSize};
      margin: 0 5px;
    }

    #cart-header-text {
      width: 40%;
      a {
        font-size: 14px;
        padding: 5px 10px;
      }
    }
  }
`;

export const CartItemsContainer = styled.div`
  margin: 5px 0;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: ${primaryColor};
  }
`;

export const TotalContainer = styled.div`
  width: 100%;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
  ${mobile} {
    width: 100%;
  }
`;

export const TotalText = styled(CounterText)`
  padding: 0;
  margin: 0;
  width: unset;
  ${({ label }: Props) =>
    label &&
    `
    font-weight: 600;
  `}
  font-size: ${({ fontSize }: Props) => (fontSize ? fontSize : '16px')};
`;
export const EmptyCart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  h1 {
    color: ${primaryGrey};
    font-size: 30px;
    padding: 0;
    margin: 0;
  }

  button {
    margin-top: 2rem;
    border: none;
    border-radius: 5px;
    padding: 8px 15px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    color: #fff;
    background: ${primaryColor};
    transition: ease-in-out all 0.3s;

    &:hover {
      background: #ff5e00;
    }
  }

  ${tablet} {
    h1 {
      font-size: 25px;
    }
    img {
      height: 250px;
    }
  }

  ${mobile} {
    margin-top: 50px;
    h1 {
      font-size: 18px;
    }
    img {
      height: 150px;
    }
    p {
      font-size: 14px;
    }
  }
`;
export const CartContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
  ${smallScreenLaptop} {
    width: 100%;
    flex-direction: column;
  }
`;
export const SummaryContainer = styled.div`
  width: 30%;
  max-width: 30%;
  min-width: 300px;
  margin-left: 20pxpx;
  box-shadow: 0px 4px 12px 0px #172e4e0f;
  padding: 15px 20px;
  max-height: 300px;
  box-sizing: border-box;
  .disabled-link {
    pointer-events: none;
    cursor: not-allowed;
  }
  ${smallScreenLaptop} {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    margin-left: 0;
  }
  ${mobile} {
    #delivery-info {
      font-size: 12px;
    }
  }
`;
export const ShopContainer = styled.div`
  box-shadow: 0px 4px 12px 0px #172e4e0f;
  margin-bottom: 20px;
  padding: 10px 20px 3px 20px;
  ${mobile} {
    margin-top: 20px;
    padding: 5px 5px;
  }
`;
export const ShopTitle = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  p {
    color: #8196b3;
    padding-left: 15px;
  }
  a {
    color: #ffa412;
    padding-left: 25px;
    display: flex;
    align-items: center;
    img {
      margin-right: 5px;
    }
  }
  ${mobile} {
    font-size: 16px;
  }
`;
interface ICheckBox {
  checked: boolean;
  radius?: string;
}
export const CheckBox = styled.label`
  padding: 0px;
  margin: 0px;
  border: 1px solid ${(props: ICheckBox) => (props.checked ? '#FFA412' : '#8196B3')};
  height: 16px;
  width: 16px;
  min-width: 16px;
  min-height: 16px;
  border-radius: ${(props: ICheckBox) => props.radius ?? '3px'};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    padding: 0px;
    margin: 0px;
    display: block;
    width: 12px;
    height: 12px;
    border-radius: ${(props: ICheckBox) => props.radius ?? '3px'};
    background: ${(props: ICheckBox) => (props.checked ? '#FFA412' : '#fff')};
  }
`;

export const TabContainer = styled(Flex)`
  width: 100%;
  margin-top: 0.5rem;

  p {
    width: 50%;
    text-align: center;
    padding: 0.75rem 0;
    cursor: pointer;
  }

  .tab {
    border-bottom: 1px solid ${tabColor};
  }

  .selected-tab {
    color: ${primaryColor};
    border-bottom: 3px solid ${primaryColor};
  }
`;
