import { SALES_RECORD_RANGE, selectModuleType } from "../actionTypes";
import { InitialState, salesReportType } from "../initialStates/selectSalesReportRange";

const selectSalesReportRange = (state: salesReportType = InitialState, action: selectModuleType) => {
  switch (action.type) {
    case SALES_RECORD_RANGE:
      return {
        ...state,
        range: action.payload,
      };

    default:
      return state;
  }
};

export default selectSalesReportRange;
