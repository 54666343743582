import React, { FunctionComponent } from 'react';
import { CItem, Contact, FooterCont, FooterSec, Info, Item, List, Section } from './style';
import locationIcon from '../../../img/w-location.svg';
import messageIcon from '../../../img/w-message.svg';
import paymentIcon from '../../../img/payments.svg';
import phoneIcon from '../../../img/w-phone.svg';
import { Link, useHistory } from 'react-router-dom';
import { Flex } from '../../../GlobalStyles/CustomizableGlobal.style';
import { FacebookIcon, InstagramIcon, TwitterIcon } from '../../../img/svgIcons';

const Footer: FunctionComponent = () => {
  return (
    <FooterCont>
      <FooterSec>
        <Info>
          <div>
            <img src="/timart-logo.svg" alt="logo" style={{ height: '50px' }} />
            <p>
              We appreciate your decision to shop with Timart for all your online needs, and we are excited to offer you the finest shopping
              experience. Thank you for choosing us!
            </p>
          </div>
        </Info>
        <Section>
          <h3>About</h3>
          <List>
            <Item>
              <a href=" https://gettimart.com/about">About Us</a>
            </Item>
            <Item>
              <a href="/faq">FAQ</a>
            </Item>
            <Item>
              <a href="https://blog.gettimart.com/">Timart Blog</a>
            </Item>
          </List>
        </Section>
        <Section>
          <h3>Solution</h3>
          <List>
            <Item>
              <a href="https://gettimart.com/download">Timart Business App</a>
            </Item>
            <Item>
              <a href="https://tmart.com.ng">Timart Online Store</a>
            </Item>
          </List>
        </Section>
        <Section>
          <h3>Delivery Coverage</h3>
          <List>
            <Item>
              <span>Abuja</span>
            </Item>
            <Item>
              <span>Minna</span>
            </Item>
            <Item>
              <span>
                Others <i style={{ color: '#F65151' }}>Coming Soon...</i>
              </span>
            </Item>
          </List>
        </Section>
        {/* <Section>
          <h3>Download</h3>
          <List>
            <Item>
              <a href="https://play.google.com/store/apps/details?id=com.quicktelsolution.timart_shop">Android</a>
            </Item>
            <Item>
              <a href="https://apps.apple.com/us/app/quickmanager/id1553915442">IOS</a>
            </Item>
            <Item>
              <a href={' https://gettimart.com/downloads/timart-business-app%20Setup%2018.2.23.exe'}>Windows</a>
            </Item>
            <Item>
              <a href={' https://gettimart.com/downloads/timart-business-app-22.10.13.dmg'}>Mac OS</a>
            </Item>
          </List>
        </Section> */}
      </FooterSec>
      <FooterSec style={{ marginTop: '35px' }}>
        <Info>
          <Section>
            <h3>Contact</h3>
            <Contact>
              <CItem>
                <div>
                  <img src={locationIcon} alt="" />
                </div>
                <div>
                  <p>Riko Arena, Opp. Zenith Bank 1st Avenue, AB close Gwanripa, Abuja.</p>
                </div>
              </CItem>
              <CItem>
                <div>
                  <img src={phoneIcon} alt="" />
                </div>
                <a href="tel:+2347046680902">+234 7046680902</a>
              </CItem>
              <CItem>
                <div>
                  <img src={messageIcon} alt="" />
                </div>
                <a href="mailto:info@timart.com.ng">info@timart.com.ng</a>
              </CItem>
            </Contact>
          </Section>
        </Info>
        <Section>
          <h3>Timart Shop</h3>
          <List>
            <Item>
              <Link to="/merchant/1">Shop</Link>
            </Item>
            {/* <Item>
              <a href="https://play.google.com/store/apps/details?id=com.quicktelsolution.timart_shop">Download App</a>
            </Item> */}
          </List>
          <h3 style={{ marginTop: '20px' }}>Payment Methods</h3>
          <List>
            <Item>
              <img src={paymentIcon} alt="" />
            </Item>
          </List>
        </Section>
        <Section>
          <h3>Top Categories</h3>
          <List>
            <div className="sublist">
              <div>
                <Item>
                  <Link to="/searchQuery?category=AUTOMOBILE&p=1">Automobile</Link>
                </Item>
                <Item>
                  <Link to="/searchQuery?category=BEAUTY&p=1">Beauty</Link>
                </Item>
                <Item>
                  <Link to="/searchQuery?category=BIOMEDCINE&p=1">Biomedicine</Link>
                </Item>
                <Item>
                  <Link to="/searchQuery?category=COMPUTING&p=1">Computing</Link>
                </Item>
                <Item>
                  <Link to="/searchQuery?category=FASHION&p=1">Fashion</Link>
                </Item>
                <Item>
                  <Link to="/searchQuery?category=RESTAURANTS&p=1">Food & Beverages</Link>
                </Item>
              </div>
              <div>
                <Item>
                  <Link to="/searchQuery?category=GENERAL STORE&p=1">General Store</Link>
                </Item>
                <Item>
                  <Link to="/searchQuery?category=HOME%2FOFFICE&p=1">Home/Office</Link>
                </Item>
                <Item>
                  <Link to="/searchQuery?category=HOSPITALITY&p=1">Hospitality</Link>
                </Item>
                <Item>
                  <Link to="/searchQuery?category=SPORTS&p=1">Sports</Link>
                </Item>
                <Item>
                  <Link to="/searchQuery?category=OTHERS&p=1">Others</Link>
                </Item>
              </div>
            </div>
          </List>
        </Section>
        <Section>
          <h3>Shopping with Timart</h3>
          <List>
            <Item>
              <Link to="/return-policy">Return Policy</Link>
            </Item>
            <Item>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </Item>
            <Item>
              <Link to="/acceptance-policy">Acceptance Policy</Link>
            </Item>
            <Item>
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
            </Item>
            {/* <Item>
              <a href="/">Disclaimer</a>
            </Item> */}
          </List>
        </Section>
        <Section>
          <h3>Join Us</h3>
          <Flex justifyContent="flex-start" gap="1.5rem">
            <a href="https://m.facebook.com/timartng/" className="socials-link">
              <FacebookIcon />
            </a>
            <a href="http://Twitter.com/TimartNig" className="socials-link">
              <TwitterIcon />
            </a>
            <a href="https://instagram.com/timartng?igshid=YmMyMTA2M2Y=" className="socials-link">
              <InstagramIcon />
            </a>
          </Flex>
        </Section>
      </FooterSec>
    </FooterCont>
  );
};

export default Footer;
