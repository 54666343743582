import { InventoryPricingType } from '../../interfaces/inventory.interface';

export interface Icart {
  fixedUnitPrice: number;
  id: string;
  image: string;
  inventoryType: string;
  merchant: string;
  price: number;
  quantity: number;
  title: string;
  variationId: string;
  variationName: string;
  inventoryPrice: InventoryPricingType;
  initialInventoryType?: string;
}

export const selectedCartItemsInitialState = {
  selectedCartItems: []
};
