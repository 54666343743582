import styled from 'styled-components';
import { BoxShadows, Colors, FontSizes, media } from '../constants/theme';

const { primaryColor, white, body, inputBorderColor, placeholderColor, lightGrey, primaryGrey, lightBlue, lightOrange, buttonHover } =
  Colors;
const { detailsFontSize, descriptionFontSize } = FontSizes;
const { primaryBoxShadow } = BoxShadows;

interface TextProps {
  fontSize?: string;
  color: string;
  fontWeight?: string;
  margin?: string;
  noWrap?: boolean;
  width?: string;
  textAlign?: string;
  textDecoration?: string;
}

export const Text = styled.p<TextProps>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
  text-align: ${({ textAlign }) => textAlign};
  text-decoration: ${({ textDecoration }) => textDecoration};

  ${({ noWrap }) =>
    noWrap &&
    `
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}

  ${media.mobile} {
    text-align: ${({ textAlign }) => textAlign || 'left'};
  }
`;

export const LinkText = styled(Text)`
  color: ${primaryColor};
  text-decoration: underline;
  cursor: pointer;
`;

interface SpanProps {
  fontSize?: string;
  color?: string;
  fontWeight?: string;
  margin?: string;
  noWrap?: boolean;
  textDecoration?: string;
  textDecorationColor?: string;
  textTransform?: string;
  textAlign?: string;
}

export const Span = styled.span<SpanProps>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 'inherit')};
  color: ${({ color }) => (color ? color : 'inherit')};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${({ margin }) => margin};
  text-decoration: ${({ textDecoration }) => textDecoration};
  text-decoration-color: ${({ textDecorationColor }) => textDecorationColor};
  text-transform: ${({ textTransform }) => textTransform};
  text-align: ${({ textAlign }) => textAlign};

  ${({ noWrap }) =>
    noWrap &&
    `
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

interface InputProps {
  height?: string;
  width?: string;
  margin?: string;
  padding?: string;
  borderRadius?: string;
  fontSize?: string;
  fontWeight?: string;
  color?: string;
  borderColor?: string;
  borderSize?: string;
  background?: string;
}

export const Input = styled.input<InputProps>`
  height: ${({ height }) => height ?? '30px'};
  width: ${({ width }) => width};
  border: ${({ borderColor, borderSize }) => `${borderSize || '1px'} solid ${borderColor || inputBorderColor}`};
  border-radius: ${({ borderRadius }) => borderRadius ?? '5px'};
  margin: ${({ margin }) => margin ?? '10px 0'};
  padding: ${({ padding }) => padding ?? '10px'};
  outline: none;
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
  background: ${({ background }) => background};

  &::placeholder {
    color: ${placeholderColor};
  }
`;

interface ButtonProps {
  backgroundColor?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
  borderColor?: string;
  borderSize?: string;
  padding?: string;
  margin?: string;
  notFilled?: boolean;
  color?: string;
  disable?: boolean;
  bgHover?: string;
  actionBtn?: boolean;
  alignSelf?: string;
}

export const Button = styled.button<ButtonProps>`
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: ${detailsFontSize};
  line-height: 16px;
  color: ${({ color }) => color ?? white};
  background-color: ${({ backgroundColor, disable, notFilled }): string =>
    disable ? lightGrey : notFilled ? 'transparent' : backgroundColor ?? primaryColor};
  border: ${({ borderSize }): string => (borderSize ? borderSize : '1px')} solid
    ${({ backgroundColor, borderColor, disable }): string =>
      disable ? lightGrey : borderColor ? borderColor : backgroundColor || primaryColor};
  box-sizing: border-box;
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '30px')};
  padding: ${({ padding }) => (padding ? padding : '12px 20px')};
  width: ${({ width }) => width};
  height: ${({ height }) => (height ? height : '50px')};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  align-self: ${({ alignSelf }) => (alignSelf ? alignSelf : 'unset')};
  margin: ${({ margin }) => (margin ? margin : 'unset')};
  cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};

  ${({ notFilled, borderColor, bgHover, backgroundColor, disable }) =>
    disable
      ? ''
      : notFilled
      ? `&:hover {
    background-color: ${borderColor ?? primaryColor};
    color: ${white};
  }`
      : `&:hover {
    background-color: ${bgHover || backgroundColor || primaryColor};
    border: 1px solid ${bgHover || backgroundColor || primaryColor};
  }`}

  ${media.mobile} {
    padding: ${({ actionBtn }) => (actionBtn ? '5px' : '1px')};
    height: ${({ actionBtn }) => actionBtn && '30px'};
    margin-top: ${({ actionBtn }) => actionBtn && '15px'};
  }
`;

interface FlexProps {
  justifyContent?: string;
  alignItems?: string;
  alignSelf?: string;
  width?: string;
  direction?: string;
  margin?: string;
  borderRadius?: string;
  background?: string;
  flexFlow?: string;
  gap?: string;
  boxShadow?: boolean;
  padding?: string;
  minHeight?: string;
  cursor?: string;
  maxHeight?: string;
  zIndex?: number;
  height?: string;
  position?: string;
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'center')};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  width: ${({ width }) => width};
  flex-direction: ${({ direction }) => (direction ? direction : 'row')};
  align-self: ${({ alignSelf }) => alignSelf ?? 'unset'};
  margin: ${({ margin }) => margin};
  gap: ${({ gap }) => gap};
  flex-flow: ${({ flexFlow }) => flexFlow};
  gap: ${({ gap }) => gap ?? 'unset'};
  background: ${({ background }) => background ?? 'unset'};
  border-radius: ${({ borderRadius }) => borderRadius ?? 'unset'};
  box-shadow: ${({ boxShadow }) => (boxShadow ? primaryBoxShadow : 'none')};
  padding: ${({ padding }) => (padding ? padding : 'none')};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : 'unset')};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : 'unset')};
  height: ${({ height }) => (height ? height : 'unset')};
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 'unset')};
  cursor: ${({ cursor }) => (cursor ? cursor : 'unset')};
  position: ${({ position }) => (position ? position : 'unset')};

  .div {
    width: 49%;
    min-width: 49%;
    max-width: 49%;
    display: flex;
    flex-direction: column;
  }
`;

//Radio Group

interface RadioItemProps {
  height?: string;
  width?: string;
}

export const RadioItem = styled.div<RadioItemProps>`
  display: flex;
  align-items: center;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  position: relative;
`;

export const RadioButtonLabel = styled.label`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 1px solid #bebebe;
`;

export const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  &:hover ~ ${RadioButtonLabel} {
    background: #bebebe;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin: 6px;
      background: ${primaryColor};
    }
  }
  ${(props) =>
    props.checked &&
    ` 
    &:checked + ${RadioButtonLabel} {
      background: ${primaryColor};
      border: 1px solid ${primaryColor};
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        margin: 6px;
        box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
        background: white;
      }
    }
  `}
`;

interface StatusProps {
  color?: string;
  background?: string;
}

export const StatusView = styled.div<StatusProps>`
  color: ${({ color }) => color || primaryColor};
  font-size: ${descriptionFontSize};
  font-weight: 500;
  background: ${({ background }) => background || lightOrange};
  border-radius: 99px;
  width: fit-content;
  padding: 3px 0.5em;
  margin: 0.3em 0;

  ${media.mobile} {
    padding: 3px 0.5em;
    margin: 0.6em 0;
  }
`;

interface EllipseProps {
  background: string;
  width: string;
  height: string;
  borderRadius?: string;
  margin?: string;
}

export const Ellipse = styled.div<EllipseProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: ${({ background }) => background};
  display: flex;
  min-width: 1em;
  align-items: center;
  justify-content: center;
  border-radius: ${({ borderRadius }) => borderRadius ?? '50%'};
  margin: ${({ margin }) => margin};
`;

interface ClassItemProps {
  active?: boolean;
}

export const ClassItem = styled.div<ClassItemProps>`
  padding: 0.3em 2em;
  border: 2px solid ${({ active }) => (active ? primaryColor : lightBlue)};
  text-align: center;
  margin: 0 0em;
  border-radius: 5px;
  color: ${({ active }) => (active ? white : primaryGrey)};
  background: ${({ active }) => (active ? primaryColor : '')};
  cursor: pointer;

  &:hover {
    border: 2px solid ${primaryColor};
  }
`;

interface TextAreaProps {
  height?: string;
  padding?: string;
  width?: string;
  minWidth?: string;
}

export const TextArea = styled.textarea<TextAreaProps>`
  height: ${({ height }) => height};
  width: ${({ width }) => width || '100%'};
  min-width: ${({ minWidth }) => minWidth || '100%'};
  padding: ${({ padding }) => padding};
`;
