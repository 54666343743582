import React, { FunctionComponent } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useHistory, withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';
import { Required } from '../../utils/formValidations.utils';
import { isloading, hasfailed, resetFetch } from '../../store/actions/fetchAction';
import { AppState } from '../../store/reducers/index';
import {
  FormContents,
  Title,
  ModalDescription,
  TextFields,
  Input,
  Button,
  Pinfo,
  Link,
  ErrorMessage,
  FormModalContainer
} from '../../GlobalStyles/forms.style';
import { pushCustomRouteQuery } from '../../utils/pushRoutes.utils';
import { Flex, Text } from '../../GlobalStyles/CustomizableGlobal.style';
import { Colors, FontSizes } from '../../constants/theme';
import { Label } from '../account/account.style';

const mapStateToProps = (state: AppState) => {
  return { status: state.fetch.status, activeShopId: state.activeShop.activeShopId };
};

export const FORGOT_PASSWORD_MEMBER = gql`
  mutation resetMemberPassword($email: String!) {
    resetMemberPassword(email: $email) {
      success
    }
  }
`;

interface Props {
  activeShopId?: string;
}

const RecoverPassword: FunctionComponent = () => {
  const { body } = Colors;
  const { bigFont } = FontSizes;

  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, control } = useForm();

  const filledEmail = useWatch({
    control,
    name: 'email'
  });

  const [forgotPassword] = useMutation<any>(FORGOT_PASSWORD_MEMBER, {
    onCompleted({ forgotPasswword }) {
      dispatch(resetFetch());
      history.push(`/auth/link-success?email=${filledEmail}`);
    }
  });

  const onSubmit = async (data: any) => {
    dispatch(isloading());
    try {
      await forgotPassword({ variables: { email: `${data.email}` } });
    } catch (e) {
      dispatch(hasfailed(e instanceof Error ? `${e}` : 'An unknown error has occured'));
    }
  };

  return (
    <FormModalContainer>
      <FormContents>
        <Flex direction="column" margin="1rem 0 2rem 0" alignItems="start">
          <Text color={body} fontSize={bigFont} fontWeight="700" margin="0.5rem 0">
            Forgot Password
          </Text>
          <ModalDescription>Enter your registered email address to get a password reset link</ModalDescription>
        </Flex>
        <TextFields onSubmit={handleSubmit(onSubmit)}>
          <Label margin="0" fontSize="16px">
            Email Address
          </Label>
          <Input
            name="email"
            type="email"
            ref={register(Required)}
            placeholder="example@gmail.com"
            style={{ borderColor: errors.email && '#F75151' }}
          />
          {errors.email && <ErrorMessage>This field is required</ErrorMessage>}

          <Button type="submit" margin="2rem 0 0 0">
            Send Reset Link
          </Button>
        </TextFields>

        <Pinfo onClick={() => history.push('/login')}>
          Remember Password? <Link>Back to Login</Link>
        </Pinfo>
      </FormContents>
    </FormModalContainer>
  );
};

export default RecoverPassword;
