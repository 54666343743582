import React, { FunctionComponent, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { connect, useDispatch, useSelector } from 'react-redux';
import { NavLink, withRouter, useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Required, EmailRequired } from '../../utils/formValidations.utils';
import {
  FormContents,
  Title,
  ModalDescription,
  TextFields,
  Input,
  Button,
  Pinfo,
  ErrorMessage,
  FormModalContainer,
  Select,
  PasswordField
} from '../../GlobalStyles/forms.style';
import { AppState } from '../../store/reducers/index';
import { isloading, hasfailed, resetFetch, hasSucceed } from '../../store/actions/fetchAction';
import { lgaStateList } from '../../constants/statesAndCities.constant';
import { Flex, Text } from '../../GlobalStyles/CustomizableGlobal.style';
import { Colors, FontSizes } from '../../constants/theme';
import { Label } from '../account/account.style';
import { LOGIN_USER, SIGNUP_USER } from '../../schema/member.schema';
import { FacebookLogo, GoogleLogo, SocialMediaDiv } from './login.style';
import { googleLoginUrl } from '../../utils/graphql.utils';

const mapStateToProps = (state: AppState) => {
  return { status: state.fetch.status };
};

interface Props {
  width?: string;
  removeExtra?: boolean;
  authPopup?: boolean;
}

const decodeQueryParams = (key: string, query: URLSearchParams) => {
  let decodedValue = decodeURIComponent(query.get(key) || '');
  if (decodedValue === 'undefined') {
    return '';
  }
  return decodedValue;
};

const Signup: FunctionComponent<Props> = ({ width, removeExtra, authPopup }) => {
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const [currentState, setCurrentState] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);

  const handleStateSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentState(e.target.value);
  };
  const useQueryParams = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQueryParams();
  const redirectTo = query.get('redirect');
  const redirect = redirectTo ? `?redirect=${redirectTo}` : '';

  const firstName = decodeQueryParams('firstName', query);
  const lastName = decodeQueryParams('lastName', query);
  const email = decodeQueryParams('email', query);

  const dispatch = useDispatch();

  const [signup] = useMutation<any>(SIGNUP_USER, {
    onCompleted() {
      dispatch(resetFetch());
      if (authPopup) {
        history.push(`/login?redirect=/cart`);
      } else {
        history.push(`/login${redirect}`);
      }
    }
  });

  const [login] = useMutation<any>(LOGIN_USER, {
    onCompleted({ memberLogin }) {
      localStorage.setItem('session', JSON.stringify(memberLogin));
      dispatch(resetFetch());
      if (authPopup) {
        history.push('/checkout?type=checkout');
      } else {
        redirectTo ? history.push(redirectTo) : history.push('/my-account/account-info');
      }
    }
  });

  const onSubmit = async (data: any) => {
    dispatch(isloading());
    try {
      await signup({
        variables: {
          fullName: `${data.firstName} ${data.lastName}`,
          email: `${data.email}`,
          mobileNumber: `${data.mobileNumber}`,
          password: `${data.password}`
        }
      });
      await login({ variables: { email: `${data.email}`, password: data.password } });
    } catch (e) {
      dispatch(hasfailed(e instanceof Error ? `${e}` : 'An unknown error has occured'));
    }
  };

  const message = useSelector((state: AppState) => state.fetch.message);
  const status = useSelector((state: AppState) => state.fetch.status);

  const { body, placeholderColor } = Colors;
  const { bigFont, detailsFontSize } = FontSizes;

  return (
    <FormModalContainer width={width}>
      <FormContents onSubmit={handleSubmit(onSubmit)}>
        {!removeExtra && (
          <>
            <Text color={body} fontSize={bigFont} margin="0.5rem 0" fontWeight="700">
              Create Account
            </Text>
            <ModalDescription>Input your details to create a free account with us</ModalDescription>
          </>
        )}

        <TextFields>
          <Label>First Name</Label>
          <Input
            name="firstName"
            defaultValue={firstName}
            ref={register(Required)}
            type="text"
            placeholder="John"
            style={{ borderColor: errors.firstName && '#F75151' }}
          />
          {errors.firstName && <ErrorMessage>This field is required</ErrorMessage>}

          <Label>Last Name</Label>
          <Input
            name="lastName"
            defaultValue={lastName}
            ref={register(Required)}
            type="text"
            placeholder="Doe"
            style={{ borderColor: errors.lastName && '#F75151' }}
          />
          {errors.lastName && <ErrorMessage>This field is required</ErrorMessage>}

          <Label>Email</Label>
          <Input
            name="email"
            defaultValue={email}
            ref={register(EmailRequired)}
            type="email"
            placeholder="example@gmail.com"
            style={{ borderColor: errors.email && '#F75151' }}
          />
          {errors.email && <ErrorMessage>Enter a valid email</ErrorMessage>}

          <Label>Mobile</Label>
          <Input
            name="mobileNumber"
            ref={register(Required)}
            type="tel"
            placeholder="08000000000"
            style={{ borderColor: errors.mobileNumber && '#F75151' }}
          />
          {errors.mobileNumber && <ErrorMessage>This field is required</ErrorMessage>}

          <Label>Password</Label>
          <PasswordField>
            <input
              name="password"
              ref={register(Required)}
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter Password"
              style={{ borderColor: errors.password && '#F75151' }}
            />
            {showPassword ? (
              <i className="im im-eye" onClick={() => setShowPassword(false)} />
            ) : (
              <i className="im im-eye-off" onClick={() => setShowPassword(true)} />
            )}
          </PasswordField>
          {errors.password && <ErrorMessage>Enter a strong password!</ErrorMessage>}
          {status === 'error' && (
            <Title style={{ color: 'red', fontSize: '10px', display: 'flex', justifyContent: 'flex-start' }}>
              {message.includes('Failed to fetch') ? 'Check your internet connection' : message}
            </Title>
          )}

          <Button type="submit" margin="3rem 0 1rem 0">
            Create an Account
          </Button>

          <Flex>
            <hr className="line" />
            <Text color={placeholderColor} fontSize={detailsFontSize} width="100%" textAlign="center">
              Or signup with
            </Text>
            <hr className="line" />
          </Flex>

          <SocialMediaDiv>
            {/* <FacebookLogo /> */}
            <a href={googleLoginUrl}>
              <GoogleLogo />
            </a>
          </SocialMediaDiv>

          {!removeExtra && (
            <Pinfo>
              Already have an account? `
              <NavLink to={`/login${redirectTo ? `?redirect=${redirectTo}` : ''}`} className="link">
                Login
              </NavLink>
              `
            </Pinfo>
          )}
        </TextFields>
      </FormContents>
    </FormModalContainer>
  );
};

export default Signup;
