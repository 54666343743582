import styled from 'styled-components';
import { BoxShadows, Colors, media } from '../constants/theme';
import cap from '../img/cap.svg';
import idea from '../img/idea.svg';

const { tablet, mobile } = media;
const { white, primaryColor } = Colors;
const { primaryBoxShadow } = BoxShadows;
interface Props {
  bodyScroll: boolean;
}

export const PageContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mobile} {
    ${({ bodyScroll }: Props) =>
      bodyScroll &&
      `
        overflow: hidden;
        height: 100vh;
    `}
  }
`;

export const CardListContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-gap: 1rem;
  padding: 2rem;

  .text-none {
    text-decoration: none;
  }

  .text-none {
    text-decoration: none;
  }
`;

export const CategoryText = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #8196B3;
  padding-left: 50px;
  span {
    text-transform: capitalize;
    color: ${primaryColor};
  }
`;

interface MainContainerProps {
  padding?: string;
}

export const MainContainer = styled.div<MainContainerProps>`
  background: ${white};
  box-shadow: ${primaryBoxShadow};
  width: 80%;
  margin: 0.5em;
  margin-top: 20px;
  padding: ${({ padding }) => padding};
  border-radius: 10px;

  ${mobile} {
    padding: 1rem;
    margin: 0px;
    margin-top: 2rem;
  }

  ${tablet} {
    width: 100%;
  }
`;

export const BgMainContainer = styled.div`
  .background-container {
    display: flex;
    justify-content: space-between;
    position: fixed;
    opacity: 0.5;
    display: flex;
    width: 100vw;
    height: 100vh;
    z-index: -1;

    .left-content {
      position: absolute;
      left: -20%;
      bottom: -15%;
      height: 100%;
      width: 40%;
      background-image: url(${cap});
      background-repeat: no-repeat;
      background-size: 100%;
    }

    .right-content {
      position: absolute;
      right: 0%;
      bottom: 10%;
      width: 40%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 115%;
      background-image: url(${idea});
    }

    ${mobile} {
      flex-direction: column;

      .left-content {
        position: absolute;
        left: -30%;
        bottom: 0%;
        width: 100%;
        height: 40%;
      }

      .right-content {
        position: absolute;
        right: -20%;
        top: -10%;
        width: 100%;
        height: 40%;
      }
    }
  }
`;
