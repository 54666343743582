import { createBrowserHistory } from 'history';
import { isServer } from './server.utils';

const fakeServerBrowserHistory = {
  push: (a: any) => {},
  replace: (a: any) => {},
  listen: (a: any) => {},
  location: {
    host: '',
    pathname: ''
  },
  createHref: () => {}
};

export default isServer() ? fakeServerBrowserHistory : createBrowserHistory();
