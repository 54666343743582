import jwt_decode from 'jwt-decode';

export const AutoLogin = (search: string) => {
  const encodedToken = new URLSearchParams(search).get('autoLoginToken');

  try {
    if (encodedToken) {
      const token = atob(encodedToken);
      const decodedToken: any = jwt_decode(token);

      localStorage.setItem(
        'session',
        JSON.stringify({
          member: decodedToken,
          token
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
};
