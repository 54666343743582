import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Colors } from '../../constants/theme';
import { Text } from '../../GlobalStyles/CustomizableGlobal.style';
import AppDownloadMediums from '../AppDownloadMediums/AppDownloadMediums';
import PopupCard from '../PopupCard';

const ModalWrapper = styled.div`
  .pop-up-title {
    width: 100%;
    text-align: center;
  }
`;

const ComingSoonModal = () => {
  const { primaryColor } = Colors;
  const urlHistory = useHistory();

  return (
    <PopupCard close={() => urlHistory.goBack()}>
      <ModalWrapper>
        <Text className="pop-up-title" fontSize="1em" color={primaryColor}>
          Coming soon on Google Play and App store
        </Text>
        <AppDownloadMediums id="app-download" />
      </ModalWrapper>
    </PopupCard>
  );
};

export default ComingSoonModal;
