import { BooleanActionPropsType } from '../../interfaces/actions.interface';
import { ALERT_NEW_MESSAGE } from '../actionTypes';
import { MessageInitialState, MessageInitialStateType } from '../initialStates/messageInitialState';

const MessageReducer = (state: MessageInitialStateType = MessageInitialState, action: BooleanActionPropsType) => {
  switch (action.type) {
    case ALERT_NEW_MESSAGE:
      return {
        ...state,
        isNewMessage: action.payload
      };

    default:
      return state;
  }
};

export default MessageReducer;
