import { InventoryPricingType } from '../../interfaces/inventory.interface';

export interface IcartCounter {
  cartItems: {
    id: string;
    title: string;
    price: number;
    quantity: number;
    merchant: string;
    inventoryPrice: InventoryPricingType;
  }[];
  loadingCart: boolean;
}

export const CartCounterInitialState = {
  cartItems: [],
  loadingCart: false
};
