import styled from 'styled-components';

export const CenterDiv = styled.div`
  text-align: center;
  justify-content: center;
  img {
    max-width: 35%;
    width: 100%;

    @media screen and (max-width: 992px) {
      max-width: 50%;
    }

    @media screen and (max-width: 700px) {
      max-width: 70%;
    }
  }
`;
