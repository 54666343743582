import { TOGGLE_SNACKBAR_CLOSE, TOGGLE_SNACKBAR_OPEN } from "../actionTypes";

export const toggleSnackbarOpen = (message: string) => ({
  type: TOGGLE_SNACKBAR_OPEN,
  payload: message,
});

export const toggleSnackbarClose = () => ({
  type: TOGGLE_SNACKBAR_CLOSE,
});
