import React, { FunctionComponent } from 'react';
import NavBar from '../navBar';
import CustomNavContainer from './customNav.style';
import { Colors } from '../../../constants/theme';

const { white, primaryGrey, primaryColor } = Colors;

interface Props {
  categoriesOpen?: boolean;
  setCategoriesOpen?: Function;
}

const CustomNav: FunctionComponent<Props> = ({ categoriesOpen, setCategoriesOpen }) => {
  const linkColor = `${primaryGrey}`;
  const signInButtonColor = `${white}`;
  const signInButtonBackgroundColor = `${primaryColor}`;
  const signInButtonHoverBackgroundColor = `${white}`;
  const signInButtonHoverColor = `${primaryColor}`;

  return (
    <CustomNavContainer>
      <NavBar
        categoriesOpen={categoriesOpen}
        setCategoriesOpen={setCategoriesOpen}
        linkColor={linkColor}
        signInButtonColor={signInButtonColor}
        signInButtonBackgroundColor={signInButtonBackgroundColor}
        signInButtonHoverColor={signInButtonHoverColor}
        signInButtonHoverBackgroundColor={signInButtonHoverBackgroundColor}
      />
    </CustomNavContainer>
  );
};

export default CustomNav;
