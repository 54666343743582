import React, { useEffect, useState } from 'react';
import { Flex, Span, Text } from '../../../GlobalStyles/CustomizableGlobal.style';
import CaretDown from '../../../img/caret-down-red-colored-filled.svg';
import WhitePin from '../../../img/white-pin.svg';
import { OptionType } from '../../../interfaces/FilterSelect.interface';
import { Colors } from '../../../constants/theme';
import { getItem, setItem } from '../../../utils/localStorage.utils';
import LocationPopup from '../../LocationPopup';
import { useHistory, useLocation } from 'react-router-dom';
import { formatName } from '../../../utils/format.utils';
import useWindowDimensions from '../../../utils/windowsDimension.utils';
import PopupCard from '../../PopupCard';
import StateCityModal from '../../StateCityModal';
import { useUserLocation } from '../../../hooks/locationContext';

export const isLocationSetKey = 'isLocationset';

const UserLocation = () => {
  const [popup, setPopup] = useState<boolean>(false);
  const { width } = useWindowDimensions();
  const { isLocationSet, setLocationSet, setCurrentCity, setCurrentState, currentCity, currentState } = useUserLocation() || {};

  const location = useLocation();
  const history = useHistory();

  const { primaryColor, body, white } = Colors;
  const path = location.pathname === '/';

  useEffect(() => {
    if (isLocationSet) {
      const { selectedState, selectedCity } = JSON.parse(getItem('userLocation') || '{}');
      setCurrentState!(selectedState);
      setCurrentCity!(selectedCity);
    }
  }, [isLocationSet]);

  const setLocation = () => {
    width < 577 ? history.push('/location') : setPopup(true);
  };

  return (
    <>
      <Flex
        className="location-flex"
        cursor="pointer"
        alignItems="center"
        gap="0.5rem"
        background={body}
        padding="0.5rem"
        borderRadius="5px"
        onClick={setLocation}
      >
        <img src={WhitePin} alt="location pin" />
        {currentState ? (
          <Flex alignItems="center" background="transparent">
            <Span color={white} fontSize="0.8rem">
              {!currentCity ? 'All cities in ' : ''}
              {currentState?.label === 'Federal Capital Territory' ? 'Abuja' : currentState?.label}
            </Span>
            {currentCity && (
              <Span color={white} fontSize="0.8rem">
                , {formatName(currentCity?.label)}
              </Span>
            )}
          </Flex>
        ) : (
          <Span color={white} fontSize="0.8rem">
            All over Nigeria
          </Span>
        )}
        <img src={CaretDown} alt="caret" />
      </Flex>

      {popup && (
        <PopupCard maxWidth="80%" width="60%" maxHeight="70%" unfit={true} close={() => setPopup(false)}>
          <StateCityModal
            setPopup={setPopup}
            setCurrentState={setCurrentState}
            setCurrentCity={setCurrentCity}
            setLocationSet={setLocationSet}
          />
        </PopupCard>
      )}
    </>
  );
};

export default UserLocation;
