import React, { FunctionComponent, useState } from 'react';
import { ErrorMessage } from '../../GlobalStyles/forms.style';
import { Input, InputBox } from '../../pages/account/account.style';
interface inputProps {
  placeholder: string;
  register: Function;
  required: any;
  errors: any;
}

const PasswordField: FunctionComponent<inputProps> = ({ placeholder, register, required, errors }) => {
  const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
  return (
    <InputBox style={{ borderColor: errors.currentPassword && '#F75151' }}>
      <Input
        name="currentPassword"
        ref={register(required)}
        type={showCurrentPassword ? 'text' : 'password'}
        placeholder={placeholder}
        autoComplete="off"
      />
      <span>
        {showCurrentPassword ? (
          <i className="im im-eye" onClick={() => setShowCurrentPassword(false)} />
        ) : (
          <i className="im im-eye-off" onClick={() => setShowCurrentPassword(true)} />
        )}
      </span>
      {errors.currentPassword && <ErrorMessage>This field is required</ErrorMessage>}
    </InputBox>
  );
};

export default PasswordField;
