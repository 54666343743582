import styled from 'styled-components';
import { media } from '../../../constants/theme';

const CustomNavContainer = styled.div`
  position: relative;
  top: 38px;
  height: 80px;

  ${media.tablet} {
    height: unset;
  }
`;

export default CustomNavContainer;
