import styled from 'styled-components';
import Select from 'react-select';
import { media, Colors, FontSizes } from '../../constants/theme';

const { mobile, tablet, smallScreenLaptop, miniMobile } = media;
const { white, primaryColor, black } = Colors;
const { bigFont, primaryFontSize, secondaryFontSize } = FontSizes;

export const Container = styled.div`
  margin: 0 auto;
  width: calc(100% - 4rem);
  padding: 0;
  max-width: 1600px;

  h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 32px;
    font-weight: 500;
  }

  .banner-container {
    width: 100%;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 230px;
    box-sizing: border-box;
    position: relative;
    border-radius: 12px;

    ${mobile} {
      height: 100px;
    }

    div {
      position: absolute;
      top: 0px;
      bottom: 0;
      left: 0px;
      right: 0px;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 230px;
      width: 100%;

      ${mobile} {
        height: 100%;
      }

      img {
        position: absolute;
        right: 300px;
        bottom: 0px;
        width: 350px;
        height: auto;
        margin-top: -300px;
        z-index: 3;

        ${smallScreenLaptop} {
          right: 170px;
          width: 200px;
        }

        ${mobile} {
          width: 150px;
          right: 2rem;
          z-index: 1;
        }

        ${miniMobile} {
          right: 0;
        }
      }
    }
  }
`;
export const Info = styled.div`
  width: 100%;
  color: white;
  background: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 230px;
  box-sizing: border-box;
  position: absolute;
  overflow: hidden;
  padding: 15px 25px;
  border-radius: 12px;

  ::before {
    content: '';
    display: block;
    position: absolute;
    height: 500px;
    width: 500px;
    top: -130px;
    right: -90px;
    background: ${primaryColor};
    border-radius: 50%;

    ${mobile} {
      height: 200px;
      width: 200px;
      bottom: -22px;
      top: unset;
    }
  }
  a {
    z-index: 2;
    background: #083e88;
    color: #fff;
    padding: 14px 25px;
    border-radius: 10px;
    position: absolute;
    right: 7rem;

    ${smallScreenLaptop} {
      right: 1rem;
      padding: 10px;
      font-size: 0.5rem;
      border-radius: 5px;
    }

    ${miniMobile} {
    }
  }
`;
export const Left = styled.div`
  max-width: 50%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  h1 {
    max-width: 100%;
    text-align: left;
    font-size: 2.5rem;
    margin: 1rem 0rem 0rem 1rem;
    font-family: 'Poppins', sans-serif;
    span {
      color: ${primaryColor};
    }

    ${tablet} {
      font-size: 2rem;
    }

    ${mobile} {
      font-size: 0.75rem;
      z-index: 3;
    }
  }
  p {
    max-width: 50%;
    font-size: 1.3rem;
    margin: 0rem 0rem 1rem 1rem;
    align-self: flex-start;
    font-family: 'Poppins', sans-serif;

    ${tablet} {
      font-size: 1rem;
      max-width: unset;
    }

    ${mobile} {
      font-size: 0.5rem;
    }
  }
`;

export const CategoryContainer = styled.div`
  width: 100%;
  display: flex;
  column-gap: 10px;
  overflow-x: scroll;
  justify-content: space-evenly;
`;

export const CategoryCard = styled.div`
  height: 150px;
  width: calc(25% - 5px);
  max-width: 350px;
  min-width: 200px;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  img:first-of-type {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }
  img:last-of-type {
    position: absolute;
    height: 120%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
  div {
    width: 100%;
    height: 25px;
    padding: 0 10px;
    position: absolute;
    bottom: 5px;
    left: 0;
    box-sizing: border-box;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    p {
      font-size: 18px;
      font-weight: 500;
      color: #fff;
    }
    span {
      font-size: 12px;
      color: #fff;
    }
  }
`;
