import React, { FunctionComponent, useEffect, useState } from 'react';
import editIcon from '../../img/edit.svg';
import redBin from '../../img/redBin.svg';
import { gql, useMutation, useQuery } from '@apollo/client';
import { hasfailed, isloading, resetFetch } from '../../store/actions/fetchAction';
import { useDispatch } from 'react-redux';
import { Colors } from '../../constants/theme';
import {
  Title,
  ContainerAddress,
  NewAddressButton,
  Header,
  Hr,
  InnerAddressContainer,
  AddressCard,
  Control,
  AddressCardDetails,
  ControlButton,
  Check,
  Text
} from './account.style';
import colouredCheck from '../../img/coloured-check.svg';
import fadedCheck from '../../img/faded-check.svg';
import { getItem, setItem } from '../../utils/localStorage.utils';
import { AddressType, GET_DEFAULT_ADDRESS_TYPE } from '../../interfaces/address.interface';
import { toggleSnackbarOpen } from '../../store/actions/snackbarActions';
import { GET_DEFAULT_ADDRESS, SET_DEFAULT_ADDRESS } from '../../schema/member.schema';
import PopupCard from '../../components/PopupCard';
import { Button, Flex, Span } from '../../GlobalStyles/CustomizableGlobal.style';
import useWindowDimensions from '../../utils/windowsDimension.utils';
import Loader from '../../components/LoaderComponent';
import { GET_MEMBER_TYPE } from '../../interfaces/GetMember.interface';

const DELETE_MEMBER_ADDRESS = gql`
  mutation deleteMemberAddress($addressId: ID!) {
    deleteMemberAddress(addressId: $addressId)
  }
`;

interface UserType {
  fullName: string;
  email: string;
  mobileNumber: string;
  Addresses: AddressType[];
}
interface IProps {
  user?: GET_MEMBER_TYPE['getMember'];
  setShow: Function;
  setShowAddAddress: Function;
  setAddressIdToEdit: Function;
  setIndexToEdit: Function;
  refetch: Function;
}

const ChangeAddress: FunctionComponent<IProps> = ({ user, setShow, setShowAddAddress, setAddressIdToEdit, setIndexToEdit, refetch }) => {
  const [selectedAddressId, setSelectedAddressId] = useState<string>('');
  const [currentAddressId, setCurrentAddressId] = useState<string>(selectedAddressId);
  const [defaultAddress, setDefaultAddress] = useState<AddressType>();
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { red, lightBlue, white } = Colors;

  const {
    data,
    loading,
    refetch: refetchDefaultAddress
  } = useQuery<GET_DEFAULT_ADDRESS_TYPE>(GET_DEFAULT_ADDRESS, {
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (data?.getDefaultAddress) {
      setDefaultAddress(data?.getDefaultAddress);
    }
  }, [data]);

  const [deleteAddress] = useMutation<any>(DELETE_MEMBER_ADDRESS, {
    onCompleted() {
      dispatch(resetFetch());
      refetch();
      setShow(true);
    }
  });

  console.log(user?.Addresses.map((add) => add.fullName));

  const [setUserDefaultAddress] = useMutation<{ setDefaultAddress: boolean }>(SET_DEFAULT_ADDRESS, {
    onCompleted() {
      dispatch(resetFetch());
      dispatch(toggleSnackbarOpen('Default address set!'));
    }
  });

  const handleSetDefaultAddress = async (address: AddressType) => {
    try {
      await setUserDefaultAddress({ variables: { addressId: address.addressId } }).then(() => {
        setSelectedAddressId(currentAddressId);
        setDefaultAddress(address);
        setShow(false);
      });
    } catch (e) {
      dispatch(hasfailed(e instanceof Error ? `${e}` : 'An unknown error has occured'));
    }
  };

  const handleAddNewAddress = () => {
    setShow(false);
    setShowAddAddress(true);
    setAddressIdToEdit('');
    setIndexToEdit(0);
  };
  const handleEditAddress = (id: string, index: number) => {
    setShow(false);
    setShowAddAddress(true);
    setAddressIdToEdit(id);
    setIndexToEdit(index);
  };

  const handleDeleteAddress = async (id: string) => {
    dispatch(isloading());
    try {
      await deleteAddress({ variables: { addressId: id } });
    } catch (e) {
      dispatch(hasfailed(e instanceof Error ? `${e}` : 'An unknown error has occured'));
    }
  };

  if (loading) return <Loader />;
  return (
    <ContainerAddress>
      <Header>
        <Title subtitle>Address Book</Title>
        <NewAddressButton onClick={handleAddNewAddress}>Add New Address</NewAddressButton>
      </Header>
      <Hr />
      <InnerAddressContainer>
        {user &&
          user?.Addresses.map((address, index) => {
            const { addressId, fullAddress } = address;
            const State = {
              stateId: Number(address.State.stateId),
              stateName: address.State.stateName,
              totalInventoryCount: address.State.totalInventoryCount
            };
            const City = {
              cityId: Number(address.City.cityId),
              cityName: address.City.cityName,
              state: address.State.stateName,
              stateId: Number(address.State.stateId),
              totalInventoryCount: address.City.totalInventoryCount
            };
            const newAddress = { ...address, State, City };

            return (
              <AddressCard
                key={addressId}
                selected={addressId === defaultAddress?.addressId}
                onClick={() => setCurrentAddressId(addressId)}
              >
                <AddressCardDetails>
                  <div>
                    <h3>{address.fullName}</h3>
                    <p>{fullAddress}</p>
                    <p>{address.mobileNumber}</p>
                  </div>
                </AddressCardDetails>
                <Control>
                  <Check selected={addressId === defaultAddress?.addressId} onClick={() => handleSetDefaultAddress(newAddress)}>
                    {addressId !== defaultAddress?.addressId ? <img src={fadedCheck} alt="" /> : <img src={colouredCheck} alt="" />}
                    <span>{addressId !== defaultAddress?.addressId ? 'Set as' : ''} Default</span>
                  </Check>
                  <div style={{ display: 'flex' }}>
                    <ControlButton style={{ color: '#607087' }} onClick={() => handleEditAddress(address.addressId, index)}>
                      <img src={editIcon} alt="" /> Edit
                    </ControlButton>
                    <ControlButton style={{ color: `${red}` }} onClick={() => setRemoveModal(true)}>
                      <img src={redBin} alt="" /> Remove
                    </ControlButton>
                  </div>
                </Control>

                {removeModal && (
                  <PopupCard
                    maxHeight="50%"
                    width={width < 577 ? '95%' : width < 1000 ? '70%' : '35%'}
                    maxWidth={width < 577 ? '95%' : width < 1000 ? '70%' : '35%'}
                    close={() => setRemoveModal(false)}
                  >
                    <Flex
                      width="80%"
                      alignItems="center"
                      justifyContent="center"
                      gap="2rem"
                      direction="column"
                      padding={width < 577 ? '2rem' : '3rem'}
                    >
                      <Span color={lightBlue} fontSize="1.2rem" textAlign="center">
                        Are you sure about removing this address?
                      </Span>
                      <Flex alignItems="center" justifyContent="space-between" width={width < 577 ? '100%' : '90%'}>
                        <Button
                          width={width < 577 ? '130px' : '150px'}
                          backgroundColor={red}
                          color={white}
                          onClick={() => {
                            handleDeleteAddress(currentAddressId);
                            setRemoveModal(false);
                          }}
                        >
                          Remove
                        </Button>
                        <Button width="150px" backgroundColor={lightBlue} color={white} onClick={() => setRemoveModal(false)}>
                          Cancel
                        </Button>
                      </Flex>
                    </Flex>
                  </PopupCard>
                )}
              </AddressCard>
            );
          })}
      </InnerAddressContainer>
    </ContainerAddress>
  );
};

export default ChangeAddress;
