import styled from 'styled-components';
import { media } from '../../../constants/theme';

const { tablet, mobile, smallScreenLaptop, miniMobile } = media;

export const RandomProductsContents = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  grid-gap: 15px;
  max-width: 1600px;
  background: #fafbfd;
  margin: 0 auto;
  padding: 20px 15px;
  border-radius: 10px;
  .text-none {
    text-decoration: none;
  }

  ${smallScreenLaptop} {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  ${mobile} {
    grid-template-columns: auto auto;
  }

  ${miniMobile} {
    grid-gap: unset;
  }
`;

export const RandomProductsWrapper = styled.div`
  border-radius: 10px;
  width: 100%;
  background: transparent;
  margin: 30px auto;
  border-radius: 10px;
  #load-more {
    margin: 0 auto;
  }

  ${tablet} {
    margin: 2rem;

    .path-text {
      font-size: 0.6em;
    }
  }

  ${mobile} {
    margin: 2rem 0.5rem;
    width: calc(100% - 1rem);
    .hide-mobile {
      display: none;
    }
  }
`;
export const SectionWrapper = styled.div`
  background: #fafbfd;
`;
export const Header = styled.div`
  color: #607087;
  background: transparent;
  max-width: 1600px;
  margin: 0 auto;
  font-size: 18px;
  display: flex;
  padding: 0 15px;
  justify-content: space-between;
  align-items: center;
  p {
    background: #fafbfd;
    padding: 15px;
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    margin-left: 20px;
    border-radius: 12px 12px 0px 0px;
    display: flex;
    color: #000;
    font-weight: 500;
    align-items: center;
    column-gap: 15px;
  }
  #location {
    padding: 5px 10px;
    color: #788290;
    font-size: 12px;
    background: transparent;
    display: inline-flex;
    column-gap: 12px;
    align-items: center;
  }
  img {
    margin-left: 10px;
  }
`;
