import React from 'react';
import styled from 'styled-components';
import Header from '../../components/header/header';
import { Colors } from '../../constants/theme';
import { Flex, Text } from '../../GlobalStyles/CustomizableGlobal.style';
import { BgMainContainer } from '../../GlobalStyles/FixedGlobal.style';
import HeaderImage from '../../img/faq-header.png';
import Tick from '../../img/tick-circle.svg';
import BottomNav from '../../components/footer/bottomNav';

const { primaryColor, white, body } = Colors;

const Container = styled.div`
  width: 60%;
  border-radius: 10px;
  background: ${white};
  color: ${body};
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 4px 4px 30px rgba(35, 54, 79, 0.1);
  line-height: 2rem;
`;

const Faq = () => {
  return (
    <BgMainContainer>
      <Header />
      <Container>
        <img src={HeaderImage} />
        <div style={{ padding: '2rem' }}>
          <Text fontWeight="700" fontSize={'1.5rem'} color={body} textAlign="center">
            Frequently Asked Questions
          </Text>
          <Flex justifyContent="flex-start">
            <img src={Tick} />
            <Text color={body} margin="0 0.5rem" fontWeight="700" fontSize="1rem">
              How to place an order on Timart?
            </Text>
          </Flex>
          <p>To place an order:</p>
          <ul>
            <li>Search for the product you want to buy on the search bar or by Category</li>
            <li>Select the product and click on “Add to cart”</li>
            <li>Click on the Cart Icon above the page and proceed to the checkout page</li>
            <li>Register or login into your existing account by entering your email and password</li>
            <li>Provide your shipping and billing information, making sure the address is accurate.</li>
            <li>Select a Delivery Method and your preferred payment method.</li>
            <li>Click on Confirm Order to complete your order.</li>
            <p>
              <i>For more information please send an email to Info@timart.com.ng</i>
            </p>
          </ul>
          <Flex justifyContent="flex-start">
            <img src={Tick} />
            <Text color={body} margin="0 0.5rem" fontWeight="700" fontSize="1rem">
              What is the reason for the varying prices of the same product on Timart?
            </Text>
          </Flex>
          <p>
            Timart is a reliable online marketplace for business owners, and we have numerous sellers competing for your patronage. As a
            result, you may come across the same product being offered by different sellers at different prices. We are of the opinion that
            by supporting these 'third-party' sellers, we can provide you with a broader range of products, more options, greater
            convenience, and better pricing.
            <br />
            <i>For more information please send an email to Info@timart.com.ng</i>
          </p>
          <Flex justifyContent="flex-start">
            <img src={Tick} />
            <Text color={body} margin="0 0.5rem" fontWeight="700" fontSize="1rem">
              Why are there different delivery time?
            </Text>
          </Flex>
          <p>
            Timart is not your typical one-stop-shop. We have sellers from all over Nigeria, each with their own unique shipping processes
            and courier partners. Plus, the good ol' factor of distance. So, while we can't promise a uniform delivery time, we can promise
            a diverse selection of products and sellers to choose from.
            <br />
            <i>For more information please send an email to Info@timart.com.ng</i>
          </p>
          <Flex justifyContent="flex-start">
            <img src={Tick} />
            <Text color={body} margin="0 0.5rem" fontWeight="700" fontSize="1rem">
              How to find the products you need on Timart?
            </Text>
          </Flex>
          <p>
            Visit our website timart.com.ng or download our App Timart Shops on google play store, and search for the product you want by
            name or browse through categories.
            <br />
            <i>For more information please send an email to Info@timart.com.ng</i>
          </p>
          <Flex justifyContent="flex-start">
            <img src={Tick} />
            <Text color={body} margin="0 0.5rem" fontWeight="700" fontSize="1rem">
              Can I trust the Seller?
            </Text>
          </Flex>
          <p>
            In Timart disbursement of funds to the Merchant is contingent upon confirmation of the buyer's receipt of the purchased item.
            That is when the Merchant marks the order as completed and the seller sees a Mark as received button on the website or mobile
            app, this shows that the transaction is completed. This process ensures the buyer's confidence in the secure delivery of their
            product, and upon receipt, Timart promptly remits payment to the Merchant.
            <br />
            <i>For more information please send an email to Info@timart.com.ng</i>
          </p>
        </div>
      </Container>
      <BottomNav />
    </BgMainContainer>
  );
};

export default Faq;
