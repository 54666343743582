import { TAB_CONTENT, selectModuleType } from "../actionTypes";
import { TabInitialState, tabType } from "../initialStates/tabInitialstate";

const changeTabItem = (state: tabType = TabInitialState, action: selectModuleType) => {
  switch (action.type) {
    case TAB_CONTENT:
      return {
        ...state,
        tabId: action.payload,
      };

    default:
      return state;
  }
};

export default changeTabItem;
