import React, { FunctionComponent, useState } from 'react';
import { Radio } from '../../pages/checkout/checkout.style';
import blackCancelIcon from '../../img/blackCancel.svg';
import editIcon from '../../img/edit.svg';
import circlePlusIcon from '../../img/circlePlus.svg';
import redBin from '../../img/redBin.svg';
import { AddAddressBtn, AddressCard, AddressContainer, Control, Header, SubmitBtn } from './style';
import { gql, useMutation, useQuery } from '@apollo/client';
import { hasfailed, isloading, resetFetch } from '../../store/actions/fetchAction';
import { useDispatch } from 'react-redux';
import { AddressType } from '../../interfaces/address.interface';
import { setItem } from '../../utils/localStorage.utils';

const DELETE_MEMBER_ADDRESS = gql`
  mutation deleteMemberAddress($addressId: ID!) {
    deleteMemberAddress(addressId: $addressId)
  }
`;

interface UserType {
  fullName: string;
  email: string;
  mobileNumber: string;
  Addresses: [
    {
      addressId: string;
      fullAddress: string;
    }
  ];
}
interface IProps {
  user?: UserType;
  setSelectedAddressIndex: Function;
  setShow: Function;
  selectedAddressIndex: number;
  setShowAddAddress: Function;
  setAddressIdToEdit: Function;
  setIndexToEdit: Function;
  refetch: Function;
  defaultAddress: AddressType | undefined;
  setDefaultAddress: Function;
}

const ChangeAddress: FunctionComponent<IProps> = ({
  user,
  selectedAddressIndex,
  setSelectedAddressIndex,
  setShow,
  setShowAddAddress,
  setAddressIdToEdit,
  setIndexToEdit,
  refetch,
  defaultAddress,
  setDefaultAddress
}) => {
  const [addressIndex, setAddressIndex] = useState<number>(selectedAddressIndex);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    setSelectedAddressIndex(addressIndex);
    setDefaultAddress(defaultAddress);
    setShow(false);
  };

  const handleAddNewAddress = () => {
    setShow(false);
    setShowAddAddress(true);
    setAddressIdToEdit('');
    setIndexToEdit(0);
  };
  const handleEditAddress = (id: string, index: number) => {
    setShow(false);
    setShowAddAddress(true);
    setAddressIdToEdit(id);
    setIndexToEdit(index);
  };

  const [deleteAddress] = useMutation<any>(DELETE_MEMBER_ADDRESS, {
    onCompleted() {
      dispatch(resetFetch());
      refetch();
      setShow(true);
    }
  });

  const handleDeleteAddress = async (id: string) => {
    dispatch(isloading());
    try {
      await deleteAddress({ variables: { addressId: id } });
    } catch (e) {
      dispatch(hasfailed(e instanceof Error ? `${e}` : 'An unknown error has occured'));
    }
  };
  return (
    <div>
      <Header>
        <div>
          <h3>Address Book</h3>
        </div>
        <p>Select from existing adress or add new address</p>
      </Header>
      <AddAddressBtn onClick={handleAddNewAddress}>
        <img src={circlePlusIcon} alt="" />
        <span>Add New Address</span>
      </AddAddressBtn>
      <AddressContainer>
        {user &&
          user?.Addresses.map((address, index) => (
            <AddressCard
              key={index}
              onClick={() => {
                setAddressIndex(index);
                setDefaultAddress(address);
              }}
            >
              <div className="address">
                <Radio selected={address.addressId === defaultAddress?.addressId}>
                  <span></span>
                </Radio>
                <div>
                  <h3>{user.fullName}</h3>
                  <p>{address.fullAddress}</p>
                  <p>{user.mobileNumber}</p>
                </div>
              </div>
              <Control>
                <button className="remove" onClick={() => handleDeleteAddress(address.addressId)}>
                  <img src={redBin} alt="" /> Remove
                </button>
                <button className="edit" onClick={() => handleEditAddress(address.addressId, index)}>
                  <img src={editIcon} alt="" /> Edit
                </button>
              </Control>
            </AddressCard>
          ))}
      </AddressContainer>
      <SubmitBtn onClick={handleSubmit}>Use this Address</SubmitBtn>
    </div>
  );
};

export default ChangeAddress;
