import styled from 'styled-components';
import { Colors, media } from '../../constants/theme';

const { tablet } = media;
const { white } = Colors;

export const SidebarContainer = styled.div`
  background: ${white};
  display: flex;
  flex-direction: column;
  width: 200px;
  border-radius: 10px;
  box-shadow: 4px 4px 30px 0px #23364f1a;
  padding: 10px;
  margin: 20px;
  height: 100%;
  ${tablet} {
    display: none;
  }
`;
export const CategoryBtn = styled.button`
  border: none;
  background: transparent;
  display: flex;
  padding: 8px 5px;
  margin: 1px 0%;
  color: #8196b3;
  cursor: pointer;
  :hover {
    color: #ffa412;
  }
  div {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    text-transform: capitalize;
  }
`;
