import { ADD_SELECT_FROM_CART } from '../actionTypes';
import { SelectedCartItemsState } from '../../interfaces/state.interface';
import { Icart, selectedCartItemsInitialState } from '../initialStates/cartSelections';

interface SelectCartActionProps {
  type: string;
  payload: Icart[];
}

export default function selectCartFunctions(
  state: SelectedCartItemsState = selectedCartItemsInitialState,
  action: SelectCartActionProps
): SelectedCartItemsState {
  switch (action.type) {
    case ADD_SELECT_FROM_CART: {
      return {
        ...state,
        selectedCartItems: action.payload
      };
    }

    default:
      return state;
  }
}
