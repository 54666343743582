import React from 'react';
import { HalfPageContent } from './login.style';
import Login from './login';
import AuthShell from './AuthShell';

const LoginPage = () => {
  return (
    <AuthShell>
      <HalfPageContent>
        <Login />
      </HalfPageContent>
    </AuthShell>
  );
};

export default LoginPage;
