import React from 'react';
import BottomNav from '../../components/footer/bottomNav';
import Header from '../../components/header/header';
import { Text } from '../../GlobalStyles/CustomizableGlobal.style';
import { Container, UList, SubList } from './policy.style';
import { Colors } from '../../constants/theme';
import { FontSizes } from '../../constants/theme';
import { TbNorthStar as Icon } from 'react-icons/tb';

const { primaryColor, body } = Colors;
const { primaryFontSize } = FontSizes;

const ReturnPolicy = () => {
  return (
    <>
      <Header />
      <Container>
        <div style={{ padding: '2rem' }}>
          <Text fontWeight="700" fontSize={primaryFontSize} color={body}>
            RETURNING ITEMS ON TIMART
          </Text>
          <UList>
            You can initiate a return for all eligible Item(s) within 7days, if the item(s) delivered is damaged, defective, counterfeit or
            wrong. To be able to request for a return, the item (s) must have;
            <SubList>
              <div className="list-title">
                <Icon className="list-icon" />
                All seals intact
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <Icon className="list-icon" />
                All tags and accessories that came with the product should be left intact, and in its original packaging.
              </div>
            </SubList>
          </UList>
          <UList>
            If the item returned is a device, you will need to remove any password you have created for the device, failure to do so will
            render your return invalid.
          </UList>
          <UList>
            The following Items cannot be returned for the purpose of hygiene and security: swimwear, underwear, lingerie, earrings,
            cosmetics, fragrances, groceries, food supplements, software, tickets, and adult products.
          </UList>
          <UList>
            Cooked food, snacks and pastries cannot be returned except where the order delivered is wrong, and in such a situation the
            seller shall bear all responsibility and consequences, including cost of delivering the correct order or refunding the customer.
          </UList>
          <Text fontWeight="700" fontSize={primaryFontSize} color={body}>
            REASONS FOR RETURN
          </Text>
          <UList>
            The reason for return must be valid, this is to prevent abuse of Return. The following reasons are the only reasons for which a
            buyer can initiate a Request for Return.
          </UList>
          <UList>
            <div className="list-title">
              <Icon className="list-icon" />
              Expired
            </div>
            <UList padding="0 30px">You can initiate a request for return of items delivered to you that has expired.</UList>
          </UList>
          <UList>
            <div className="list-title">
              <Icon className="list-icon" />
              Damaged
            </div>
            <UList padding="0 30px">Items delivered that were discovered to be damaged by the customer can be returned</UList>
          </UList>
          <UList>
            <div className="list-title">
              <Icon className="list-icon" />
              Counterfeit
            </div>
            <UList padding="0 30px">
              When the Item is delivered, and it is not what was described by the seller, but only a counterfeit.
            </UList>
          </UList>
          <UList>
            <div className="list-title">
              <Icon className="list-icon" />
              Wrong Item
            </div>
            <UList padding="0 30px">When the item delivered to the buyer is not what was ordered.</UList>
          </UList>
          <UList>
            <div className="list-title">
              <Icon className="list-icon" />
              Defective
            </div>
            <UList padding="0 30px">
              When the Item has a defect. That is when a product does not look or work like it has been presented, by the seller.
            </UList>
          </UList>
          <Text fontWeight="700" fontSize={primaryFontSize} color={body}>
            STEPS OF RETURNING
          </Text>
          <Text fontWeight="700" fontSize={primaryFontSize} color={body}>
            ITEMS STEP 1: SEND A COMPLAINT
          </Text>
          <UList>
            Send a complaint to our customer service requesting for a return, through message or call on :
            <a style={{ color: `${primaryColor}` }} href="tel:+2347046680902">
              {' '}
              +234 704 668 0902
            </a>{' '}
            or Send a complaint via email to :{' '}
            <a style={{ color: `${primaryColor}` }} href="mailto:info@timart.com.ng">
              info@timart.com.ng
            </a>
          </UList>
          <UList>
            <em>
              <strong>Your complain must: </strong>
            </em>
            --State clearly the reasons for return.
          </UList>
          <UList>
            <em>
              <strong>Optional: </strong>
            </em>
            --You can also add images for additional proof.
          </UList>
          <Text fontWeight="700" fontSize={primaryFontSize} color={body}>
            STEP 2: VALIDATION OF COMPLAIN
          </Text>
          <UList>Your complaint will be considered within 3 - 7 days. An email will be sent to you once your claim is validated.</UList>
          <Text fontWeight="700" fontSize={primaryFontSize} color={body}>
            STEP 3: RETURNING THE ITEM
          </Text>
          <UList>
            You will package the Item in its original form, e.g in its original package, with any accessories, or gift that accompanied it.
            If your delivery was handled by Timart, Timart will pick up the item and deliver to seller within 1 - 4 days If the delivery was
            carried out by the seller, you will need to return the Item yourself, within 1 - 4 days. And the seller will incur the cost of
            delivery.
          </UList>
          <Text fontWeight="700" fontSize={primaryFontSize} color={body}>
            STEP 4: REFUND PROCESS
          </Text>
          <UList>
            Once Timart has delivered an item to the seller or confirmed receipt of returned item from the seller, Timart will assist in
            notifying the seller to resolve the issue as it is the sole responsibility of the seller to resolve the issue without any
            obligation on Timart.
          </UList>
          <Text fontWeight="700" fontSize={primaryFontSize} color={body}>
            RETURNABLE ITEMS
          </Text>
          <UList>
            Note:The following items listed below are the items that can be returned:
            <SubList>
              <div className="list-title">
                <Icon className="list-icon" />
                Items that have their seal and tags can be returned
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <Icon className="list-icon" />
                Items with complete accessories and original packaging can be returned
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <Icon className="list-icon" />
                Items that do not fall into any of these categories;
              </div>
              <SubList>
                <div className="list-title">
                  <Icon className="list-icon" />
                  Perishable goods e.g fruits, vegetables etc except when a damage to the item was noticed at the UList of delivery.{' '}
                </div>
              </SubList>
              <SubList>
                <div className="list-title">
                  <Icon className="list-icon" />
                  Swimwear, underwear, lingerie, earrings, cosmetics, fragrances, groceries, food supplements, software, tickets, and adult
                  products.
                </div>
              </SubList>
              <SubList>
                <div className="list-title">
                  <Icon className="list-icon" />
                  Other items that may be determined and updated from time to time in accordance with the law of the territory.
                </div>
              </SubList>
              <SubList>
                <div className="list-title">
                  <Icon className="list-icon" />
                  Cooked food, snacks and pastries cannot be returned except where the order delivered is wrong, and in such a situation the
                  seller shall bear all responsibility and consequences, including cost of delivering the correct order or refunding the
                  customer.
                </div>
              </SubList>
            </SubList>
          </UList>
        </div>
      </Container>
      <BottomNav />
    </>
  );
};

export default ReturnPolicy;
