import React, { FunctionComponent } from 'react';
import { LoadContainer } from './LoaderComponent.style';
export const Loader: FunctionComponent = () => {
  return (
    <LoadContainer>
      <div className="loader">
        <div className="pulse"></div>
      </div>
    </LoadContainer>
  );
};

export default Loader;
