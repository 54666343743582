import styled from 'styled-components';
import { Colors, FontSizes, media } from '../../constants/theme';
import { Text } from '../../GlobalStyles/CustomizableGlobal.style';

const { blackLight, primaryColor, white, body } = Colors;
const { primaryFontSize, secondaryFontSize } = FontSizes;
const { smallScreenLaptop, tablet, mobile } = media;

interface Props {
  margin?: string;
  padding?: string;
}

export const Content = styled(Text)`
  ${media.mobile} {
    font-size: 14px;
    text-align: left;
  }
`;
export const Container = styled.div`
  width: 60%;
  border-radius: 10px;
  background: ${white};
  color: ${body};
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 4px 4px 30px rgba(35, 54, 79, 0.1);
  line-height: 2rem;

  a {
    color: ${primaryColor};
    text-decoration: underline;
  }

  ${smallScreenLaptop} {
    width: 100%;
    margin-top: 3rem;
  }

  ${tablet} {
    width: 100%;
    margin-top: 3rem;
  }

  ${mobile} {
    width: 100%;
    margin-top: 3rem;
  }
`;

export const UList = styled.ul<Props>`
  margin: ${({ margin }) => margin ?? '20px 0 20px 0'};
  padding: ${({ padding }) => padding ?? '0 0 0 40px'};
  font-size: ${secondaryFontSize};

  .list-title {
    display: flex;
    align-items: baseline;
  }

  .list-icon {
    color: ${primaryColor};
    margin-right: 10px;
    min-width: 16px;
  }

  ${mobile} {
    font-size: 14px;
    margin: 10px 0;
    padding: ${({ padding }) => padding ?? '0'};
  }
`;
export const OList = styled.ol<Props>`
  margin: ${({ margin }) => margin ?? '0 0 20px 0'};
  padding: ${({ padding }) => padding ?? '0 0 0 40px'};
  font-size: ${secondaryFontSize};

  .list-title {
    margin-left: 20px;
    display: flex;
    align-items: flex-start;
  }

  span {
    margin-right: 20px;
  }

  .list-icon {
    color: ${primaryColor};
    margin-right: 10px;
    width: 16px;
  }

  ${mobile} {
    font-size: 14px;
    margin: 10px 0;
    padding: ${({ padding }) => padding ?? '0'};

    .list-title {
      margin-left: 0px;
      margin-bottom: 30px;
      display: flex;
      align-items: flex-start;
    }
  }
`;
export const SubList = styled.p`
  margin: 15px 0 15px 30px;
  font-size: ${primaryFontSize};

  ${mobile} {
    margin: 10px 0 15px 10px;
    font-size: 14px;
  }
`;
