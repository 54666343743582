import React, { FunctionComponent } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { CategoryCard, CategoryContainer, Container, Info, Left } from './BannerOne.style';
import { AppState } from '../../store/reducers/index';
import foodBg from '../../img/food.png';
import coverBg from '../../img/cover.svg';
import casualMan from '../../img/casual-man.png';
import casualManMobile from '../../img/casual-man-mobile.svg';
import techBg from '../../img/tech.png';
import fashionBg from '../../img/fashion.png';
import useWindowDimensions from '../../utils/windowsDimension.utils';

const mapStateToProps = (state: AppState) => {
  return { fireRedirect: state.redirect.fireRedirect };
};

const Banner: FunctionComponent = () => {
  const { width } = useWindowDimensions();

  return (
    <Container>
      <div className="banner-container">
        <div>
          <img src={casualMan} alt="casual man" />
        </div>
        <Info>
          <Left>
            <h1 className="title">
              Take control! <span>Bargain</span> prices for what you buy
            </h1>
            <p className="subtitle">The market place with a complete peace of mind.</p>
          </Left>
          <Link to="/searchQuery?category=ALL%20CATEGORIES&p=1">Start Shopping</Link>
        </Info>
      </div>
      <h2>Our top categories</h2>
      <CategoryContainer>
        <CategoryCard>
          <img src={foodBg} alt="" />
          <img src={coverBg} alt="" />
          <div>
            <p>Food</p>
            <span>240+</span>
          </div>
        </CategoryCard>
        <CategoryCard>
          <img src={fashionBg} alt="" />
          <img src={coverBg} alt="" />
          <div>
            <p>Fashion</p>
            <span>260+</span>
          </div>
        </CategoryCard>
        <CategoryCard>
          <img src={techBg} alt="" />
          <img src={coverBg} alt="" />
          <div>
            <p>Gadgets</p>
            <span>240+</span>
          </div>
        </CategoryCard>
        <CategoryCard>
          <img src={foodBg} alt="" />
          <img src={coverBg} alt="" />
          <div>
            <p>Food</p>
            <span>240+</span>
          </div>
        </CategoryCard>
      </CategoryContainer>
    </Container>
  );
};

export default Banner;
