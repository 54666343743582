import { useMutation } from '@apollo/client';
import React, { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Colors } from '../../constants/theme';
import { Button, Ellipse, Flex, Text } from '../../GlobalStyles/CustomizableGlobal.style';
import { GET_INVENTORY_TYPE, Inventory } from '../../interfaces/inventory.interface';
import { SEND_MESSAGE_TO_SHOP } from '../../schema/messages.schema';
import { hasfailed, isloading, resetFetch } from '../../store/actions/fetchAction';
import formatImageUrl from '../../utils/format.utils';
import showAmount from '../../utils/priceFormat.utils';
import PopupCard from '../PopupCard';
import { BargainContents, BargainInput, OfferPriceField } from './style';
import DefaultProductImage from '../../img/timart-image-load.svg';
import styled from 'styled-components';
import Image from '../image';
import { Shop } from '../../interfaces/shop.interface';
import { AppState } from '../../store/reducers';
import Error from '../ErrorComponent';
import { getInventoryPrice } from '../../utils/inventory.utils';

interface Props {
  toggleBargain: Function;
  product?: Inventory;
  shop?: Shop;
  maxHeight?: string;
  inventoryType?: string;
  variationId?: string;
  bargainPrice?: number;
  refetch?: Function;
}

const BargainModal: FunctionComponent<Props> = ({
  toggleBargain,
  product,
  maxHeight,
  inventoryType,
  variationId,
  bargainPrice,
  shop,
  refetch
}) => {
  const { onlineGreen, primaryGrey, primaryColor, lightBlue, inputBorderColor, buttonHover, red } = Colors;
  const [focus, setFocus] = useState<boolean>();
  const dispatch = useDispatch();
  const history = useHistory();
  const trackable = product?.trackable;
  const packPrice = product?.TrackableItem?.packPrice || 0;
  const unitPrice = product?.TrackableItem?.unitPrice || 0;
  const isVariation = product?.isVariation;
  const sellingPrice = product?.NonTrackableItem?.sellingPrice || 0;
  const variationPrice = product?.Variations?.filter((variation) => variation.variationId === variationId)[0]?.price;
  const { Shop, inventoryId, Images } = product || {};

  const validInventoryType = ['PIECES', 'PACK', 'NON_TRACKABLE'];
  const inventoryPricing = validInventoryType.includes(inventoryType || '')
    ? getInventoryPrice(product?.InventoryPrice, inventoryType)
    : { sellingPrice: null, bargainPrice: null, discountPrice: null };

  const originalPrice = inventoryPricing?.sellingPrice || 0;
  const [offerPrice, setOfferPrice] = useState(bargainPrice || originalPrice);
  const isNotOffer = !offerPrice || offerPrice <= 0;

  const showOfferPrice = (event: any) => {
    setOfferPrice(event.target.value);
  };

  const focusOfferField = (event: any) => {
    setFocus(true);
  };

  const [sendMessageToShop] = useMutation<any>(SEND_MESSAGE_TO_SHOP, {
    onCompleted({ sendMessageToShop }) {
      dispatch(resetFetch());
      const shopId = sendMessageToShop.Shop.shopId;
      toggleBargain(false);
      refetch ? refetch() : history.push(`/my-account/messages?shop=${shopId}`);
    },
    onError: (e) => dispatch(hasfailed(e.message))
  });

  const sendMessage = async () => {
    dispatch(isloading());
    try {
      await sendMessageToShop({
        variables: {
          shopId: shop?.shopId,
          inventoryId: inventoryId,
          inventoryType: inventoryType,
          isBargain: true,
          bargainAmount: Number(offerPrice),
          variationId: variationId || null
        }
      });
    } catch (e) {
      dispatch(hasfailed(e instanceof Error ? `${e}` : 'An unknown error has occured'));
    }
  };

  const makeAnOffer = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendMessage();
  };

  const status = useSelector<AppState>((state) => state.fetch.status);
  const message = useSelector<AppState>((state) => state.fetch.message);

  return (
    <PopupCard maxHeight={maxHeight} close={() => toggleBargain(false)}>
      <BargainContents maxHeight={maxHeight}>
        <Flex justifyContent="space-between">
          <Flex>
            <Ellipse background={onlineGreen} width="15px" height="15px" />
            <Text color={primaryGrey} margin={'0 0.5em'}>
              Seller is Online
            </Text>
          </Flex>
          {/* <Text className="close-button" onClick={() => toggleBargain(false)} color={primaryGrey} margin={'0.5em 0'}>
            <i className="im im-x-mark" />
          </Text> */}
        </Flex>
        <form onSubmit={makeAnOffer}>
          <Flex direction="column">
            <Image
              className="bargain-prod-img"
              src={Images ? formatImageUrl(Images[0]?.smallImageOnlineURL, 'product') : DefaultProductImage}
              alt="product preview"
            />
            <Text color={primaryGrey} fontWeight={'600'} fontSize={'1.5em'} margin={'0.5em 0'}>
              {product?.inventoryName}
            </Text>
            <Text className="fit-to-mobile" color={primaryColor} fontWeight={'600'} fontSize={'1.5em'} margin={'0.5em 0'}>
              BARGAIN THIS PRICE
            </Text>
            <Flex className="fit-to-mobile" width="100%">
              <Text color={lightBlue} margin={'0 0.5em'}>
                How much are you willing to pay for this product?
              </Text>
            </Flex>
            <Flex width="60%">
              <Text color={primaryGrey} fontWeight={'600'} fontSize={'1.5em'} margin={'0.5em'}>
                N
              </Text>
              <BargainInput
                autoFocus
                type="number"
                onChange={showOfferPrice}
                onFocus={focusOfferField}
                defaultValue={originalPrice}
                value={offerPrice}
                focus={focus}
                min={0}
              />
            </Flex>
            {
              <Text
                color={isNotOffer || status === 'error' ? red : status === 'loading' ? primaryColor : 'transparent'}
                fontSize={'0.75em'}
                margin={'0'}
              >
                {status === 'loading' ? 'Making Offer...' : `${message}` || 'Bargain price must be greater than 0'}
              </Text>
            }

            <Flex justifyContent="space-between" className="price-diff" width="70%">
              <Flex direction="column">
                <Text color={primaryGrey} margin="0">
                  Original Price
                </Text>
                <Text color={primaryGrey} fontWeight={'600'}>
                  {originalPrice ? showAmount(originalPrice) : 'Not available'}
                </Text>
              </Flex>
              <div className="vl" />
              <Flex direction="column">
                <Text color={primaryGrey} margin="0">
                  Reduction
                </Text>
                <Text color={primaryGrey} fontWeight={'600'}>
                  {originalPrice ? showAmount(originalPrice - (offerPrice ?? 0)) : 'Error'}
                </Text>
              </Flex>
            </Flex>

            <Button
              width="50%"
              height="3em"
              borderColor={primaryColor}
              bgHover={buttonHover}
              borderSize={'2px'}
              borderRadius={'5px'}
              margin={'1em 0'}
              type="submit"
              disable={isNotOffer}
              disabled={isNotOffer}
            >
              Make an Offer
            </Button>
          </Flex>
        </form>
      </BargainContents>
    </PopupCard>
  );
};

export default BargainModal;
