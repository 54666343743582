import styled from 'styled-components';
import { media } from '../../constants/theme';

const Container = styled.header`
  width: 100%;
  height: 118px;

  ${media.mobile} {
    height: 0;
  }
`;
export const ContactOptions = styled.div`
  display: flex;
  margin: 0;
  height: 40px;
  padding: 3px 1rem;
  color: #607087;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #eee;
    border-radius: 6px;
  }
`;

export default Container;
