import styled, { keyframes } from "styled-components";
import { Colors } from "../../constants/theme";

const { primaryGrey, white } = Colors;

const fadein = keyframes`
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 1rem;
      opacity: 1;
    }
`;

const fadeout = keyframes`
    from {
      bottom: 1rem;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
`;

interface ContainerProps {
  time: string;
}

export const SnackbarContainer = styled.div<ContainerProps>`
  width: fit-content;
  min-width: 100px;
  max-width: 500px;
  position: fixed;
  bottom: 1em;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0 1em;
  border-radius: 1.5em;
  background-color: ${primaryGrey};
  color: ${white};
  z-index: 20;
  animation: ${fadein} 0.5s, ${fadeout} 0.5s ${({ time }) => time};

  p {
    text-align: center;
  }
`;
