import gql from 'graphql-tag';
import { ImageSchema } from './image.schema';

export const ShopUrlSchema = `
  shopId
  shopTag
`;

export const ShopSchema = `
  shopId
  shopName
  shopAddress
  fullAddress
  shopPhone
  distance
  latitude
  longitude
  isBargainEnabled
  returnPolicy
  ShopURL {
    ${ShopUrlSchema}
  }
  Images {
    ${ImageSchema}
  }
  City {
    cityId
    cityName
  }
  State {
    stateId
    stateName
  }
`;

export const GET_ONLINE_STATUS = gql`
  subscription ShopOnlineStatus($shopId: String!) {
    shopOnlineStatus(shopId: $shopId) {
      isOnline
      lastSeenDate
    }
  }
`;

export const GET_ALL_SHOP_CATEGORY = gql`
  {
    getShopCategories {
      shopCategoryName
      shopCategoryId
    }
  }
`;

export const GET_ALL_SHOPS = gql`
  query getAllShops($page: Int, $latitude: Float, $longitude: Float, $limit: Int, $stateId: Int, $cityId: Int) {
    getAllShops(page: $page, latitude: $latitude, longitude: $longitude, limit: $limit, stateId: $stateId, cityId: $cityId) {
      totalShops
      shops {
        shopId
        shopName
        shopAddress
        distance
        ShopURL {
          shopTag
          shopId
        }
        Images {
          largeImageOnlineURL
          smallImageOnlineURL
          mediumImageOnlineURL
        }
      }
    }
  }
`;

export interface GET_ALL_SHOPS_TYPE {
  getAllShops: {
    totalShops: number;
    shops: [
      {
        shopId: string;
        shopName: string;
        shopAddress: string;
        distance: number;
        ShopURL: {
          shopTag: string;
          shopId: string;
        };
        Images: [
          {
            largeImageOnlineURL: string;
            smallImageOnlineURL: string;
            mediumImageOnlineURL: string;
          }
        ];
      }
    ];
  };
}
