import React, { FunctionComponent } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { CategoryCard, CategoryContainer, Container, Info, Left } from './BannerTwo.style';
import { AppState } from '../../store/reducers/index';
import foodBg from '../../img/swallow.png';
import coverBg from '../../img/cover.svg';
import riceBg from '../../img/rice.png';
import chopBg from '../../img/chops.png';

const mapStateToProps = (state: AppState) => {
  return { fireRedirect: state.redirect.fireRedirect };
};

const Banner: FunctionComponent = () => {
  return (
    <Container>
      <h2>Hungry? Checkout our finest picks</h2>
      <CategoryContainer>
        <CategoryCard>
          <img src={foodBg} alt="" />
          <img src={coverBg} alt="" />
          <div>
            <p>Swallow</p>
            <span>Pounded Yam, Semo, Amala, Wheat...</span>
          </div>
        </CategoryCard>
        <CategoryCard>
          <img src={chopBg} alt="" />
          <img src={coverBg} alt="" />
          <div>
            <p>Chops & Snacks</p>
            <span>Pizza, Meat Pie, Chicken pie, Shawarma...</span>
          </div>
        </CategoryCard>
        <CategoryCard>
          <img src={riceBg} alt="" />
          <img src={coverBg} alt="" />
          <div>
            <p>Rice</p>
            <span>White rice, Fried rice, Nigerian Jollof...</span>
          </div>
        </CategoryCard>
        <CategoryCard>
          <img src={foodBg} alt="" />
          <img src={coverBg} alt="" />
          <div>
            <p>Swallow</p>
            <span>Pounded Yam, Semo, Amala, Wheat..</span>
          </div>
        </CategoryCard>
      </CategoryContainer>
    </Container>
  );
};

export default Banner;
