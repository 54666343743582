import gql from 'graphql-tag';
import { ImageSchema } from './image.schema';

export const LOGOUT_MEMBER_SCHEMA = gql`
  mutation memberLogout {
    memberLogout
  }
`;

export const GET_MEMBER_SCHEMA = gql`
  query getMember {
    getMember {
      memberId
      fullName
      email
      mobileNumber
      password
      Addresses {
        addressId
        country
        fullAddress
        State {
          stateId
          stateName
        }
        City {
          cityId
          cityName
        }
        streetAddress
        mobileNumber
        fullName
      }
    }
  }
`;
export const GET_DEFAULT_ADDRESS = gql`
  query GetDefaultAddress {
    getDefaultAddress {
      addressId
      memberId
      country
      State {
        stateId
        stateName
        totalInventoryCount
      }
      City {
        cityId
        cityName
        state
        stateId
        totalInventoryCount
      }
      fullAddress
      streetAddress
      mobileNumber
      fullName
    }
  }
`;

export const GET_MEMBER_ORDERS_SCHEMA = gql`
  query getMemberOrders($page: Int, $limit: Int, $completedOrders: Boolean) {
    getMemberOrders(page: $page, limit: $limit, completedOrders: $completedOrders) {
      subTotal
      totalAmount
      orderId
      ownerMemberId
      orderNumber
      paymentMethod
      deliveryFee
      transactionFee
      deliveryOption
      Shop {
        shopName
        ShopURL {
          shopTag
        }
      }
      OrderItems {
        Steps {
          stepId
          stepName
        }
        Inventory {
          Images {
            ${ImageSchema}
          }
          InventoryUrl {
            inventoryId
            url
          }
        }
        orderItemId
        inventoryName
        inventoryType
        orderId
        itemName
        amount
        quantity
      }
      updatedAt
      createdAt
    }
  }
`;

export const UPDATE_MEMBER_ADDRESS = gql`
  mutation updateMemberAddress(
    $addressId: ID!
    $country: String
    $stateId: Int
    $cityId: Int
    $streetAddress: String
    $mobileNumber: String
    $fullName: String
  ) {
    updateMemberAddress(
      addressId: $addressId
      country: $country
      stateId: $stateId
      cityId: $cityId
      streetAddress: $streetAddress
      mobileNumber: $mobileNumber
      fullName: $fullName
    )
  }
`;

export const CREATE_MEMBER_ADDRESS = gql`
  mutation createMemberAddress(
    $country: String
    $stateId: Int
    $cityId: Int
    $streetAddress: String
    $mobileNumber: String
    $fullName: String
  ) {
    createMemberAddress(
      country: $country
      stateId: $stateId
      cityId: $cityId
      streetAddress: $streetAddress
      mobileNumber: $mobileNumber
      fullName: $fullName
    ) {
      addressId
      memberId
      country
      City {
        cityId
        cityName
        state
        stateId
        totalInventoryCount
      }
      fullAddress
      streetAddress
      mobileNumber
      fullName
      State {
        stateId
        stateName
        totalInventoryCount
      }
    }
  }
`;

export const SET_DEFAULT_ADDRESS = gql`
  mutation SetDefaultAddress($addressId: ID!) {
    setDefaultAddress(addressId: $addressId)
  }
`;

export const LOGIN_USER = gql`
  mutation memberLogin($email: String!, $password: String!) {
    memberLogin(email: $email, password: $password) {
      member {
        memberId
        fullName
        email
        mobileNumber
        city
        state
        Addresses {
          addressId
          fullAddress
        }
      }
      message
      token
    }
  }
`;

export const SIGNUP_USER = gql`
  mutation memberSignUp($fullName: String!, $email: String!, $mobileNumber: String!, $password: String!) {
    memberSignUp(fullName: $fullName, email: $email, mobileNumber: $mobileNumber, password: $password) {
      message
    }
  }
`;
