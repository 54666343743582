export const CLICKED = 'CLICKED';
export const SELECT_MODULE = 'SELECT_MODULE';
export const SALES_RECORD_RANGE = 'SALES_RECORD_RANGE';
export const FETCH_LIMIT = 'FETCH_LIMIT';
export const ADD_PAGE_NUMBER = 'ADD_PAGE_NUMBER';
export const ADD_SHOP_DATA = 'ADD_SHOP_DATA';
export const EXPAND_STOCK_CARD = 'EXPAND_STOCK_CARD';
export const COLLAPSE_STOCK_CARD = 'COLLAPSE_STOCK_CARD';
export const NEXT_CHECKOUT_OPTION = 'NEXT_CHECKOUT_OPTION';
export const INCREMENT_COUNTER = 'INCREMENT_COUNTER';
export const DECREMENT_COUNTER = 'DECREMENT_COUNTER';
export const RESET_COUNTER = 'RESET_COUNTER';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_CART_FROM_LOCALSTORAGE = 'ADD_TO_CART_FROM_LOCALSTORAGE';
export const CLEAR_CART = 'CLEAR_CART';
export const LOAD_CART = 'LOAD_CART';
export const USER_CHECKOUT_INFO = 'USER_CHECKOUT_INFO';
export const PAYMENT_METHOD = 'PAYMENT_METHOD';
export const RESET_CART_COUNT = 'RESET_CART_COUNT';
export const GET_DATA_PENDING = 'GET_DATA_PENDING';
export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS';
export const DATA_SUCCESS = 'DATA_SUCCESS';
export const CURRENT_PAGE = 'CURRENT_PAGE';
export const DATA_PER_PAGE = 'DATA_PER_PAGE';
export const GET_DATA_FAILURE = 'GET_DATA_FAILURE';
export const SELECT_SHOP = 'SELECT_SHOP';
export const CURRENT_SHOP_ID = 'CURRENT_SHOP_ID';
export const SELECT_DATE = 'SELECT_DATE';
export const NAVBARCLICKED = 'NAVBARCLICKED';
export const RESET_FETCH = 'RESET_FETCH';
export const SHOP_CARD_ID = 'SHOP_CARD_ID';
export const TAB_CONTENT = 'TAB_CONTENT';
export const SHOP_INVENTORY_ID = 'SHOP_INVENTORY_ID';
export const FIREREDIRECT = 'FIREREDIRECT';
export const ADD_SELECT_FROM_CART = 'ADD_SELECT_FROM_CART';
export const CLEAR_SELECT_FROM_CART = 'CLEAR_SELECT_FROM_CART';

export const TOGGLE_SNACKBAR_OPEN = 'TOGGLE_SNACKBAR_OPEN';
export const TOGGLE_SNACKBAR_CLOSE = 'TOGGLE_SNACKBAR_CLOSE';

export const ALERT_NEW_MESSAGE = 'ALERT_NEW_MESSAGE';

export interface selectModuleType {
  type: string;
  payload: string;
}

export interface fetchLimitType {
  type: string;
  page?: number;
  payload: any[];
}

export interface arrayActionType {
  type: string;
  id?: string;
  payload: {
    id: string;
    title: string;
    price: number;
  };
  cartData: any[];
}

export interface userDetails {
  type: string;
  userData: {
    address: string;
    city: string;
    mobile: string;
    state: string;
    yourName: string;
  };
  method: {
    payment: '';
  };
}
