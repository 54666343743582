import React, { FunctionComponent } from 'react';
import { CategoryCard, CategoryContainer, Container, Info, Right } from './Pharmacies.style';
import ProductCard from '../../../components/ProductCard';
import Loader from '../../../components/LoaderComponent';
import { Link } from 'react-router-dom';
import getGeoLocationPosition from '../../../utils/queryLocation.utils';
import Error from '../../../components/ErrorComponent';
import PharmacyMan from '../../../img/pharmacy-man.png';
import PharmacyManMobile from '../../../img/pharmacy-man-mobile.svg';
import { useQuery } from '@apollo/client';
import { SEARCH_INVENTORY_TYPE } from '../../../interfaces/inventory.interface';
import { SEARCH_INVENTORY } from '../../../schema/inventory.schema';
import { SectionWrapper } from '../TopProductsList/TopProductList.style';
import { RandomProductsContents } from '../RandomProductsList/RandomProductsList.style';
import getProductPrice from '../../../interfaces/GetProductPrice.interface';
import useWindowDimensions from '../../../utils/windowsDimension.utils';
import { getItem } from '../../../utils/localStorage.utils';
import { useUserLocation } from '../../../hooks/locationContext';

const Pharmacies: FunctionComponent = () => {
  const position = getGeoLocationPosition();
  const { width } = useWindowDimensions();
  const { currentCity, currentState } = useUserLocation() || {};

  const { data, loading, error } = useQuery<SEARCH_INVENTORY_TYPE>(SEARCH_INVENTORY, {
    variables: {
      category: 'BIOMEDCINE',
      page: 1,
      limit: 12,
      sortBy: 'RANDOM',
      searchType: 'INVENTORY',
      stateId: currentState?.value,
      cityId: currentCity?.value
    }
  });

  const isBig = width > 425;

  if (loading) return <Loader />;
  if (error) return <Error message={error.message} />;

  return (
    <Container>
      <Info>
        <img src={PharmacyMan} alt="pharmacy banner" />
        <span></span>
        <Right>
          <h1>Your Pharmacy is just a click away</h1>
          <p>Get one click access to local pharmacies around you, have your purchase delivered to you fast.</p>
          <Link className="button" to="/searchQuery?category=BIOMEDCINE&p=1">
            Start Shopping
          </Link>
        </Right>
      </Info>
      <SectionWrapper>
        <RandomProductsContents>
          {data?.searchInventoryOrShop.inventories.map((product) => {
            const { inventoryId, inventoryType, Variations } = product;
            const variationId = Variations.length && Variations?.[0].variationId;

            return (
              <ProductCard
                key={inventoryId}
                price={getProductPrice(product, inventoryType || 'NON_TRACKABLE', variationId)}
                inventory={product}
              />
            );
          })}
        </RandomProductsContents>
      </SectionWrapper>
    </Container>
  );
};

export default Pharmacies;
