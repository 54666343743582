import gql from 'graphql-tag';

export const GET_SHOP_CATEGORIES_SCHEMA = gql`
  query getShopCategories {
    getShopCategories {
      shopCategoryId
      shopCategoryName
      description
    }
  }
`;
