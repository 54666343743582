import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { CheckBox } from '../../components/OrderSummary/OrderSummary.style';
import ProductPriceRange from '../../components/ProductPriceRange';
import { Colors, FontSizes } from '../../constants/theme';
import { ClassItem, Flex, StatusView, Text } from '../../GlobalStyles/CustomizableGlobal.style';
import { InventoryPricingType, InventoryType } from '../../interfaces/inventory.interface';
import { PACK, PIECES, PIECES_AND_PACK } from '../../utils/inventory.utils';
import showAmount from '../../utils/priceFormat.utils';
import { ProductClassWrapper, Label } from './product.style';

const { lightBlue, primaryGrey, primaryColor, white, darkBlue } = Colors;
const { bigFont } = FontSizes;

interface Props {
  handleChange: Function;
  selectedClass?: InventoryType;
  inventoryType?: InventoryType;
  perPack?: number;
  inventoryPrice?: InventoryPricingType | null;
}

const ProductClass: FunctionComponent<Props> = ({ handleChange, selectedClass, inventoryPrice, inventoryType }) => {
  const { darkBlue } = Colors;
  const { bigFont } = FontSizes;

  const [checked, setChecked] = useState<boolean | null>(null);

  const isPieces = () => {
    return selectedClass === 'PIECES';
  };

  const isPack = () => {
    return selectedClass === 'PACK';
  };

  const isPiecesAndPack = () => {
    return selectedClass === PIECES_AND_PACK;
  };

  return (
    <Flex direction="column" justifyContent="flex-start" alignItems="flex-start">
      <Text color={lightBlue} margin="0 0 1rem 0">
        Select type:
      </Text>
      <Flex gap="3rem">
        {(inventoryType === PIECES || inventoryType === PIECES_AND_PACK) && (
          <Flex alignItems="center" gap="5px" onClick={() => handleChange('PIECES')} cursor="pointer">
            <CheckBox radius="50%" htmlFor="toggle-pieces" checked={isPieces()}>
              <span></span>
            </CheckBox>
            <input type="checkbox" id="toggle-pieces" hidden />
            <Text fontSize="15px" color={isPieces() ? primaryColor : lightBlue} margin="0 0 0 5px">
              Pieces
            </Text>
          </Flex>
        )}
        {(inventoryType === PACK || inventoryType === PIECES_AND_PACK) && (
          <Flex alignItems="center" gap="5px" onClick={() => handleChange('PACK')} cursor="pointer">
            <CheckBox radius="50%" htmlFor="toggle-pack" checked={isPack()}>
              <span></span>
            </CheckBox>
            <input type="checkbox" id="toggle-pack" hidden />
            <Text fontSize="15px" color={isPack() ? primaryColor : lightBlue} margin="0 0 0 5px">
              Pack
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default ProductClass;
