import { SELECT_MODULE, selectModuleType } from "../actionTypes";
import SelectModuleInitialState from "../initialStates/selectReportModule";
import { SelectModule } from "../../interfaces/state.interface";

const selectReport = (state: SelectModule = SelectModuleInitialState, action: selectModuleType) => {
  switch (action.type) {
    case SELECT_MODULE:
      return {
        ...state,
        option: action.payload,
      };

    default:
      return state;
  }
};

export default selectReport;
