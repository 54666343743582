import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Colors, FontSizes } from '../../constants/theme';
import { Flex, Text, Ellipse, Button, LinkText, Span } from '../../GlobalStyles/CustomizableGlobal.style';
import {
  AcceptButton,
  ChatBubble,
  ChatBubbleWrapper,
  ChatSpace,
  ChatSpaceBottom,
  ChatSpaceHeader,
  ConversationBox,
  GenericBubble,
  ListOfChats,
  MessageInput,
  MessagesWrapper,
  ProfileList,
  SearchContainer,
  ShopImage
} from './messages.style';
import DefaultShopImage from '../../img/sampleShopImg.jpg';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import {
  FETCH_SHOP_LIST,
  FETCH_SHOP_MESSAGES,
  GET_MESSAGE_SENT,
  SEND_MESSAGE_TO_SHOP,
  UPDATE_MESSAGE_LAST_SEEN
} from '../../schema/messages.schema';
import { useDispatch, useSelector } from 'react-redux';
import {
  BargainStatusType,
  FETCH_SHOP_LIST_TYPE,
  FETCH_SHOP_MESSAGES_TYPE,
  GET_MESSAGE_SENT_TYPE
} from '../../interfaces/fetchMessages.interface';
import { isloading, hasfailed, resetFetch } from '../../store/actions/fetchAction';
import { CameraIcon, MicIcon, SearchIcon, SendIcon } from '../../components/svgIcons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import moment from 'moment';
import formatImageUrl from '../../utils/format.utils';
import { getItem, setItem } from '../../utils/localStorage.utils';
import showAmount from '../../utils/priceFormat.utils';
import Image from '../../components/image';
import BargainModal from '../../components/BargainModal';
import { Inventory } from '../../interfaces/inventory.interface';
import DefaultProductImage from '../../img/timart-image-load.svg';
import { InView, useInView } from 'react-intersection-observer';
import { AppState } from '../../store/reducers';
import { alertNewMessage } from '../../store/actions/messageActions';
import Loader from '../../components/LoaderComponent';
import EmptyChatIcon from '../../img/empty-chat-icon.png';
import { toggleSnackbarOpen } from '../../store/actions/snackbarActions';
import Snackbar from '../../components/snackbar';
import { Shop } from '../../interfaces/shop.interface';
import { addToCart } from '../../store/actions/cartCountAction';
import { getInventoryPrice } from '../../utils/inventory.utils';

const { primaryGrey, grey4, blackLight, green, primaryColor, lightBlue, secondaryColor, white } = Colors;
const { detailsFontSize, secondaryFontSize, descriptionFontSize } = FontSizes;

interface ChatListItemProps {
  storeName: string;
  time: string;
  lastSummary: string;
  shopImage: [
    {
      smallImageOnlineURL: string;
    }
  ];
  unseenMessageCount: number;
}

const ChatListItem: FunctionComponent<ChatListItemProps> = ({ storeName, time, lastSummary, shopImage, unseenMessageCount }) => {
  return (
    <Flex className="chats">
      <ShopImage
        src={shopImage ? formatImageUrl(shopImage[0]?.smallImageOnlineURL, 'product') : DefaultShopImage}
        alt="shop profile picture"
      />
      <div className="shop-details">
        <Flex justifyContent="space-between" className="shop-and-time">
          <Text className="store-name" color={blackLight} fontSize={detailsFontSize} margin="0">
            {storeName}
          </Text>
          <Text color={blackLight} fontSize={'0.6rem'} margin="0" className="time-stamp">
            {moment(time).format('LT')}
          </Text>
        </Flex>
        <Text className="last-chat" color={blackLight} fontSize={'0.6rem'} margin="0">
          {lastSummary}
        </Text>
        {unseenMessageCount ? (
          <Ellipse className="unread" background={primaryColor} width="fit-content" height="1em" borderRadius="30px">
            {unseenMessageCount}
          </Ellipse>
        ) : null}
      </div>
    </Flex>
  );
};

interface ChatBubbleItemProps {
  direction: 'right' | 'left';
  shopImage?: [
    {
      smallImageOnlineURL: string;
    }
  ];
  message: string;
  createdAt: string;
  lastMessageFromShop: string;
  messageId: string;
  updateSync: Function;
}

export const ChatBubbleItem: FunctionComponent<ChatBubbleItemProps> = ({
  direction,
  message,
  createdAt,
  shopImage,
  lastMessageFromShop,
  messageId,
  updateSync
}) => {
  const { ref, inView } = useInView({ triggerOnce: true });
  const isLast = messageId === lastMessageFromShop;

  useEffect(() => {
    if (isLast) {
      updateSync({
        variables: {
          messageId: messageId
        }
      });
    }
  }, [isLast]);

  return (
    <ChatBubbleWrapper ref={ref} direction={direction}>
      <Flex>
        {direction === 'left' && (
          <ShopImage
            src={shopImage ? formatImageUrl(shopImage[0]?.smallImageOnlineURL, 'product') : DefaultShopImage}
            alt="shop profile picture"
          />
        )}
        <ChatBubble direction={direction}>
          <Text color={'inherit'} fontSize={'0.8rem'} margin={'0'}>
            {message}
          </Text>
        </ChatBubble>
      </Flex>
      <Text color={blackLight} fontSize={'0.5rem'} margin="0.3em 0">
        {moment(createdAt).format('LT')}
      </Text>
    </ChatBubbleWrapper>
  );
};

interface BargainBubbleProps {
  direction: 'right' | 'left';
  price: number;
  isBargain: boolean;
  isLastPrice: boolean;
  createdAt: string;
  inventory: Inventory;
  shop: Shop;
  inventoryType: string;
  variationId: string;
  lastMessageFromShop: string;
  messageId: string;
  updateSync: Function;
  bargainStatus: BargainStatusType;
  refetch: Function;
}

const BargainBubbleItem: FunctionComponent<BargainBubbleProps> = ({
  direction,
  price,
  isBargain,
  isLastPrice,
  createdAt,
  inventory,
  shop,
  variationId,
  lastMessageFromShop,
  messageId,
  updateSync,
  bargainStatus,
  inventoryType,
  refetch
}) => {
  const [bargainOpen, toggleBargain] = useState<boolean>(false);
  const inventoryImage = inventory?.Images;
  const imageUrl = inventoryImage[0]?.smallImageOnlineURL;
  const { ref, inView } = useInView({ triggerOnce: true });
  const isLast = messageId === lastMessageFromShop;
  const isBargainAccepted = bargainStatus === 'ACCEPTED';
  const dispatch = useDispatch();
  const history = useHistory();

  const { inventoryId, inventoryName, InventoryPrice, trackable, Shop, Variations } = inventory;
  const { shopName } = Shop || {};
  const validInventoryType = ['PIECES', 'PACK', 'NON_TRACKABLE'];
  const variationName = Variations?.find((variation) => variation.variationId === variationId)?.variationName;

  const inventoryPricing = validInventoryType.includes(inventoryType)
    ? getInventoryPrice(InventoryPrice, inventoryType)
    : { sellingPrice: null, bargainPrice: null, discountPrice: null };

  useEffect(() => {
    if (isLast) {
      updateSync({
        variables: {
          messageId: messageId
        }
      });
    }
  }, [isLast]);

  const addBargainToCart = () => {
    dispatch(
      addToCart(inventoryId, inventoryName, price, 1, price, shopName || '', imageUrl, inventoryType, variationId, variationName || '', {
        sellingPrice: inventoryPricing?.sellingPrice || null,
        discountPrice: null,
        bargainPrice: price
      })
    );
    history.push('/cart');
  };

  return (
    <ChatBubbleWrapper ref={ref} direction={direction} isBargain={isBargain}>
      <GenericBubble direction={direction} isBargain={isBargain}>
        {isLastPrice || isBargainAccepted ? (
          <Flex direction="column" alignItems="baseline">
            {isLastPrice && (
              <Text color={secondaryColor} fontSize={descriptionFontSize}>
                <span>
                  <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                </span>{' '}
                Last price. You can’t continue bargain
              </Text>
            )}
            {isBargainAccepted && (
              <Text color={green} fontSize={descriptionFontSize}>
                Bargain Accepted for <Span textTransform="uppercase">{inventoryName}</Span>
              </Text>
            )}
            <AcceptButton onClick={addBargainToCart}>
              <Text color={white}>
                {isBargainAccepted ? '' : 'Accept and'} Add to Cart {showAmount(price)}
              </Text>
            </AcceptButton>
          </Flex>
        ) : (
          <Flex>
            <Flex direction="column" alignItems="baseline">
              <Text color={blackLight} fontSize={'0.75rem'} margin="0.3em 0">
                BARGAINED
              </Text>
              <Text color={lightBlue} fontSize={'0.5rem'} margin="0.3em 0">
                {inventory?.inventoryName} - {showAmount(price)}
              </Text>
              <LinkText onClick={() => toggleBargain(true)} color={lightBlue} fontSize={'0.5rem'} margin="0.3em 0">
                Click here to continue bargain or view bargain history
              </LinkText>
            </Flex>
            <Image
              className={'inventory-image'}
              src={inventoryImage ? formatImageUrl(inventoryImage[0]?.smallImageOnlineURL, 'product') : DefaultProductImage}
              alt="shop profile picture"
            />
          </Flex>
        )}
      </GenericBubble>
      <Text color={blackLight} fontSize={'0.5rem'} margin="0.3em 0">
        {isLastPrice ? 'This link expires in 3days time from now' : moment(createdAt).format('LT')}
      </Text>

      {bargainOpen && (
        <BargainModal
          toggleBargain={toggleBargain}
          product={inventory}
          shop={shop}
          maxHeight="500px"
          inventoryType={inventoryType}
          variationId={variationId}
          bargainPrice={price}
          refetch={refetch}
        />
      )}
    </ChatBubbleWrapper>
  );
};

interface Props {
  isMobile: boolean;
}

const Messages: FunctionComponent<Props> = ({ isMobile }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const chatContainerRef = useRef<HTMLHeadingElement>();
  const [convoSearch, setConvoSearch] = useState<string>();

  const status = useSelector((state: AppState) => state.fetch.status);
  const show = useSelector((state: AppState) => state.snackbar.toggleSnackbar);

  var y = window.scrollY;

  const queryParams = qs.parse(location.search);
  const useQueryParams = () => {
    return new URLSearchParams(location.search);
  };
  let query = useQueryParams();
  const shopId = query.get('shop');

  const [messageInput, setMessage] = useState<string>();
  const [page, setPage] = useState<number>(1);
  const [isDataReload, setDataReload] = useState<boolean>(false);
  const limit = 10;

  const { data, loading, error, refetch, fetchMore } = useQuery<FETCH_SHOP_MESSAGES_TYPE>(FETCH_SHOP_MESSAGES, {
    variables: {
      shopId: shopId || '',
      limit: limit,
      page: page
    },
    pollInterval: 500
  });

  const [rawMessagesData, setRawMessagesData] = useState<FETCH_SHOP_MESSAGES_TYPE | undefined>(data);

  var element = document.querySelector('.ref');

  const updateQuery = (previousResult: any, { fetchMoreResult }: any) => {
    setDataReload(true);
    if (!fetchMoreResult) {
      return previousResult;
    }

    const previousData = rawMessagesData ? rawMessagesData.fetchShopMessages : previousResult.fetchShopMessages;
    const fetchMoreData = fetchMoreResult.fetchShopMessages;

    fetchMoreResult.fetchShopMessages = [...fetchMoreData, ...previousData];
    setRawMessagesData(fetchMoreResult);

    return { ...fetchMoreResult };
  };

  function isOverflown() {
    if (element) {
      return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
    } else {
      return false;
    }
  }

  const handleScroll = async () => {
    if (element?.scrollTop === 0 && isOverflown()) {
      await fetchMore({
        updateQuery,
        variables: {
          shopId: shopId || '',
          limit: limit,
          page: page + 1
        }
      });
      setPage(page + 1);
    }
  };

  const [, updateState] = React.useState<any>();
  const rerender = React.useCallback(() => updateState({}), []);
  useEffect(() => {
    rerender();
  }, []);

  useEffect(() => {
    if (element && !isDataReload) {
      element.scrollTop = element.scrollHeight;
    }
  }, [data, element]);

  const sortedData = (rawMessagesData || data)?.fetchShopMessages.slice().sort(function compare(a, b) {
    var dateA = new Date(a.createdAt);
    var dateB = new Date(b.createdAt);
    return dateA.getTime() - dateB.getTime();
  });

  const FetchShopMessages = (shopId: string) => {
    const newQueries = {
      ...queryParams,
      shop: shopId
    };
    history.push({
      search: qs.stringify(newQueries)
    });
  };

  const shopList = useQuery<FETCH_SHOP_LIST_TYPE>(FETCH_SHOP_LIST);
  const conversations = shopList.data?.fetchShopList.conversations;

  const filteredConversations = conversations?.filter((convo) => convo.Shop.shopName.includes(convoSearch || ''));

  const [sendMessageToShop] = useMutation<any>(SEND_MESSAGE_TO_SHOP, {
    onCompleted() {
      dispatch(resetFetch());
      refetch();
    }
  });

  const [updateMessageLastSeen] = useMutation<any>(UPDATE_MESSAGE_LAST_SEEN, {
    onCompleted() {
      shopList.refetch();
    }
  });

  const sendMessage = async (e: any) => {
    e.preventDefault();
    dispatch(toggleSnackbarOpen('Sending...'));
    try {
      await sendMessageToShop({
        variables: {
          shopId: shopId,
          message: messageInput
        }
      });
      setMessage('');
    } catch (e) {
      dispatch(hasfailed(e instanceof Error ? `${e}` : 'An unknown error has occured'));
    }
  };

  const goToConversation = (shopId: string) => {
    history.push(`/my-account/messages?shop=${shopId}`);
    setItem('last-shop', { shopId });
  };

  const [showList, setShowList] = useState<'visible' | 'hidden'>(isMobile ? 'visible' : 'hidden');

  useEffect(() => {
    !isMobile && setShowList('visible');
  }, [isMobile]);

  const isNewMessage = useSelector((state: AppState) => state.messages.isNewMessage);

  const fetchMessages = async () => {
    await refetch();
    await shopList.refetch();
  };

  useEffect(() => {
    if (isNewMessage) {
      fetchMessages();
      dispatch(alertNewMessage(false));
      return;
    }
    setRawMessagesData(undefined);
    setPage(1);
    refetch();
  }, [isNewMessage, shopId]);

  const bottomRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (bottomRef.current === null) {
      return;
    }

    bottomRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [loading]);

  if (!loading && !shopList.data?.fetchShopList.conversations.length) {
    return (
      <MessagesWrapper>
        <Flex direction="column" alignItems="center" justifyContent="center">
          <img src={EmptyChatIcon} />
          <Text fontSize={detailsFontSize} color={blackLight} margin="0.5em 0">
            No Conversation Yet
          </Text>
          <Text fontSize={descriptionFontSize} color={blackLight} margin="0">
            Your conversations will appear here
          </Text>
        </Flex>
      </MessagesWrapper>
    );
  }

  return (
    <MessagesWrapper>
      <ListOfChats display={showList}>
        <Flex justifyContent={isMobile ? 'space-between' : 'center'}>
          <Text fontSize={detailsFontSize} color={primaryGrey} margin="1em 1.5em 0 1.5em" fontWeight="700">
            Messages
          </Text>
          {isMobile && (
            <Text fontSize={detailsFontSize} color={primaryGrey} margin="1em 1.5em 0 1.5em" onClick={() => setShowList('hidden')}>
              <i className="fa fa-times" aria-hidden="true" />
            </Text>
          )}
        </Flex>
        <SearchContainer>
          <div className="search-icon">
            <SearchIcon />
          </div>
          <input type="text" placeholder="Type your search here" className="input-field" onChange={(e) => setConvoSearch(e.target.value)} />
        </SearchContainer>
        <ProfileList>
          {shopList.loading ? (
            <Loader />
          ) : (
            (convoSearch ? filteredConversations : conversations)?.map((convo, i) => {
              const { Shop, updatedAt, unseenMessageCount } = convo;
              return (
                <li
                  key={i}
                  className={shopId === Shop.shopId ? 'selected' : ''}
                  onClick={() => {
                    goToConversation(Shop.shopId);
                    isMobile && setShowList('hidden');
                  }}
                >
                  <ChatListItem
                    shopImage={Shop.Images}
                    storeName={Shop.shopName}
                    time={updatedAt}
                    unseenMessageCount={unseenMessageCount}
                    lastSummary={'BARGAINED. Plain T-shirt Roundneck. N35,000'}
                  />
                </li>
              );
            })
          )}
        </ProfileList>
      </ListOfChats>
      <ChatSpace>
        <ChatSpaceHeader>
          <Flex justifyContent="baseline" className="open-convo-info">
            {showList === 'hidden' && (
              <Text color={primaryGrey} fontSize={secondaryFontSize} margin="0 0.5em" onClick={() => setShowList('visible')}>
                <i className="fa fa-bars" aria-hidden="true" />
              </Text>
            )}
            <ShopImage src={DefaultShopImage} alt="shop profile picture" />
            <Flex direction="column" alignItems="baseline" className="shop-details">
              <Text color={primaryGrey} fontSize={detailsFontSize} margin="0.3em 0">
                {data?.fetchShopMessages[0]?.Shop.shopName}
              </Text>
              <Text className="last-chat" color={green} fontSize={'0.75rem'} margin="0">
                Online Now
              </Text>
            </Flex>
          </Flex>
        </ChatSpaceHeader>
        <ConversationBox className="ref" onScroll={handleScroll} onClick={() => setShowList(isMobile ? 'hidden' : 'visible')}>
          {!shopId && (
            <div style={{ margin: 'auto' }}>
              <Text fontSize={detailsFontSize} color={blackLight} margin="0.5em 0">
                No conversations selected
              </Text>
            </div>
          )}
          {loading ? (
            <Loader />
          ) : (
            sortedData?.map((msg) => {
              const {
                message,
                messageId,
                createdAt,
                FromUser,
                Shop,
                isBargain,
                Inventory,
                bargainAmount,
                inventoryType,
                Variation,
                isBargainLastPrice,
                bargainStatus
              } = msg;
              const direction = FromUser ? 'left' : 'right';
              const lastMessageFromShop = sortedData.filter((m) => m.FromUser).pop()?.messageId;
              return (
                <>
                  {isBargain ? (
                    <BargainBubbleItem
                      key={messageId}
                      messageId={messageId}
                      direction={direction}
                      inventory={Inventory}
                      shop={Shop}
                      price={bargainAmount}
                      isBargain={isBargain}
                      createdAt={createdAt}
                      inventoryType={inventoryType}
                      variationId={Variation?.variationId}
                      lastMessageFromShop={lastMessageFromShop || ''}
                      updateSync={updateMessageLastSeen}
                      isLastPrice={isBargainLastPrice}
                      bargainStatus={bargainStatus}
                      refetch={refetch}
                    />
                  ) : (
                    <ChatBubbleItem
                      messageId={messageId}
                      key={messageId}
                      shopImage={Shop.Images}
                      message={message}
                      createdAt={createdAt}
                      direction={direction}
                      lastMessageFromShop={lastMessageFromShop || ''}
                      updateSync={updateMessageLastSeen}
                    />
                  )}
                </>
              );
            })
          )}
          <div ref={bottomRef} />
        </ConversationBox>
        <ChatSpaceBottom onSubmit={sendMessage}>
          <Flex justifyContent="space-between">
            <button type="button">
              <CameraIcon />
            </button>
            <MessageInput
              type="text"
              id="message"
              placeholder="Write a message..."
              onChange={(e) => setMessage(e.target.value)}
              value={messageInput}
            />
            <button type="submit" disabled={!messageInput || !shopId}>
              <SendIcon />
            </button>
          </Flex>
        </ChatSpaceBottom>
      </ChatSpace>
      {show && <Snackbar />}
    </MessagesWrapper>
  );
};

export default Messages;
