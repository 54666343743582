import React, { FunctionComponent, useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import { Required } from '../../utils/formValidations.utils';
import { isloading, hasfailed, resetFetch } from '../../store/actions/fetchAction';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/reducers';
import { Input, InputBox, Label } from '../account/account.style';
import {
  FormContents,
  Title,
  ModalDescription,
  TextFields,
  Button,
  Pinfo,
  Link,
  Recover,
  ErrorMessage,
  FormModalContainer,
  PasswordField as PasswordInput
} from '../../GlobalStyles/forms.style';
import { withRouter } from 'react-router-dom';
import { pushCustomRouteQuery } from '../../utils/pushRoutes.utils';
import PasswordField from '../../components/PasswordField';
import { Flex, Text } from '../../GlobalStyles/CustomizableGlobal.style';
import { Colors, FontSizes } from '../../constants/theme';
import { GoogleLogo, FacebookLogo, SocialMediaDiv } from './login.style';
import { LOGIN_USER } from '../../schema/member.schema';
import { setItem } from '../../utils/localStorage.utils';
import { googleLoginUrl } from '../../utils/graphql.utils';

interface LoginType {
  email: string;
  password: string;
}

interface Props {
  width?: string;
  removeExtra?: boolean;
  authPopup?: boolean;
}

const Login: FunctionComponent<Props> = ({ width, removeExtra, authPopup }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [message, setMessage] = useState('');

  const { body, placeholderColor } = Colors;
  const { bigFont, detailsFontSize } = FontSizes;

  const useQueryParams = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQueryParams();
  const redirectTo = query.get('redirect');
  const google = query.get('google');
  const token = query.get('token');

  useEffect(() => {
    const proceedToAccount = () => {
      if (localStorage.getItem('session') && !redirectTo) {
        history.push('/my-account/account-info');
      }
      if (token) {
        setItem('session', { token });
        history.push('/');
      }
    };
    proceedToAccount();
  });

  const [login] = useMutation<any>(LOGIN_USER, {
    onCompleted({ memberLogin }) {
      localStorage.setItem('session', JSON.stringify(memberLogin));
      dispatch(resetFetch());
      if (authPopup) {
        history.push('/checkout?type=checkout');
      } else {
        redirectTo ? history.push(redirectTo) : history.push('/my-account/account-info');
      }
    }
  });

  const onSubmit = async (data: any) => {
    dispatch(isloading());
    try {
      await login({ variables: { email: `${data.email}`, password: data.currentPassword } });
    } catch (e: any) {
      dispatch(hasfailed(e.message));
      setMessage(e.message.includes('Failed to fetch') ? 'Check your internet connection' : e.message);
    }
  };

  const status = useSelector((state: AppState) => state.fetch.status);

  return (
    <>
      <FormModalContainer width={width}>
        <FormContents onSubmit={handleSubmit(onSubmit)}>
          {!removeExtra && (
            <>
              <Text color={body} fontSize={bigFont} fontWeight="700">
                LOGIN
              </Text>
              <ModalDescription>Welcome back! Login to your account</ModalDescription>
            </>
          )}
          <TextFields>
            <Label margin="0.75rem 0">Email</Label>
            <InputBox style={{ borderColor: errors.email && '#F75151' }}>
              <Input name="email" ref={register(Required)} type="text" placeholder="Example@gmail.com" />
              {errors.email && <ErrorMessage>This field is required</ErrorMessage>}
            </InputBox>
            <Label margin="0.75rem 0">Password</Label>
            <PasswordField placeholder="Enter Password" register={register} required={Required} errors={errors} />
            <div>
              {status !== 'error' ? (
                <div></div>
              ) : (
                <Title style={{ color: 'red', fontSize: '10px', display: 'flex', justifyContent: 'flex-start' }}>
                  {message.includes('Failed to fetch') ? 'Check your internet connection' : message}
                </Title>
              )}
              <Recover onClick={() => history.push('/auth/recoverPassword')}>
                <Link>Recover Password</Link>
              </Recover>
            </div>

            <Button margin="3rem 0" type="submit">
              Login
            </Button>

            <Flex>
              <hr className="line" />
              <Text color={placeholderColor} fontSize={detailsFontSize} width="100%" textAlign="center">
                Or login with
              </Text>
              <hr className="line" />
            </Flex>

            <SocialMediaDiv>
              {/* <FacebookLogo /> */}
              <a href={googleLoginUrl}>
                <GoogleLogo />
              </a>
            </SocialMediaDiv>

            {!removeExtra && (
              <Pinfo>
                Don't have an account?{' '}
                <NavLink to={`/register${redirectTo ? `?redirect=${redirectTo}` : ''}`} className="link">
                  Create Account
                </NavLink>
              </Pinfo>
            )}
          </TextFields>
        </FormContents>
      </FormModalContainer>
    </>
  );
};

export default Login;
