import styled from 'styled-components';
import { Colors, FontSizes, media } from '../../constants/theme';

const { darkGreen, blackLight, white, offWhite, line, lightBlue, senderBubble, receiverBubble } = Colors;
const { descriptionFontSize, primaryFontSize } = FontSizes;
const { smallScreenLaptop, mobile, tablet, standarScreenLaptop } = media;

export const MessagesWrapper = styled.div`
  background: ${offWhite};
  box-shadow: -8px 0px 30px rgba(24, 88, 177, 0.08);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  position: relative;
  height: 70vh;

  .chats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
    width: 100%;
  }

  .unread {
    color: ${white};
    font-size: 10px;
    position: absolute;
    right: 4px;
    bottom: 0px;
    padding: 0.2em;
    box-shadow: -8px 0px 30px rgba(24, 88, 177, 0.08);
  }

  ${mobile} {
    height: calc(100vh - 120px);
  }
`;

interface ListOfChatsProps {
  display: 'visible' | 'hidden';
}

export const ListOfChats = styled.div<ListOfChatsProps>`
  flex-direction: column;
  background: ${white};
  transition: all ease-in-out 0.3s;

  ${({ display }) => {
    if (display === 'visible') {
      return `
        display: flex;
        width: 30%;
        transition: all ease-in-out 0.3s;

        ${mobile} {
          position: absolute;
          left: 0;
          width: 70%;
          background: ${white};
          height: 100%;
          z-index: 8;
          box-shadow: 0px 4px 20px rgba(24, 88, 177, 0.1);
        }
      `;
    } else {
      return `
        display: none;
      `;
    }
  }}
`;

export const SearchContainer = styled.form`
  display: flex;
  background: #f6f8fb;
  margin: 1em 0;
  box-shadow: 0px 4px 20px rgba(24, 88, 177, 0.1);
  width: 90%;
  height: 2.5em;
  align-self: center;

  .input-field {
    outline: none;
  }

  input {
    border: none;
    border-radius: 50px;
    background: transparent;
    width: 100%;
    border-radius: 20px 0 0 20px;
  }

  .search-icon {
    background: transparent;
    border-radius: 0px 50px 50px 0px;
    padding: 10px 15px;
    border: none;
    display: flex;
    align-items: center;
    color: ${lightBlue};
    cursor: pointer;

    path {
      stroke: ${lightBlue};
    }
  }

  ${smallScreenLaptop} {
    padding-right: 0px;
  }

  ${tablet} {
    max-width: 100%;
    display: flex;
    justify-content: center;
    padding-right: 0;
    background: ${white};
    box-shadow: 0;
    border-radius: 0;
  }
`;

export const ProfileList = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: 70vh;

  li {
    padding: 0.5em;
    border-bottom: 1px solid ${line};
    display: flex;
    justify-content: space-around;
  }

  .selected {
    background: ${offWhite};
  }

  .shop-details {
    margin-left: 10px;
    width: 100%;
  }

  .shop-and-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    margin-bottom: 10px;
  }

  .store-name {
    width: 18ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    align-self: flex-start;
  }

  .last-chat {
    width: 33ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }

  .time-stamp {
    min-width: 8ch;
    white-space: nowrap;
  }

  ${tablet} {
    max-width: 100%;
    display: flex;
    justify-content: center;
    padding-right: 0;
    flex-direction: column;
  }

  ${smallScreenLaptop} {
    .store-name {
      width: 9ch;
    }

    .last-chat {
      width: 28ch;
    }

    .time-stamp {
      min-width: 8ch;
    }
  }

  @media screen and (max-width: 1400px) {
    .store-name {
      width: 9ch;
    }

    .last-chat {
      width: 25ch;
    }
  }

  ${mobile} {
    display: flex;
    flex-direction: column;

    .store-name {
      width: 15ch;
      display: flex;
      justify-self: flex-start;
    }

    .last-chat {
      width: 27ch;
      display: flex;
      justify-self: flex-start;
    }
  }
`;

export const ShopImage = styled.img`
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
`;

export const ChatSpace = styled.div`
  width: 70%;
  background: ${offWhite};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all ease-in-out 0.3s;

  ${mobile} {
    width: 100%;
  }
`;

export const ChatSpaceHeader = styled.div`
  width: 100%;
  background: ${white};
  padding: 0.5em 0;
  box-shadow: 0px 4px 20px rgba(24, 88, 177, 0.12);

  .open-convo-info,
  .shop-details {
    margin: 0 1em;
  }
`;

export const ChatSpaceBottom = styled.form`
  width: 100%;
  background: ${white};
  padding: 0.5em 0;
  box-shadow: 0px 4px 20px rgba(24, 88, 177, 0.12);

  .plus-ellipse {
    i {
      font-size: ${primaryFontSize};
    }
  }

  button {
    border: none;
    background: transparent;
    cursor: pointer;
  }
`;

export const MessageInput = styled.input`
  width: 70%;
  padding: 10px;
  border: none;
  outline: none;
`;

export const ConversationBox = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

interface ChatBubbleProps {
  direction: 'right' | 'left';
  isBargain?: boolean;
}

export const ChatBubbleWrapper = styled.div<ChatBubbleProps>`
  align-self: ${({ direction }) => (direction === 'right' ? 'flex-end' : 'flex-start')};
  max-width: ${({ isBargain }) => (isBargain ? '70%' : '50%')};
  margin: 1em 1.5em 0 1.5em;
  text-align: ${({ direction }) => (direction === 'right' ? 'left' : 'right')};
`;

export const GenericBubble = styled.div<ChatBubbleProps>`
  position: relative;
  background-color: ${({ direction, isBargain }) => (isBargain ? 'transparent' : direction === 'right' ? senderBubble : receiverBubble)};
  color: ${({ direction }) => (direction === 'right' ? white : blackLight)};
  padding: 0.75em;
  border-radius: 4px;
  margin-left: ${({ direction }) => (direction === 'left' ? '1em' : '')};
  border: ${({ isBargain }) => (isBargain ? `2px dotted ${lightBlue}` : '')};

  .inventory-image {
    width: 50px;
    height: 50px;
    margin: 0 1em;
  }
`;

export const ChatBubble = styled(GenericBubble)`
  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 30%;
    ${({ direction }) => {
      if (direction === 'right') {
        return `
        left: 100%;
        border-top: 0.5em solid transparent;
        border-left: 0.75em solid ${senderBubble};
        border-bottom: 0.5em solid transparent;
        `;
      }
      if (direction === 'left') {
        return `
          right: 100%;
          border-top: 0.5em solid transparent;
          border-right: 0.75em solid ${receiverBubble};
          border-bottom: 0.5em solid transparent;
        `;
      }
    }}
  }
`;

export const AcceptButton = styled.div`
  background: ${darkGreen};
  border-radius: 10px;
  padding: 0 1em;
  cursor: pointer;
`;
