import styled from 'styled-components';
import { PageContainer } from '../../GlobalStyles/FixedGlobal.style';

const MerchantContainer = styled(PageContainer)`
  position: relative;
  height: max-content;

  #load-more-button {
    margin: 2em auto;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
`;

export default MerchantContainer;
