import styled from 'styled-components';
import { ShopDescription } from '../ShopCard/ShopCard.style';
import { SliderProps } from '../../interfaces/Slider.interface';
import { Colors, media, FontSizes, BoxShadows } from '../../constants/theme';

interface Props extends SliderProps {
  location?: boolean;
  name?: boolean;
  price?: boolean;
}

const { primaryColor, white, secondaryColor, lightGrey } = Colors;
const { mobile, miniMobile } = media;
const { primaryFontSize } = FontSizes;
const { cardBoxShadow } = BoxShadows;

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: ${white};
  margin: 10px;
  position: relative;
  border-radius: 10px;
  height: 250px;
  max-width: 190px;
  min-width: 190px;
  &:hover {
    box-shadow: ${cardBoxShadow};
  }
  #wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  ${mobile} {
    max-width: 145px;
    min-width: 145px;
    height: 180px;
  }

  ${miniMobile} {
    margin: 10px 5px;
  }
`;

export const ImgContainer = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid #f3f3f3;
  height: 60%;
  max-height: 60%;
  min-height: 60%;
  position: relative;
  img {
    height: 100%;
    width: auto;
    object-position: center;
  }
`;
export const ShopTag = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  padding: 0 5px !important;
  align-items: center;
  height: 20px;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 99;
  background: #f4f7fa;
  max-height: 20px;
  img {
    height: 18px;
    width: auto;
  }
  p {
    color: #a0aab9;
  }
  span {
    color: #30415a;
  }
`;

export const ProductInfo = styled.div`
  bottom: 0;
  height: 40%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-top: 5px;
  justify-content: flex-end;

  .more-info {
    display: none;
  }

  &:hover {
    .more-info {
      position: absolute;
      display: flex;
      bottom: 110%;
      right: 0px;
      left: 0px;
      margin: auto;
      width: 180px;
      height: auto;
      padding: 10px;
      border-radius: 6px;
      background: ${primaryColor};
      color: ${white};
      font-size: 14px;
      text-align: center;
      text-justify: center;
      align-items: center;
      box-shadow: 0px 4px 20px rgba(24, 88, 177, 0.12);
    }

    .more-info > .pointer {
      position: absolute;
      display: flex;
      bottom: -6px;
      right: 0px;
      left: 0px;
      margin: auto;
      width: 20px;
    }
  }

  .info-div {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .info-div > .price {
    margin: 0;
    padding: 5px 0 0 0;
    font-weight: 600;
  }
`;

export const Absolute = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  ${mobile} {
    top: 0;
    z-index: 2;
  }
`;

interface ProductTextProps {
  fontSize: string;
  color: string;
  name?: boolean;
  mt?: string;
}

export const ProductText = styled.p<ProductTextProps>`
  margin: 0px;
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ color }) => color};
  width: 100%;
  padding: 0;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: ${({ mt }) => mt};
`;

export const ProductDescription = styled(ShopDescription)`
  color: ${lightGrey};
  margin: 8px 0;

  ${({ location }: Props) =>
    location &&
    `
        color: ${secondaryColor};
    `};

  ${({ sideSlide }: SliderProps) =>
    sideSlide &&
    `
        display: none;
    `};
`;

export const Circle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${primaryFontSize};
  color: ${white};
  background-color: ${primaryColor};
`;

interface CartBadgeContainerProps {
  opacity?: string;
  cursor?: string;
}

export const CartBadgeContainer = styled.div<CartBadgeContainerProps>`
  display: flex;
  position: absolute;
  top: 6%;
  opacity: ${({ opacity }) => opacity};
  cursor: ${({ cursor }) => cursor};
  left: 8%;
  width: 50px;
  height: 50px;
  z-index: 1;
  border-radius: 50%;
  background: #fff2dcf8;
  justify-content: center;
  align-items: center;
  transition: all ease-in-out 0.3s;

  .cart-badge {
    font-size: 30px;
    color: #fb9e12;
  }

  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: #fb9e12;

    .cart-badge {
      color: #fff;
    }
  }
`;

export const OutOfStockBanner = styled.img`
  position: absolute;
  bottom: 100%;
  width: 100%;
`;
