import styled from 'styled-components';
import contactPageBG from '../../img/contactPageBG.png';
import { Colors, FontSizes, FontFamily, media } from '../../constants/theme';

const { primaryColor, white, lightGrey, placeholderColor, blackLight, inputBorderColor } = Colors;
const { descriptionFontSize, bigFont } = FontSizes;
const { primaryFont } = FontFamily;
const { mobile } = media;

interface Props {
  orange?: boolean;
  checkout?: boolean;
}

export const ContactPageContainer = styled.section`
  width: 100px
  display: flex;
  flex-direction: columns;
  justify-content: end;
  background : linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url(${contactPageBG}) no-repeat;
  background-size: 100% 100%;
`;

export const FormCard = styled.div`
  display: flex;
  justify-content: end;
  background-repeat: no-repeat;
  width: 100%;
  ${mobile} {
    margin-top: 70px;
  }
`;

export const FormContents = styled.div`
  box-shadow: 3px 2px 14px 2px rgba(0, 0, 0, 0.05);
  padding: 10px;
  max-width: 100%;
`;

export const ContactFormContents = styled(FormContents)`
  width: 25%;
  background-color: #fff;
  box-shadow: 6px 0px 16px #888888;
  padding: 27px 40px;
  border-radius: 6px;
  margin: 25px 50px;
  ${mobile} {
    width: 100%;
    top: 38px;
    padding: 27px 17px;
    justify-self: center;
    box-shadow: unset;
  }
`;
interface InputProps {
  contact?: boolean;
}

export const PageTitle = styled.h1`
  color: ${primaryColor};
  text-transform: uppercase;
  font-size: ${bigFont};
  margin: 0;
`;

export const Input = styled.input<InputProps>`
  height: 20px;
  border: 1px solid ${inputBorderColor};
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
  outline: none;
  &::placeholder {
    color: ${placeholderColor};
  }
  &:focus {
    outline: none;
    box-shadow: 3px 2px 5px black;
  }
  ${({ contact }) =>
    contact &&
    `
    width: 93%;
`}
`;

export const ModalDescription = styled.p`
  color: ${lightGrey};
  font-size: ${descriptionFontSize};
  margin: 0;
  ${(props: Props) =>
    props.orange &&
    `
        color: ${primaryColor};
    `}
  ${(props: Props) =>
    props.checkout &&
    `
      color: ${blackLight};
      font-weight: 700;
      font-size: 18px;
      margin: 20px 0;
    `}
`;

export const ContactDescription = styled(ModalDescription)`
  color: #4f4f4f;
  margin-top: 10px;
`;

export const Message = styled.textarea`
  height: 170px;
  border: 1px solid ${inputBorderColor};
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
  outline: none;
  font-family: ${primaryFont};
  &:focus {
    outline: none;
    box-shadow: 3px 2px 5px black;
  }
`;

export const ContactMessage = styled(Message)`
  height: 85px;
  width: 93%;
  border-radius: 6px;
  border-style: solid;
  margin-top: 12px;
`;

interface ButtonProps {
  width?: string;
  contact?: boolean;
  shipping?: boolean;
  margin?: string;
  backgroundColor?: string;
}

export const Button = styled.button<ButtonProps>`
  width: ${({ width }) => (width ? width : '100%')};
  height: 50px;
  border-radius: 5px;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : primaryColor)};
  color: ${white};
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
  margin: ${({ margin }) => margin};
  &:hover {
    background-color: #f7ac3b;
  }
  ${({ contact }) =>
    contact &&
    `
        margin-top: 40px;
    `}
  ${({ shipping }) =>
    shipping &&
    `
          margin-top: 30px;
      `}
`;

export const SocialIconCont = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const SocialIcons = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 50px 25px 0;
  padding: 0 40px;

  ${mobile} {
    width: 100%;
    margin: unset;
    margin-bottom: 15px;
  }
`;

export const IconCont = styled.div`
  background-color: #b9b9b9;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 5px;
  border-radius: 50%;

  &:hover {
    background-color: ${primaryColor};
  }
`;

export const ThankYouPageContainer = styled.section`
  width: 100%;
  height: 74vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 65px;

  ${mobile} {
    margin-bottom: unset;
  }
`;

export const Image = styled.img`
  ${mobile} {
    width: 65%;
  }
`;

export const Heading = styled.h2`
  font-size: 1.5rem;
  color: ${primaryColor};
  text-align: center;
  margin-top: 10px;
`;

export const Description = styled.p`
  font-size: 1 rem;
  margin: 0;
  color: ${blackLight};
  text-align: center;
`;
