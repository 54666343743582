import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { SuccessContainer, SuccessIcon, SuccessMsg, Pinfo } from './success.style';
import { generateUrlForLogin } from '../../utils/customRoute.utils';

interface Props {
  message: string;
}

const Success: FunctionComponent<Props> = ({ message }) => {
  return (
    <SuccessContainer>
      <SuccessIcon className="fas fa-check-circle" />
      <SuccessMsg>{message}</SuccessMsg>
    </SuccessContainer>
  );
};

export default Success;
