import styled from 'styled-components';
import { Colors, media } from '../../../constants/theme';

const { primaryGrey, lightbg, primaryColor } = Colors;
const { mobile } = media;

export const H1 = styled.h1`
  text-align: center;
  align-items: center;
  color: ${primaryGrey};
  width: 30%;
  font-size: 24px;

  span {
    color: ${primaryColor};
    margin: 0px;
    padding: 0px;
  }

  ${mobile} {
    font-size: 1rem;
  }
`;

export const FilterContainer = styled.div`
  background-color: ${lightbg};
  padding: 0.5em 2em;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  ${mobile} {
    padding: 1.5em 2em;
  }

  .price-range {
    input {
      background: ${lightbg};
    }
  }

  .apply-button {
    padding: 5px;
  }

  .top-filters-container {
    flex-flow: row wrap;

    ${mobile} {
      justify-content: center;
    }
  }
`;
