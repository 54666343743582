import React, { FunctionComponent } from 'react';
import Header from '../../components/header/header';
import BottomNav from '../../components/footer/bottomNav';
import { Helmet } from 'react-helmet-async';
import { Title } from '../../constants/meta.constant';
import { connect } from 'react-redux';
//icons
import Whatsapp from '../../img/whatsapp.png';
import Facebook from '../../img/facebook.png';
import twitter from '../../img/twitter.png';
//styles
import {
  SocialIcons,
  IconCont,
  ContactPageContainer,
  SocialIconCont,
  ContactFormContents,
  ContactDescription,
  FormCard,
  ContactMessage,
  Button,
  Input,
  PageTitle
} from './ContactUs.style';

const ContactUsPage: FunctionComponent = () => {
  return (
    <>
      <Helmet>
        <title>{`${Title} | contact timart`}</title>
        <meta name="description" content="get in touch with timart" />
      </Helmet>

      <Header />
      <ContactPageContainer>
        <FormCard>
          <ContactFormContents>
            <PageTitle>CONTACT US</PageTitle>
            <ContactDescription> Any questions or remarks? Fill the form below.</ContactDescription>
            <form action="https://formsubmit.co/89f260502e3f29ef80ec85a2fd1fa5d7" method="post">
              <Input contact name="fullName" required type="text" placeholder="Your Full Name*" />

              <Input contact name="email" required type="email" placeholder="Email*" />

              <Input contact name="phoneNumber" type="tel" placeholder="Mobile Number" />
              <ContactMessage name="message" required placeholder="Your Message*" />
              <input type="hidden" name="_subject" value="New submission!" />
              <input type="hidden" name="_autoresponse" value="Your message has been received. We will revert back very soon" />
              <input type="hidden" name="_next" value="https://timart.com.ng/thank-you" />
              <input type="hidden" name="_cc" value="quickmanager.app@gmail.com" />
              <Button contact type="submit">
                Send Message
              </Button>
            </form>
          </ContactFormContents>
        </FormCard>
        <SocialIconCont>
          <SocialIcons>
            <a href="https://wa.me/message/5UWYTYAMFQRZL1" target="blank">
              <IconCont>
                <img src={Whatsapp} alt="whatsApp-link" />
              </IconCont>
            </a>
            <a href="https://m.facebook.com/timartng/" target="blank">
              <IconCont>
                <img src={Facebook} alt="facebook-link" />
              </IconCont>
            </a>
            <a href="https://twitter.com/Timartng?t=r4cbNdzSmC9P9jtK6-xb_w&s=09" target="blank">
              <IconCont>
                <img src={twitter} alt="twitter-link" />
              </IconCont>
            </a>
          </SocialIcons>
        </SocialIconCont>
      </ContactPageContainer>
      <BottomNav />
    </>
  );
};

export default connect(null)(ContactUsPage);
