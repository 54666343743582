export const isServer = () => !globalThis.document;
export const isClient = () => !!globalThis.document;

const serverLocalStorage = () => {
  const item = {} as any;

  return {
    getItem: (key: string) => item[key],
    setItem: (key: string, value: string): void => {
      item[key] = value;
    }
  };
};

export const localStorage = isServer() ? serverLocalStorage() : globalThis.localStorage;

export const domainName = process.env.REACT_APP_DOMAIN_NAME_URL;
