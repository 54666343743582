import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getItem, setItem } from '../../utils/localStorage.utils';
import { addToCart, addToCartFromLocalStorage } from '../../store/actions/cartCountAction';
import { AppState } from '../../store/reducers';
import { CounterContainer, CounterCircle, CounterText } from './counter.style';
import { InventoryPrice, InventoryPricingType } from '../../interfaces/inventory.interface';
import { getInventoryPrice } from '../../utils/inventory.utils';
import { MinusIcon, AddIcon } from '../../img/svgIcons';

interface Props {
  onCart?: boolean;
  inventoryId: string;
  inventoryType?: string;
  variationId?: string;
  variationName?: string;
  productName: string;
  merchant: string;
  imageurl: string;
  fixedPrice: number;
  setFixedPrice?: Function;
  getTotal?: Function;
  dotSize?: string;
  counterInitial?: number;
  setNotNull?: Function;
  openModal?: Function;
  inventoryPrice?: InventoryPrice | undefined;
  initialInventoryType?: string;
}

const Counter: FunctionComponent<Props> = ({
  onCart,
  inventoryId,
  inventoryType,
  variationId,
  variationName,
  fixedPrice,
  getTotal,
  dotSize,
  productName,
  merchant,
  imageurl,
  setFixedPrice,
  counterInitial,
  setNotNull,
  openModal,
  inventoryPrice,
  initialInventoryType
}) => {
  const cartItems = useSelector((state: AppState) => state.CartCounter.cartItems);
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(counterInitial || 0);
  const [price, setPrice] = useState(fixedPrice);
  const inventoryPricing =
    inventoryPrice && inventoryType
      ? getInventoryPrice(inventoryPrice, inventoryType, variationId)
      : { sellingPrice: null, discountPrice: null, bargainPrice: null };

  useEffect(() => {
    if (setNotNull) {
      setNotNull(counter >= 1);
    }
  }, [counter]);

  let existingCartItem = cartItems.find((cartItem) => {
    let updated =
      cartItem.id === inventoryId &&
      cartItem.inventoryType === inventoryType &&
      (inventoryType === 'VARIATION' ? cartItem.variationId === variationId : true);
    return updated;
  });

  const incrementCounter = () => {
    if (existingCartItem) {
      setCounter(counter + 1);
      existingCartItem.quantity = existingCartItem.quantity + 1;
      existingCartItem.price = existingCartItem.price + existingCartItem.fixedUnitPrice;
      setPrice(price + existingCartItem.fixedUnitPrice);
      if (setFixedPrice && price) {
        setFixedPrice(price + existingCartItem.fixedUnitPrice);
      }
      let index = cartItems
        .map((cartItem: any) => cartItem.id)
        .indexOf(existingCartItem.id && existingCartItem.variationId === variationId);
      let copyOfCartItems = [...cartItems];
      copyOfCartItems[index] = existingCartItem;
      setItem('allCartItems', copyOfCartItems);
      if (getItem('allCartItems')) {
        let allCartItems = JSON.parse(getItem('allCartItems') || '[]');
        dispatch(addToCartFromLocalStorage(allCartItems));
      }
      if (getTotal) {
        getTotal();
      }
    } else {
      dispatch(
        addToCart(
          inventoryId,
          productName,
          price || 0,
          1,
          price || 0,
          merchant,
          imageurl,
          inventoryType || '',
          variationId || '',
          variationName || '',
          inventoryPricing || { sellingPrice: null, discountPrice: null, bargainPrice: null },
          initialInventoryType || ''
        )
      );
    }
  };

  const decrementCounter = () => {
    if (counter > 1 && existingCartItem) {
      setCounter(counter - 1);
      existingCartItem.quantity = existingCartItem.quantity - 1;
      existingCartItem.price = existingCartItem.price - existingCartItem.fixedUnitPrice;
      setPrice(price - existingCartItem.fixedUnitPrice);
      if (setFixedPrice && price) {
        setFixedPrice(price - existingCartItem.fixedUnitPrice);
      }

      let index = cartItems
        .map((cartItem: any) => cartItem.id)
        .indexOf(existingCartItem.id && existingCartItem.variationId === variationId);
      let copyOfCartItems = [...cartItems];
      copyOfCartItems[index] = existingCartItem;
      setItem('allCartItems', copyOfCartItems);
      if (getItem('allCartItems')) {
        let allCartItems = JSON.parse(getItem('allCartItems') || '[]');
        dispatch(addToCartFromLocalStorage(allCartItems));
      }
      if (getTotal) {
        getTotal();
      }
    }
  };

  const updateTotalPrice = () => {
    if (existingCartItem) {
      existingCartItem.price = existingCartItem?.fixedUnitPrice * existingCartItem?.quantity;
      setPrice(existingCartItem?.price);
      if (setFixedPrice) {
        setFixedPrice(existingCartItem?.price);
      }
      if (getTotal) {
        getTotal();
      }
    }
  };

  useEffect(() => {
    updateTotalPrice();
    existingCartItem && setCounter(existingCartItem.quantity);
  }, [cartItems, existingCartItem]);

  return (
    <CounterContainer onCart={onCart}>
      <CounterCircle
        dotSize={dotSize}
        onCart={onCart}
        onClick={() => (inventoryType === 'VARIATION' && openModal ? openModal(true) : decrementCounter())}
        style={{ opacity: `${counter <= 1 ? '0.5' : '1'}` }}
      >
        <MinusIcon size="20" />
      </CounterCircle>
      <CounterText>{counter}</CounterText>
      <CounterCircle
        dotSize={dotSize}
        onCart={onCart}
        onClick={() => (inventoryType === 'VARIATION' && openModal ? openModal(true) : incrementCounter())}
      >
        <AddIcon size="20" />
      </CounterCircle>
    </CounterContainer>
  );
};

export default Counter;
