import sampleProductImg from '../img/no_image.svg';

export const formatImageUrl = (image?: string, defaultImageType?: 'product' | 'shop'): string => {
  if (!image) {
    return defaultImageType === 'product' ? sampleProductImg : '';
  }

  return image?.includes('http') ? image : `https://cloud.quickshop.com.ng${image}`;
};

export const formatName = (name?: string) => {
  if (!name) {
    return '';
  }

  const words = name.toLowerCase().split(' ');
  for (var i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  return words.join(' ');
};

export default formatImageUrl;
