import React from 'react';
import styled from 'styled-components';
import { Colors, FontSizes, media } from '../../constants/theme';
import MobileSectionBg from '../../img/launch-page-bg-mobile.jpg';
import { Flex } from '../../GlobalStyles/CustomizableGlobal.style';

export const FacebookLogo = () => {
  return (
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_10971_36284)">
        <path
          d="M28.5 14C28.5 6.26806 22.2319 0 14.5 0C6.76806 0 0.5 6.26795 0.5 14C0.5 20.9878 5.61963 26.7797 12.3125 27.8299V18.0469H8.75781V14H12.3125V10.9156C12.3125 7.40688 14.4027 5.46875 17.6005 5.46875C19.1322 5.46875 20.7344 5.74219 20.7344 5.74219V9.1875H18.9691C17.2298 9.1875 16.6875 10.2667 16.6875 11.3739V14H20.5703L19.9496 18.0469H16.6875V27.8299C23.3804 26.7797 28.5 20.9879 28.5 14Z"
          fill="#1877F2"
        />
        <path
          d="M19.9496 18.0469L20.5703 14H16.6875V11.3739C16.6875 10.2666 17.2299 9.1875 18.9691 9.1875H20.7344V5.74219C20.7344 5.74219 19.1322 5.46875 17.6005 5.46875C14.4027 5.46875 12.3125 7.40688 12.3125 10.9156V14H8.75781V18.0469H12.3125V27.8299C13.0361 27.9433 13.7675 28.0002 14.5 28C15.2325 28.0002 15.9639 27.9434 16.6875 27.8299V18.0469H19.9496Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_10971_36284">
          <rect width="28" height="28" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const GoogleLogo = () => {
  return (
    <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.6651 12.9704H27.6247V12.9168H15.9997V18.0835H23.2995C22.2346 21.0911 19.3729 23.2502 15.9997 23.2502C11.7197 23.2502 8.24967 19.7801 8.24967 15.5002C8.24967 11.2202 11.7197 7.75016 15.9997 7.75016C17.9753 7.75016 19.7726 8.49545 21.1412 9.71285L24.7946 6.05937C22.4877 3.90939 19.4019 2.5835 15.9997 2.5835C8.86645 2.5835 3.08301 8.36693 3.08301 15.5002C3.08301 22.6334 8.86645 28.4168 15.9997 28.4168C23.1329 28.4168 28.9163 22.6334 28.9163 15.5002C28.9163 14.6341 28.8272 13.7887 28.6651 12.9704Z"
        fill="#FFC107"
      />
      <path
        d="M4.57227 9.4881L8.81604 12.6004C9.96433 9.75741 12.7453 7.75016 15.9996 7.75016C17.9752 7.75016 19.7726 8.49545 21.1411 9.71285L24.7946 6.05937C22.4877 3.90939 19.4019 2.5835 15.9996 2.5835C11.0383 2.5835 6.73581 5.38447 4.57227 9.4881Z"
        fill="#FF3D00"
      />
      <path
        d="M16 28.4166C19.3364 28.4166 22.368 27.1398 24.66 25.0635L20.6623 21.6806C19.3219 22.7 17.684 23.2513 16 23.25C12.6404 23.25 9.78777 21.1077 8.7131 18.1182L4.50098 21.3635C6.63868 25.5465 10.98 28.4166 16 28.4166Z"
        fill="#4CAF50"
      />
      <path
        d="M28.6654 12.9701H27.625V12.9165H16V18.0832H23.2999C22.7904 19.5146 21.8728 20.7654 20.6603 21.6811L20.6623 21.6798L24.66 25.0627C24.3771 25.3197 28.9167 21.9582 28.9167 15.4998C28.9167 14.6338 28.8275 13.7884 28.6654 12.9701Z"
        fill="#1976D2"
      />
    </svg>
  );
};

const { darkBlue, primaryColor, white } = Colors;
const { detailsFontSize } = FontSizes;
const { mobile, tablet } = media;

export const LoginWrapper = styled(Flex)`
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;

  ${tablet} {
    flex-direction: column;
    overflow-y: auto;
  }

  .line {
    border: 1px solid #9ea8b7;
    width: 100%;
  }

  .app-download {
    margin: 1rem 0;
  }
`;

interface SectionProps {
  background?: string;
  display?: string;
  isPhotoBg?: boolean;
}

export const HalfSection = styled.div<SectionProps>`
  width: 50%;
  height: 100%;
  background: ${({ background }) => background};
  background-size: cover;
  display: ${({ display }) => display};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 1.5rem;
  overflow: auto;

  ${tablet} {
    width: 90%;
    text-align: center;
    height: 100%;
    overflow: unset;
  }

  ${mobile} {
    background: ${({ isPhotoBg }) => (isPhotoBg ? `url(${MobileSectionBg})` : white)};
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

interface AbsoluteProps {
  top?: string;
  bottom?: string;
  right?: string;
  left?: string;
}

export const Absolute = styled.div<AbsoluteProps>`
  position: absolute;
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  right: ${({ right }) => right};
  left: ${({ left }) => left};
`;

export const HalfPageContent = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SocialMediaDiv = styled.div`
  display: flex;
  justify-content: center !important;
  align-items: center;
  gap: 1.5rem;
`;

export const GetTimartText = styled.p`
  color: ${darkBlue};
  font-size: ${detailsFontSize};

  span {
    color: ${primaryColor};
  }
`;
