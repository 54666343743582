import styled from 'styled-components';

export const Header = styled.div`
  padding: 15px;
  border-bottom: 1px solid #e4ebf4;
  div {
    display: flex;
    justify-content: space-between;
    h3 {
      color: #ffa412;
      font-size: 20px;
      margin: 0;
    }
    button {
      background: transparent;
      border: none;
      display: flex;
      align-items: center;
      cursor: pointer;
      :hover {
        opacity: 0.6;
      }
      img {
        margin-right: 10px;
      }
    }
  }
  p {
    margin: 0;
    font-size: 12px;
    color: #4f4f4f;
  }
`;
export const AddAddressBtn = styled.button`
  border: none;
  background: transparent;
  border-bottom: 1px solid #e4ebf4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  cursor: pointer;
  :hover {
    color: #ffa412;
  }
  img {
    margin-right: 15px;
  }
`;
export const AddressContainer = styled.div`
  max-height: 300px;
  overflow-y: scroll;
  padding: 15px;
`;
export const AddressCard = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid #8196b3;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  cursor: pointer;

  .address {
    display: flex;
    margin-bottom: 50px;
  }

  h3 {
    margin: 0;
    font-size: 15px;
    color: #607087;
  }
  p {
    margin: 0;
    font-size: 13px;
    color: #8196b3;
  }
`;
export const Control = styled.div`
  display: flex;
  position: absolute;
  right: 10px;
  bottom: 10px;

  button {
    display: flex;
    border: none;
    border-radius: 5px;
    background: #f5f5f5;
    padding: 5px 15px;
    align-content: center;
    cursor: pointer;
    img {
      height: 14px;
      margin-right: 10px;
    }
  }

  button:nth-child(1) {
    background: #ffdfdf;
    color: #607087;
    margin-right: 20px;

    :hover {
      opacity: 0.8;
      color: #ffa412;
    }
  }
  button:nth-child(2) {
    color: #607087;

    :hover {
      opacity: 0.8;
      color: #ffa412;
    }
  }
`;
export const SubmitBtn = styled.button`
  background: #ffa412;
  color: #fff;
  padding: 15px 0;
  width: calc(100% - 30px);
  border-radius: 5px;
  margin: 5px 15px 15px 15px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
`;
