import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import ShopCard from '../../../components/ShopCard';
import ShopListContainer, { ShopListHeadingSection, ShopListWrapper } from './MerchantList.style';
import Error from '../../../components/ErrorComponent';
import Loader from '../../../components/LoaderComponent';
import { addFetchLimit, addShopData, addPageNumber } from '../../../store/actions/fetchLimitAction';
import { Flex, Text } from '../../../GlobalStyles/CustomizableGlobal.style';
import { Colors, FontSizes } from '../../../constants/theme';
import Filter from '../../../components/FilterCard';
import Pagination from '../../../components/pagination';
import { useLocation, useParams } from 'react-router-dom';
import getGeoLocation, { locationPermissionRequest } from '../../../utils/queryLocation.utils';
import getGeoLocationPosition from '../../../utils/queryLocation.utils';
import { limit } from '../../../constants/limit.constant';
import { GET_ALL_SHOPS, GET_ALL_SHOPS_TYPE } from '../../../schema/shop.schema';
import { getItem } from '../../../utils/localStorage.utils';
import { SEARCH_INVENTORY } from '../../../schema/inventory.schema';
import { SEARCH_INVENTORY_TYPE } from '../../../interfaces/inventory.interface';

interface Props {
  addFetchLimit: (data?: any[]) => void;
  addShopData: (data?: any[]) => void;
  addPageNumber: () => void;
  marginTop?: string;
  withSearch?: boolean;
  noPagination?: boolean;
}

const ShopList: FunctionComponent<Props> = ({ addFetchLimit, marginTop, withSearch, noPagination }) => {
  const locationPermission = locationPermissionRequest.getPermissionStatus();
  const position = getGeoLocationPosition();
  const { page } = useParams<{ page: string }>();
  const { selectedState, selectedCity } = JSON.parse(getItem('userLocation')! ?? "{}");

  const useQueryParams = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQueryParams();
  const currentPage = Number(page) || 1;

  const { error, data, loading, refetch } = useQuery<SEARCH_INVENTORY_TYPE>(SEARCH_INVENTORY, {
    variables: {
      page: currentPage,
      limit: limit,
      latitude: position.latitude,
      longitude: position.longitude,
      stateId: selectedState?.value,
      cityId: selectedCity?.value,
      searchType: 'SHOP',
      searchString:  query.get('q'),
    }
  });
  const { body, primaryColor, secondaryColor } = Colors;
  const { titleFont, detailsFontSize, primaryFontSize } = FontSizes;

  const allShop = data?.searchInventoryOrShop.shops;
  const totalShops = data?.searchInventoryOrShop.totalInventoriesAndShops;

  useEffect(() => {
    const loadAllShops = () => {
      addFetchLimit(allShop);
    };
    loadAllShops();
  });

  const openLocationModal = async () => {
    locationPermissionRequest.setPermissionStatus('granted');
    getGeoLocation((currentPosition) => {
      refetch({ page: currentPage, limit: limit, latitude: currentPosition.latitude, longitude: currentPosition.longitude });
    });
  };
  const encodedSearchQuery = query.get('q');
  let searchQuery = '';
  if (encodedSearchQuery) searchQuery = decodeURIComponent(encodedSearchQuery);

  if (loading) return <Loader />;
  if (error) return <Error message={error.message} />;

  return (
    <ShopListWrapper marginTop={'40px'}>
      {withSearch && (
        <ShopListHeadingSection>
          <div className="filter-section">
            <Text color={body} fontSize={titleFont} fontWeight={'500'}>
              Shops
            </Text>
            <Text color={primaryColor} fontSize={detailsFontSize} margin={'0 10px'}>
              {allShop?.length}
            </Text>
          </div>
          <Filter searchQuery={searchQuery} />
        </ShopListHeadingSection>
      )}
      <Text color={body} fontSize={'28px'} margin={'1rem'} fontWeight={'500'}>
        Shops
      </Text>
      {locationPermission === 'denied' && (
        <Flex alignItems="center" justifyContent="initial" className="clickable" onClick={openLocationModal}>
          <Text color={secondaryColor} fontSize={primaryFontSize} fontWeight="500" margin="0 0.5em">
            <b>Enable</b> location to view shops near you
          </Text>
        </Flex>
      )}
      <ShopListContainer>
        {allShop?.map((item) => {
          const { shopName, shopAddress, shopId, Images, distance, ShopURL } = item;
          return (
            <ShopCard
              key={Math.random()}
              peek
              image={Images?.[0].mediumImageOnlineURL ?? ''}
              shopId={shopId}
              name={shopName}
              location={shopAddress}
              distance={distance}
              shopTag={ShopURL?.shopTag ?? ""}
            />
          );
        })}
      </ShopListContainer>
      {!noPagination && (
        <Pagination originalPath={'/merchant'} paramType={'ROUTE'} currentPage={currentPage} totalItems={totalShops || 0} limit={limit} />
      )}
    </ShopListWrapper>
  );
};

export default connect(null, { addPageNumber, addFetchLimit, addShopData })(ShopList);
