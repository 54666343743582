import gql from 'graphql-tag';
import { InventorySchema } from './inventory.schema';
import { ShopSchema } from './shop.schema';

const MessageSchema = `
  messageId
  Member {
    memberId
    fullName
    email
  }
  FromUser {
    userId
    businessName
    fullName
    email
    isMerchant
    merchantId
  }
  Inventory {
    ${InventorySchema}
  }
  Shop {
    ${ShopSchema}
  }
  inventoryType
  Variation {
    variationId
  }
  message
  isBargain
  bargainAmount
  isBargainLastPrice
  createdAt
  bargainStatus
`;

export const FETCH_SHOP_MESSAGES = gql`
  query FetchShopMessages($shopId: String!, $page: Int, $limit: Int) {
    fetchShopMessages(shopId: $shopId, page: $page, limit: $limit) {
      ${MessageSchema}
    }
  }
`;

export const SEND_MESSAGE_TO_SHOP = gql`
mutation SendMessageToShop($shopId: String!, $message: String, $inventoryId: ID, $inventoryType: String, $isBargain: Boolean, $bargainAmount: Int, $variationId: ID) {
  sendMessageToShop(shopId: $shopId, message: $message, inventoryId: $inventoryId, inventoryType: $inventoryType, isBargain: $isBargain, bargainAmount: $bargainAmount, variationId: $variationId) {
    ${MessageSchema}
  }
}
`;
export const REQUEST_LOCATION_FEE = gql`
  mutation RequestLocationShippingFee($cityId: String!, $stateId: String!, $shopId: String!) {
    requestLocationShippingFee(cityId: $cityId, stateId: $stateId, shopId: $shopId)
  }
`;

export const FETCH_SHOP_LIST = gql`
  query FetchShopList {
    fetchShopList {
      conversations {
        unseenMessageCount
        updatedAt
        Shop {
          shopName
          shopId
          Images {
            smallImageOnlineURL
          }
        }
      }
      totalUnseenMessage
      totalConversations
    }
  }
`;

export const GET_MESSAGE_SENT = gql`
  subscription MessageSent($memberId: String) {
    messageSent(memberId: $memberId) {
      messageId
      Shop {
        shopName
      }
      message
      isBargain
      bargainAmount
      bargainStatus
      isBargainLastPrice
      updatedAt
    }
  }
`;

export const UPDATE_MESSAGE_LAST_SEEN = gql`
  mutation UpdateMessageLastSeen($messageId: String!) {
    updateMessageLastSeen(messageId: $messageId)
  }
`;
