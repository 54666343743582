import React, { FunctionComponent } from 'react';
import { AuthWrapper, AuthPageContent, FormContainer } from './auth.style';
import RecoverPassword, { FORGOT_PASSWORD_MEMBER } from './RecoverPassword';
import UpdatePassword from './UpdatePassword';
import { useQuery } from '../../utils/customRoute.utils';
import PopupCard from '../../components/PopupCard';
import { AppState } from '../../store/reducers';
import Loader from '../../components/LoaderComponent';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Title } from '../../constants/meta.constant';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import ColorLogo from '../../img/logo-color.svg';
import LinkSent from './LinkSent';
import { useMutation } from '@apollo/client';
import { resetFetch } from '../../store/actions/fetchAction';
interface LinkParams {
  authType: string;
}

const Auth: FunctionComponent = () => {
  const status = useSelector((state: AppState) => state.fetch.status);
  const { tab } = useParams<{ tab: string }>();

  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <>
      <Helmet>
        <title>{`${Title} | Auth`}</title>
        <meta name="description" content="Login or signup on Timart Ecommerce site" />
      </Helmet>
      <AuthWrapper>
        <AuthPageContent>
          <NavLink to={'/'}>
            <img src={ColorLogo} />
          </NavLink>
          <FormContainer className="auth-section">
            {tab === 'recoverPassword' && <RecoverPassword />}
            {tab === 'updatePassword' && <UpdatePassword />}
            {tab === 'link-success' && <LinkSent />}
          </FormContainer>
        </AuthPageContent>
        {status === 'loading' && (
          <PopupCard transparent>
            <Loader />
          </PopupCard>
        )}
      </AuthWrapper>
    </>
  );
};

export default Auth;
