import React from 'react';

const BackgroundIcons = () => {
  return (
    <div className="background-container">
      <div className="left-content"></div>
      <div className="right-content"></div>
    </div>
  );
};

export default BackgroundIcons;
