import gql from 'graphql-tag';

export const CREATE_MEMBER_ORDER = gql`
  mutation createMemberOrder(
    $orderItems: [JSONObject!]!
    $paymentMethod: PaymentOption!
    $deliveryOption: DeliveryOption!
    $referenceId: String
    $addressId: ID!
  ) {
    createMemberOrder(
      orderItems: $orderItems
      paymentMethod: $paymentMethod
      deliveryOption: $deliveryOption
      referenceId: $referenceId
      addressId: $addressId
    ) {
      successful
    }
  }
`;

export const GET_ORDER_FEE = gql`
  mutation getOrderFees($orderItems: [JSONObject!]!, $paymentMethod: PaymentOption!, $deliveryOption: DeliveryOption!, $addressId: ID) {
    getOrderFees(orderItems: $orderItems, paymentMethod: $paymentMethod, deliveryOption: $deliveryOption, addressId: $addressId) {
      deliveryFee
      transactionFee
      totalAmount
      subTotal
      availablePaymentOptions
      availableDeliveryOptions
      errorMessages
      warningMessages
      errorAndWarningMessages {
        shopId
        errorMessages
      }
    }
  }
`;

export interface GET_ORDER_FEE_TYPE {
  deliveryFee: number;
  transactionFee: number;
  totalAmount: number;
  subTotal: number;
  availablePaymentOptions: string[];
  availableDeliveryOptions: string[];
  errorMessages: string[];
  warningMessages: string[];
  errorAndWarningMessages: [
    {
      shopId: string;
      errorMessages: string[];
    }
  ];
}

export const INITIATE_ORDER_PAYMENT = gql`
  mutation InitiateOrderPayment($orderItems: [JSONObject]!, $deliveryOption: DeliveryOption!, $addressId: ID) {
    initiateOrderPayment(orderItems: $orderItems, deliveryOption: $deliveryOption, addressId: $addressId) {
      referenceId
    }
  }
`;
