import React, { FunctionComponent, useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { Colors, FontSizes } from '../../constants/theme';
import { Button, Text } from '../../GlobalStyles/CustomizableGlobal.style';
import history from '../../utils/history.utils';
import { PaginationWrapper } from './pagination.style';
import qs from 'query-string';
import { Pagination as ReactPagination } from 'react-headless-pagination';
import { windowAdapter } from '../../utils/serverClientAdapter.utils';

interface Props {
  currentPage: number;
  totalItems: number;
  limit: number;
  paramType: 'SEARCH' | 'ROUTE';
  originalPath?: string;
}

const Pagination: FunctionComponent<Props> = ({ currentPage, totalItems, limit, paramType, originalPath }) => {
  const { secondaryFontSize } = FontSizes;
  const { primaryGrey } = Colors;
  const location = useLocation();
  const lastPage = Math.ceil(totalItems / limit);
  const queryParams = qs.parse(location.search);
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === lastPage;

  const changePage = (page: number) => {
    if (paramType === 'SEARCH') {
      const newQueries = {
        ...queryParams,
        p: page
      };
      history.push({
        search: qs.stringify(newQueries)
      });
    }
    if (paramType === 'ROUTE') {
      history.push(`${originalPath}/${page}`);
    }
  };

  const handlePageChange = (page: number) => {
    changePage(page + 1);
  };

  useEffect(() => {
    if (windowAdapter) {
      windowAdapter.scrollTo(0, 0);
    }
  }, [currentPage]);

  return (
    <PaginationWrapper>
      <ReactPagination
        currentPage={currentPage - 1}
        setCurrentPage={handlePageChange}
        totalPages={lastPage}
        className="react-pagination-wrap"
        truncableText="..."
        truncableClassName=""
      >
        <ReactPagination.PrevButton className="pagination-button">
          <Text className={`page-controls ${isFirstPage ? 'disable' : ''}`} fontSize={secondaryFontSize} color={primaryGrey}>
            Previous
          </Text>
        </ReactPagination.PrevButton>

        <div className="page-numbers-wrap">
          <ReactPagination.PageButton activeClassName="current-page" inactiveClassName="not-current-page" className="page-numbers" />
        </div>

        <ReactPagination.NextButton className="pagination-button">
          <Text className={`page-controls ${isLastPage ? 'disable' : ''}`} fontSize={secondaryFontSize} color={primaryGrey}>
            Next
          </Text>
        </ReactPagination.NextButton>
      </ReactPagination>
    </PaginationWrapper>
  );
};

export default Pagination;
