import { OperationVariables, QueryResult, useQuery } from '@apollo/client';
import React, { FunctionComponent, useState } from 'react';
import qs from 'query-string';
import { GET_SHOP_CATEGORIES_TYPE } from '../../interfaces/ShopCategories.interface';
import { GET_SHOP_CATEGORIES_SCHEMA } from '../../schema/ShopCategories.schema';
import Error from '../ErrorComponent';
import Loader from '../LoaderComponent';
import { CategoryBtn, SidebarContainer } from './sidebar.style';
import history from '../../utils/history.utils';
import {
  AutomobileIcon,
  BallIcon,
  BasketIcon,
  BeautyIcon,
  BedIcon,
  ComputingIcon,
  FashionIcon,
  FoodIcon,
  MedicsIcon,
  OfficeIcon,
  OtherIcon,
  ShapeIcon
} from './svgIcons';
import { useLocation } from 'react-router-dom';

interface Props {
  categoryData: QueryResult<GET_SHOP_CATEGORIES_TYPE, OperationVariables>;
}

export const sidebarIcons = [
  BeautyIcon,
  MedicsIcon,
  FashionIcon,
  OfficeIcon,
  BallIcon,
  BasketIcon,
  FoodIcon,
  ComputingIcon,
  BedIcon,
  OtherIcon,
  AutomobileIcon
];
const Sidebar: FunctionComponent<Props> = ({ categoryData }) => {
  const [display, setDisplay] = useState(false);
  const location = useLocation();
  const [categoryIndex, setCategoryIndex] = useState(0);
  const { data, loading, error } = categoryData;
  const categories = [
    {
      id: '',
      name: 'All Categories',
      icon: ShapeIcon
    },
    ...(data?.getShopCategories.map((cat, index) => {
      const mapped = {
        id: cat.shopCategoryId,
        name: cat.shopCategoryName.toLowerCase(),
        icon: sidebarIcons[index]
      };
      return mapped;
    }) || [])
  ];

  const handleMouseOver = (index: number) => {
    setDisplay(true);
    setCategoryIndex(index);
  };

  const queryParams = qs.parse(location.search);
  const handleCategorySearch = (name: string) => {
    const newQueries = {
      ...queryParams,
      category: name
    };
    history.push({
      search: qs.stringify(newQueries)
    });
  };

  return (
    <SidebarContainer>
      {loading && <Loader />}
      {error && <Error message={error.message} />}
      {categories.map((item, i) => {
        const { name, icon: Icon, id } = item;
        return (
          <CategoryBtn onMouseOver={() => handleMouseOver(i)} onClick={() => handleCategorySearch(name.toUpperCase())} key={i}>
            <div>
              <Icon />
            </div>
            <span>{name}</span>
          </CategoryBtn>
        );
      })}
    </SidebarContainer>
  );
};

export default Sidebar;
