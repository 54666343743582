import { IFetchLimit, fetchLimitInitialState } from "./../initialStates/fetchLimit";
import { FETCH_LIMIT, ADD_SHOP_DATA, fetchLimitType, ADD_PAGE_NUMBER } from "../actionTypes";

const fetchShopLimit = (state: IFetchLimit = fetchLimitInitialState, action: fetchLimitType) => {
  switch (action.type) {
    case FETCH_LIMIT: {
      return {
        ...state,
        shopData: [action.payload],
      };
    }

    case ADD_PAGE_NUMBER: {
      return {
        ...state,
        page: state.page + 1,
      };
    }

    case ADD_SHOP_DATA: {
      return {
        ...state,
        shopData: [...state.shopData, ...action.payload],
      };
    }

    default:
      return state;
  }
};

export default fetchShopLimit;
