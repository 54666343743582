import styled from 'styled-components';
import { media } from '../../../constants/theme';

const { tablet, mobile, smallScreenLaptop, mediumScreenLaptop, miniMobile } = media;

export const ShopViewContents = styled.div`
  display: inline-grid;
  justify-content: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  max-width: 1600px;
  margin: 0 auto;
  grid-gap: 1rem;
  margin-top: 2rem;

  .not-found {
    display: flex;
    min-width: 100vw;
    align-items: center;
    justify-content: center;
    height: 150px;
  }

  .text-none {
    text-decoration: none;
  }

  ${mediumScreenLaptop} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(180px, auto);
    grid-gap: 1rem;
  }
  ${smallScreenLaptop} {
    grid-template-columns: repeat(4, 1fr);
  }
  ${tablet} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${mobile} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${miniMobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ShopViewWrapper = styled.div`
  margin: 1rem 5rem;
  min-height: 100vh;

  #load-more {
    margin: 0 auto;
  }

  ${tablet} {
    margin: 2rem;
    margin-top: 1rem;

    .path-text {
      font-size: 0.6em;
    }
  }

  ${mobile} {
    margin: 1rem;

    .hide-mobile {
      display: none;
    }
  }
`;
