import React, { createContext, useState, ReactNode } from 'react';
import { OptionType } from '../interfaces/FilterSelect.interface';
import { getItem } from '../utils/localStorage.utils';

interface MyContextProps {
  isLocationSet: boolean;
  setLocationSet: React.Dispatch<React.SetStateAction<boolean>>;
  currentState: OptionType | null;
  setCurrentState: React.Dispatch<React.SetStateAction<OptionType | null>>;
  currentCity: OptionType | null;
  setCurrentCity: React.Dispatch<React.SetStateAction<OptionType | null>>;
}

const LocationContext = createContext<MyContextProps | undefined>(undefined);

interface MyProviderProps {
  children: ReactNode;
}

export const LocationProvider = ({ children }: MyProviderProps) => {
  const { selectedState, selectedCity } = JSON.parse(getItem('userLocation') || '{}');

  const defaultLocation = {
    label: 'Federal Capital Territory',
    totalInventoryCount: 6,
    value: 15
  };

  const [isLocationSet, setLocationSet] = useState(false);
  const [currentState, setCurrentState] = useState<OptionType | null>(selectedState || defaultLocation);
  const [currentCity, setCurrentCity] = useState<OptionType | null>(selectedCity);

  return (
    <LocationContext.Provider value={{ isLocationSet, setLocationSet, currentState, setCurrentState, currentCity, setCurrentCity }}>
      {children}
    </LocationContext.Provider>
  );
};

export const useUserLocation = () => React.useContext(LocationContext);
