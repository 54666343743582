import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import history from './history.utils';

interface ICustomRoute {
  Children: any;
  queryPath: string;
}

interface ICustomRoutePath {
  [key: string]: number;
}

export const customRoutePaths: ICustomRoutePath = {};

const CustomRoute = ({ Children, queryPath }: ICustomRoute) => {
  const Component = () => {
    let queryParams = new URLSearchParams(location.search);
    if (queryParams.get(queryPath)) {
      return <Children />;
    }
    return null;
  };

  customRoutePaths[queryPath] = 1;
  const location = (globalThis as any).location;
  return <Route path={`/`} children={Component} />;
};

export const generateRouteUrl = (queryKey: string, queryValue: string, queryParams: any) => {
  queryParams.append(queryKey, queryValue);
  return '?' + queryParams.toString();
};

export const removeAllModalRoutes = () => {
  const location = (globalThis as any).location;
  let queryParams = new URLSearchParams(location?.search);

  let routes = queryParams.keys();
  for (let key of routes) {
    if (customRoutePaths[key]) {
      queryParams.delete(key);
    }
  }
  let query = `?${queryParams.toString()}`;
  history.replace(query);
};

export const generateUrlForContact = (): string => {
  const location = (globalThis as any).location;
  let queryParams = new URLSearchParams(location?.search);
  queryParams.delete('login');
  return generateRouteUrl('contactUs', 'contact-us', queryParams);
};

export const generateUrlForThankYou = (): string => {
  const location = (globalThis as any).location;
  let queryParams = new URLSearchParams(location?.search);
  queryParams.delete('contactUs');
  return generateRouteUrl('thank-you', 'ThankYouPage', queryParams);
};

export const generateUrlForLogin = (): string => {
  const location = (globalThis as any).location;
  let queryParams = new URLSearchParams(location?.search);
  queryParams.delete('signup');
  return generateRouteUrl('login', 'loginForm', queryParams);
};

export const generateUrlForSignup = (): string => {
  const location = (globalThis as any).location;
  let queryParams = new URLSearchParams(location?.search);
  return generateRouteUrl('type', 'signup', queryParams);
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default CustomRoute;
