const formatDistance = (distance: number | undefined) => {
  if (!distance) return '';
  let distanceInKm = Number(distance.toFixed(2));
  if (distanceInKm >= 0.01) {
    return `${distanceInKm} km`;
  } else {
    return `< 0.01 km`;
  }
};

export default formatDistance;
