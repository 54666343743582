import React, { FunctionComponent } from 'react';
import { Absolute, HalfSection, LoginWrapper } from './login.style';
import LaunchPageBG from '../../img/launch-page-bg.jpg';
import WiggleArrow from '../../img/wiggle-arrow.svg';
import WiggleArrowSmall from '../../img/wiggle-arrow-small.svg';
import ColorLogo from '../../img/logo-color.svg';
import { Flex, Span, Text } from '../../GlobalStyles/CustomizableGlobal.style';
import { Colors } from '../../constants/theme';
import AppDownloadMediums from '../../components/AppDownloadMediums';
import { NavLink } from 'react-router-dom';
import useWindowDimensions from '../../utils/windowsDimension.utils';

interface Props {
  children: JSX.Element;
}

const AuthShell: FunctionComponent<Props> = ({ children }) => {
  const { primaryColor, darkBlue } = Colors;
  const { width } = useWindowDimensions();
  const isMobile = width <= 576;

  return (
    <LoginWrapper>
      <HalfSection background={`url(${LaunchPageBG})`} isPhotoBg display={isMobile ? 'none' : 'flex'}>
        <Absolute left="10%" top="10%">
          <NavLink to={'/'}>
            <img src={ColorLogo} />
          </NavLink>
        </Absolute>
        <Absolute right="5%" bottom="40%">
          <img src={WiggleArrow} />
        </Absolute>
      </HalfSection>
      <HalfSection background={'#FAF7f7'}>
        {children}
        <Flex margin="5rem 0 0 0" className="app-download">
          <AppDownloadMediums />
        </Flex>
        <Flex margin="3rem 0 1rem 0" style={{ position: 'relative' }}>
          <Absolute left={isMobile ? '5%' : '20%'} bottom="10%">
            <img src={WiggleArrowSmall} />
          </Absolute>
          <Text color={darkBlue}>
            Find out more on{' '}
            <a href="gettimart.com" target="_blank">
              <Span color={primaryColor}>gettimart.com</Span>
            </a>
          </Text>
        </Flex>
      </HalfSection>
    </LoginWrapper>
  );
};

export default AuthShell;
