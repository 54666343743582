import React, { FunctionComponent, useEffect } from 'react';
import Select from 'react-select';
import { Colors } from '../../constants/theme';
import { OptionType } from '../../interfaces/FilterSelect.interface';

interface IProps {
  placeholder: string;
  options: OptionType | any;
  value: OptionType | any;
  defaultValue?: OptionType;
  onChange: (val: OptionType) => void;
  isClearable?: boolean;
  customStyles?: any;
}

const ReactSelect: FunctionComponent<IProps> = ({ placeholder, defaultValue, options, value, onChange, isClearable, customStyles }) => {
  const { primaryColor, white } = Colors;

  const customSelectStyles = {
    indicatorsContainer: () => ({
      backgroundColor: `${primaryColor}`,
      borderRadius: '0px 5px 5px 0px',
      height: '40px',
      width: '40px',
      cursor: 'pointer',
      paddingLeft: '3px',
      svg: {
        color: `${white}`
      }
    })
  };

  useEffect(() => {
    if (defaultValue?.value) {
      onChange && onChange(defaultValue);
    }
  }, []);

  return (
    <Select
      defaulValue={defaultValue}
      value={value}
      onChange={onChange}
      styles={customStyles || customSelectStyles}
      options={options}
      placeholder={placeholder}
      className="select"
      zIndex={2000}
      isClearable={isClearable}
      inputProps={{ autoComplete: 'off' }}
    />
  );
};
export default ReactSelect;
