import { Inventory, InventoryType } from './inventory.interface';

const PIECES = 'PIECES';
const PACK = 'PACK';
const VARIATION = 'VARIATION';
const NON_TRACKABLE = 'NON_TRACKABLE';
const PIECES_AND_PACK = 'PIECES_AND_PACK';

const getProductPrice = (product: Inventory, selectedInventoryType: InventoryType, selectedVariationId: string): number => {
  let price = 0;

  switch (selectedInventoryType) {
    case PIECES:
      price = product.TrackableItem?.unitPrice;
      break;
    case PACK:
      price = product.TrackableItem?.packPrice;
      break;
    case VARIATION:
      let gottenPrice = product?.Variations?.filter((variation) => variation.variationId === selectedVariationId)?.[0]?.price ?? 0;
      price = gottenPrice;
      break;
    case NON_TRACKABLE:
      price = product.NonTrackableItem?.sellingPrice;
      break;
    case PIECES_AND_PACK:
      break;
    default:
      throw Error(`InventoryType not found ${selectedInventoryType}`);
  }
  return price;
};

export default getProductPrice;
