import gql from 'graphql-tag';
import { ImageSchema } from './image.schema';
import { ShopSchema } from './shop.schema';

const InventoryPricingType = `
  sellingPrice
  bargainPrice
  discountPrice
`;

export const InventorySchema = `
  inventoryName
  inventoryDescription
  inventoryId
  quantityInPacks
  quantityInPieces
  trackable
  isVariation
  quantity
  returnPolicy
  Images {
    ${ImageSchema}
  }
  TrackableItem {
    unitPiecesCostPrice
    unitPackCostPrice
    perPack
    unitPrice
    packPrice
  }
  NonTrackableItem {
    costPrice
    sellingPrice
    fixedSellingPrice
  }
  Variations {
    variationId
    variationName
    inventoryId
    price
    cost
    shopId
  }
  Shop {
    ${ShopSchema}
  }
  InventoryUrl {
    inventoryUrlId
    shopId
    inventoryId
    url
  }
  inventoryType
  InventoryPrice {
    PIECES {
      ${InventoryPricingType}
    }
    PACK {
      ${InventoryPricingType}
    }
    NON_TRACKABLE{
      ${InventoryPricingType}
    }
    VARIATION {
      variationId
      price {
        ${InventoryPricingType}
      }
    }
  }
`;

export const GET_ALL_SHOP_INVENTORY_SCHEMA = gql`
  query getAllShopInventory($shopId: ID, $shopTag: String, $searchString: String!, $inventoryCategoryId: ID!, $page: Int, $limit: Int) {
    getAllShopInventory(
      shopId: $shopId
      shopTag: $shopTag
      searchString: $searchString
      inventoryCategoryId: $inventoryCategoryId
      page: $page
      limit: $limit
    ) {
      totalInventory
      inventories {
        ${InventorySchema}
      }
      Shop {
        ${ShopSchema}
      }
    }
  }
`;

export const GET_ALL_SHOP_INVENTORY_CATEGORY_SCHEMA = gql`
  query getAllShopInventoryCategory($shopId: ID, $shopTag: String) {
    getAllShopInventoryCategory(shopId: $shopId, shopTag: $shopTag) {
      inventoryCategoryId
      inventorycategoryName
    }
  }
`;

export const GET_INVENTORIES_AT_RANDOM_SCHEMA = gql`
query getInventoriesAtRandom($limit: Int, $stateId: Int, $cityId: Int) {
  getInventoriesAtRandom(limit: $limit, stateId: $stateId, cityId: $cityId) {
    ${InventorySchema}
  }
}
`;

export const GET_INVENTORY = gql`
  query getInventory($inventoryId: ID, $inventoryUrl: String) {
    getInventory(inventoryId: $inventoryId, inventoryUrl: $inventoryUrl) {
      ${InventorySchema}
    }
  }`;

export const GET_ALL_INVENTORY = gql`
  query getAllInventory($page: Int, $limit: Int, $inventoryCategoryId: ID) {
    getAllInventory(page: $page, limit: $limit, inventoryCategoryId: $inventoryCategoryId) {
      inventories{
        ${InventorySchema}
      }
    }
  }`;

export const SEARCH_INVENTORY = gql`
  query searchInventoryOrShop(
    $category: String
    $searchString: String
    $searchType: SearchType
    $city: String
    $page: Int
    $latitude: Float
    $longitude: Float
    $limit: Int
    $stateId: Int 
    $cityId: Int
    $sortBy: SortBy
  ) {
    searchInventoryOrShop(
      category: $category
      searchString: $searchString
      searchType: $searchType
      city: $city
      page: $page
      latitude: $latitude
      longitude: $longitude
      limit: $limit
      stateId: $stateId
      cityId: $cityId
      sortBy: $sortBy
    ) {
      totalInventoriesAndShops
      inventories {
        ${InventorySchema}
      }
      shops {
        ${ShopSchema}
      }
    }
  }
`;

export const GET_INVENTORIES = gql`
  query getInventories($inventoryIds: [ID!]) {
    getInventories(inventoryIds: $inventoryIds) {
      inventoryId
      inventoryName
      inventoryDescription
      Images {
        largeImageOnlineURL
        mediumImageOnlineURL
        smallImageOnlineURL
      }
      TrackableItem {
        unitPrice
        packPrice
      }
      NonTrackableItem {
        sellingPrice
      }
      Variations {
        variationId
        variationName
        price
        cost
      }
    }
  }
`;
