import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { NavLink, withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  Nav,
  LogoContainer,
  LogoImg,
  Cart,
  Hr,
  CartCounter,
  RightContainer,
  NavItem,
  DynamicSearchBar,
  SearchButton,
  MobileSidebar,
  SidebarSection,
  ParentLink,
  NormalLink,
  UserLocationContainer,
  HelpContainer,
  MerchantContainer
} from './navBar.style';
import CartImg from '../../../img/cartIcon.svg';
import { NavStyles } from '../../../interfaces/NavbarStyles.interface';
import { AppState } from '../../../store/reducers/index';
import { landingNavbarOpen } from '../../../store/actions/landingNavButton';
import redirectSearchResult from '../../../store/actions/redirectToResult';
import { redirectType } from '../../../store/initialStates/redirectToSearchResult';
import { useLocation } from 'react-router-dom';
import search from '../../../img/search.svg';
import mobileSearch from '../../../img/mobile-search.svg';
import AccountIcon from '../../../img/User.svg';
import BreadCrumb from '../../../img/Breadcrumb.svg';
import Shops from '../../../img/shops.svg';
import shopIcon from '../../../img/solid-shop.svg';
import chevLeft from '../../../img/chevron-left.svg';
import close from '../../../img/close.svg';
import caret from '../../../img/caret-right.svg';
import searchB from '../../../img/search-blue.svg';
import arrowRight from '../../../img/arrow-grey.svg';
import useWindowDimensions from '../../../utils/windowsDimension.utils';
import { windowAdapter } from '../../../utils/serverClientAdapter.utils';
import { getItem, getItemAsObject } from '../../../utils/localStorage.utils';
import { gql, useQuery, useSubscription } from '@apollo/client';
import { GET_MESSAGE_SENT_TYPE } from '../../../interfaces/fetchMessages.interface';
import { GET_MESSAGE_SENT } from '../../../schema/messages.schema';
import { toggleSnackbarOpen } from '../../../store/actions/snackbarActions';
import Snackbar from '../../snackbar';
import { alertNewMessage } from '../../../store/actions/messageActions';
import { GET_ALL_SHOP_CATEGORY } from '../../../schema/shop.schema';
import { GET_SHOP_CATEGORIES_TYPE } from '../../../interfaces/ShopCategories.interface';
import UserLocation from './userLocation';
import { Flex, Text } from '../../../GlobalStyles/CustomizableGlobal.style';
import { Colors } from '../../../constants/theme';
import Help from '../../../img/help.svg';
import mobileLogo from '../../../img/timart-logo-mobile.png';
import { ShopImage } from '../../ShopCard/ShopCard.style';
import { CartIcon, ChevDownIcon, HelpIcon, ShopIcon, UserIcon } from '../../../img/svgIcons';

const mapStateToProps = (state: AppState) => {
  return {
    clicked: state.landingNavButton.clicked,
    cartItems: state.CartCounter.cartItems,
    fireRedirect: state.redirect.fireRedirect
  };
};

interface Props extends NavStyles, redirectType {
  clicked: boolean;
  cartItems: any[];
  landingNavbarOpen: () => void;
  redirectSearchResult: (value: boolean) => void;
  categoriesOpen?: boolean;
  setCategoriesOpen?: Function;
  showSearchIcon?: boolean;
}

const NavBar: FunctionComponent<Props & RouteComponentProps> = ({
  cartItems,
  fireRedirect,
  redirectSearchResult,
  categoriesOpen,
  setCategoriesOpen
}) => {
  const [scroll, setScroll] = useState<boolean>(true);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [searchNav, setSearchNav] = useState<boolean>(false);
  const [cat, setCat] = useState('');
  const [newMessage, setNewMessage] = useState<GET_MESSAGE_SENT_TYPE>();

  const { primaryGrey } = Colors;

  const { width } = useWindowDimensions();
  const isSession = getItem('session');

  const dispatch = useDispatch();
  const location = useLocation();
  const show = useSelector((state: AppState) => state.snackbar.toggleSnackbar);
  const isloadingCart = useSelector((state: AppState) => state.CartCounter.loadingCart);

  const getMessages = useSubscription<GET_MESSAGE_SENT_TYPE>(GET_MESSAGE_SENT, {
    variables: { memberId: JSON.parse(isSession || '{}')?.member?.memberId }
  });

  const isNewMessage = getMessages.data;
  const notLandingPage = location.pathname !== '/';
  const shopName = getMessages.data?.messageSent.Shop.shopName;

  useEffect(() => {
    if (isNewMessage) {
      setNewMessage(isNewMessage);
      dispatch(toggleSnackbarOpen(`New Message from ${shopName}`));
      dispatch(alertNewMessage(true));
      return;
    }
  }, [isNewMessage]);

  const navItems = [
    { text: 'Shops Around You', src: ShopIcon, alt: 'shops', link: 'merchant/1' },
    { text: isSession ? 'My Account' : 'Login/Create Account', src: UserIcon, alt: 'account', caret, link: 'login' }
    // { text: 'Help', src: Help, alt: 'help', link: '' }
  ];

  useEffect(() => {
    const onScroll = (): void => {
      const isTop = windowAdapter.scrollY < 1;
      if (isTop !== scroll) {
        setScroll(!scroll);
      }
    };
    document.addEventListener('scroll', onScroll);

    return (): void => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [scroll, setScroll]);

  const useQueryParams = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQueryParams();

  const encodedQuery = query.get('q');
  let searchQuery = '';
  if (encodedQuery) searchQuery = decodeURIComponent(encodedQuery);

  const encodedCategory = query.get('category');
  let category = '';
  if (encodedCategory) category = decodeURIComponent(encodedCategory);

  useEffect(() => {
    setCat(category);
  }, [category]);

  const urlLocation = useLocation();
  const linkRef = useRef<HTMLAnchorElement>(null);
  const queryData = useRef<HTMLInputElement>(null);
  let searchInput: string = queryData.current ? queryData.current.value : '';

  const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Enter') {
      linkRef?.current && linkRef.current.click();
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const handleInputChange = (query: boolean): void => {
    redirectSearchResult(query);
  };

  const { data } = useQuery<GET_SHOP_CATEGORIES_TYPE>(GET_ALL_SHOP_CATEGORY);
  const categories = data?.getShopCategories.map((cat) => cat.shopCategoryName);

  const SearchBar = (desktop: boolean = false) => {
    return (
      <DynamicSearchBar desktop={desktop} className="active">
        {searchNav && <img onClick={(e) => setSearchNav(false)} className="close-icon" src={chevLeft} alt="close" />}
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <div className="input-wrapper">
              <Link
                to={
                  searchInput !== ''
                    ? {
                        pathname: '/searchQuery',
                        search: `?category=${encodeURIComponent(cat)}&p=1&q=${encodeURIComponent(searchInput)}`
                      }
                    : urlLocation
                }
              >
                <button type="submit" className="m-btn">
                  <img src={searchB} alt="searchIcon" />
                </button>
              </Link>
              <input
                className="input-field"
                onChange={() => handleInputChange(!fireRedirect)}
                onKeyPress={handleKeydown}
                onKeyDown={handleKeydown}
                type="text"
                ref={queryData}
                defaultValue={''}
                placeholder="Type your search here..."
              />
            </div>
            <div className="divider" />
            <Link
              to={
                searchInput !== ''
                  ? {
                      pathname: '/searchQuery',
                      search: `?category=${encodeURIComponent(cat)}&p=1&q=${encodeURIComponent(searchInput)}`
                    }
                  : urlLocation
              }
            >
              <SearchButton desktop={desktop} type="submit">
                <img src={search} alt="searchIcon" />
              </SearchButton>
            </Link>
          </div>
        </form>
      </DynamicSearchBar>
    );
  };

  return (
    <Nav>
      {searchNav ? (
        SearchBar()
      ) : (
        <Flex width={'100%'} justifyContent="space-between">
          <LogoContainer>
            <img
              onClick={(e) => {
                setMenuOpen(menuOpen || categoriesOpen ? false : true);
                if (setCategoriesOpen) {
                  setCategoriesOpen(!categoriesOpen);
                }
              }}
              className="breadcrumb"
              src={menuOpen || categoriesOpen ? close : BreadCrumb}
              alt="breadcrumb"
            />
            <Link to="/">
              <LogoImg src={width < 426 ? mobileLogo : '/timart-logo.svg'} alt="logo" />
            </Link>
          </LogoContainer>
          <MerchantContainer>
            <NavLink to={`/merchant/1`}>
              <NavItem menuOpen={menuOpen}>
                <div style={{ display: 'flex' }}>
                  <ShopIcon />
                  <h5 style={{ marginLeft: '10px' }}>Shops</h5>
                </div>
              </NavItem>
            </NavLink>
          </MerchantContainer>

          <UserLocationContainer>
            <Text color={primaryGrey} margin={'0 0.5rem'}>
              Find Products in
            </Text>
            <UserLocation />
            <div style={{ margin: '0 1rem' }}>{width >= 1024 && SearchBar(true)}</div>
          </UserLocationContainer>
          <HelpContainer>
            <p>
              <HelpIcon />
              <span>Help</span>
              <ChevDownIcon />
            </p>
            <div className="dropdown">
              <a href="https://gettimart.com/about">About Us</a>
              <Link to="/faq">FAQs</Link>
            </div>
          </HelpContainer>

          <RightContainer>
            {width < 768 && notLandingPage ? (
              <img className="mobile-search" onClick={() => setSearchNav(true)} src={mobileSearch} alt="searchIcon" />
            ) : null}
            <div
              className={`nav-links-container ${(menuOpen || categoriesOpen) && 'active'} `}
              onClick={(e) => {
                setMenuOpen(false);
                if (setCategoriesOpen) {
                  setCategoriesOpen(false);
                }
              }}
            >
              <div className={`nav-links ${(menuOpen || categoriesOpen) && 'active'} `}>
                {width > 768 ? (
                  navItems.slice(1).map((item, i) => (
                    <NavLink to={`/${item.link}`} key={i}>
                      <NavItem menuOpen={menuOpen}>
                        <div style={{ display: 'flex' }}>
                          <item.src />
                          <h5 style={{ marginLeft: '10px' }}>{item.text}</h5>
                        </div>
                      </NavItem>
                    </NavLink>
                  ))
                ) : (
                  <MobileSidebar>
                    <SidebarSection>
                      <ParentLink to="/login">
                        <span>Account</span>
                        <img src={arrowRight} alt="" />
                      </ParentLink>
                      <NormalLink to="/my-account/order-history">Order History</NormalLink>
                    </SidebarSection>
                    <SidebarSection>
                      <ParentLink to="">
                        <span>Categories</span>
                        <img src={arrowRight} alt="" />
                      </ParentLink>
                      {categories?.map((cat, index) => (
                        <NormalLink
                          key={index}
                          to={{
                            pathname: '/searchQuery',
                            search: `?category=${encodeURIComponent(cat)}&p=1`
                          }}
                        >
                          {cat.toLowerCase()}
                        </NormalLink>
                      ))}
                    </SidebarSection>
                    <SidebarSection>
                      <ParentLink to="/merchant/1">
                        <span>Shops</span>
                        <span style={{ color: '#BDBCBC', fontSize: '12px' }}>See all</span>
                      </ParentLink>
                    </SidebarSection>
                    <SidebarSection>
                      {/* <NormalLink to="https://gettimart.com/">Help</NormalLink> */}
                      {/* <NormalLink to="https://gettimart.com/download">Download App</NormalLink> */}
                      <NormalLink to="/contact-us">Contact Us</NormalLink>
                    </SidebarSection>
                  </MobileSidebar>
                )}
              </div>
            </div>
            <Link to={'/cart'}>
              <Cart>
                <CartIcon />
                <span>Cart</span>
                <CartCounter>{isloadingCart ? '-' : cartItems.length}</CartCounter>
              </Cart>
            </Link>
          </RightContainer>
        </Flex>
      )}
      {show && <Snackbar />}
    </Nav>
  );
};

export default withRouter(connect(mapStateToProps, { redirectSearchResult, landingNavbarOpen })(NavBar));
