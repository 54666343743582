import styled from 'styled-components';
import { Colors, media } from '../../constants/theme';

const { inputBorderColor, primaryGrey, primaryColor, lightBlue } = Colors;
const { mobile } = media;

interface BargainContentsProps {
  maxHeight?: string;
}

export const BargainContents = styled.div<BargainContentsProps>`
  max-height: ${({ maxHeight }) => maxHeight};

  .close-button {
    cursor: pointer;

    &:hover {
      color: ${primaryColor};
    }
  }

  .vl {
    border-left: 1px solid ${lightBlue};
    height: 90%;
  }

  .price-diff {
    margin: 2em 0;
    height: 4em;

    ${mobile} {
      width: 80%;
    }
  }

  .fit-to-mobile {
    ${mobile} {
      width: 90%;
      text-align: center;
    }
  }

  .bargain-prod-img {
    width: 3.5em;
  }
`;

interface OfferProps {
  focus?: boolean;
}

export const OfferPriceField = styled.div<OfferProps>`
  height: 30px;
  width: 50%;
  border: 1px solid ${({ focus }) => (focus ? primaryColor : inputBorderColor)};
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
  outline: none;
  font-size: 1.7em;
  font-weight: 600;
  color: ${primaryGrey};
  text-align: center;
`;

interface BargainInputProps {
  focus?: boolean;
}

export const BargainInput = styled.input<BargainInputProps>`
  height: 30px;
  width: 50%;
  border: 1px solid ${({ focus }) => (focus ? primaryColor : inputBorderColor)};
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
  outline: none;
  font-size: 1.7em;
  font-weight: 600;
  color: ${primaryGrey};
  text-align: center;
`;
