import styled from 'styled-components';
import { Colors, media } from '../../constants/theme';
import { Flex } from '../../GlobalStyles/CustomizableGlobal.style';

const { primaryColor, white, lightOrange } = Colors;
const { mobile, smallScreenLaptop } = media;

interface Props {
  notLast?: boolean;
  color?: string;
}
interface DeliveryCardProps {
  selected: boolean;
}

export const CheckoutPageContentWrapper = styled.div`
  padding: 10px 40px;
  max-width: 1420px;
  margin: 0 auto;
  margin-top: 4rem;
  h3 {
    color: #2f2f2f;
    margin: 0;
  }
  ${smallScreenLaptop} {
    margin-top: 5rem;
    padding: 10px;
  }
  ${mobile} {
    margin-top: 4rem;
    padding: 10px;
  }
`;

export const CheckoutInformation = styled.div`
  display: flex;
  align-self: center;
  margin: 0 auto;
  width: 100%;

  ${mobile} {
    width: 100%;
  }
`;

export const OrderSummaryContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 50%;
  ${mobile} {
    width: 100%;
  }
`;

export const RadioPoints = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
`;

export const RadioCircle = styled.i`
  font-size: 30px;
  color: ${({ color }: Props): string => `${color}`};
  position: relative;

  &::before {
    background-color: ${white};
  }
`;

export const Line = styled.hr`
  width: 99%;
  border: 1px solid ${primaryColor};
`;

export const Slider = styled.div`
  position: relative;
  width: 90%;
  margin-top: 20px;

  ${mobile} {
    width: 100%;
  }
`;
export const Left = styled.div`
  width: 70%;
  padding-right: 15px;
  ${smallScreenLaptop} {
    width: 100%;
    padding: 0;
  }
`;
export const Right = styled.div`
  width: 30%;
  padding: 0 15px;
  margin-top: 1rem;

  h2 {
    margin: 0;
  }
  ${smallScreenLaptop} {
    width: 100%;
    padding: 0;
  }
`;
export const CheckoutContainer = styled.div`
  display: flex;
  ${smallScreenLaptop} {
    flex-direction: column;
  }
`;
export const PaymentOptionContainer = styled.div`
  box-shadow: 0px 4px 12px 0px #172e4e0f;
  background: #fff;
  max-width: 350px;
  ${smallScreenLaptop} {
    max-width: 100%;
    width: 100%;
  }
  h2 {
    padding: 15px;
    border-bottom: 1px solid #e4ebf4;
  }
`;
export const DeliveryOptionContainer = styled.div`
  padding-bottom: 15px;
  box-shadow: 0px 4px 12px 0px #172e4e0f;
  margin: 17px 0;
`;
export const DeliveryNav = styled.div`
  display: flex;
  border-bottom: 1px solid #e4ebf4;
  padding: 0 15px;
  p {
    margin-left: 15px;
  }
`;
export const DeliveryCard = styled.div`
  margin: 15px;
  border-radius: 10px;
  background: ${(props: DeliveryCardProps) => (props.selected ? '#E8F6EE' : '#fff')};
  border: 1px solid ${(props: DeliveryCardProps) => (props.selected ? '#2AD774' : '#8196B3')};
  cursor: pointer;
`;
export const DeliveryCardTop = styled.div`
  display: flex;
  border-bottom: 1px solid #e4ebf4;
  margin-bottom: 5px;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  p {
    margin: 0;
  }
  #change-address-btn {
    cursor: pointer;
    background: #ffa412;
    color: #fff;
    border: none;
    min-width: 120px;
    padding: 10px;
    border-radius: 5px;
    font-weight: 500;
    :hover {
      opacity: 0.8;
    }
    @media screen and (max-width: 500px) {
      margin: 3% 0;
      width: 100%;
    }
  }
  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: start;
  }
`;
export const Radio = styled.button`
  border: 1px solid ${(props: DeliveryCardProps) => (props.selected ? '#FFA412' : '#8196B3')};
  display: flex;
  background: transparent;
  margin-right: 15px;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  align-items: center;
  justify-content: center;
  span {
    display: flex;
    height: 12px;
    width: 12px;
    min-width: 12px;
    min-height: 12px;
    border-radius: 50%;
    background: ${(props: DeliveryCardProps) => (props.selected ? '#FFA412' : 'transparent')};
  }
  .label {
    color: #ffa412;
    font-weight: 400;
    font-size: 20px;
  }

  cursor: pointer;
`;
export const RadioPayment = styled.button`
  display: flex;
  border: none;
  background: transparent;
  width: 100%;
  p {
    font-size: 16px;
  }
  :hover {
    color: ${primaryColor};
  }
  justify-content: space-between;
  span {
    display: flex;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    border: 1px solid ${(props: DeliveryCardProps) => (props.selected ? '#FFA412' : '#D9D9D9')};
  }
  .inner-span {
    background: ${(props: DeliveryCardProps) => (props.selected ? '#FFA412' : '#E4EBF4')};
    height: 10px;
    width: 10px;
    margin: auto;
    border-radius: 50%;
  }
  cursor: pointer;
`;
export const DeliveryTitle = styled.p`
  font-size: 15px;
  font-weight: 500;
  span {
    font-size: 12px;
    font-weight: 400;
  }
`;
export const DeliveryCardBottom = styled.div`
  display: flex;
  padding-bottom: 10px;
`;
export const IconContainer = styled.div`
  width: 48px;
  display: flex;
  justify-content: center;
  img {
    width: 20px;
    height: 24px;
    margin-top: 10px;
  }
`;
export const CardDetails = styled.div`
  width: 100%;
  h3,
  p {
    margin: 0;
    font-size: 13px;
  }
  h3 {
    color: #607087;
  }
  p {
    color: #8196b3;
  }
`;
export const SelectPaymentContainer = styled.div`
  border-bottom: 1px solid #e4ebf4;
  position: relative;
`;
export const PaymentOptionCard = styled.div`
  border: 1px solid ${(props: DeliveryCardProps) => (props.selected ? '#2AD774' : '#D9D9D9')};
  background: ${(props: DeliveryCardProps) => (props.selected ? '#E8F6EE' : '#fff')};
  display: flex;
  margin: 15px;
  padding: 10px;
  justify-content: space-between;
  p {
    margin: 0;
  }
`;
export const PaymentSummary = styled.div`
  padding: 15px;
  margin-top: 1em;
`;

interface ButtonProps {
  disable?: boolean;
}
export const ContinueButton = styled.button<ButtonProps>`
  padding: 15px 20px;
  border-radius: 5px;
  background: #ffa412;
  color: white;
  width: 100%;
  border: none;
  font-size: 16px;
  opacity: ${(disable) => (disable ? '0.5' : '1')};
  cursor: ${(disable) => (disable ? 'not-allowed' : 'pointer')};
`;
export const SmallText = styled.p`
  font-size: 12px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  color: #8196b3;
  img {
    margin-right: 15px;
    height: 12px;
  }
`;

export const DeliveryWarningContainer = styled(Flex)`
  text-align: center;
  margin: 1.5em 2.5em;
  img {
    margin-bottom: 2em;
  }

  .hover-btn {
    :hover {
      opacity: 0.7;
    }
  }

  .hover-txt {
    :hover {
      opacity: 0.6;
    }
  }
`;

export const ErrorModal = styled.div`
  height: 100%;
  position: absolute;
  background: #423f3fe5;
  padding: 0.5em;
  text-align: center;
  overflow: auto;
  img {
    width: 50px;
  }
`;
