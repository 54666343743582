import React, { FunctionComponent } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { Required } from '../../utils/formValidations.utils';
import { isloading, hasfailed, resetFetch } from '../../store/actions/fetchAction';
import { AppState } from '../../store/reducers/index';
import {
  FormContents,
  Title,
  ModalDescription,
  TextFields,
  Input,
  Button,
  ErrorMessage,
  FormModalContainer
} from '../../GlobalStyles/forms.style';
import { withRouter } from 'react-router-dom';
import { Flex, Text } from '../../GlobalStyles/CustomizableGlobal.style';
import { Colors, FontSizes } from '../../constants/theme';
import { Label } from '../account/account.style';
import { useQuery } from '../../utils/customRoute.utils';

const mapStateToProps = (state: AppState) => {
  return { status: state.fetch.status, activeShopId: state.activeShop.activeShopId };
};

export const UPDATE_PASSWORD_MEMBER = gql`
  mutation setNewMemberPassword($newPassword: String!, $passwordResetCode: String!) {
    setNewMemberPassword(newPassword: $newPassword, passwordResetCode: $passwordResetCode) {
      success
    }
  }
`;

interface Props {
  isloading: Function;
  hasfailed: Function;
  resetFetch: Function;
  activeShopId?: string;
}

const UpdatePassword: FunctionComponent<Props> = ({ isloading, hasfailed, resetFetch, activeShopId }) => {
  const { body } = Colors;
  const { bigFont } = FontSizes;

  const history = useHistory();
  const dispatch = useDispatch();
  let code = useQuery().get('code');
  const { register, handleSubmit, errors } = useForm();

  const [updatePassword] = useMutation<any>(UPDATE_PASSWORD_MEMBER, {
    onCompleted({ updatePassword }) {
      resetFetch();
      history.push('/login');
    }
  });

  const onSubmit = async (data: any) => {
    isloading();
    try {
      await updatePassword({ variables: { newPassword: `${data.newPassword}`, passwordResetCode: code } });
    } catch (e) {
      dispatch(hasfailed(e instanceof Error ? `${e}` : 'An unknown error has occured'));
    }
  };

  return (
    <FormModalContainer>
      <FormContents>
        <Flex direction="column" margin="1rem 0 2rem 0" alignItems="start">
          <Text color={body} fontSize={bigFont} fontWeight="700" margin="0.5rem 0">
            Reset Password
          </Text>
          <ModalDescription>Enter your registered email address to get a password reset link</ModalDescription>
        </Flex>
        <TextFields onSubmit={handleSubmit(onSubmit)}>
          <Label margin="0" fontSize="16px">
            New Password
          </Label>
          <Input
            name="newPassword"
            type="password"
            ref={register(Required)}
            placeholder="New Password"
            style={{ borderColor: errors.currentPassword && '#F75151' }}
          />
          {errors.newPassword && <ErrorMessage>This field is required</ErrorMessage>}

          <Label margin="1rem 0 0 0" fontSize="16px">
            Confirm Password
          </Label>
          <Input
            name="passwordResetCode"
            type="password"
            ref={register(Required)}
            placeholder="Confirm Password"
            style={{ borderColor: errors.currentPassword && '#F75151' }}
          />
          {errors.passwordResetCode && <ErrorMessage>This field is required</ErrorMessage>}
          <Button type="submit" margin="2rem 0 0 0">
            Reset Password
          </Button>
        </TextFields>
      </FormContents>
    </FormModalContainer>
  );
};

export default withRouter(connect(mapStateToProps, { isloading, hasfailed, resetFetch })(UpdatePassword));
