import styled from 'styled-components';
import { Colors } from '../../../constants/theme';

const { primaryColor } = Colors;

export const FooterCont = styled.footer`
  position: relative;
  width: 100%;
  background: #f4f7fa;
  color: #181817;
  padding: 35px 0;

  .socials-link {
    &:hover {
      path {
        fill: ${primaryColor};
      }
    }
  }
`;
export const FooterSec = styled.div`
  margin: 0 auto;
  width: calc(100% - 4rem);
  max-width: 1600px;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;
export const Info = styled.div`
  width: 320px;
  max-width: 320px;
  p {
    font-size: 13px;
    width: 100%;
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
    max-width: 100% !important;
  }
`;

interface SectionProps {
  width?: string;
}
export const Section = styled.div<SectionProps>`
  width: 100%;
  h3 {
    margin: 0;
    padding-bottom: 15px;
    font-size: 16px;
  }
  @media screen and (max-width: 1000px) {
    width: 100% !important;
    padding: 15px 0;
    max-width: 100% !important;
    margin-top: 15px;
  }
  @media screen and (max-width: 480px) {
    width: 100% !important;
    padding: 0;
    max-width: 100% !important;
  }
`;
export const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  .sublist {
    display: flex;
    column-gap: 1.5rem;
  }
`;
export const CategoryContainer = styled.div`
  display: flex;
  column-gap: 10px;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;
export const Item = styled.li`
  display: flex;
  margin: 3px 0;
  align-items: center;
  position: relative;
  a {
    text-decoration: none;
    color: #181817;
    font-size: 13px;
    cursor: pointer;
    :hover {
      transform: scale(1.02);
      color: #ffa412;
    }
  }
  span {
    color: #181817;
    font-size: 13px;
  }
`;
export const Contact = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CItem = styled.div`
  display: flex;
  margin-bottom: 6px;
  div {
    min-width: 18px;
  }
  p {
    margin: 0;
    font-size: 13px;
    padding-left: 10px;
  }
  a {
    text-decoration: none;
    color: #181817;
    font-size: 13px;
    cursor: pointer;
    padding-left: 10px;
  }
`;
