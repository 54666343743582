import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { ShopCardContainer, ShopName, ShopNameContainer, CardContent } from './ShopCard.style';
import { Shop } from '../../interfaces/shop.interface';
import { formatImageUrl } from '../../utils/format.utils';
import { Text } from '../../GlobalStyles/CustomizableGlobal.style';
import { Colors, FontSizes } from '../../constants/theme';
import formatDistance from '../../utils/distanceFromat.utils';
import DefaultShopImage from '../../img/sampleShopImg.jpg';
import Image from '../image';
import markIcon from '../../img/marker.svg';

interface Props extends Shop {
  location?: string;
  peek?: boolean;
  onClick?: React.MouseEventHandler;
  image?: string;
  name?: string;
  shopTag: string;
}

const defaultProps = {
  peek: false
};

const ShopCard: FunctionComponent<Props> = ({ shopId, peek, name, image, distance, onClick, shopTag }) => {
  const { primaryColor } = Colors;
  const { descriptionFontSize } = FontSizes;

  return (
    <Link to={`/${shopTag}`} className="text-none">
      <ShopCardContainer peek={peek} onClick={onClick} tabIndex={0}>
        <Image src={image ? formatImageUrl(image, 'shop') : DefaultShopImage} alt="shops" />
        <CardContent>
          <ShopNameContainer data-title={name}>
            <ShopName>{name}</ShopName>
          </ShopNameContainer>
          {distance ? (
            <Text
              color={primaryColor}
              fontSize={descriptionFontSize}
              margin={'0.5em'}
              style={{ display: 'flex', alignItems: 'center', fontStyle: 'italic' }}
            >
              <img src={markIcon} alt="mark" style={{ marginRight: '5px' }}></img>
              {formatDistance(distance)} Away
            </Text>
          ) : null}
        </CardContent>
      </ShopCardContainer>
    </Link>
  );
};

ShopCard.defaultProps = defaultProps;

export default ShopCard;
