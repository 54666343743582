import { GET_DATA_FAILURE, GET_DATA_PENDING, DATA_SUCCESS, RESET_FETCH } from "../actionTypes";
import fetchInitialState from "../initialStates/fetchInitialState";
import { FetchState } from "../../interfaces/state.interface";
import { ActionProps } from "../../interfaces/actions.interface";

export default function fetchFunctions(state = fetchInitialState, action: ActionProps): FetchState {
  switch (action.type) {
    case GET_DATA_PENDING:
      return {
        ...state,
        status: action.payload,
      };

    case GET_DATA_FAILURE:
      return {
        ...state,
        status: "error",
        message: action.payload,
        error: true,
      };

    case DATA_SUCCESS:
      return {
        ...state,
        status: "success",
        successMsg: action.payload,
        error: false,
      };

    case RESET_FETCH:
      return { ...state, status: "", message: action.payload, error: false };

    default:
      return state;
  }
}
