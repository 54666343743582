import styled from 'styled-components';
import { Circle } from '../ProductCard/ProductCard.style';
import { FontSizes, Colors, media } from '../../constants/theme';

const { detailsFontSize } = FontSizes;
const { primaryGrey, white } = Colors;
const { mobile } = media;

interface CounterProps {
  dotSize?: string;
  onCart?: boolean;
  onCheckoutPage?: boolean;
}

export const CounterCircle = styled(Circle)<CounterProps>`
  width: ${({ dotSize }) => dotSize || '37px'};
  height: ${({ dotSize }) => dotSize || '37px'};
  font-size: ${detailsFontSize};
  text-align: center;
  border-radius: 5px;
  color: ${white};
  cursor: pointer;

  ${({ onCart }) =>
    onCart &&
    `
    width: 28px;
    height: 28px;
    font-size: 10px;

    ${mobile} {
      width: 20px;
      height: 20px;
    }
  `}

  ${({ onCheckoutPage }) =>
    onCheckoutPage &&
    `
    display: none;
  `}
`;

export const CounterContainer = styled.div<CounterProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  ${({ onCart }) =>
    onCart &&
    `
    margin: 0;
    width: unset;
  `}
`;

export const CounterText = styled.p`
  font-size: 18px;
  color: ${primaryGrey};
  margin: 0 20px;
  font-weight: 700;
  width: 4ch;
  text-align: center;

  ${mobile} {
    margin: 0 10px;
  }
`;
