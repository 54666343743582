import React from 'react';
import styled from 'styled-components';
import Header from '../../components/header/header';
import { Colors, FontSizes, media } from '../../constants/theme';
import { Text } from '../../GlobalStyles/CustomizableGlobal.style';
import { BgMainContainer, PageContainer } from '../../GlobalStyles/FixedGlobal.style';
import HeaderImage from '../../img/privacy-policy-header.png';
import BottomNav from '../../components/footer/bottomNav';

const { primaryColor, white, body } = Colors;
const { primaryFontSize } = FontSizes;
const { smallScreenLaptop, tablet, mobile } = media;

const Container = styled.div`
  width: 60%;
  border-radius: 10px;
  background: ${white};
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 4px 4px 30px rgba(35, 54, 79, 0.1);
  line-height: 2rem;

  li {
    color: ${primaryColor};
  }

  ${smallScreenLaptop} {
    width: 100%;
    margin-top: 3rem;
  }

  ${tablet} {
    width: 100%;
    margin-top: 3rem;
  }

  ${mobile} {
    width: 100%;
    margin-top: 3rem;
  }
`;

const PrivacyPolicy = () => {
  return (
    <BgMainContainer>
      <Header />
      <Container>
        <img src={HeaderImage} />
        <div style={{ padding: '2rem' }}>
          <Text fontWeight="700" fontSize={'2rem'} color={body} textAlign="center">
            Privacy Policy for Timart
          </Text>
          <p>
            At Timart, we take your privacy seriously and are committed to protecting your personal information. This policy outlines what
            information we collect, how we use it, and how we keep it safe.
          </p>
          <ul>
            <li>Information we collect</li>
            <p>
              We collect the following types of information: Personal information such as your name, email address, shipping address, and
              phone number. Payment information such as your credit card details, and Communication between you and our customer support
              team.
            </p>
            <li>How we use your information</li>
            <p>
              We use your information to process your orders and payments, provide you with customer support, personalize your shopping
              experience, improve our website and services, communicate with you about promotions and offers, and comply with legal
              requirements.
            </p>
            <li>How we protect your information</li>
            <p>
              We take appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure,
              or destruction. We use secure servers, SSL encryption, and only share your information with trusted third-party service
              providers who also comply with data protection regulations.
            </p>
            <li>Sharing your information</li>
            <p>
              We may share your information with: Third-party service providers who assist us in providing our services such as payment
              processing, shipping, and marketing. Law enforcement or regulatory authorities if required by law or if we believe that it is
              necessary to prevent fraud or protect our legal rights.
            </p>
            <li>Your rights</li>
            <p>
              You have the right to access, correct, or delete your personal information. You also have the right to object to the
              processing of your personal information or to withdraw your consent. Please contact us using the information below to exercise
              these rights.
            </p>
            <li>Changes to this policy</li>
            <p>
              We may update this policy from time to time, and we will notify you of any changes by posting the new policy on our website.
            </p>
          </ul>
        </div>
        <div className="background-container">
          <div className="left-content"></div>
          <div className="right-content"></div>
        </div>
      </Container>
      <BottomNav />
    </BgMainContainer>
  );
};

export default PrivacyPolicy;
