import { GET_DATA_PENDING, GET_DATA_FAILURE, DATA_SUCCESS, RESET_FETCH } from "../actionTypes";
import { ActionProps } from "../../interfaces/actions.interface";

export const isloading = (): ActionProps => {
  return {
    type: GET_DATA_PENDING,
    payload: "loading",
  };
};

export const hasfailed = (message: string): ActionProps => {
  return {
    type: GET_DATA_FAILURE,
    payload: message,
  };
};

export const hasSucceed = (successMsg: string): ActionProps => {
  return {
    type: DATA_SUCCESS,
    payload: successMsg,
  };
};

export const resetFetch = (): ActionProps => {
  return {
    type: RESET_FETCH,
    payload: "",
  };
};
