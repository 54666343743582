import styled from "styled-components";
import Select from "react-select";
import { media, Colors, FontSizes } from "../../constants/theme";

const { mobile, tablet, smallScreenLaptop } = media;
const { white, primaryColor, black } = Colors;
const { bigFont, primaryFontSize, secondaryFontSize } = FontSizes;

export const FilterCardContainer = styled.div`
  max-width: 90vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  ${smallScreenLaptop} {
    justify-content: center;
  }

  ${tablet} {
    flex-direction: column;
    height: 180px;
    padding: 20px 0;
  }

  ${mobile} {
    flex-direction: column;
    height: 3rem;
    padding: 20px 0;
  }
`;

export const FilterSelectContainer = styled.div`
  display: flex;
  padding-left: 50px;

  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: ${bigFont};
    line-height: 25px;
    color: ${black};
  }

  .filterTitle {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .selectionRow {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${smallScreenLaptop} {
    padding-left: 0px;

    h2 {
      font-size: 18px;
    }
  }

  ${tablet} {
    padding-left: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .filterTitle {
      display: inline;
      text-align: center;
    }

    .selectionRow {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h2 {
      font-size: ${secondaryFontSize};
    }
  }

  ${mobile} {
    padding-left: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .filterTitle {
      display: inline;
      text-align: left;
    }

    .selectionRow {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h2 {
      font-size: ${primaryFontSize};
    }
  }
`;

export const SelectContainer = styled(Select)`
  width: 200px;
  padding: 12px 10px;
  margin: 0 10px;
  background: ${white};
  outline: none;
  height: 65px;

  .react-select__control,
  .react-select__control--is-focused {
    border: 1px solid #f75151 !important;
    box-sizing: border-box;
    border-radius: 30px;
    box-shadow: none;
  }

  .react-select__placeholder {
    color: ${black};
  }

  ${mobile} {
    width: 40vw;
    padding: 8px 6px;
    margin: 0 10px;
    background: ${white};
    outline: none;
    height: 65px;

    .react-select__control,
    .react-select__control--is-focused {
      box-sizing: border-box;
      border-radius: 30px;
    }
  }
`;

export const DynamicSearchBar = styled.div`
  width: 400px;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  transition: all ease-in-out 0.3s;

  .input-container {
    display: flex;
    position: relative;
    width: 100%;
  }
  .input-field {
    display: flex;
    background: #e4ebf4;
    outline: none;
    border-radius: 4px 0px 0px 4px;
    border: none;
    padding: 0 70px 0 20px;
    font-size: 18px;
    width: 100%;
    height: 40px;
  }
  ${smallScreenLaptop} {
    height: 30px;
    width: 100%;

    .input-field {
      width: 100%;
      height: 30px;
      font-size: 16px;
      padding: 0 53px 0 10px;
    }
    .input-container {
      display: flex;
      position: relative;
      width: 100%;
    }
  }
  ${tablet} {
    height: 30px;
    width: 100%;

    .input-field {
      width: 100%;
      height: 30px;
      font-size: 16px;
      padding: 0 33px 0 10px;
    }
    .input-container {
      display: flex;
      position: relative;
      width: 100%;
    }
  }
  ${mobile} {
    width: 100%;
    height: 30px;
    margin-top: 1rem;

    .input-field {
      display: flex;
      background: #e4ebf4;
      outline: none;
      border-radius: 4px 0px 0px 4px;
      border: none;
      padding: 0 55px 0 10px;
      font-size: 16px;
      width: 100%;
      height: 30px;
    }
    .input-container {
      display: flex;
      position: relative;
      width: 100%;
    }
  }
`;

export const SearchButton = styled.button`
  display: flex;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 70px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: ${primaryColor};
  border-radius: 4px;
  cursor: pointer;

  img {
    width: 1.2rem;
    height: 100%;
  }
  ${smallScreenLaptop} {
    height: 30px;
    width: 50px;

    img {
      width: 1rem;
    }
  }
  ${tablet} {
    width: 40px;
    height: 100%;

    img {
      width: 15px;
    }
  }
  ${mobile} {
    width: 50px;
    height: 100%;

    img {
      width: 15px;
    }
  }
`;
