import React, { FunctionComponent, useState } from 'react';
import { locationPermissionRequest, permissionType } from '../../utils/queryLocation.utils';
import LocationModal from '../LocationModal';

const WrapWithLocation: FunctionComponent = ({ children }) => {
  const locationPermission = locationPermissionRequest.getPermissionStatus();
  const [permission, setPermission] = useState<string>(locationPermission || 'prompt');

  const updateLocationPermisssion = (status: permissionType) => {
    locationPermissionRequest.setPermissionStatus(status);
    setPermission(status);
  };

  return (
    <div style={{ position: 'relative' }}>
      {children}
      {permission === 'prompt' && <LocationModal setPermission={updateLocationPermisssion} />}
    </div>
  );
};

export default WrapWithLocation;
