import styled from 'styled-components';
import { media } from '../../constants/theme';
import Backgroundimage from '../../img/auth-background-img.jpg';

const { smallScreenLaptop } = media;

export const AuthWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: cover;
  background: #fafafa;
  height: fit-content;
  min-height: 100vh;
  background-attachment: fixed;
`;

export const AuthPageContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0;
  margin: auto;
  width: 50%;

  .auth-section {
    margin-top: 5em;
    width: 90%;
  }

  ${smallScreenLaptop} {
    flex-flow: column-reverse;
  }
`;

export const AuthPageInfo = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: 0 2rem;
  text-align: center;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  #app-download {
    margin: 1em 0;
  }
`;
