import { USER_CHECKOUT_INFO, PAYMENT_METHOD, userDetails } from "../actionTypes";
import { UserInfoInitialState, IuserInfo } from "../initialStates/userCheckoutInfo";

const UserDataFunctions = (state: IuserInfo = UserInfoInitialState, action: userDetails) => {
  switch (action.type) {
    case USER_CHECKOUT_INFO: {
      return {
        ...state,
        userData: action.userData,
      };
    }

    case PAYMENT_METHOD: {
      return {
        ...state,
        method: action.method,
      };
    }

    default:
      return state;
  }
};

export default UserDataFunctions;
