import styled from 'styled-components';
import Select from 'react-select';
import { media, Colors, FontSizes } from '../../constants/theme';

const { mobile, tablet, smallScreenLaptop } = media;
const { white, primaryColor, black } = Colors;
const { bigFont, primaryFontSize, secondaryFontSize } = FontSizes;

export const Container = styled.div`
  margin: 0 auto;
  width: calc(100% - 4rem);
  padding: 0;
  max-width: 1600px;
  h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 32px;
    font-weight: 500;
  }

  ${mobile} {
    h2 {
      font-size: 16px;
    }
  }
`;
export const Info = styled.div`
  width: 100%;
  color: white;
  background: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 230px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  padding: 15px 25px;
  border-radius: 12px;
  ::before {
    content: '';
    display: block;
    position: absolute;
    height: 400px;
    width: 400px;
    top: -60px;
    right: -110px;
    background: ${primaryColor};
    border-radius: 50%;
  }
  a {
    z-index: 2;
    background: #083e88;
    color: #fff;
    padding: 14px 25px;
    border-radius: 10px;
  }
`;
export const Left = styled.div`
  width: 70%;
  h1 {
    width: 50%;
    min-width: 380px;
    max-width: 450px;
    font-family: 'Poppins', sans-serif;
    span {
      color: ${primaryColor};
    }
  }
  p {
    width: 40%;
    min-width: 300px;
    max-width: 320px;
    font-family: 'Poppins', sans-serif;
    margin-top: 10px;
    span {
      color: ${primaryColor};
    }
  }
`;

export const CategoryContainer = styled.div`
  width: 100%;
  display: flex;
  column-gap: 10px;
  overflow-x: scroll;
  justify-content: space-evenly;
`;

export const CategoryCard = styled.div`
  height: 190px;
  max-height: 190px;
  width: calc(25% - 5px);
  max-width: 350px;
  min-width: 200px;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  img:first-of-type {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: 0;
  }
  img:last-of-type {
    position: absolute;
    height: 140%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
  div {
    width: 100%;
    padding: 0 10px;
    position: absolute;
    bottom: 5px;
    left: 0;
    box-sizing: border-box;
    z-index: 2;
    font-family: 'Montserrat', sans-serif;
    p {
      font-size: 18px;
      font-weight: 500;
      color: #fff;
      padding: 0;
      margin: 0;
    }
    span {
      font-size: 12px;
      color: #fff;
    }
  }
`;
