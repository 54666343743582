import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import OrderSummary from '../../components/OrderSummary';
import { CartContainer } from './cart.style';
import { nextCheckoutOption } from '../../store/actions/checkoutActions';
import { AppState } from '../../store/reducers/index';
import Header from '../../components/header/header';
import { Flex } from '../../GlobalStyles/CustomizableGlobal.style';
import { Helmet } from 'react-helmet-async';
import { Title } from '../../constants/meta.constant';

const mapStateToProps = (state: AppState) => {
  return {
    checkoutMode: state.checkout.checkoutMode,
    cartItems: state.CartCounter.cartItems
  };
};

interface Props {
  nextCheckoutOption: Function;
  cartItems: any[];
}

const Cart: FunctionComponent<Props> = () => {
  return (
    <div>
      <Helmet>
        <title>{`${Title} | Cart`}</title>
        <meta name="description" content="View Items in your Timart ecommerce cart" />
      </Helmet>
      <Header />
      <CartContainer>
        <Flex justifyContent="unset" id="cart-contents">
          <OrderSummary />
        </Flex>
      </CartContainer>
    </div>
  );
};

export default connect(mapStateToProps, { nextCheckoutOption })(Cart);
