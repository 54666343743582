import React from 'react';
import styled from 'styled-components';
import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';
import { media } from '../../constants/theme';
import chevronDown from '../../img/chevron-down.svg';

const { mobile } = media;

const ItemWithChevron = ({ header, ...rest }: any) => (
  <Item
    {...rest}
    header={
      <>
        {header}
        <img className="chevron-down" src={chevronDown} alt="Chevron Down" />
      </>
    }
  />
);

export const AccordionItem = styled(ItemWithChevron)`
  border-bottom: 1px solid #ccc;
  display: none;
  .szh-accordion__item {
    &-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0;
      padding: 1rem;
      font-size: 1rem;
      font-weight: 400;
      text-align: left;
      color: #4f4f4f;
      background-color: transparent;
      border: none;
      &:hover {
        background-color: #f3f3f3;
      }
    }
    
    &-content {
      font-size: .8rem;
      transition: height 0.2s ease-in-out;
    }

    &-panel {
      padding: 1rem;
    }
  }

  .chevron-down {
    margin-left: auto;
    transition: transform 0.2s ease-in-out;
  }

  &.szh-accordion__item--expanded {
    .szh-accordion__item-btn {
      background-color: #e7e7e7;
    }
    .chevron-down {
      transform: rotate(180deg);
    }
  }

  & p {
    color: #607087;
  }

  ${mobile} {
   display: block;
`;

export const AccordionContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  border-radius: 5px;
`;
