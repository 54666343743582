import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import BottomNav from '../../components/footer/bottomNav';
import Header from '../../components/header/header';
import thankYou from '../../img/thanyou.png';
import { ThankYouPageContainer, ContentContainer, Heading, Image, Description } from './ContactUs.style';

const ThankYou: FunctionComponent = () => {
  return (
    <>
      <Header />
      <ThankYouPageContainer>
        <ContentContainer>
          <Image src={thankYou} alt="thank you page image" />
          <Heading>Thank you for contacting us</Heading>
          <Description>We will get back to you soon!</Description>
        </ContentContainer>
      </ThankYouPageContainer>
      <BottomNav />
    </>
  );
};
export default connect(null)(ThankYou);
