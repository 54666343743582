import styled from "styled-components";
import { media } from "../../constants/theme";

const { smallScreenLaptop, mobile, tablet } = media;

export const CartContainer = styled.div`
  margin: 30px;
  height: calc(100vh - 50px);
  display: flex;
  flex-flow: column;
  padding-bottom: 2em;

  ${tablet} {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #cart-contents {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  ${smallScreenLaptop} {
    #cart-contents {
      flex-direction: column;
      text-align: center;
    }
  }

  ${mobile} {
    #cart-contents {
      margin-top: unset;
    }
    justify-content: flex-start;
    margin: 30px 20px;
  }
`;

export const MainCartContents = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 100%;
`;
