import React, { FunctionComponent, useEffect, useState } from 'react';
import { ModalState } from '../../interfaces/state.interface';
import SearchList from './SearchList/SearchList';
import BottomNav from '../../components/footer/bottomNav';
import WrapWithLocation from '../../components/WrapWithLocation';
import { Helmet } from 'react-helmet-async';
import { Title } from '../../constants/meta.constant';
import { useLocation } from 'react-router-dom';
import Header from '../../components/header/header';
import Sidebar from '../../components/sidebar';
import { Flex } from '../../GlobalStyles/CustomizableGlobal.style';
import { SearchQueryContainer } from './SearchQuery.style';
import { useQuery } from '@apollo/client';
import { GET_SHOP_CATEGORIES_TYPE } from '../../interfaces/ShopCategories.interface';
import { GET_SHOP_CATEGORIES_SCHEMA } from '../../schema/ShopCategories.schema';
import { SEARCH_INVENTORY } from '../../schema/inventory.schema';
import { limit } from '../../constants/limit.constant';
import getGeoLocationPosition from '../../utils/queryLocation.utils';
import { SEARCH_INVENTORY_TYPE } from '../../interfaces/inventory.interface';
import { getItem } from '../../utils/localStorage.utils';
import { useUserLocation } from '../../hooks/locationContext';

const SearchQuery: FunctionComponent<ModalState> = ({ modalOpen }) => {
  const position = getGeoLocationPosition();
  const useQueryParams = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQueryParams();
  const page = query.get('p') || '';

  const encodedQuery = query.get('q');
  let searchQuery = '';
  if (encodedQuery) searchQuery = decodeURIComponent(encodedQuery);

  const encodedCategory = query.get('category');
  let category = '';
  if (encodedCategory) category = decodeURIComponent(encodedCategory);

  const { currentCity, currentState } = useUserLocation() || {};

  const categoryData = useQuery<GET_SHOP_CATEGORIES_TYPE>(GET_SHOP_CATEGORIES_SCHEMA);
  const { error, data, loading } = useQuery<SEARCH_INVENTORY_TYPE>(SEARCH_INVENTORY, {
    variables: {
      category,
      searchString: searchQuery,
      page: Number(page) || 1,
      latitude: position.latitude,
      longitude: position.longitude,
      limit,
      searchType: 'INVENTORY',
      stateId: currentState?.value,
      cityId: currentCity?.value
    }
  });

  return (
    <>
      <Helmet>
        <title>{`${Title} | Search - ${query}`}</title>
        <meta name="description" content="Search favorite Products and Shops" />
      </Helmet>
      <WrapWithLocation>
        <SearchQueryContainer bodyScroll={modalOpen}>
          <Header />
          <Flex className="search-results" alignItems="unset" justifyContent="center" minHeight="70vh">
            <Sidebar categoryData={categoryData} />
            <SearchList
              searchCategory={category}
              inventorySearchResult={data?.searchInventoryOrShop.inventories}
              page={page}
              error={error}
              loading={loading}
              searchItem={{ search: searchQuery, cat: category, selectedCity: currentCity?.label, selectedState: currentState?.label }}
              totalItems={data?.searchInventoryOrShop.totalInventoriesAndShops}
            />
          </Flex>
          <BottomNav />
        </SearchQueryContainer>
      </WrapWithLocation>
    </>
  );
};

export default SearchQuery;
