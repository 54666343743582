import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  CategoryBtn,
  Container,
  ContOne,
  Dot,
  DotContainer,
  Right,
  Sidebar,
  Slide,
  SlideItem,
  BannerLink,
  BannerDescription,
  BannerTitle,
  AnchorBannerLink
} from './HeroBanner.style';
import { redirectType } from '../../../store/initialStates/redirectToSearchResult';
import { AppState } from '../../../store/reducers/index';
import redirectSearchResult from '../../../store/actions/redirectToResult';
import { ShapeIcon } from './svgIcons';
import { useQuery } from '@apollo/client';
import { GET_SHOP_CATEGORIES_TYPE } from '../../../interfaces/ShopCategories.interface';
import { GET_SHOP_CATEGORIES_SCHEMA } from '../../../schema/ShopCategories.schema';
import { sidebarIcons } from '../../sidebar/sidebar';
import Loader from '../../LoaderComponent';
import Error from '../../ErrorComponent';
import { Span } from '../../../GlobalStyles/CustomizableGlobal.style';
import { Colors } from '../../../constants/theme';
import BargainBanner from '../../../img/bargain-banner.png';
import SellBanner from '../../../img/sell-banner.png';
import RediscoverBanner from '../../../img/rediscover-banner.png';

const mapStateToProps = (state: AppState) => {
  return { fireRedirect: state.redirect.fireRedirect };
};

interface IHeroBanner extends redirectType {
  redirectSearchResult: (value: boolean) => void;
}

const { white, primaryColor, black, grey, navyBlue } = Colors;

const HeroBanner: FunctionComponent<IHeroBanner> = ({ fireRedirect, redirectSearchResult }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const slideContainer = useRef<HTMLDivElement>(null);
  const [slideArr, setSlideArr] = useState<number[]>([]);
  const [display, setDisplay] = useState(false);

  const handleMouseOut = () => {
    setDisplay(false);
  };

  const changeIndex = () => {
    const lastIndex = slideContainer.current?.children.length || 0;
    let currentIndex: number = 0;
    setSlideIndex((index) => {
      currentIndex = index;
      return index;
    });
    if (currentIndex == lastIndex - 1) {
      setSlideIndex(0);
    } else {
      setSlideIndex((index) => index + 1);
    }
  };
  const handleIndexChange = (item: number) => {
    setSlideIndex(item);
  };
  useEffect(() => {
    const slideNum = slideContainer.current?.children.length || 0;
    const arr = [];
    for (let i = 0; i < slideNum; i++) {
      arr.push(i);
    }
    setSlideArr(arr);
    const d = setInterval(changeIndex, 20000);
    return () => {
      clearInterval(d);
    };
  }, []);

  const { data, loading, error } = useQuery<GET_SHOP_CATEGORIES_TYPE>(GET_SHOP_CATEGORIES_SCHEMA);
  const categories = [
    {
      id: '',
      name: 'All Categories',
      icon: ShapeIcon
    },
    ...(data?.getShopCategories.map((cat, index) => {
      const mapped = {
        id: cat.shopCategoryId,
        name: cat.shopCategoryName.toLowerCase(),
        icon: sidebarIcons[index]
      };
      return mapped;
    }) || [])
  ];

  function sideBar() {
    if (loading) return <Loader />;
    if (error) return <Error message={error.message} />;
    return categories.map((item, i) => {
      const { name, icon: Icon } = item;
      return (
        <Link
          key={i}
          to={{
            pathname: '/searchQuery',
            search: `?category=${encodeURIComponent(name.toUpperCase())}&p=1`
          }}
        >
          <CategoryBtn>
            <div>
              <Icon />
            </div>
            <span>{name}</span>
          </CategoryBtn>
        </Link>
      );
    });
  }

  return (
    <Container>
      <Sidebar>{sideBar()}</Sidebar>
      <Right>
        <Slide ref={slideContainer}>
          <SlideItem position={0} slideIndex={slideIndex}>
            <SlideOne />
          </SlideItem>
          <SlideItem position={1} slideIndex={slideIndex}>
            <SlideTwo />
          </SlideItem>
          <SlideItem position={2} slideIndex={slideIndex}>
            <SlideThree />
          </SlideItem>
        </Slide>

        <DotContainer>
          {slideArr.map((item) => (
            <Dot key={item} position={item} slideIndex={slideIndex} onClick={() => handleIndexChange(item)}></Dot>
          ))}
        </DotContainer>
      </Right>
    </Container>
  );
};

const SlideOne = () => (
  <ContOne background={primaryColor}>
    <BannerTitle color={black}>BARGAIN</BannerTitle>
    <BannerDescription color={black}>
      Bargain prices on Timart Shop and settle for the <Span color={white}>Best</Span>.
    </BannerDescription>
    <BannerLink to="/merchant/1" background={white} color={primaryColor}>
      Shop Now
    </BannerLink>
    <img src={BargainBanner} alt="" />
  </ContOne>
);

const SlideTwo = () => (
  <ContOne background={grey}>
    <BannerTitle color={black} fontSize={'3.5rem'}>
      Sell your Products on Timart for <Span color={white}>FREE</Span>
    </BannerTitle>
    <AnchorBannerLink href="https://gettimart.com" background={primaryColor} color={white} target="_blank">
      Get Started
    </AnchorBannerLink>
    <img src={SellBanner} alt="" />
  </ContOne>
);

const SlideThree = () => (
  <ContOne background={navyBlue}>
    <BannerTitle color={white} fontSize={'3.5rem'}>
      Rediscover a Great <Span color={primaryColor}>Shopping</Span> Tradition
    </BannerTitle>
    <BannerLink to="/merchant/1" background={primaryColor} color={white}>
      Shop Now
    </BannerLink>
    <img src={RediscoverBanner} alt="" />
  </ContOne>
);

export default connect(mapStateToProps, { redirectSearchResult })(HeroBanner);
