import { SHOP_CARD_ID, selectModuleType } from "../actionTypes";
import { ShopCardInitialState, shopCardType } from "../initialStates/shopCardIdInitialState";

const ShopCardId = (state: shopCardType = ShopCardInitialState, action: selectModuleType) => {
  switch (action.type) {
    case SHOP_CARD_ID:
      return {
        ...state,
        currentShopCardId: action.payload,
      };

    default:
      return state;
  }
};

export default ShopCardId;
