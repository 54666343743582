import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { InventoryPricingType } from '../interfaces/inventory.interface';
import { addToCart, addToCartFromLocalStorage } from '../store/actions/cartCountAction';
import { toggleSnackbarOpen } from '../store/actions/snackbarActions';
import { AppState } from '../store/reducers';
import { removeAllModalRoutes } from './customRoute.utils';
import { getItem, setItem } from './localStorage.utils';

export const addDataToCartUtil = (
  cartItems: any,
  price: number,
  inventoryId: string,
  count: number,
  productName: string,
  fixedUnitPrice: number,
  productSupplier: string,
  imageUrl: string,
  inventoryTypeProp: string,
  variationId: string,
  variationName: string,
  dispatch: Dispatch<any>,
  inventoryPricing: InventoryPricingType,
  initialInventoryType?: string
) => {
  const existingCartItem = cartItems?.find((cartItem: any) => {
    let updated = cartItem.id === inventoryId;
    return updated;
  });
  if (existingCartItem) {
    existingCartItem.quantity = existingCartItem.quantity + count;
    existingCartItem.price = existingCartItem.price * count;
    let index = cartItems.map((cartItem: any) => cartItem.id).indexOf(existingCartItem.id);
    cartItems[index] = existingCartItem;
    setItem('allCartItems', cartItems);
    if (getItem('allCartItems')) {
      let allCartItems = JSON.parse(getItem('allCartItems') || '[]');
      dispatch(addToCartFromLocalStorage(allCartItems));
    }
  } else {
    dispatch(
      addToCart(
        inventoryId,
        productName,
        price,
        count,
        fixedUnitPrice,
        productSupplier,
        imageUrl,
        inventoryTypeProp,
        variationId,
        variationName,
        inventoryPricing,
        initialInventoryType
      )
    );
  }
  removeAllModalRoutes();
  dispatch(toggleSnackbarOpen('Added to cart'));
};
