import styled from 'styled-components';
import { Colors, media } from '../../constants/theme';

const { white, overlayColor } = Colors;
const { mobile, tablet, smallScreenLaptop } = media;

interface PopupContainerProps {
  width?: string;
  unfit?: boolean;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  transparent?: boolean;
}

export const PopupContainer = styled.div<PopupContainerProps>`
  background-color: ${({ transparent }) => (transparent ? 'transparent' : white)};
  max-width: ${({ maxWidth }) => maxWidth ?? '422px'};
  max-height: ${({ maxHeight }) => maxHeight};
  border-radius: 8px;
  width: ${({ width }) => width ?? '100%'};
  margin: 0 auto;
  position: absolute;
  padding: 1em;
  height: ${({ height }) => height};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;

  ${smallScreenLaptop} {
    width: 80%;
  }
  ${tablet} {
    width: 80%;
  }

  ${mobile} {
    width: 80%;
  }
`;

export const PopupWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PopupOverlay = styled.div`
  width: 100%;
  height: 100%;
  background: ${overlayColor};
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  max-height: 80vh;

  .close {
    position: absolute;
    top: 2%;
    right: 2%;
    cursor: pointer;
  }
`;
