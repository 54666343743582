export interface IuserInfo {
  userData: {
    address: string;
    city: string;
    mobile: string;
    state: string;
    yourName: string;
  };
  method: {
    payment: string;
  };
}

export const UserInfoInitialState = {
  userData: {
    address: "",
    city: "",
    mobile: "",
    state: "",
    yourName: "",
  },
  method: {
    payment: "",
  },
};
