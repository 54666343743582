import React, { FunctionComponent } from 'react';
import Container from './style';
import TopNav from './topNav';
import CustomNav from './customNavbar/customNav';

interface Props {
  categoriesOpen?: boolean;
  setCategoriesOpen?: Function;
}

const Header: FunctionComponent<Props> = ({ categoriesOpen, setCategoriesOpen }) => {
  return (
    <Container>
      <TopNav />
      <CustomNav categoriesOpen={categoriesOpen} setCategoriesOpen={setCategoriesOpen} />
    </Container>
  );
};

export default Header;
