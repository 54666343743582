import history from './history.utils';

export const pushCustomRouteQuery = (pathname: string, queryKey: string, query: string, redirectTo?: string | null) => {
  const redirect = redirectTo ? `&redirect=${redirectTo}` : '';
  history.push({
    pathname: `/${pathname}`,
    search: `?${queryKey}=${query + redirect}`
  });
};

// TODO: THIS IS TEMPORARY. REMOVE LATER
export const BROAD_MIND_SHOP_NAME = 'Build and Groove:Gombe 2022';
export const BROAD_MIND_SHOP_TAG = 'build-and-groove-gombe-2022';
