import { gql } from '@apollo/client';

export const GET_STATES = gql`
  query getStates {
    getStates {
      stateId
      stateName
    }
  }
`;

export const GET_CITIES = gql`
  query getCities($stateId: Int!) {
    getCities(stateId: $stateId) {
      cityId
      cityName
    }
  }
`;
export const GET_STATES_WITH_INVENTORYCOUNT = gql`
  query GetStatesWithInventoryCount {
    getStatesWithInventoryCount {
      stateId
      stateName
      totalInventoryCount
    }
  }
`;
export const GET_CITIES_WITH_INVENTORYCOUNT = gql`
  query GetCitiesWithInventoryCount($stateId: Int!) {
    getCitiesWithInventoryCount(stateId: $stateId) {
      cityId
      cityName
      state
      stateId
      totalInventoryCount
    }
  }
`;

export const GET_DELIVERY_FEE = gql`
  query Query($cityId: Int!, $stateId: Int!, $inventoryId: String, $inventoryUrl: String) {
    getDeliveryFee(cityId: $cityId, stateId: $stateId, inventoryId: $inventoryId, inventoryUrl: $inventoryUrl)
  }
`;
