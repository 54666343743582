import React, { FunctionComponent, useEffect, useState } from 'react';
import { PopupContainer, PopupWrapper, PopupOverlay, Container } from './PopupCard.style';
import Close from '../.././img/close.svg';

interface Props {
  close?: Function;
  width?: string;
  height?: string;
  unfit?: boolean;
  maxWidth?: string;
  maxHeight?: string;
  transparent?: boolean;
}

const PopupCard: FunctionComponent<Props> = ({ children, close, width, unfit, height, maxWidth, maxHeight, transparent }) => {
  const [closeCard, setCloseCard] = useState<boolean>(true);
  const handleClose = () => {
    if (close) {
      setCloseCard(false);
      close();
    }
  };

  useEffect(() => {
    document.body.classList.add('modal-open');

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  return (
    <>
      {closeCard && (
        <PopupWrapper>
          <PopupOverlay onClick={() => handleClose()} />
          <PopupContainer transparent={transparent} maxHeight={maxHeight} maxWidth={maxWidth} height={height} unfit={unfit} width={width}>
            <Container>
              <img className="close" onClick={() => handleClose()} src={Close} alt="close" />
              {children}
            </Container>
          </PopupContainer>
        </PopupWrapper>
      )}
    </>
  );
};

export default PopupCard;
