import styled from 'styled-components';
import { Colors, media } from '../../constants/theme';
const { skyBlue } = Colors;
const { tablet, mobile, smallScreenLaptop, mediumScreenLaptop } = media;

interface DropdownProps {
  width?: string;
  height?: string;
  containerColor?: string;
  iconContainerColor?: string;
  fontSize?: string;
  borderRadius?: string;
  border?: string;
  color?: string;
  boxShadow?: string;
  margin?: string;
  padding?: string;
  overflowY?: string;
  optionsWidth?: string;
}
export const Container = styled.div`
  position: relative;
  font-size: ${(props: DropdownProps) => props.fontSize || '16px'};
  width: ${(props: DropdownProps) => props.width || '150px'};
  display: flex;
  height: ${(props: DropdownProps) => props.height || '35px'};
  box-shadow: ${(props: DropdownProps) => props.boxShadow || 'none'};
  margin: ${(props: DropdownProps) => props.margin || '0'};
  border: ${(props: DropdownProps) => (props.border ? props.border : 'none')};
  border-radius: ${(props: DropdownProps) => (props.border ? '12px' : 'none')};
  background: ${(props: DropdownProps) => props.containerColor ?? 'white'};

  span {
    position: absolute;
    left: 1rem;
    top: -10px;
    font-size: 12px;
    z-index: 20;
    background-color: white;
    color: #8596b3;
    padding: 0 5px;
  }

  .closeDiv {
    height: 100vh;
    width: 100vw;
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
`;
export const DropdownBtn = styled.button`
  background-color: ${(props: DropdownProps) => props.containerColor ?? 'white'} !important;
  border: none !important;
  font-size: ${(props: DropdownProps) => props.fontSize || '16px'};
  padding: ${(props: DropdownProps) => props.padding || '0'};
  width: 100%;
  display: flex;
  position: relative;
  cursor: pointer;
  align-items: center;
  overflow: hidden;
`;
export const IconContainer = styled.div`
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  img {
    width: 20px;
  }
`;
export const Icon = styled.div`
  width: 30px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  img {
    width: 20px;
  }
`;
export const Selected = styled.div`
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
  color: ${(props: DropdownProps) => props.color};
  padding-left: 5px;
`;
export const Options = styled.div<DropdownProps>`
  position: absolute;
  display: flex;
  right: -50px;
  width: ${(props: DropdownProps) => props.optionsWidth || 'auto'};
  flex-direction: column;
  top: ${(props: DropdownProps) => `calc(${props.height} + 2px)` || '37px'};
  border-radius: ${(props: DropdownProps) => props.borderRadius || '3px'};
  box-shadow: ${(props: DropdownProps) => props.boxShadow || 'none'};
  padding: ${(props: DropdownProps) => props.padding || 'none'};
  background: #f6f8fb;
  overflow-y: ${(props: DropdownProps) => props.overflowY};
  border-radius: 0 0 1rem 1rem;
  border: 1px solid #eee;
  z-index: 99999;
  max-height: 200px;
  overflow-y: scroll;

  -ms-overflow-style: none;
  scrollbar-width: 5px;

  ::-webkit-scrollbar {
    background-color: #f6f8fb;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ffbe62;
    border-radius: 4px;
  }

  .select-button {
    cursor: pointer;
    background: none !important;
    border: none !important;
    height: ${(props: DropdownProps) => props.height || '35px'};
    color: ${(props: DropdownProps) => props.color};
    font-size: ${(props: DropdownProps) => props.fontSize || '16px'};
    min-height: 40px;
    text-align: left;
    text-transform: capitalize;
    padding-left: 1rem;
    transition: all ease-in-out 0.3s;

    &:hover {
      background: ${skyBlue} !important;
      opacity: 0.8 !important;
    }
  }

  ${smallScreenLaptop} {
    right: -30px;
  }
  ${mediumScreenLaptop} {
    right: -37px;
  }
  ${tablet} {
    width: 105%;
    right: 0px;
  }
  ${mobile} {
    width: 105%;
    right: 3px;
  }
`;
