import { Dispatch } from 'react';
import { toggleSnackbarOpen } from '../store/actions/snackbarActions';

export const showErrorMessage = (dispatch: Dispatch<any>, error: any) => {
  if (error instanceof Error) {
    dispatch(toggleSnackbarOpen(`${error}`));
  } else {
    dispatch(toggleSnackbarOpen('An unknown error occurred'));
  }
};
