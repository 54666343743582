import { FETCH_LIMIT, ADD_SHOP_DATA, ADD_PAGE_NUMBER } from "../actionTypes";

export const addFetchLimit = (data?: any[]) => {
  return { type: FETCH_LIMIT, payload: data };
};

export const addShopData = (data?: any[]) => {
  return { type: ADD_SHOP_DATA, payload: data };
};

export const addPageNumber = () => {
  return { type: ADD_PAGE_NUMBER };
};
