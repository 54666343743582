import styled from 'styled-components';
import { media } from '../../../constants/theme';
import { Link } from 'react-router-dom';

const { mobile, smallScreenLaptop, tablet } = media;

interface IDot {
  position: number;
  slideIndex: number;
}
interface ICategory {
  display?: boolean;
  isActive?: boolean;
  isFirstIndex?: boolean;
}
export const Container = styled.div`
  display: flex;
  padding: 20px 2rem;
  max-width: 1600px;
  min-height: 450px;
  width: calc(100vw - 64px);
  margin-top: 15px;
  justify-content: space-between;
  margin: 0 auto;

  ${tablet} {
    margin-top: 10px;
  }
  ${mobile} {
    padding: 0 0.5rem;
    min-height: 200px;
    width: calc(100% - 1rem);
  }
`;
export const Sidebar = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  width: 200px;
  border-radius: 10px;
  box-shadow: 4px 4px 30px 0px #23364f1a;
  padding: 10px;
  margin-right: 20px;
  ${tablet} {
    display: none;
  }
`;
export const CategoryBtn = styled.button`
  border: none;
  background: transparent;
  display: flex;
  padding: 8px 5px;
  margin: 1px 0%;
  color: ${(props: ICategory) => (props.isActive ? '#ffa412' : '#8196b3')};
  transition: linear 0.3s;
  transform: ${(props: ICategory) => (props.isActive ? 'scale(1.05)' : 'scale(1)')};
  cursor: ${(props: ICategory) => (props.isFirstIndex ? 'default' : 'pointer')};

  &:hover {
    color: #ffa412;
  }
  div {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    text-transform: capitalize;
  }
`;
export const Slide = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const SlideItem = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: 0.5s linear;
  border-radius: 10px;
  left: calc(100% * ${(props: IDot) => props.position - props.slideIndex});
  z-index: 1;
  img {
    width: 100%;
    height: auto;
  }
`;
export const Right = styled.div`
  width: 85%;
  position: relative;
  ${tablet} {
    width: 100%;
    height: 400px;
    margin-top: 10px;
  }
  ${mobile} {
    width: 100%;
    height: 200px;
    margin-top: 15px;
  }
`;
export const DotContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 10px;
  display: flex;
  justify-content: center;
  z-index: 2;
`;

export const Dot = styled.div`
  height: 12px;
  width: 12px;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  background: ${(props: IDot) => (props.position === props.slideIndex ? '#607087' : '#E4EBF4')};
  margin: 5px;
`;

interface ContOneProps {
  background: string;
}

export const ContOne = styled.div<ContOneProps>`
  background: ${({ background }) => background};
  font-family: 'Poppins', sans-serif;
  height: 100%;
  width: 100%;
  box-shadow: 4px 4px 30px rgba(35, 54, 79, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 35px;
  position: relative;

  img {
    width: 40%;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
`;

interface LinkProps {
  background: string;
  color: string;
}

export const BannerLink = styled(Link)<LinkProps>`
  display: inline-flex;
  justify-content: center;
  padding: 10px 15px;
  background: ${({ background }) => background};
  border-radius: 10px;
  color: ${({ color }) => color};
  font-weight: 500;
  font-size: 20px;
  width: 130px;
  cursor: pointer;

  ${mobile} {
    font-size: 16px;
    width: 95px;
  }
`;

export const AnchorBannerLink = styled.a<LinkProps>`
  display: inline-flex;
  justify-content: center;
  padding: 10px 15px;
  background: ${({ background }) => background};
  border-radius: 10px;
  color: ${({ color }) => color};
  font-weight: 500;
  font-size: 20px;
  width: 130px;
  cursor: pointer;

  ${mobile} {
    font-size: 16px;
    width: 95px;
  }
`;

interface BannerTextProps {
  color: string;
  fontSize?: string;
}

export const BannerDescription = styled.p<BannerTextProps>`
  color: ${({ color }) => color};
  font-weight: 500;
  width: 50%;
  font-size: 2rem;

  ${smallScreenLaptop} {
    font-size: 14px;
  }

  ${tablet} {
    width: 40%;
  }

  ${mobile} {
    width: 57%;
  }
`;

export const BannerTitle = styled.h2<BannerTextProps>`
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize || '5rem'};
  width: 60%;
  font-weight: 800;
  margin: 0;

  ${smallScreenLaptop} {
    font-size: 30px;
  }

  ${tablet} {
    width: 60%;
    font-size: 25px;
  }

  ${mobile} {
    width: 100%;
  }
`;
