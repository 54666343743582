import React, { FunctionComponent, useState, useEffect } from 'react';
import blackCancelIcon from '../../img/blackCancel.svg';
import { Header, SubmitBtn } from '../ChangeAddress/style';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/reducers/index';
import { FormContents, ModalDescription, TextFields, Input, Button, Error as FormError } from '../../GlobalStyles/forms.style';
import { isloading, hasfailed, resetFetch } from '../../store/actions/fetchAction';
import { Required } from '../../utils/formValidations.utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { FormContainer, DropDownContainer, DropDown } from './style';
import { GET_STATES_TYPES, GET_CITIES_TYPES } from '../../interfaces/GetStateAndCity.interface';
import { GET_STATES, GET_CITIES } from '../../schema/GetStateAndCity.schema';
import ReactSelect from '../../components/ReactSelect/ReactSelect';
import { OptionType } from '../../interfaces/FilterSelect.interface';
import { UserType, IProps } from '../../interfaces/UserType.interface';
import { UPDATE_MEMBER_ADDRESS, CREATE_MEMBER_ADDRESS, SET_DEFAULT_ADDRESS } from '../../schema/member.schema';
import PopupCard from '../PopupCard';
import Loader from '../LoaderComponent';
import Error from '../ErrorComponent';
import { setItem } from '../../utils/localStorage.utils';
import { AddressType } from '../../interfaces/address.interface';
import { CREATE_MEMBER_ADDRESS_TYPE } from '../../interfaces/GetMember.interface';

const AddressForm: FunctionComponent<IProps> = ({
  setShow,
  user,
  indexToEdit,
  addressIdToEdit,
  isCheckout,
  refetch,
  setDefaultAddress
}) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, control } = useForm();
  const status = useSelector<AppState>((state) => state.fetch.status);
  const message = useSelector<AppState>((state) => state.fetch.message);

  const [setUserDefaultAddress] = useMutation<{ setDefaultAddress: boolean }>(SET_DEFAULT_ADDRESS, {
    onCompleted() {
      dispatch(resetFetch());
    }
  });

  const handleSetDefaultAddress = async (address: AddressType) => {
    try {
      await setUserDefaultAddress({ variables: { addressId: address.addressId } });
    } catch (e) {
      dispatch(hasfailed(e instanceof Error ? `${e}` : 'An unknown error has occured'));
    }
  };

  const [createMemberAddress] = useMutation<any>(CREATE_MEMBER_ADDRESS, {
    onCompleted({ createMemberAddress }) {
      dispatch(resetFetch());
      setDefaultAddress && setDefaultAddress(createMemberAddress);
      handleSetDefaultAddress(createMemberAddress);
      refetch();
      setShow(false);
    },
    onError: (e) => {
      dispatch(hasfailed(e.message));
    }
  });

  const [errMsg, setErrMsg] = useState<boolean>(false);
  const [updateMemberAddress] = useMutation<any>(UPDATE_MEMBER_ADDRESS, {
    onCompleted() {
      dispatch(resetFetch());
      refetch();
      setShow(false);
    },
    onError: (e) => {
      dispatch(hasfailed(e.message));
    }
  });
  const onSubmit = async (formData: any) => {
    const { fullName, address, mobileNumber } = formData;
    if (selectedStateId?.value == undefined || selectedCityId?.value == undefined) {
      setErrMsg(true);
    } else {
      setErrMsg(false);

      dispatch(isloading());
      try {
        if (addressIdToEdit) {
          await updateMemberAddress({
            variables: {
              addressId: `${addressIdToEdit}`,
              fullName: `${fullName}`,
              mobileNumber: `${mobileNumber}`,
              stateId: Number(selectedStateId?.value),
              cityId: Number(selectedCityId?.value),
              streetAddress: `${address}`
            }
          });
        } else {
          await createMemberAddress({
            variables: {
              fullName: `${fullName}`,
              mobileNumber: `${mobileNumber}`,
              stateId: Number(selectedStateId?.value),
              cityId: Number(selectedCityId?.value),
              streetAddress: `${address}`
            }
          });
        }
      } catch (e) {
        dispatch(hasfailed(e instanceof Error ? `${e}` : 'An unknown error has occured'));
      }
    }
  };
  const isEdit = () => !!(addressIdToEdit && user?.Addresses[indexToEdit]);
  const [selectedStateId, setSelectedStateId] = useState<OptionType>();
  const [selectedCityId, setSelectedCityId] = useState<OptionType>();

  const handleStateChange = (event: any) => {
    const stateId = event;
    setSelectedStateId(stateId);
  };

  const handleCityChange = (event: any) => {
    const cityId = event;
    setSelectedCityId(cityId);
  };

  const { data: statesData, loading: statesLoading } = useQuery<GET_STATES_TYPES>(GET_STATES);

  const stateOptions = statesData?.getStates.slice(1).map((state) => {
    return { value: state.stateId, label: state.stateName };
  });

  const {
    data: citiesData,
    loading: citiesLoading,
    error
  } = useQuery<GET_CITIES_TYPES>(GET_CITIES, {
    variables: {
      stateId: Number(selectedStateId?.value)
    }
  });

  const cityOptions = citiesData?.getCities.map((city) => {
    return { value: city.cityId, label: city.cityName };
  });

  return (
    <div style={{ width: '100%' }}>
      {!isCheckout && (
        <Header>
          <div>
            <h3>Address Form</h3>
          </div>
          <p>Fill the form to register a delivery address</p>
        </Header>
      )}
      <FormContainer onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DropDownContainer>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
            <FormError style={{ marginTop: '10px', color: '#4f4f4f' }}>Enter Your Full Name</FormError>
            <Input
              defaultValue={user?.fullName}
              name="fullName"
              ref={register(Required)}
              type="text"
              placeholder="Full Name"
              style={{ borderColor: errors.fullName && '#F75151', margin: '0' }}
            />
            {errors.fullName && <FormError>This field is required</FormError>}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
            <FormError style={{ marginTop: '10px', color: '#4f4f4f' }}>Enter Your Mobile Number</FormError>
            <Input
              defaultValue={user?.mobileNumber}
              name="mobileNumber"
              ref={register(Required)}
              type="text"
              placeholder="Mobile Number"
              style={{ borderColor: errors.mobileNumber && '#F75151', margin: '0' }}
            />
            {errors.mobileNumber && <FormError>This field is required</FormError>}
          </div>
        </DropDownContainer>
        <div style={{ display: 'flex', flexDirection: 'column', margin: '20px 0', width: '100%' }}>
          <DropDownContainer>
            <DropDown>
              <FormError style={{ color: '#4f4f4f' }}>Select Your State</FormError>
              <ReactSelect
                defaultValue={
                  isEdit()
                    ? {
                        value: user?.Addresses[indexToEdit]?.State?.stateId,
                        label: user?.Addresses[indexToEdit]?.State?.stateName
                      }
                    : {}
                }
                value={selectedStateId}
                onChange={handleStateChange}
                options={stateOptions}
                placeholder={statesLoading ? 'Loading States' : 'Select State'}
                isClearable={true}
              />
              {errMsg && <FormError>This field is required</FormError>}
            </DropDown>

            <DropDown>
              <FormError style={{ color: '#4f4f4f' }}>Select Your Local Goverment Area</FormError>
              <ReactSelect
                defaultValue={
                  isEdit()
                    ? {
                        value: user?.Addresses[indexToEdit]?.City?.cityId,
                        label: user?.Addresses[indexToEdit]?.City?.cityName
                      }
                    : {}
                }
                value={selectedCityId}
                onChange={handleCityChange}
                options={cityOptions}
                placeholder={citiesLoading ? 'Loading Cities' : 'Select City'}
                isClearable={true}
              />
              {errMsg && <FormError>This field is required</FormError>}
            </DropDown>
          </DropDownContainer>
          <div style={{ display: 'flex', flexDirection: 'column', margin: '20px 0' }}>
            <FormError style={{ color: '#4f4f4f' }}>Enter Your Street Address</FormError>
            <Input
              defaultValue={isEdit() ? user?.Addresses[indexToEdit]?.streetAddress : ''}
              name="address"
              ref={register(Required)}
              type="text"
              placeholder="Address"
              style={{ borderColor: errors.address && '#F75151', margin: '0' }}
            />
            {errors.address && <FormError>This field is required</FormError>}
          </div>

          <SubmitBtn type="submit" style={{ margin: '10px 0', width: '100%', outline: 'none' }}>
            {isCheckout ? 'Save' : 'Continue'}
          </SubmitBtn>
        </div>
      </FormContainer>
      {status === 'loading' && (
        <PopupCard transparent>
          <Loader />
        </PopupCard>
      )}
      {status === 'error' && (
        <PopupCard>
          <Error message={String(message)} />
        </PopupCard>
      )}
    </div>
  );
};

export default AddressForm;
