import { GET_DATA_SUCCESS, CURRENT_PAGE, DATA_PER_PAGE } from "../actionTypes";
import InitialState from "../initialStates/inventoryStock";
import { InventoryStockType } from "../../interfaces/state.interface";
import { InventoryStockProps } from "../../interfaces/actions.interface";

const inventoryStockReducer = (state: InventoryStockType = InitialState, action: InventoryStockProps) => {
  switch (action.type) {
    case GET_DATA_SUCCESS:
      return {
        ...state,
      };

    case CURRENT_PAGE:
      return {
        ...state,
      };

    case DATA_PER_PAGE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default inventoryStockReducer;
