import styled from 'styled-components';
import { FontSizes, media, Colors } from '../../../constants/theme';

const { descriptionFontSize } = FontSizes;
const { primaryColor } = Colors;

export const Row = styled.div`
  height: 38px;
  width: calc(100vw - 64px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1a222d;
  position: fixed;
  padding: 0 2rem;
  top: 0;
  z-index: 8;

  .download-app {
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: ${descriptionFontSize};
    line-height: 14px;
    color: ${primaryColor};
    padding: 5px 20px;
    cursor: pointer;
  }

  .text-none {
    margin-bottom: 3px;
  }

  ${media.mobile} {
    display: none;
  }
`;

export const TopNavLink = styled.span`
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: ${descriptionFontSize};
  line-height: 14px;
  color: ${primaryColor};
  padding: 5px 0;
`;
