import React from 'react';
import styled from 'styled-components';
import Header from '../../components/header/header';
import { Colors, media } from '../../constants/theme';
import BackgroundIcons from '../../GlobalStyles/BackgroundIcons';
import { Flex, Text } from '../../GlobalStyles/CustomizableGlobal.style';
import { BgMainContainer } from '../../GlobalStyles/FixedGlobal.style';
import HeaderImage from '../../img/tc-header.png';
import Tick from '../../img/tick-circle.svg';
import BottomNav from '../../components/footer/bottomNav';

const { primaryColor, white, body } = Colors;
const { smallScreenLaptop, tablet, mobile } = media;

const Container = styled.div`
  width: 60%;
  border-radius: 10px;
  background: ${white};
  color: ${body};
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 4px 4px 30px rgba(35, 54, 79, 0.1);
  line-height: 2rem;

  a {
    color: ${primaryColor};
    text-decoration: underline;
  }

  ${smallScreenLaptop} {
    width: 100%;
    margin-top: 3rem;
  }

  ${tablet} {
    width: 100%;
    margin-top: 3rem;
  }

  ${mobile} {
    width: 100%;
    margin-top: 3rem;
  }
`;

const TermsAndConditions = () => {
  return (
    <BgMainContainer>
      <Header />
      <Container>
        <img src={HeaderImage} />
        <div style={{ padding: '2rem' }}>
          <Text fontWeight="700" fontSize={'1.5rem'} color={body} textAlign="center">
            GENERAL TERMS AND CONDITIONS OF USE OF TIMART FOR SELLERS AND CUSTOMERS
          </Text>
          <Flex justifyContent="flex-start">
            <img src={Tick} />
            <Text color={body} margin="0 0.5rem" fontWeight="700" fontSize="1rem">
              INTRODUCTION
            </Text>
          </Flex>
          <ul>
            <li>
              Welcome to Timart, an ecommerce platform for Sellers to display their products and provides Buyers with a wide range of
              products to purchase online.
            </li>
            <li>For the purpose of this document “Timart E-commerce” shall hereinafter be referred to as “Timart”</li>
            <li>These general terms and conditions shall apply to buyers and sellers on Timart and shall govern the use of Timart.</li>
            <li>Acceptance of Terms</li>
            <ol>
              <li>By using Timart you are indicating your acceptance of the terms and conditions that have been set forth.</li>
              <li>
                That you agree to follow all of the rules and guidelines that have been established by Timart for the use of its services.
              </li>
              <li>If you do not agree with these terms and conditions, you should not use Timart.</li>
            </ol>
            <li>
              By using Timart for any business or other organizational project you confirm that you have obtained the necessary authority
              to:
            </li>
            <ol type="a">
              <li>Agree to these general terms and conditions</li>
              <li>
                Bind yourself and the company or other legal entity that operates that business to these general terms and conditions.
              </li>
              <li>
                Agree that you in these general terms and conditions shall reference both the individual user and the relevant person,
                company or legal entity unless the context requires otherwise.
              </li>
            </ol>
          </ul>
          <Flex justifyContent="flex-start">
            <img src={Tick} />
            <Text color={body} margin="0 0.5rem" fontWeight="700" fontSize="1rem">
              ACCOUNT CREATION
            </Text>
          </Flex>
          <ul>
            <li>
              In order to make purchases through Timart, you must create an account. You are responsible for maintaining the confidentiality
              of your account and password, and for restricting access to your computer.
            </li>
            <li>You agree to accept responsibility for all activities that occur under your account or password.</li>
            <li>
              Your account is meant for your personal use only and cannot be transferred to any other person or entity. If you allow someone
              else to access and manage your account, you do so at your own risk.
            </li>
            <li>
              We reserve the right to suspend or terminate your account, as well as modify your account information, without notice or
              explanation, at our sole discretion. However, if we cancel any products or services that you have already paid for and you
              have not violated these general terms and conditions, we will provide a refund for the same.
            </li>
            <li>You may cancel your account on Timart by contacting us.</li>
          </ul>
          <Flex justifyContent="flex-start">
            <img src={Tick} />
            <Text color={body} margin="0 0.5rem" fontWeight="700" fontSize="1rem">
              PRODUCT AVAILABILITY
            </Text>
          </Flex>
          <p>You agree that:</p>
          <ul>
            <li>Timart builds the bridge between Buyer and Seller by providing a virtual platform for trade.</li>
            <li>Timart is not involved in any transactions between the buyer and seller unless we are identified as the seller.</li>
            <li>
              By confirming the purchase of a product(s) on Timart you are committing to either buying or selling the said product(s).
            </li>
            <li>We do not guarantee that all items listed on our website are in stock.</li>
            <li>If we cannot fulfill your order, we will notify you as soon as possible.</li>
          </ul>
          <Flex justifyContent="flex-start">
            <img src={Tick} />
            <Text color={body} margin="0 0.5rem" fontWeight="700" fontSize="1rem">
              PRODUCT INFORMATION
            </Text>
          </Flex>
          <ul>
            <li>We cannot guarantee that the information provided is error-free, complete, or current.</li>
            <li>In the event of a mistake, we reserve the right to cancel any order and refund the customer's money.</li>
          </ul>
          <Flex justifyContent="flex-start">
            <img src={Tick} />
            <Text color={body} margin="0 0.5rem" fontWeight="700" fontSize="1rem">
              TERMS AND CONDITIONS THAT GOVERN YOUR CONTENT
            </Text>
          </Flex>
          <ul>
            <li>
              The following terms and conditions refer to "your content," which encompasses all materials and works you submit to Timart for
              publication, storage, or transmission, including text, graphics, images, audio, video, scripts, software, and files, ( and as
              may be subsequently reviewed and included by the company)
            </li>
            <li>
              Your content must be accurate, truthful, appropriate, civil, and in line with accepted standards of behavior on the internet.
              It must not be offensive, obscene, indecent, pornographic, violent, or discriminatory, and must not infringe on any legal
              rights or give rise to legal action.
            </li>
            <li>You must not use our marketplace to link to any material that breaches these terms and conditions.</li>
            <li>Additionally, you must not interfere with any transactions or engage in any unlawful activity on the marketplace.</li>
            <li>
              You recognize that each user on Timart is accountable for their interactions with others, and it is your responsibility to act
              prudently and wisely when communicating with other users. It is prohibited to provide them with any personal information,
              including credit card details.
            </li>
            <li>
              We reserve the right to review and remove any content at our discretion and request that you report any illegal activity to
              us.
            </li>
          </ul>
          <Flex justifyContent="flex-start">
            <img src={Tick} />
            <Text color={body} margin="0 0.5rem" fontWeight="700" fontSize="1rem">
              TIMART AND YOUR CONTENTS
            </Text>
          </Flex>
          <ul>
            <li>
              By agreeing to these terms and conditions, you give us permission to utilize, reproduce, preserve, modify, publish, translate,
              and disseminate your material worldwide, on both Timart and our promotional platforms, as well as on any current or future
              media, with no chance of revocation and without compensation. Additionally, you grant us permission to sub-license the
              previously stated rights and to pursue legal action in the event of infringement.
            </li>
            <li>
              If you violate our content rules or if we suspect that you have done so, we reserve the right to edit, delete, or unpublish
              any or all of your content, without prejudice to any other rights we may have under these terms and conditions.
            </li>
          </ul>
          <Flex justifyContent="flex-start">
            <img src={Tick} />
            <Text color={body} margin="0 0.5rem" fontWeight="700" fontSize="1rem">
              PRICING AND PAYMENT
            </Text>
          </Flex>
          <ul>
            <li>All prices listed on our website are in Naira (NGN) and are subject to change without notice.</li>
          </ul>
          <Flex justifyContent="flex-start">
            <img src={Tick} />
            <Text color={body} margin="0 0.5rem" fontWeight="700" fontSize="1rem">
              RETURNS AND REFUNDS
            </Text>
          </Flex>
          <ul>
            <li>
              If you are not satisfied with your purchase, you may return it within 14 days of receipt for a refund.{' '}
              <a href="/return-policy">Read our return and refund policy</a>
            </li>
          </ul>
          <Flex justifyContent="flex-start">
            <img src={Tick} />
            <Text color={body} margin="0 0.5rem" fontWeight="700" fontSize="1rem">
              PRIVACY POLICY FOR TIMART
            </Text>
          </Flex>
          <p>
            At Timart, we take your privacy seriously and are committed to protecting your personal information. This policy outlines what
            information we collect, how we use it, and how we keep it safe. <a href="/privacy-policy">Please read</a> it carefully before
            using our website.
          </p>
          <Flex justifyContent="flex-start">
            <img src={Tick} />
            <Text color={body} margin="0 0.5rem" fontWeight="700" fontSize="1rem">
              DELIVERY
            </Text>
          </Flex>
          <ul>
            <li>
              Timart concurrently handles delivery in Minna and Abuja alone. For purchases made outside Minna and Abuja the Seller Handles
              Delivery and bears all responsibility for the delivery and any other thing that may transpire in the course of delivery e.g
              Damages, Loss, Theft etc.
            </li>
            <li>
              On no account will Timart be responsible for a Delivery made by the Seller, However Timart will inform the Seller to resolve
              any dispute reported.
            </li>
            <li>
              Cost of delivery:
              <ol type="a">
                <li>When handled by the seller, it will be determined by the seller.</li>
                <li>When handled by Timart will be determined by Timart</li>
              </ol>
            </li>
          </ul>
          <Flex justifyContent="flex-start">
            <img src={Tick} />
            <Text color={body} margin="0 0.5rem" fontWeight="700" fontSize="1rem">
              MODIFICATION OF TERMS
            </Text>
          </Flex>
          <ul>
            <li>
              We reserve the right to modify these terms and conditions at any time. You should review these terms periodically for changes.
              Your continued use of our website after we post any modifications to these terms and conditions constitutes your acceptance of
              the modified terms.
            </li>
          </ul>
        </div>
        <BackgroundIcons />
      </Container>
      <BottomNav />
    </BgMainContainer>
  );
};

export default TermsAndConditions;
