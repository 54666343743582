import React, { FunctionComponent, useEffect } from 'react';
import { Route, Switch, RouteComponentProps, withRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import LandingPage from './pages/landing';
import Merchant from './pages/merchants';
import ShopView from './pages/shop';
import SearchQuery from './pages/SearchQuery';
import Checkout from './pages/checkout/checkout';
import MyAccount from './pages/account';
import Auth from './pages/auth';
import ProductPage from './pages/product';
import PageNotFound from './pages/PageNotFound';
import ContactUs from './pages/contact/index';
import ThankYou from './pages/contact/ThankYou';
import Cart from './pages/cart';
import routes from './utils/urlRoutes.utils';
import { AutoLogin } from './utils/autoLogin.utils';
import { useLocation } from 'react-router-dom';
import ReturnPolicy from './pages/Policy/ReturnPolicy';
import AcceptancePolicy from './pages/Policy/AcceptancePolicy';
import ScrollToTop from './utils/scrollToTop.utils';
import AppBanner from './components/AppBanner';
import Location from './pages/location';
import PrivacyPolicy from './pages/Policy/PrivacyPolicy';
import TermsAndConditions from './pages/Policy/TermsAndConditions';
import Faq from './pages/Policy/faq';
import LoginPage from './pages/LoginAndRegister/loginPage';
import RegisterPage from './pages/LoginAndRegister/RegisterPage';

// remove
const { nossr, ssr } = routes;

const googleTagTrackingId = 'GTM-TLNT7FD';
const tagManagerArgs = {
  gtmId: googleTagTrackingId
};

const setupPixel = () => {
  const script = document?.createElement?.('script');

  if (!script) {
    return;
  }

  script.innerHTML = `
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '746186196816743');
  fbq('track', 'PageView');
  `;

  const head = document?.querySelector?.('head');

  if (head) {
    head.appendChild(script);
  }
};

const App: FunctionComponent<RouteComponentProps> = () => {
  const { search } = useLocation();

  useEffect(() => {
    if ((window as any)?.location?.host?.match(/^(www\.)?tmart\.com\.ng/)) {
      TagManager.initialize(tagManagerArgs);

      // setupPixel()
    }
    AutoLogin(search);
  }, []);

  useEffect(() => {
    const loader = document?.querySelector?.('.app-loader');
    if (loader) {
      loader.classList.add('app-loader--hide');
    }
  }, []);

  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route path={ssr.home} exact component={LandingPage} />
        <Route path={nossr.contactUs} exact component={ContactUs} />
        <Route path={nossr.thankYou} exact component={ThankYou} />
        <Route path={nossr.returnPolicy} exact component={ReturnPolicy} />
        <Route path={nossr.acceptancePolicy} exact component={AcceptancePolicy} />
        <Route path={ssr.merchant} component={Merchant} />
        <Route path={ssr.search} component={SearchQuery} />
        <Route path={ssr.product} component={ProductPage} />
        <Route path={nossr.checkout} component={Checkout} />
        <Route path={nossr.account} component={MyAccount} />
        <Route path={nossr.auth} component={Auth} />
        <Route path={nossr.login} component={LoginPage} />
        <Route path={nossr.register} component={RegisterPage} />
        <Route path={nossr.cart} component={Cart} />
        <Route path={nossr.location} component={Location} />
        <Route path={nossr.privacyPolicy} component={PrivacyPolicy} />
        <Route path={nossr.termsAndConditions} component={TermsAndConditions} />
        <Route path={nossr.faq} component={Faq} />
        <Route path={nossr.pageNotFound} component={PageNotFound} />
        <Route path={ssr.shop} component={ShopView} />
      </Switch>
    </>
  );
};

export default withRouter(App);
