import styled from 'styled-components';
import { Colors, FontSizes } from '../../constants/theme';

const { primaryColor, white, body } = Colors;
const { secondaryFontSize } = FontSizes;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .react-pagination-wrap {
    display: flex;
  }

  .pagination-button {
    border: none;
    background: transparent;
  }

  .current-page {
    width: 20px;
    height: 20px;
    background: ${primaryColor};
    color: ${white};
    border-radius: 3px;
    text-align: center;
    padding: 0.3em;
  }

  .not-current-page {
    color: ${body};
  }

  .page-numbers {
    margin: 0 0.5em;
    cursor: pointer;
    font-size: ${secondaryFontSize};
  }

  .page-numbers-wrap {
    display: flex;
    align-items: center;
  }

  .page-controls {
    margin: 0.5em;
    cursor: pointer;

    &:hover {
      color: ${primaryColor};
    }
  }

  .disable {
    opacity: 0.4;
    cursor: not-allowed;
  }

  .page-numbers {
    &:hover {
      color: ${primaryColor};
    }
  }
`;
export const ArrowContainer = styled.span`
  :hover {
    cursor: pointer;
  }
`;
