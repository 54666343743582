import React from 'react';
import BottomNav from '../../components/footer/bottomNav';
import Header from '../../components/header/header';
import { Container, OList, SubList, Content } from './policy.style';
import { Colors } from '../../constants/theme';
import { FontSizes } from '../../constants/theme';

const { primaryColor, body } = Colors;
const { primaryFontSize } = FontSizes;

const AcceptancePolicy = () => {
  return (
    <>
      <Header />
      <Container>
        <div style={{ padding: '2rem' }}>
          <Content fontWeight="700" fontSize={primaryFontSize} color={body}>
            GENERAL TERMS AND CONDITIONS OF USE OF TIMART FOR SELLERS AND CUSTOMERS
          </Content>
          <OList>
            <div className="list-title">
              <span>1.</span>
              <Content margin="0px" fontWeight="700" fontSize={primaryFontSize} color={body}>
                INTRODUCTION
              </Content>
            </div>
            <SubList>
              <div className="list-title">
                <span>1.1</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  Timart is the company name for the following services: “Timart Business app”, “Timart E-commerce”, “Timart Logistics” and
                  other services that the company may render.
                </Content>
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>1.2</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  The Terms and conditions shall apply to all customers and sellers on Timart.
                </Content>
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>1.3</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  Agreeing to our terms and conditions permits buyers and sellers to use Timart, disagreeing to our terms and conditions
                  automatically withdraws the permission.{' '}
                </Content>
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>1.4</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  By using Timart you agree that;
                </Content>
              </div>
              <SubList>
                <div className="list-title">
                  <span>1.4.1</span>
                  <Content margin="0px" fontSize={primaryFontSize} color={body}>
                    You are not under 18 years of age and are of legal age to accept this agreement.
                  </Content>
                </div>
              </SubList>
              <SubList>
                <div className="list-title">
                  <span>1.4.2</span>
                  <Content margin="0px" fontSize={primaryFontSize} color={body}>
                    If you are representing a person, or legal entity, you agree that you have the necessary power and rights needed to
                    accept this agreement on behalf of the person or legal entity you represent.
                  </Content>
                </div>
              </SubList>
              <SubList>
                <div className="list-title">
                  <span>1.4.3</span>
                  <Content margin="0px" fontSize={primaryFontSize} color={body}>
                    You bind yourself, any person or legal entity you represent to these terms and conditions.
                  </Content>
                </div>
              </SubList>
              <SubList>
                <div className="list-title">
                  <span>1.4.4</span>
                  <Content margin="0px" fontSize={primaryFontSize} color={body}>
                    You have read, understood and are bound by these terms and conditions and are willing to comply with requirements listed
                    herein.
                  </Content>
                </div>
              </SubList>
            </SubList>
          </OList>
          <OList>
            <div className="list-title">
              <span>2.</span>
              <Content margin="0px" fontWeight="700" fontSize={primaryFontSize} color={body}>
                ON-BOARDING CUSTOMER AND SELLER
              </Content>
            </div>
            <SubList>
              <div className="list-title">
                <span>2.1</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  Seller’s registration; To use Timart as a Seller, you would be required to register, with your email and password, after
                  which you will create a business. When creating a business, you will be required to provide certain details, including but
                  not limited to phone number, business name, business address. You warrant that the information provided is and shall
                  remain accurate and true. You also agree to update any information that ceases to be true, promptly without delay.
                </Content>
              </div>
              <SubList>
                <div className="list-title">
                  <span>2.1.1</span>
                  <Content margin="0px" fontSize={primaryFontSize} color={body}>
                    That you are solely responsible for the security of your profile, your password and user ID or name.
                  </Content>
                </div>
              </SubList>
              <SubList>
                <div className="list-title">
                  <span>2.1.2</span>
                  <Content margin="0px" fontSize={primaryFontSize} color={body}>
                    You agree to inform Timart through email at{' '}
                    <a style={{ color: `${primaryColor}` }} href="mailto:info@timart.com.ng">
                      info@timart.com.ng
                    </a>
                    , when you have the knowledge that there has been a breach in your profile security or you suspect that your password
                    and username are being used by an unauthorized party.
                  </Content>
                </div>
              </SubList>
              <SubList>
                <div className="list-title">
                  <span>2.1.3</span>
                  <Content margin="0px" fontSize={primaryFontSize} color={body}>
                    You shall not share password, username or profile details with any third party. You shall bear all consequences that
                    result from failure to keep your password and profile details safe.
                  </Content>
                </div>
              </SubList>
              <SubList>
                <div className="list-title">
                  <span>2.1.4</span>
                  <Content margin="0px" fontSize={primaryFontSize} color={body}>
                    You shall not transfer your account to a third party to manage, any such transfer made is made at the risk of the seller
                    and the seller shall bear all consequences that arise from such transfer.
                  </Content>
                </div>
              </SubList>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>2.2</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  Customer’s Registration; as a customer you would be required to register on Timart E-commerce with your Email address and
                  password, and by registering you agree that:
                </Content>
              </div>
              <SubList>
                <div className="list-title">
                  <span>2.2.1</span>
                  <Content margin="0px" fontSize={primaryFontSize} color={body}>
                    You shall be responsible for maintaining the security of your password, username and profile details.
                  </Content>
                </div>
              </SubList>
              <SubList>
                <div className="list-title">
                  <span>2.2.2</span>
                  <Content margin="0px" fontSize={primaryFontSize} color={body}>
                    You shall not share your information with any third party, and shall bear every consequence that may arise from that
                    act.
                  </Content>
                </div>
              </SubList>
              <SubList>
                <div className="list-title">
                  <span>2.2.3</span>
                  <Content margin="0px" fontSize={primaryFontSize} color={body}>
                    You shall inform us via email at{' '}
                    <a style={{ color: `${primaryColor}` }} href="mailto: info@timart.com.ng">
                      info@timart.com.ng
                    </a>{' '}
                    upon any knowledge of breach on your profile security, password and username.
                  </Content>
                </div>
              </SubList>
            </SubList>
          </OList>
          <OList>
            <div className="list-title">
              <span>3.</span>
              <Content margin="0px" fontWeight="700" fontSize={primaryFontSize} color={body}>
                SELLING ON TIMART E-COMMERCE
              </Content>
            </div>
            <SubList>
              <div className="list-title">
                <span>3.1</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  To sell on Timart E-commerce the seller shall download and register on the Timart business app and “Activate Online
                  Presence” (find steps on our Faqs)
                </Content>
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>3.2</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  By registering on the Timart business App the you agree that you are not under 18 years of age.
                </Content>
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>3.3</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  By choosing to go live with your products through online presence, you are agreeing to the terms and conditions of use, if
                  you disagree with our terms and conditions or any part of our terms and conditions you must not publish your products
                  online.
                </Content>
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>3.4</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  Toggling the online presence button means that your products will be displayed online at (Tmart.com.ng) for the general
                  public to access, view and make purchases at any time and anywhere in the world.
                </Content>
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>3.5</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  Remaining connected;
                </Content>
              </div>
              <SubList>
                <div className="list-title">
                  <span>3.5.1</span>
                  <Content margin="0px" fontSize={primaryFontSize} color={body}>
                    The online presence button will remain on as long as you perform your daily inventory and sales recording on Timart
                    business app(TBA). If the system detects dormancy (i.e no activity on the Timart Business App) for a period longer than
                    3days, the online button will automatically be toggled off. This is to ensure that only products from active sellers are
                    displayed online, preventing customers from placing orders that will be unattended.
                  </Content>
                </div>
              </SubList>
              <SubList>
                <div className="list-title">
                  <span>3.5.2</span>
                  <Content margin="0px" fontSize={primaryFontSize} color={body}>
                    All prices must be updated by sellers as soon as there is a change in price. When customers place orders which are
                    unable to be completed due to outdated prices the seller will be banned from the ecommerce platform for a period ranging
                    from 1 week to a permanent ban as seen fit by Timart upon consideration.
                  </Content>
                </div>
              </SubList>
            </SubList>
          </OList>
          <OList>
            <div className="list-title">
              <span>4.</span>
              <Content margin="0px" fontWeight="700" fontSize={primaryFontSize} color={body}>
                FULFILLING ORDERS AND DELIVERIES
              </Content>
            </div>
            <SubList>
              <div className="list-title">
                <span>4.1</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  There are two options on handling delivery, a seller can choose to handle the delivery or choose Timart logistics to
                  handle the delivery (for orders within Minna, Niger state and Abuja)
                </Content>
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>4.2</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  For orders within Minna, Niger state and Abuja, sellers are urged to use Timart logistics to ensure customer goods are
                  protected and delivered intact and on time.
                </Content>
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>4.3</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  For orders outside Minna, Niger state, and Abuja, sellers are urged to use other means of delivery available to them,
                  including motor parks or other logistics services. Timart is working hard to partner with other logistics companies
                  outside Minna, Niger state, and Abuja to ensure safe and smooth delivery of products.
                </Content>
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>4.4</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  Timart is not responsible for orders delivered using other services.
                </Content>
              </div>
            </SubList>
          </OList>
          <OList>
            <div className="list-title">
              <span>5.</span>
              <Content margin="0px" fontWeight="700" fontSize={primaryFontSize} color={body}>
                PAYMENT METHODS
              </Content>
            </div>
            <SubList>
              <div className="list-title">
                <span>5.1</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  We currently have only two payment methods available to customers accessing sellers products on Timart E-commerce.
                </Content>
              </div>
              <SubList>
                <div className="list-title">
                  <span>5.1.1</span>
                  <Content margin="0px" fontSize={primaryFontSize} color={body}>
                    Online payment using credit card details
                  </Content>
                </div>
              </SubList>
              <SubList>
                <div className="list-title">
                  <span>5.1.2</span>
                  <Content margin="0px" fontSize={primaryFontSize} color={body}>
                    Pay on delivery
                  </Content>
                </div>
              </SubList>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>5.2</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  Payment is made to Timart, upon receipt of the item by the customer, Timart remits the paid amount to the seller.
                </Content>
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>5.3</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  Online transaction charges, delivery charges, packaging charges, handling charges, administrative charges, insurance
                  costs, and any other extra charges where applicable will be borne by the customer, if this is expressly and clearly stated
                  in the product listing by the Seller.
                </Content>
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>5.4</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  Delivery of digital products may be made electronically.
                </Content>
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>5.5</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  After successful delivery, the payment of the item together with the shipping costs(if the seller chooses to handle the
                  shipping) will be deposited directly into the seller's account between 1 - 7 days. Same will apply to payments on
                  delivery, buyers pay on their doorsteps, through cash or any other legal means and amount paid will be deposited into the
                  seller’s account between 1 - 7 days.
                </Content>
              </div>
            </SubList>
          </OList>
          <OList>
            <div className="list-title">
              <span>6.</span>
              <Content margin="0px" fontWeight="700" fontSize={primaryFontSize} color={body}>
                FAULTY PRODUCTS AND DISPUTES
              </Content>
            </div>
            <SubList>
              <div className="list-title">
                <span>6.1</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  What makes a faulty product include the following;
                </Content>
              </div>
              <SubList>
                <div className="list-title">
                  <span>6.1.1</span>
                  <Content margin="0px" fontSize={primaryFontSize} color={body}>
                    Item that does not meet the standard it was presented on tmart.com.ng by the seller.
                  </Content>
                </div>
              </SubList>
              <SubList>
                <div className="list-title">
                  <span>6.1.2</span>
                  <Content margin="0px" fontSize={primaryFontSize} color={body}>
                    Item that fails to meet the variation in size or color, or quantity ordered by the customer
                  </Content>
                </div>
              </SubList>
              <SubList>
                <div className="list-title">
                  <span>6.1.3</span>
                  <Content margin="0px" fontSize={primaryFontSize} color={body}>
                    Item that does not function as described by the seller
                  </Content>
                </div>
              </SubList>
              <SubList>
                <div className="list-title">
                  <span>6.1.4</span>
                  <Content margin="0px" fontSize={primaryFontSize} color={body}>
                    Item that does not meet the requirements of its advertisement.
                  </Content>
                </div>
              </SubList>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>6.2</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  The seller shall be legally bound to replace or retract the item.
                </Content>
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>6.3</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  All charges resulting from this will be incurred by the seller.
                </Content>
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>6.4</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  The seller may face disciplinary action including but not limited to ban from Timart services.
                </Content>
              </div>
            </SubList>
          </OList>
          <OList>
            <div className="list-title">
              <span>7.</span>
              <Content margin="0px" fontWeight="700" fontSize={primaryFontSize} color={body}>
                RETURN POLICY
              </Content>
            </div>
            <SubList>
              <div className="list-title">
                <span>7.1</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  Return of products shall be carried out in accordance with our return policy, on the return policy page as be amended from
                  time to time. Accepting a request for return is by our discretion, after due consideration of the matter.
                </Content>
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>7.2</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  Return of item and refund after a completed transaction, shall be handled between the seller and the customer. Timart will
                  only assist in notifying the seller to settle the issue.
                </Content>
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>7.3</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  Return of item shall be carried out by Timart only when the delivery was handled by Timart.
                </Content>
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>7.4</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  The following items cannot be returned for hygiene and security reasons: swimwear, underwear, lingerie, earrings,
                  cosmetics, fragrances, groceries, food supplements, software, tickets, and adult products.
                </Content>
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>7.5</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  Changes to our return policy shall take effect in respect to all purchases made from the date of publication of change on
                  our website.
                </Content>
              </div>
            </SubList>
          </OList>
          <OList>
            <div className="list-title">
              <span>8.</span>
              <Content margin="0px" fontWeight="700" fontSize={primaryFontSize} color={body}>
                DEFINITIONS
              </Content>
            </div>
            <SubList>
              <div className="list-title">
                <span>8.1</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  For the purpose of these document, “Seller” shall mean, a person registered as a user on Timart business app and sells on
                  the Timart E-commerce platform
                </Content>
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>8.2</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  For the purpose of these document, “customer” shall mean, a person who has registered on Timart E-commerce to buy items on
                  the platform.
                </Content>
              </div>
            </SubList>
            <SubList>
              <div className="list-title">
                <span>8.3</span>
                <Content margin="0px" fontSize={primaryFontSize} color={body}>
                  For the purpose of these document, “item” shall mean, products and services that are being sold on Timart E-commerce.
                </Content>
              </div>
            </SubList>
          </OList>
        </div>
      </Container>
      <BottomNav />
    </>
  );
};

export default AcceptancePolicy;
