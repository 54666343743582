import React, { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Colors, FontSizes, media } from '../../constants/theme';
import {
  Button,
  Flex,
  StatusView,
  RadioButton,
  RadioButtonLabel,
  RadioItem,
  Text,
  Span
} from '../../GlobalStyles/CustomizableGlobal.style';
import showAmount from '../../utils/priceFormat.utils';
import { toggleSnackbarOpen } from '../../store/actions/snackbarActions';
import PopupCard from '../PopupCard';
import styled from 'styled-components';
import Counter from '../counter';
import { useHistory } from 'react-router-dom';
import { InventoryPrice, InventoryPricingType } from '../../interfaces/inventory.interface';
import { getInventoryPrice } from '../../utils/inventory.utils';

interface Props {
  inventoryType?: string;
  setinventoryType: Function;
  setModalOpen: Function;
  addToCart: Function;
  unitPrice: number;
  packPrice: number;
  perPack: number;
  productName: string;
  merchant: string;
  imageurl: string;
  Variations: [
    {
      variationId: string;
      variationName: string;
      inventoryId: string;
      price: number;
      cost: number;
      shopId: string;
    }
  ];
  inventoryTypeProp?: string;
  inventoryId: string;
  inventoryPrice: InventoryPrice | undefined;
}

const { mobile } = media;

const PopUpWrapper = styled.div`
  width: 100%;

  .label-price {
    width: 100%;
    display: flex;
    align-items: center;
  }
`;

const ListContainer = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
`;
const ListItem = styled.li`
  margin: 1em 0;
`;

const ModalFlex = styled(Flex)`
  ${mobile} {
    justify-content: space-around;
  }
`;

const LiDiv = styled.div`
  width: 15ch;
`;

const ItemTypeModal: FunctionComponent<Props> = ({
  inventoryType,
  setinventoryType,
  setModalOpen,
  addToCart,
  unitPrice,
  packPrice,
  inventoryTypeProp,
  perPack,
  Variations,
  inventoryId,
  productName,
  imageurl,
  merchant,
  inventoryPrice
}) => {
  const { primaryGrey, primaryColor, white } = Colors;
  const { primaryFontSize, detailsFontSize } = FontSizes;
  const dispatch = useDispatch();
  const [price, setPrice] = useState<number>();
  const [isProceedToCart, setProceedToCart] = useState(false);
  const history = useHistory();

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setinventoryType(value);
    if (inventoryType === 'PIECES') {
      setPrice(unitPrice);
    }
    if (inventoryType === 'PACK') {
      setPrice(packPrice);
    }
  };

  const cartAction = () => {
    if (inventoryType === 'PIECES_AND_PACK') {
      dispatch(toggleSnackbarOpen('please select an option'));
    } else {
      addToCart(price);
      setModalOpen(false);
    }
  };

  const close = () => {
    return true;
  };

  const closeModal = (event: any) => {
    event.stopPropagation();
    close();
  };

  return (
    <PopupCard close={() => setModalOpen(false)}>
      <PopUpWrapper onClick={(event) => closeModal(event)}>
        {inventoryTypeProp == 'PIECES_AND_PACK' && (
          <Flex direction="column">
            <Text fontSize={primaryFontSize} color={primaryColor} fontWeight={'600'}>
              Please select pieces or pack
            </Text>
            <ListContainer>
              <ListItem>
                <ModalFlex justifyContent="space-between">
                  <LiDiv>
                    <Text textAlign="left" color={primaryGrey} margin={'0'} fontWeight={'700'}>
                      Pieces
                    </Text>
                    <Text textAlign="left" color={primaryGrey} margin={'0'}>
                      {showAmount(unitPrice)}
                    </Text>
                  </LiDiv>
                  <Counter
                    dotSize="2em"
                    inventoryId={inventoryId}
                    inventoryType={'PIECES'}
                    initialInventoryType={'PIECES_AND_PACK'}
                    productName={productName}
                    imageurl={imageurl}
                    merchant={merchant}
                    fixedPrice={unitPrice}
                    setNotNull={setProceedToCart}
                    inventoryPrice={inventoryPrice}
                  />
                </ModalFlex>
              </ListItem>
              <ListItem>
                <ModalFlex justifyContent="space-between">
                  <LiDiv>
                    <Text textAlign="left" color={primaryGrey} margin={'0'} fontWeight={'700'} width="200px">
                      Pack{' '}
                      <Span color={primaryGrey} fontSize={'0.75em'}>
                        {' '}
                        x {perPack} Pieces
                      </Span>
                    </Text>
                    <Text textAlign="left" color={primaryGrey} margin={'0'}>
                      {showAmount(packPrice)}
                    </Text>
                  </LiDiv>
                  <Counter
                    dotSize="2em"
                    inventoryId={inventoryId}
                    inventoryType={'PACK'}
                    initialInventoryType={'PIECES_AND_PACK'}
                    productName={productName}
                    imageurl={imageurl}
                    merchant={merchant}
                    fixedPrice={packPrice}
                    setNotNull={setProceedToCart}
                    inventoryPrice={inventoryPrice}
                  />
                </ModalFlex>
              </ListItem>
            </ListContainer>
          </Flex>
        )}
        {inventoryTypeProp == 'VARIATION' && (
          <Flex direction="column">
            <Text fontSize={primaryFontSize} color={primaryColor} fontWeight={'600'}>
              Please select a variation
            </Text>
            <ListContainer>
              {Variations.map((variation) => {
                const { variationId, variationName, price } = variation;
                return (
                  <ListItem key={variationId}>
                    <Flex justifyContent="space-between">
                      <div>
                        <Text color={primaryGrey} margin={'0'} fontWeight={'700'}>
                          {variationName}
                        </Text>
                        <Text color={primaryGrey} margin={'0'}>
                          {showAmount(price)}
                        </Text>
                      </div>
                      <Counter
                        dotSize="2em"
                        inventoryId={inventoryId}
                        variationId={variationId}
                        variationName={variationName}
                        inventoryType={'VARIATION'}
                        productName={productName}
                        imageurl={imageurl}
                        merchant={merchant}
                        fixedPrice={price}
                        setNotNull={setProceedToCart}
                        inventoryPrice={inventoryPrice}
                      />
                    </Flex>
                  </ListItem>
                );
              })}
            </ListContainer>
          </Flex>
        )}
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Button
            borderRadius="5px"
            width={'45%'}
            backgroundColor={white}
            borderColor={primaryColor}
            color={primaryColor}
            borderSize={'2px'}
            onClick={() => setModalOpen(false)}
          >
            Continue Shopping
          </Button>
          <Button
            disabled={!isProceedToCart}
            style={{ opacity: `${!isProceedToCart ? '0.5' : '1'}` }}
            borderRadius="5px"
            width={'45%'}
            onClick={() => history.push('/cart')}
          >
            Proceed to Cart
          </Button>
        </Flex>
      </PopUpWrapper>
    </PopupCard>
  );
};

export default ItemTypeModal;
