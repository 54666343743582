import React from 'react';
import { Helmet } from 'react-helmet-async';
import { formatName } from '../../utils/format.utils';

interface HelmetProps {
  title: string;
  description: string;
  imageUrl: string;
  type: 'product' | 'shop' | 'website';
  url?: string;
  isLandingPage?: boolean;
  price?: number | null;
  inventoryId?: string;
  isOutOfStock?: boolean;
  brand?: string;
}

export default ({
  title,
  description,
  imageUrl,
  type,
  url,
  isLandingPage = false,
  price,
  inventoryId,
  isOutOfStock,
  brand
}: HelmetProps) => {
  title = formatName(title);
  const shortDescription = isLandingPage ? description : `Get ${title?.trim()} on Timart E-commerce`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={shortDescription} />

      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:url" content={url} />
      <meta name="og:image" content={imageUrl} />
      <meta name="og:type" content={type} />

      {/* Facebook MicroData for pixel */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={imageUrl} />
      <meta property="product:brand" content={brand} />
      <meta property="product:availability" content={isOutOfStock ? 'out of stock' : 'in stock'} />
      <meta property="product:condition" content="new" />
      <meta property="product:price:amount" content={String(price || 0)} />
      <meta property="product:price:currency" content="NGN" />
      <meta property="product:retailer_item_id" content={inventoryId} />
    </Helmet>
  );
};
