import React, { FunctionComponent, useState } from 'react';
import PopupCard from '../PopupCard';
import Login from '../../pages/LoginAndRegister/login';
import { Flex, Text } from '../../GlobalStyles/CustomizableGlobal.style';
import { Colors, FontSizes } from '../../constants/theme';
import Signup from '../../pages/LoginAndRegister/signup';
import { TabContainer } from './OrderSummary.style';

interface Props {
  close: Function;
}

const AuthPopup: FunctionComponent<Props> = ({ close }) => {
  const { body, primaryGrey, tabColor } = Colors;
  const { bigFont, descriptionFontSize } = FontSizes;

  const [tab, setTab] = useState('login');

  return (
    <PopupCard close={() => close()}>
      <Text color={body} fontSize={bigFont} fontWeight="700">
        Hello Guest!
      </Text>
      <Text color={primaryGrey} fontSize={descriptionFontSize} margin="0" textAlign="left">
        Please you need to Login or Create an account to continue
      </Text>

      <TabContainer>
        <Text
          onClick={() => setTab('login')}
          className={tab === 'login' ? 'selected-tab' : 'tab'}
          color={tabColor}
          fontSize="1rem"
          margin="0"
        >
          Login
        </Text>
        <Text
          onClick={() => setTab('signup')}
          className={tab === 'signup' ? 'selected-tab' : 'tab'}
          color={tabColor}
          fontSize="1rem"
          margin="0"
        >
          Create Account
        </Text>
      </TabContainer>
      {tab === 'login' && <Login width="100%" removeExtra authPopup />}
      {tab === 'signup' && <Signup width="100%" removeExtra authPopup />}
    </PopupCard>
  );
};

export default AuthPopup;
