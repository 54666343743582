import React from 'react';
import NotFound404 from '../../img/page-not-found.svg';
import { CenterDiv } from './PageNotFound.style';
import { Link } from 'react-router-dom';
import routes from '../../utils/urlRoutes.utils';

const PageNotFound = () => {
  const { ssr } = routes;
  return (
    <CenterDiv>
      <img src={NotFound404} alt="page not found" />
      <h1 style={{ color: '#8196B3' }}>Page Not Found</h1>
      <Link to={ssr.home}>Home</Link>
    </CenterDiv>
  );
};

export default PageNotFound;
