import React, { FunctionComponent } from 'react';
import { FormModalContainer, ModalDescription } from '../../GlobalStyles/forms.style';
import { Button } from '../contact/ContactUs.style';
import { Flex, Span } from '../../GlobalStyles/CustomizableGlobal.style';
import { Colors } from '../../constants/theme';
import { useDispatch } from 'react-redux';
import { hasfailed, isloading, resetFetch } from '../../store/actions/fetchAction';
import { useQuery } from '../../utils/customRoute.utils';
import { useMutation } from '@apollo/client';
import { FORGOT_PASSWORD_MEMBER } from './RecoverPassword';
import { useHistory } from 'react-router-dom';

const LinkSent: FunctionComponent = () => {
  const { primaryColor } = Colors;
  const dispatch = useDispatch();
  const history = useHistory();

  let email = useQuery().get('email');

  const [forgotPassword] = useMutation<any>(FORGOT_PASSWORD_MEMBER, {
    onCompleted({ forgotPasswword }) {
      dispatch(resetFetch());
      history.push(`/auth/link-success?email=${email}`);
    }
  });

  const resendLink = async () => {
    dispatch(isloading());
    try {
      await forgotPassword({ variables: { email } });
    } catch (e) {
      dispatch(hasfailed(e instanceof Error ? `${e}` : 'An unknown error has occured'));
    }
  };

  return (
    <FormModalContainer>
      <Flex margin="1rem 0">
        <svg xmlns="http://www.w3.org/2000/svg" width="97" height="98" viewBox="0 0 97 98" fill="none">
          <g clip-path="url(#clip0_11374_11987)">
            <path
              d="M0.90918 74.5719L28.9482 45.0187L7.42637 13.6281C6.36543 15.0062 5.60762 16.8437 5.45605 18.9875L-0.000195313 69.0594C-0.151758 71.2031 0.151367 73.0406 0.90918 74.5719ZM95.0295 8.42188L54.4107 46.55L86.3904 90.1906C88.2092 88.6594 89.4217 86.3625 89.7248 83.6062L96.9998 15.3125C97.3029 12.5563 96.3936 9.95312 95.0295 8.42188Z"
              fill="#B0BDC6"
            />
            <path
              d="M38.9514 60.332L28.9482 45.0195L0.90918 74.5727C1.51543 75.9508 2.57637 76.8695 3.78887 77.1758L80.7826 91.8758C82.9045 92.3352 84.8748 91.5695 86.3904 90.1914L54.4107 46.5508L38.9514 60.332Z"
              fill="#CAD5DD"
            />
            <path
              d="M89.8768 6.12595L10.7611 11.4853C9.54863 11.6385 8.33613 12.4041 7.42676 13.6291L29.1002 45.0197L39.1033 60.3322L54.5627 46.551L95.0299 8.42283C93.6658 6.89158 91.8471 5.97283 89.8768 6.12595Z"
              fill="#DFE9EF"
            />
          </g>
          <defs>
            <clipPath id="clip0_11374_11987">
              <rect width="97" height="98" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Flex>
      <ModalDescription style={{ textAlign: 'center' }}>
        A password reset link has been sent to <br />
        <b>{email}</b> <br />
        To create your new password, click the link in the email and enter a new password.
      </ModalDescription>

      <ModalDescription style={{ textAlign: 'center', marginTop: '2rem' }}>
        Didn&apos;t receive the email? Check your junk mail, spam folder or{' '}
        <Span style={{ cursor: 'pointer' }} color={primaryColor} onClick={resendLink}>
          Click here to send another link.
        </Span>
      </ModalDescription>
      <Button onClick={() => history.push('/login')} type="submit" margin="2rem 0 0 0">
        Back to Login
      </Button>
    </FormModalContainer>
  );
};

export default LinkSent;
