import { SELECT_DATE } from "../actionTypes";
import { ActionProps } from "../../interfaces/actions.interface";
import { DateState } from "../../interfaces/state.interface";
import DateInitialState from "../initialStates/dateInitialState";

export default function datePickerFunctions(state = DateInitialState, action: ActionProps): DateState {
  switch (action.type) {
    case SELECT_DATE: {
      return {
        ...state,
        date: action.payload,
      };
    }

    default:
      return state;
  }
}
