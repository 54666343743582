import React, { FunctionComponent } from 'react';
import { Colors, FontSizes } from '../constants/theme';
import { Span, Text } from '../GlobalStyles/CustomizableGlobal.style';
import { InventoryPricingType } from '../interfaces/inventory.interface';
import showAmount from '../utils/priceFormat.utils';

interface Props {
  inventoryPrice: InventoryPricingType | null;
  packPrice?: number;
  unitPrice?: number;
  selectedInventoryType: string;
  customClass?: string;
}

const PriceFormatComponent: FunctionComponent<Props> = ({ inventoryPrice, customClass, packPrice, unitPrice, selectedInventoryType }) => {
  const { darkBlue, primaryColor } = Colors;
  const { bigFont, primaryFontSize } = FontSizes;
  const { sellingPrice, bargainPrice, discountPrice } = inventoryPrice || {};
  const isReduced = bargainPrice || discountPrice ? true : false;
  const price = selectedInventoryType === 'PACK' ? packPrice : unitPrice;

  return (
    <Text className={customClass} fontWeight={'700'} fontSize={bigFont} color={darkBlue} margin="0.3em 0">
      <Span
        textDecoration={isReduced ? 'line-through' : ''}
        textDecorationColor={primaryColor}
        fontSize={isReduced ? primaryFontSize : bigFont}
      >
        {showAmount(sellingPrice || price || 0)}
      </Span>
      {isReduced && <Span margin="0 0.5rem">{showAmount(inventoryPrice?.bargainPrice || inventoryPrice?.discountPrice || 0)}</Span>}
    </Text>
  );
};

export default PriceFormatComponent;
