import styled from 'styled-components';
import { media } from '../../../constants/theme';

const { mobile, smallScreenLaptop } = media;

interface ShopListProps {
  marginTop?: string;
}

export const ShopListWrapper = styled.div<ShopListProps>`
  padding: 0 3em;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0')};

  .filter-section {
    display: flex;
    align-items: center;
  }

  .clickable {
    cursor: pointer;
  }

  ${mobile} {
    padding: unset;
    margin: 40px 1rem;
  }
`;

export const ShopListHeadingSection = styled.div`
  display: flex;
  justify-content: space-evenly;

  ${mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

const ShopListContainer = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  max-width: 1600px;
  margin: 0 auto;
  grid-gap: 1rem;
  column-gap: 10px;
  ${smallScreenLaptop} {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .text-none {
    text-decoration: none;
  }

  .text-none {
    text-decoration: none;
  }
`;

export default ShopListContainer;
