import styled from 'styled-components';
import { Colors, media } from '../../constants/theme';

const { primaryGrey, lightBlue } = Colors;
const { mobile } = media;

export const Crumb = styled.p`
  color: ${lightBlue};
  margin: 3px 5px;
  text-transform: capitalize;
  cursor: pointer;
  text-align: center;

  &:last-of-type {
    color: ${primaryGrey};
    cursor: auto;
  }
`;
export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: #fcfcfc;
  width: fit-content;
  padding: 3px 5px;
  border-radius: 5px;
  font-size: 14px;

  .arrow {
    &:last-of-type {
      display: none;
    }
  }

  ${mobile} {
    justify-content: center;
    width: 100%;
  }
`;
