import { FIREREDIRECT } from "../actionTypes";
import { RedirectInitialState, redirectType } from "../initialStates/redirectToSearchResult";

interface redirectActionType {
  type: string;
  payload: boolean;
}

const RedirectSearchResultPage = (state: redirectType = RedirectInitialState, action: redirectActionType) => {
  switch (action.type) {
    case FIREREDIRECT:
      return {
        ...state,
        fireRedirect: action.payload,
      };

    default:
      return state;
  }
};

export default RedirectSearchResultPage;
