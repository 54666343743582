import React, { FunctionComponent, useEffect, useState } from 'react';
import { Flex } from '../../GlobalStyles/CustomizableGlobal.style';
import { DropDown } from '../AddressForm/style';
import ReactSelect from '../ReactSelect';
import { OptionType } from '../../interfaces/FilterSelect.interface';
import { useQuery } from '@apollo/client';
import { GET_CITIES_TYPES, GET_STATES_TYPES } from '../../interfaces/GetStateAndCity.interface';
import { GET_CITIES, GET_STATES } from '../../schema/GetStateAndCity.schema';
import Loader from '../LoaderComponent';
import { GET_DEFAULT_ADDRESS_TYPE } from '../../interfaces/address.interface';
import { GET_DEFAULT_ADDRESS } from '../../schema/member.schema';

interface LocationEntryProps {
  flexDirection?: string;
  padding?: string;
  selectedState?: OptionType;
  selectedCity?: OptionType;
  setSelectedState: React.Dispatch<React.SetStateAction<OptionType | undefined>>;
  setSelectedCity: React.Dispatch<React.SetStateAction<OptionType | undefined>>;
}

interface IStateCity {
  value: number;
  label: string;
}

const LocationEntry: FunctionComponent<LocationEntryProps> = ({
  flexDirection = 'column',
  padding = '32px',
  selectedState,
  setSelectedState,
  selectedCity,
  setSelectedCity
}) => {
  const [defaultState, setDefaultState] = useState<IStateCity>();
  const [defaultCity, setDefaultCity] = useState<IStateCity>();

  const { data, loading } = useQuery<GET_DEFAULT_ADDRESS_TYPE>(GET_DEFAULT_ADDRESS, {
    fetchPolicy: 'no-cache'
  });

  const handleStateChange = (event: any) => {
    const state = event;
    setSelectedState!(state);
  };

  const handleCityChange = (event: any) => {
    const city = event;
    setSelectedCity!(city);
  };

  const { data: statesData, loading: statesLoading } = useQuery<GET_STATES_TYPES>(GET_STATES);

  const stateOptions = statesData?.getStates.slice(1).map((state) => {
    return { value: state.stateId, label: state.stateName };
  });

  const {
    data: citiesData,
    loading: citiesLoading,
    error
  } = useQuery<GET_CITIES_TYPES>(GET_CITIES, {
    variables: {
      stateId: Number(selectedState?.value)
    }
  });

  const cityOptions = citiesData?.getCities.map((city) => {
    return { value: city.cityId, label: city.cityName };
  });

  useEffect(() => {
    if (data?.getDefaultAddress) {
      const address = data?.getDefaultAddress;
      setSelectedState({ value: String(address.State.stateId), label: address.State.stateName });
      setSelectedCity({ value: String(address.City.cityId), label: address.City.cityName });
    }
  }, [data]);

  const selectedSTateObject = stateOptions?.find((option) => Number(option.value) === Number(defaultState?.value));
  const selectedCityObject = stateOptions?.find((option) => Number(option.value) === Number(defaultState?.value));

  if (loading) return <Loader />;

  return (
    <Flex direction={flexDirection} style={{ padding, textAlign: 'center', width: '100%', gap: '1rem' }}>
      <DropDown>
        <ReactSelect
          defaultValue={selectedSTateObject}
          value={selectedState}
          onChange={handleStateChange}
          options={stateOptions}
          placeholder={statesLoading ? 'Loading States' : 'Select State'}
          isClearable={true}
        />
      </DropDown>

      <DropDown>
        <ReactSelect
          defaultValue={selectedCityObject}
          value={selectedCity}
          onChange={handleCityChange}
          options={cityOptions}
          placeholder={citiesLoading ? 'Loading Cities' : 'Select City'}
          isClearable={true}
        />
      </DropDown>
    </Flex>
  );
};

export default LocationEntry;
