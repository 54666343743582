import styled from 'styled-components';
import { Colors, media } from '../../../constants/theme';

const { tablet, mobile, smallScreenLaptop, miniMobile } = media;
const { primaryColor } = Colors;

export const RandomProductsContents = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  grid-gap: 15px;
  max-width: 1600px;
  background: #fafbfd;
  margin: 0 auto;
  padding: 20px 15px;
  border-radius: 10px;

  .text-none {
    text-decoration: none;
  }

  ${smallScreenLaptop} {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  ${mobile} {
    grid-template-columns: auto auto;
  }

  ${miniMobile} {
    grid-gap: unset;
  }
`;

export const RandomProductsWrapper = styled.div`
  border-radius: 10px;
  width: 95%;
  background: transparent;
  margin: 30px auto;
  border-radius: 10px;

  .banner-container {
    margin: 0 auto;
    width: calc(100% - 1rem);
    padding: 0;
    max-width: 1600px;
  }

  #load-more {
    margin: 0 auto;
  }

  ${tablet} {
    margin: 2rem;

    .path-text {
      font-size: 0.6em;
    }
  }

  ${tablet} {
    margin: 2rem 0.5rem;
    width: calc(100% - 1rem);

    .hide-mobile {
      display: none;
    }
  }
`;
export const SectionWrapper = styled.div`
  background: #fafbfd;
`;

export const Header = styled.div`
  color: #607087;
  background: transparent;
  max-width: 1600px;
  margin: 0 auto;
  font-size: 18px;
  display: flex;
  padding: 0 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  justify-content: space-between;
  align-items: center;

  p {
    background: #fafbfd;
    padding: 15px;
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    margin-left: 20px;
    border-radius: 12px 12px 0px 0px;
    display: flex;
    color: #000;
    font-weight: 500;
    align-items: center;
    column-gap: 15px;

    ${mobile} {
      padding: 0.5rem;
    }
  }

  #location {
    padding: 5px 10px;
    color: #788290;
    font-size: 12px;
    background: transparent;
    display: inline-flex;
    column-gap: 12px;
    align-items: center;
  }

  img {
    margin-left: 10px;
  }

  ${mobile} {
    font-size: 16px;

    img {
      margin-left: 0;
    }
  }
`;
export const Info = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 0;
  max-width: 1600px;

  .banner-container,
  .image-banner,
  #school {
    padding: 15px 35px;
    border-radius: 12px;
    justify-content: space-between;
    height: 280px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    ${tablet} {
      height: 230px;
    }

    ${mobile} {
      height: 150px;
      padding: 1rem;
    }
  }

  .banner-container {
    width: 100%;
    color: white;
    background: transparent;
    position: relative;
    overflow: unset;

    ${mobile} {
      padding: 1rem;
    }
  }

  .image-banner {
    width: 100%;
    color: white;
    background: transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: unset;
    z-index: 5;

    img {
      position: absolute;
      right: 220px;
      bottom: 0;

      ${smallScreenLaptop} {
        right: 7rem;
      }

      ${tablet} {
        width: 150px;
      }
    }
  }

  #school {
    width: 100%;
    color: white;
    background: ${primaryColor};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;

    ${mobile} {
      flex-flow: row wrap;
    }

    ::before {
      content: '';
      display: block;
      position: absolute;
      height: 450px;
      width: 600px;
      top: -30px;
      right: 40px;
      background: black;
      border-radius: 50%;

      ${smallScreenLaptop} {
        right: -55px;
      }

      ${tablet} {
        height: 250px;
        width: 400px;
        z-index: 0;
        right: -140px;
        bottom: 0;
      }
    }

    .school-bag {
      position: absolute;
      right: 650px;
      z-index: 4;

      ${smallScreenLaptop} {
        right: unset;
        left: 40%;
        bottom: 0;
      }

      ${tablet} {
        width: 120px;
        left: 50%;
      }
    }

    .lunch-box {
      position: absolute;
      right: 480px;

      ${smallScreenLaptop} {
        right: unset;
        left: 50%;
        bottom: 0;
      }

      ${tablet} {
        width: 100px;
        left: 55%;
        bottom: unset;
        top: 2rem;
      }
    }
    a {
      z-index: 7;
      background: ${primaryColor};
      color: #fff;
      padding: 14px 25px;
      border-radius: 10px;
      display: inline-block;
      margin-right: 30px;

      ${smallScreenLaptop} {
        z-index: 7;
      }

      ${tablet} {
        font-size: 1rem;
        padding: 0.75rem 0.5rem;
      }

      ${mobile} {
        position: absolute;
        right: 0;
        margin: 0.5rem;
        font-size: 0.75rem;
        padding: 0.5rem;
      }
    }
  }
`;
export const Left = styled.div`
  width: 70%;
  padding-bottom: 60px;

  ${tablet} {
    z-index: 1;
  }

  h1 {
    width: 50%;
    min-width: 380px;
    max-width: 450px;
    font-family: 'Poppins', sans-serif;
    font-size: 35px;

    span {
      color: black;
    }
  }
  p {
    width: 40%;
    min-width: 300px;
    max-width: 320px;
    font-family: 'Poppins', sans-serif;
    margin-top: 10px;
  }

  ${tablet} {
    h1 {
      font-size: 1.5rem;
    }
  }

  ${mobile} {
    width: 100%;
    z-index: 9;

    h1 {
      min-width: unset;
      width: 50%;
      font-size: 0.75rem;
      margin: 0;
    }

    p {
      min-width: unset;
      width: 100%;
      font-size: 0.75rem;
    }

    #desc-text {
      width: 50%;
    }
  }
`;
