import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import TagManager from 'react-gtm-module';
import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import configureStore from './store/rootStore';
import history from './utils/history.utils';
import client from './utils/graphql.utils';
import { HelmetProvider } from 'react-helmet-async';
import { LocationProvider } from './hooks/locationContext';

const googleTagTrackingId = 'GTM-R1D786GY5E';
const tagManagerArgs = {
  gtmId: googleTagTrackingId
};

TagManager.initialize(tagManagerArgs);

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <LocationProvider>
            <Router history={history as any}>
              <App />
            </Router>
          </LocationProvider>
        </Provider>
      </ApolloProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
