import styled from 'styled-components';
import { media, Colors, FontSizes } from '../../../constants/theme';

const { mobile, tablet, smallScreenLaptop } = media;
const { white, primaryColor, black } = Colors;
const { bigFont, primaryFontSize, secondaryFontSize } = FontSizes;

export const Container = styled.div`
  margin: 0 auto;
  width: calc(100% - 1rem);
  padding: 0;
  max-width: 1600px;

  h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 32px;
    font-weight: 500;
  }
`;
export const Info = styled.div`
  width: 95%;
  color: white;
  background: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 280px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  padding: 15px 25px;
  border-radius: 12px;
  margin: 0 auto;

  ::before {
    content: '';
    display: block;
    position: absolute;
    height: 550px;
    width: 550px;
    top: 0px;
    right: -250px;
    background: ${primaryColor};
    border-radius: 50%;
    z-index: 5;

    ${mobile} {
      display: none;
    }
  }

  a {
    z-index: 2;
    background: #083e88;
    color: #fff;
    padding: 14px 25px;
    border-radius: 10px;
  }

  img {
    position: absolute;
    left: 0px;
    top: 0;
    bottom: 0;
    width: 1000px;
    z-index: 1;
  }

  span {
    position: absolute;
    height: 500px;
    width: 500px;
    top: -60px;
    right: 300px;
    background: ${black};
    border-radius: 50%;
    z-index: 4;

    ${smallScreenLaptop} {
      right: 0;
    }
  }

  ${mobile} {
    padding: 1rem;
    height: 150px;
    display: flex;
    justify-content: end;

    span {
      width: 300px;
      height: 300px;
      right: -5rem;
    }

    img {
      left: -20rem;
    }
  }
`;
export const Right = styled.div`
  width: 50%;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  z-index: 5;

  h1 {
    width: 90%;
    max-width: 90%;
    font-size: 2.5rem;
    line-height: 2.5rem;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    span {
      color: ${primaryColor};
    }
  }
  p {
    width: 90%;
    min-width: 300px;
    max-width: 90%;
    font-size: 1.2rem;
    line-height: 2rem;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    span {
      color: ${primaryColor};
    }
  }

  .button {
    background-color: ${primaryColor};
    width: fit-content;
  }

  ${tablet} {
    position: inherit;

    h1 {
      width: 100%;
      font-size: 1.5rem;
      max-width: unset;
      line-height: 1.75rem;
    }

    p {
      width: 100%;
      font-size: 0.8rem;
      min-width: unset;
      max-width: unset;
      line-height: 1.5rem;
    }

    ${mobile} {
      h1 {
        font-size: 1rem;
        line-height: unset;
      }

      p {
        font-size: 0.5rem;
        line-height: unset;
      }

      .button {
        padding: 0.5rem;
        font-size: 0.75rem;
      }
    }
  }
`;

export const CategoryContainer = styled.div`
  width: 100%;
  display: flex;
  column-gap: 10px;
  overflow-x: scroll;
`;
export const CategoryCard = styled.div`
  height: 150px;
  width: calc(25% - 5px);
  max-width: 350px;
  min-width: 200px;
  position: relative;
  overflow: hidden;
  border-radius: 12px;

  img:first-of-type {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }

  img:last-of-type {
    position: absolute;
    height: 120%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  div {
    width: 100%;
    height: 25px;
    padding: 0 10px;
    position: absolute;
    bottom: 5px;
    left: 0;
    box-sizing: border-box;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Montserrat', sans-serif;

    p {
      font-size: 18px;
      font-weight: 500;
      color: #fff;
    }

    span {
      font-size: 12px;
      color: #fff;
    }
  }
`;
