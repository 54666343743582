import { NEXT_CHECKOUT_OPTION } from "../actionTypes";
import { ActionProps } from "../../interfaces/actions.interface";
import { CheckoutState } from "../../interfaces/state.interface";
import CheckoutInitialState from "../initialStates/checkoutState";

export default function checkoutFunctions(state = CheckoutInitialState, action: ActionProps): CheckoutState {
  switch (action.type) {
    case NEXT_CHECKOUT_OPTION: {
      return {
        ...state,
        checkoutMode: action.payload,
      };
    }

    default:
      return state;
  }
}
