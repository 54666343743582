import React, { FunctionComponent, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { H1 } from './SearchList.style';
import ShopCard from '../../../components/ShopCard';
import ProductCard from '../../../components/ProductCard';
import Error from '../../../components/ErrorComponent';
import Loader from '../../../components/LoaderComponent';
import Pagination from '../../../components/pagination';
import getProductPrice from '../../../interfaces/GetProductPrice.interface';
import getGeoLocationPosition from '../../../utils/queryLocation.utils';
import { limit } from '../../../constants/limit.constant';
import { Inventory, SEARCH_INVENTORY_TYPE } from '../../../interfaces/inventory.interface';
import { CardListContainer, CategoryText, MainContainer } from '../../../GlobalStyles/FixedGlobal.style';
import { Colors, FontSizes, media } from '../../../constants/theme';
import { GET_SHOP_CATEGORIES_TYPE } from '../../../interfaces/ShopCategories.interface';
import { Span } from '../../../GlobalStyles/CustomizableGlobal.style';
import { useQuery } from '../../../utils/customRoute.utils';

interface Props {
  searchCategory: string | null;
  page: string;
  totalItems: number | undefined;
  error: ApolloError | undefined;
  loading: boolean;
  searchItem: { search: string; cat: string; selectedState: string | undefined; selectedCity: string | undefined };
  inventorySearchResult: Inventory[] | undefined;
  shops?: SEARCH_INVENTORY_TYPE['searchInventoryOrShop']['shops'] | undefined;
}

const SearchList: FunctionComponent<Props> = ({ inventorySearchResult, error, shops, loading, page, searchItem, totalItems }) => {
  const currentPage = Number(page) || 1;
  const { primaryColor, lightBlue, lightbg, hoverPrimary } = Colors;
  const lastIndex = currentPage * limit;
  const firstIndex = lastIndex - limit + 1;
  const { mobile } = media;
  const query = useQuery();

  const customSelectStyles = {
    container: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      [mobile]: {
        width: '100%'
      }
    }),
    indicatorsContainer: () => ({
      borderRadius: '0px 5px 5px 0px',
      cursor: 'pointer',
      paddingLeft: '3px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }),
    valueContainer: () => ({
      width: '8rem',
      margin: '0 0.5em'
    }),
    control: (provided: Record<string, unknown>, { isFocused }: any) => ({
      ...provided,
      borderColor: lightBlue,
      background: lightbg,
      boxShadow: 'none',
      '&:hover': {
        borderColor: lightBlue
      }
    }),
    placeholder: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      color: lightBlue
    }),
    singleValue: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      color: lightBlue
    }),
    indicatorSeparator: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      display: 'none'
    }),
    option: (provided: Record<string, unknown>, { isFocused }: any) => ({
      ...provided,
      backgroundColor: isFocused ? hoverPrimary : 'inherit',
      color: isFocused ? primaryColor : lightBlue
    })
  };

  const customShorterSelect = {
    ...customSelectStyles,
    valueContainer: () => ({
      width: '6rem',
      margin: '0 0.5em'
    })
  };

  const sortOptions = [{ value: '', label: 'By Location' }];
  const arrangeOptions = [
    { value: 0, label: 'High to Low' },
    { value: 1, label: 'Low to High' }
  ];

  const [selectedSort, setSelectedSort] = useState();
  const [arrangeSort, setArrangeSort] = useState(arrangeOptions[0]);

  const handleSortChange = (event: any) => {
    setSelectedSort(event);
  };

  const handleArrangeSortChange = (event: any) => {
    setArrangeSort(event);
  };

  const handlePriceRange = () => {
    console.log();
  };

  const { selectedState, selectedCity } = searchItem;

  if (inventorySearchResult && inventorySearchResult.length < 1)
    return (
      <MainContainer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <H1>
          Your search <Span textTransform="lowercase">{searchItem.search || searchItem.cat}</Span> did not match any product, category or
          shop {selectedState ? 'in' : ''} {selectedState && <Span textTransform="capitalize">{selectedState}</Span>}
          {selectedCity && <Span textTransform="capitalize">, {selectedCity}</Span>}
        </H1>
      </MainContainer>
    );
  return (
    <MainContainer padding="0 0 1em 0">
      {/* <FilterContainer>
        <Flex justifyContent={'space-between'}>
          <Text color={blackLight} fontSize={secondaryFontSize} margin={'0'}>
            Search Results-{' '}
            <Span color={primaryColor}>
              <i>{searchItem}</i>
            </Span>
          </Text>
          <Text color={lightBlue}>
            {firstIndex}-{lastIndex} of {inventorySearchResult?.totalInventoriesAndShops || 0} results
          </Text>
        </Flex>
        <Flex justifyContent="flex-start" className="top-filters-container">
          <Select
            value={selectedSort}
            onChange={handleSortChange}
            styles={customSelectStyles}
            options={sortOptions}
            placeholder={'Select Sort'}
            className="select"
            components={{ DropdownIndicator }}
          />
          <Flex margin="0 1em" className="price-range">
            <Text color={lightBlue} margin="0 0.5em">
              Price
            </Text>
            <Input color={lightBlue} borderColor={lightBlue} borderRadius={'3px'} width={'61px'} padding="2px 5px" />
            <Text color={lightBlue} margin="0 0.5em">
              -
            </Text>
            <Input color={lightBlue} borderColor={lightBlue} borderRadius={'3px'} width={'61px'} padding="2px 5px" />
            <Button height="35px" borderRadius="3px" margin="0 1em" onClick={handlePriceRange} className={'apply-button'}>
              Apply
            </Button>
          </Flex>
        </Flex>
        <Flex justifyContent="flex-end" margin="1em 0">
          <Text color={lightBlue} margin="0 0.5em">
            Sort By:
          </Text>
          <Select
            value={arrangeSort}
            onChange={handleArrangeSortChange}
            styles={customShorterSelect}
            options={arrangeOptions}
            placeholder={'Select Sort'}
            className="select"
            components={{ DropdownIndicator }}
          />
        </Flex>
      </FilterContainer> */}
      {query.get('category') ? (
        <CategoryText>
          All categories &gt; <span>"{query.get('category')?.toLocaleLowerCase()}"</span>
        </CategoryText>
      ) : null}
      <CardListContainer>
        {shops?.map((shop) => {
          const { shopId, shopName, shopAddress, Images, distance, ShopURL } = shop;
          return (
            <div key={shopId}>
              <ShopCard
                peek
                image={Images?.[0]?.mediumImageOnlineURL ?? ''}
                shopId={shopId}
                name={shopName}
                location={shopAddress}
                distance={distance}
                shopTag={ShopURL?.shopTag as string}
              />
            </div>
          );
        })}

        {inventorySearchResult?.map((product) => {
          const { inventoryId, inventoryType, Variations } = product;
          const variationId = Variations.length && Variations?.[0].variationId;

          return (
            <div key={product.inventoryId}>
              <ProductCard
                key={inventoryId}
                price={getProductPrice(product, inventoryType || 'NON_TRACKABLE', variationId)}
                inventory={product}
              />
            </div>
          );
        })}
      </CardListContainer>
      {loading && <Loader />}
      {error && <Error message={error.message} />}
      <Pagination paramType={'SEARCH'} currentPage={currentPage} totalItems={totalItems || 0} limit={limit} />
    </MainContainer>
  );
};

export default SearchList;
