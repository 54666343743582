import React, { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { Button, ErrorMessage } from '../../GlobalStyles/forms.style';
import { Required } from '../../utils/formValidations.utils';
import { Colors } from '../../constants/theme';
import { Flex, Title, Text, Input, FormContainer, InputBox, AccSettingRWrapper, AccSettingLWrapper, Hr, Container } from './account.style';
import { isloading, hasfailed, resetFetch } from '../../store/actions/fetchAction';
import { GET_MEMBER_TYPE } from '../../interfaces/GetMember.interface';
import { useDispatch } from 'react-redux';

const UPDATE_MEMBER = gql`
  mutation updateMember($fullName: String!, $state: String, $city: String, $mobileNumber: String) {
    updateMember(fullName: $fullName, state: $state, city: $city, mobileNumber: $mobileNumber)
  }
`;

interface Props {
  isloading: Function;
  hasfailed: Function;
  resetFetch: Function;
  data?: GET_MEMBER_TYPE;
}

const AccountSettings: FunctionComponent<Props> = ({ isloading, hasfailed, resetFetch, data }) => {
  const { register, handleSubmit, errors } = useForm();
  const [showSuccessMessage, setShowSuccessMessage] = useState<string>('');
  const member = data?.getMember;
  const dispatch = useDispatch();

  const [updateMember] = useMutation<any>(UPDATE_MEMBER, {
    onCompleted() {
      setShowSuccessMessage('Account info updated successfully');
      dispatch(resetFetch());
    }
  });

  const firstName = member?.fullName.split(' ')[0];
  const lastName = member?.fullName.split(' ')[1];
  const mobile = member?.mobileNumber;

  const onSubmit = async (data: any) => {
    const { firstName, state, city, mobile, lastName } = data;
    dispatch(isloading());
    try {
      await updateMember({
        variables: {
          fullName: `${firstName + ' ' + lastName}`,
          state: `${state}`,
          city: `${city}`,
          mobileNumber: `${mobile}`
        }
      });
    } catch (e) {
      dispatch(hasfailed(e instanceof Error ? `${e}` : 'An unknown error has occured'));
    }
  };

  return (
    <Container>
      <Title subtitle>Account Information</Title>
      <Hr />
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <div className="outerDiv">
          <div className="innerDiv">
            <p className="p">First Name</p>
            <InputBox style={{ borderColor: errors.firstName && '#F75151' }}>
              <Input defaultValue={firstName} name="firstName" ref={register(Required)} type="text" placeholder="First Name" />
              {errors.firstName && <ErrorMessage>This field is required</ErrorMessage>}
            </InputBox>
          </div>
          <div className="innerDiv">
            <p className="p">Last Name</p>
            <InputBox style={{ borderColor: errors.lastName && '#F75151' }}>
              <Input defaultValue={lastName} name="lastName" ref={register(Required)} type="text" placeholder="Last Name" />
              {errors.lastName && <ErrorMessage>This field is required</ErrorMessage>}
            </InputBox>
          </div>
        </div>

        <div className="outerDiv">
          <div className="innerDiv">
            <p className="p">Email</p>
            <InputBox>
              <Input defaultValue={member?.email} name="email" type="text" placeholder="Email" disabled />
            </InputBox>
          </div>
          <div className="innerDiv">
            <p className="p">Mobile</p>
            <InputBox style={{ borderColor: errors.mobile && '#F75151' }}>
              <Input defaultValue={mobile} name="mobile" ref={register(Required)} type="tel" placeholder="Mobile" />
              {errors.mobile && <ErrorMessage>This field is required</ErrorMessage>}
            </InputBox>
          </div>
        </div>

        <div style={{ margin: '10px 0' }}>
          <ErrorMessage>{showSuccessMessage}</ErrorMessage>
        </div>

        <Flex alignItems="center" justifyContent="center">
          <Button>Update</Button>
        </Flex>
      </FormContainer>
    </Container>
  );
};

export default connect(null, { isloading, resetFetch, hasfailed })(AccountSettings);
