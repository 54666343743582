import { SELECT_SHOP, CURRENT_SHOP_ID, selectModuleType } from "../actionTypes";
import { ShopInitialState, shopType } from "../initialStates/selectShop";

const selectActiveShop = (state: shopType = ShopInitialState, action: selectModuleType) => {
  switch (action.type) {
    case SELECT_SHOP:
      return {
        ...state,
        activeShopId: action.payload,
      };

    case CURRENT_SHOP_ID:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default selectActiveShop;
