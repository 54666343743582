import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { media } from '../../constants/theme';
import DefaultProductImage from '../../img/timart-image-load.svg';

interface Props {
  src: string;
  alt: string;
  className?: string;
  isSquare?: boolean;
  size?: number;
}

interface ImgProps {
  isSquare?: boolean;
  size?: number;
}

const { mobile } = media;

const Img = styled.img<ImgProps>`
  ${({ isSquare, size }) =>
    isSquare
      ? `
    width: ${size || '100%'};
  `
      : ''};
`;

const Image: FunctionComponent<Props> = ({ src, alt, className, isSquare, size }) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>();
  const imageStyle = !imageLoaded ? { display: 'none' } : {};
  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  return (
    <>
      {!imageLoaded && <Img isSquare={isSquare} size={size} className={className} src={DefaultProductImage} alt="placeholder image" />}
      <Img isSquare={isSquare} size={size} className={className} src={src} style={imageStyle} alt={alt} onLoad={handleImageLoaded} />
    </>
  );
};

export default Image;
