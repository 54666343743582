import styled from 'styled-components';
import { Colors, media, FontSizes } from '../../constants/theme';

interface Props {
  location?: boolean;
  peek?: boolean;
}

const { primaryColor, white, primaryGrey, secondaryColor } = Colors;
const { mobile } = media;
const { descriptionFontSize, primaryFontSize } = FontSizes;

export const ShopCardContainer = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column;
  background-color: ${white};
  border-radius: 10px;
  max-width: 240px;
  text-decoration: none;
  cursor: pointer !important;
  outline: none;
  width: 210px;
  justify-content: space-around;

  ${({ peek }: Props) =>
    peek &&
    `
        width: 200px;
        height: 260px;
        margin: 10px;
        cursor: pointer;
        :hover {
          box-shadow: 0px 4px 20px rgba(24, 88, 177, 0.12);
        }
    `}
  ${mobile} {
    margin: 0 auto;
  }
`;

export const CardContent = styled.div`
  display: flex;
  align-self: center;
  flex-flow: column;
  padding: 10px;
  width: calc(100% - 30px);
`;

export const ShopImage = styled.img`
  width: 100%;
  height: 50%;
  object-fit: cover;

  ${mobile} {
    width: 50%;
    height: 100%;
  }
`;

export const ShopNameContainer = styled.span`
  display: inline-block;

  &[data-title]:hover {
    position: relative;
  }

  &[data-title]:hover:before {
    position: absolute;
    top: auto;
    bottom: 100%;
    z-index: 99999;
    content: attr(data-title);
    margin-top: 0;
    margin-bottom: 0.2em;
    padding: 0.5em;
    width: 180px;
    overflow: hidden;
    word-wrap: break-word;
    font-size: ${descriptionFontSize};
    color: ${white};
    text-align: center;
    background-color: ${primaryGrey};
    box-sizing: border-box;
    border-radius: 10px;
    border: solid 1px ${white};
  }
`;

export const ShopName = styled.h2`
  color: ${primaryGrey};
  font-size: ${primaryFontSize};
  font-weight: 400;
  margin-bottom: 5px;
  margin: 0;
  width: 21ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;

  ${({ peek }: Props) =>
    peek &&
    `
        margin-bottom: 10px;
    `}

  ${mobile} {
    font-size: 14px;
    font-weight: 700;
  }
`;

export const ShopDescription = styled.p`
  color: ${primaryGrey};
  font-size: 10px;
  display: flex;
  margin: 5px 0;
  text-decoration: none;

  ${({ location }: Props) =>
    location &&
    `
        color: ${primaryColor};
        margin: 10px;
  `}

  ${({ peek, location }: Props) =>
    location &&
    peek &&
    `
        margin: 10px 0;
        color: ${secondaryColor};
        line-height: 11px;
  `}

    i {
    margin: 0 3px;
  }

  ${mobile} {
    line-height: 9px;
  }
`;

export const Description = styled.div``;

export const LowerContent = styled.div`
  display: flex;
  flex-flow: column;

  ${({ peek }: Props) =>
    peek &&
    `
        flex-flow: column-reverse;
    `}
`;
