import styled from 'styled-components';
import { Colors, media } from '../../constants/theme';

const { mobile } = media;
const { primaryColor } = Colors;

export const OrderCompletionDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-self: center;
  width: 50%;

  ${mobile} {
    width: 100%;
  }
`;

export const OrderCompletionDetails = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const OrderDetail = styled.div`
  display: flex;
  flex-flow: column;
`;

export const RightOrderDetails = styled.div`
  display: flex;
  background-color: #f2f8fe;
  padding: 0 20px;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  flex-flow: row;
  width: 100%;

  ${mobile} {
    padding: 0 10px;
  }
`;

export const DetailName = styled.h5`
  font-size: 16px;
  width: fit-content;
  margin-bottom: 0;
  margin-top: 15px;
`;

export const OptionDescription = styled.p`
  font-size: 16px;
`;

export const CompleteOrderButton = styled.p`
  padding: 10px;
  border-radius: 5px;
  background: ${primaryColor};
  color: white;
  width: 45%;
  border: none;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    background: #ff5e00;
  }

  ${mobile} {
    border-radius: 5px;
    width: 90%;
    font-size: 14px;
    margin: 0px;
    margin-top: 10px;
  }
`;
export const CompleteOrderButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mobile} {
    flex-direction: column;
  }
`;
